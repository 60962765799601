import { deepClone } from "@/utils/";
import {
  addView,
  viewDetail,
  editView,
  deleteView,
  listViews,
} from "@/api/system/views.js";
import {
  formDetail,
  formAuditAuthDetail,
  httpGetFormFieldV2,
  formAuditAuthFieldDetail,
} from "@/api/system/form";
export default {
  data() {
    return {
      specalViewNames: [
        // "一级审核视图",
        // "二级审核视图",
        // "三级审核视图",
        "管理视图",
        "总管理员视图",
      ],
    };
  },
  methods: {
    httpFormReleaseAndCreateView(formId) {
      this.httpFormRelease().then(() => {
        this.onCreateView(formId);
      });
    },

    // 创建视图
    onCreateView(formId) {
      const setInfo = this.formDetail;

      const saveView = (viewConfig = {}) => {
        viewConfig.formId = formId;

        let config = deepClone(viewConfig);
        return addView(config);
      };

      return new Promise((resolve, reject) => {
        const saveViewList = [
          // saveView({
          //   actions: "A1,A2,A3,A5,E1,E2",
          //   filters: [
          //     //
          //     {
          //       key: "name",
          //       operator: "is",
          //       type: "sysField",
          //       value: "userId",
          //       widgetType: "name",
          //       valueType: "sysField",
          //     },
          //   ],
          //   name: "已填数据",
          //   style: { lineHeight: "medium" },
          //   sortList: [{
          //     "field": "createTime",
          //     "order": "desc",
          //     "fieldType": "sysField",
          //     "direction": -1
          //   }],
          //   viewRange: 3,
          //   showFields: formFields(false, [(x) => x.hidden == false], true), //显示全部非隐藏字段
          //   showColumns: [
          //     ...formFields(true, [(x, i) => x.hidden == false, (x, i) => i < 10]),
          //     ...sysFields(),
          //   ], //显示前10个非隐藏字段+全部系统字段
          //   sort: 1
          // }),
          // saveView({
          //   actions: [
          //     "A2",
          //     "A3",
          //     "A4",
          //     "C1",
          //     "C2",
          //     "C3",
          //     "C4",
          //     "C5",
          //     "D1",
          //     "E1",
          //     "E2",
          //     "E3",
          //     "E4",
          //     "F1",
          //     "F2",
          //   ].join(","),
          //   filters: [],
          //   name: "总管理列表",
          //   style: { lineHeight: "medium" },
          //   sortList: [
          //     {
          //       field: "createTime",
          //       order: "desc",
          //       fieldType: "sysField",
          //       direction: -1,
          //     },
          //   ],
          //   viewRange: 6,
          //   showFields: this.formFields(false, [], true, false),
          //   showColumns: [
          //     ...this.formFields(true, [], true, false),
          //     ...this.sysFields(setInfo),
          //   ],
          //   sort: 4,
          // }),
        ];
        // if (setInfo.auditAuth) {
        // saveViewList.push(
        //   saveView({
        //     actions: ["A2", "B2", "B1", "E3", "E4"].join(","),
        //     filters:[
        //       {
        //         "valueType": "sysField",
        //         "type": "sysField",
        //         "value": "agencyUserId",
        //         "key": "audit",
        //         "operator": "is",
        //         "widgetType": "audit"
        //       }
        //     ],
        //     name: "待审核",
        //     style: { lineHeight: "medium" },
        //     sortList: [{
        //       "field": "createTime",
        //       "order": "desc",
        //       "fieldType": "sysField",
        //       "direction": -1
        //     }],
        //     viewRange: 4,
        //     showFields: formFields(false, [(x) => x.hidden == false], true), //显示全部非隐藏字段
        //     showColumns: [
        //       ...formFields(true, [(x, i) => x.hidden == false, (x, i) => i < 10]),
        //       ...sysFields(),
        //     ], //显示前10个非隐藏字段+全部系统字段
        //     sort: 2
        //   })
        // );
        // saveViewList.push(
        //   saveView({
        //     actions: ["A2","B1", "E3", "E4"].join(","),
        //     filters:[
        //       {
        //         "valueType": "sysField",
        //         "type": "sysField",
        //         "value": "agencyUserId",
        //         "key": "auditOperator",
        //         "operator": "is",
        //         "widgetType": "audit"
        //       }
        //     ],
        //     name: "已审核",
        //     style: { lineHeight: "medium" },
        //     sortList: [{
        //       "field": "createTime",
        //       "order": "desc",
        //       "fieldType": "sysField",
        //       "direction": -1
        //     }],
        //     viewRange: 4,
        //     showFields: formFields(false, [(x) => x.hidden == false], true), //显示全部非隐藏字段
        //     showColumns: [
        //       ...formFields(true, [(x, i) => x.hidden == false, (x, i) => i < 10]),
        //       ...sysFields(),
        //     ], //显示前10个非隐藏字段+全部系统字段
        //     sort: 3
        //   })
        // );
        // }

        // if (setInfo.viewAuth) {
        // saveViewList.push(
        //   saveView({
        //     actions: [
        //       "A2",
        //       "A4",
        //       "C1",
        //       "C2",
        //       "C3",
        //       "C4",
        //       "C5",
        //       "E4",
        //     ].join(","),
        //     filters: [
        //       {
        //         "valueType": "sysField",
        //         "type": "sysField",
        //         "value": "agencyUserId",
        //         "key": "viewAuthAgencyUserId",
        //         "operator": "is",
        //         "widgetType": "viewAuthAgencyUserId"
        //       }
        //     ],
        //     name: "数据管理",
        //     style: { lineHeight: "medium" },
        //     sortList: [{
        //       "field": "createTime",
        //       "order": "desc",
        //       "fieldType": "sysField",
        //       "direction": -1
        //     }],
        //     viewRange: 5,
        //     showFields: formFields(),
        //     showColumns: [...formFields(true), ...sysFields()],
        //     sort: 3
        //   })
        // );
        // }

        // Promise.all(saveViewList).then((res) => {
          console.log("执行完成", res);
          resolve();
        // });
      });
    },

    // 根据表单配置修改对应的视图
    setViewList(formId) {
      return new Promise(async (resolve, reject) => {
        // 获取表单所有视图
        let [listViewsRes, formDetailRes, formFieldRes, formAuditAuthRes, formAuditAuthFieldRes] =
          await Promise.all([
            listViews(formId),
            formDetail(formId),
            httpGetFormFieldV2(formId),
            formAuditAuthDetail(formId),
            formAuditAuthFieldDetail(formId)
          ]);

        console.log(
          "viewList",
          listViewsRes,
          formDetailRes,
          formFieldRes,
          formAuditAuthRes
        );
        let viewList = listViewsRes.data.filter((x) =>
          this.specalViewNames.includes(x.name)
        );

        let promiseList = [];

        let formDetailResData = formDetailRes.data;
        let formAuditAuthResData = formAuditAuthRes.data;
        let formFieldResData = formFieldRes.data;
        let formAuditAuthFieldResData = formAuditAuthFieldRes.data;

        // if (formDetailResData.auditAuth) {
        //   for (let i = 0; i < 3; i++) {
        //     if (
        //       formAuditAuthResData.auditInfos.find(
        //         (x) => x.auditLevel === i + 1
        //       )
        //     ) {
        //       // 表单定义有一级审核， 判断是否有 一级审核视图
        //       // let view = viewList.find(
        //       //   (x) => x.name == this.specalViewNames[i]
        //       // );
        //       this.getViewEditPromise(
        //         promiseList,
        //         formDetailResData,
        //         formFieldResData,
        //         formAuditAuthFieldResData,
        //         viewList,
        //         this.specalViewNames[i],
        //         [
        //           {
        //             key: "auditors_"+(i+1),
        //             operator: "in",
        //             type: "sysField",
        //             value: "agencyUserId",
        //             widgetType: "auditors_"+(i+1),
        //             filterOptions: null,
        //             valueType: "sysField",
        //           },
        //           {
        //             key: "status",
        //             operator: "in",
        //             type: "sysField",
        //             value:
        //               i == 0
        //                 ? "2,4,5,6"
        //                 : i == 1
        //                 ? "6,8,9,10"
        //                 : i == 2
        //                 ? "10,12,13"
        //                 : "14",
        //             widgetType: "status",
        //             valueType: "sysField",
        //             filterOptions: null,
        //           },
        //         ],
        //         "audit:" + i, //viewType
        //         10 + i + 1, //sort
        //         4, //viewRange
        //         "B1,B2,A2" // actions
        //       );
        //     } else {
        //       // 没有审核， 删除一级审核视图
        //       let removeView = viewList.find(
        //         (x) => x.name == this.specalViewNames[i]
        //       );
        //       if (removeView) {
        //         promiseList.push(deleteView(removeView.id));
        //       }
        //     }
        //   }
        // }

        if (formDetailResData.viewAuth) {
          this.getViewEditPromise(
            promiseList,
            formDetailResData,
            formFieldResData,
            formAuditAuthFieldResData,
            viewList,
            "管理视图",
            [
              {
                  "key": "viewAuthAgencyUserId",
                  "operator": "in",
                  "type": "sysField",
                  "value": "agencyUserId",
                  "widgetType": "viewAuthAgencyUserId",
                  "filterOptions": null,
                  "valueType": "sysField"
              }
          ],
            "manage", //viewType
            20, //sort
            5, //viewRange
            "A2,C1,C2,C3,C4,C5" // actions
          );
        } else {
          // 没有审核， 删除一级审核视图
          let removeView = viewList.find((x) => x.name == "管理视图");
          if (removeView) {
            promiseList.push(deleteView(removeView.id));
          }
        }

        this.getViewEditPromise(
          promiseList,
          formDetailResData,
          formFieldResData,
          formAuditAuthFieldResData,
          viewList,
          "总管理员视图",
          [],
          "manage", //viewType
          100, //sort
          6, //viewRange
          "A2,A4,C1,C2,C3,C4,C5,D1,E1,E2,E3,E4,F1,F2,A7,A6,A8" // actions
        );
        Promise.all(promiseList).then((res) => {
          resolve();
        });
      });
    },
    getViewEditPromise(
      promiseList,
      formDetailResData,
      formFieldResData,
      formAuditAuthFieldResData,
      viewList,
      viewName,
      filters,
      viewType,
      sort,
      viewRange,
      actions
    ) {
      
      let view = viewList.find(
        (x) => x.name == viewName //this.specalViewNames[i]
      );

      if (view) {
        // 修改一级审核视图

        viewDetail(view.id).then(async (res) => {
          let v = res.data;
          v.showColumns = this.combineViewFieldsWithFormFields(
            v.showColumns,
            formFieldResData.fieldList
          );
          v.showFields = this.combineViewFieldsWithFormFields(
            v.showFields,
            formFieldResData.fieldList
          );

          promiseList.push(editView(v));
        });
      } else {
        // 创建审核视图
        let showColumns = [],
          showFields = [];
        showColumns = this.combineViewFieldsWithFormFields(
          showColumns,
          formFieldResData.fieldList
        );
        showFields = this.combineViewFieldsWithFormFields(
          showFields,
          formFieldResData.fieldList
        );
        if(!viewName) return;
        if (viewName.indexOf("审核视图")>=0) {
            if(formAuditAuthFieldResData){
              let auth;
              if (viewName == "一级审核视图") {
                auth = formAuditAuthFieldResData.find((x) => x.level == 1);
              } else if (viewName == "二级审核视图") {
                auth = formAuditAuthFieldResData.find((x) => x.level == 2);
              } else if (viewName == "三级审核视图") {
                auth = formAuditAuthFieldResData.find((x) => x.level == 3);
              }
              if (auth) {
                showFields.forEach((col) => {
                  col.hide = auth.fieldAuth[col.field].includes(3); // 包含3 (隐藏)的隐藏
                  col.editable = !auth.fieldAuth[col.field].includes(2); //不包含2（只读）的可编辑
                });
                showColumns.forEach((col) => {
                  col.hide = auth.fieldAuth[col.field].includes(3); // 包含3 (隐藏)的隐藏
                  col.editable = !auth.fieldAuth[col.field].includes(2); //不包含2（只读）的可编辑
                });
              }
          }
        }


        promiseList.push(
          addView({
            formId: formDetailResData.formId,
            name: viewName,
            style: {
              lineHeight: "medium",
            },
            showFields: [...showFields, ...this.sysFields(formDetailResData)],
            showColumns: [...showColumns, ...this.sysFields(formDetailResData)],
            sortList: [
              {
                field: "createTime",
                fieldType: "sysField",
                direction: -1,
              },
            ],
            filters: filters,
            viewRange: viewRange,
            viewType: viewType,
            sort: sort,
            actions: actions,
          })
        );
      }
    },

    combineViewFieldsWithFormFields(viewFields, formFields) {
      let removeViewFields = [],
        addViewFields = [];

      viewFields
        .filter((x) => x.type == "formField")
        .forEach((x) => {
          // 如果视图的字段在表单中不存在，标记要删除
          if (!formFields.find((y) => y.name == x.field)) {
            removeViewFields.push(x.field);
          }
        });
      formFields.forEach((y) => {
        // 如果表单的字段在视图中不存在，标记要新增
        if (
          !viewFields.find((x) => x.type == "formField" && x.field == y.name)
        ) {
          addViewFields.push(y.name);
        }
      });

      viewFields = viewFields.filter(
        (x) => x.type != "formField" || !removeViewFields.includes(x.field)
      );

      formFields
        .filter((x) => addViewFields.includes(x.name))
        .map((x) => {
          return {
            hide: false,
            headerName: x.displayName,
            field: x.name,
            disabled: false,
            editable: true,
            type: "formField",
          };
        })
        .forEach((x) => {
          let insertBefore = viewFields.findIndex(x=>x.type=='sysField');
          viewFields.splice(insertBefore,0,x)
          // viewFields.push(x);
        });

      return viewFields;
    },
    formFields(
      addType = false,
      filters = [],
      editable = undefined,
      hide = undefined
    ) {
      let formJson = this.$refs.vformDesigner.getFormJson();
      let columns = [];

      let filterdFieldList = formJson.fieldList;

      filters.forEach((filter) => {
        filterdFieldList = filterdFieldList.filter(
          (x) => filter.field == x.name
        );
      });

      const isInArray = (array, compare) => {
        return !!array.find(compare);
      };

      formJson.fieldList.forEach((item) => {
        let col = {
          field: item.name,
          headerName: item.displayName,
          hide: isInArray(filterdFieldList, (x) => x.name === item.name)
            ? false
            : true,
        };
        if (editable !== undefined) {
          // col.editable= isInArray(filterdFieldList,(x=>x.name===item.name))? editable: false
          col.editable = editable;
        }
        if (hide !== undefined) {
          col.hide = hide;
        }

        if (addType) {
          col.type = "formField";
        }
        columns.push(col);
      });
      return columns;
    },

    sysFields(setInfo) {
      let fields = [
        {
          field: "name",
          headerName: "填报人",
          hide: false,
          disabled: false,
          type: "sysField",
        },
        {
          field: "fillTime",
          headerName: "填报时间",
          hide: false,
          disabled: false,
          type: "sysField",
        },
        {
          field: "updateTime",
          headerName: "更新时间",
          hide: false,
          disabled: false,
          type: "sysField",
        },
        {
          field: "status",
          headerName: "当前状态",
          hide: false,
          disabled: false,
          type: "sysField",
        },
        {
          field: "payStatus",
          headerName: "支付状态",
          disabled: false,
          hide: !setInfo.pay,
          type: "sysField",
        },
        {
          field: "auditStatus",
          headerName: "审核意见",
          disabled: false,
          hide: !setInfo.auditAuth,
          type: "sysField",
        },
        {
          field: "confirmStatus",
          headerName: "导入确认",
          disabled: false,
          hide: !setInfo.isBatchFill,
          type: "sysField",
        },
      ];
      return fields;
    },
  },
};
