var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clearfix app-form-set" },
    [
      _c("div", { staticClass: "form-set-menu fl left-menu" }, [
        _c(
          "div",
          {
            staticClass: "menu-item",
            class: { active: _vm.setCur === 1 },
            on: {
              click: function ($event) {
                return _vm.onTabClick(1)
              },
            },
          },
          [
            _c("svg-icon", {
              staticClass: "tab-title-icon",
              attrs: { "icon-class": "myFormSubmit" },
            }),
            _vm._v(" 提交设置 "),
          ],
          1
        ),
        _vm.type != "1"
          ? _c(
              "div",
              {
                staticClass: "menu-item",
                class: { active: _vm.setCur === 2 },
                on: {
                  click: function ($event) {
                    return _vm.onTabClick(2)
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticClass: "tab-title-icon",
                  attrs: { "icon-class": "myFormPay" },
                }),
                _vm._v(" 支付设置 "),
              ],
              1
            )
          : _vm._e(),
        _vm.type != "1"
          ? _c(
              "div",
              {
                staticClass: "menu-item",
                class: { active: _vm.setCur === 3 },
                on: {
                  click: function ($event) {
                    return _vm.onTabClick(3)
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticClass: "tab-title-icon",
                  attrs: { "icon-class": "myFormPower" },
                }),
                _vm._v(" 填报设置 "),
              ],
              1
            )
          : _vm._e(),
        _vm.type != "1"
          ? _c(
              "div",
              {
                staticClass: "menu-item",
                class: { active: _vm.setCur === 4 },
                on: {
                  click: function ($event) {
                    return _vm.onTabClick(4)
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticClass: "tab-title-icon",
                  attrs: { "icon-class": "myFormReview" },
                }),
                _vm._v(" 审核设置 "),
              ],
              1
            )
          : _vm._e(),
        _vm.type != "1"
          ? _c(
              "div",
              {
                staticClass: "menu-item",
                class: { active: _vm.setCur === 5 },
                on: {
                  click: function ($event) {
                    return _vm.onTabClick(5)
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticClass: "tab-title-icon",
                  attrs: { "icon-class": "myFormSee" },
                }),
                _vm._v(" 管理权限 "),
              ],
              1
            )
          : _vm._e(),
        _vm.type != "1"
          ? _c(
              "div",
              {
                staticClass: "menu-item",
                class: { active: _vm.setCur === 6 },
                on: {
                  click: function ($event) {
                    return _vm.onTabClick(6)
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticClass: "tab-title-icon",
                  attrs: { "icon-class": "myFormExport" },
                }),
                _vm._v(" 导出设置 "),
              ],
              1
            )
          : _vm._e(),
        _vm.type != "1"
          ? _c(
              "div",
              {
                staticClass: "menu-item",
                class: { active: _vm.setCur === 7 },
                on: {
                  click: function ($event) {
                    return _vm.onTabClick(7)
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticClass: "tab-title-icon",
                  attrs: { "icon-class": "myOtherSet" },
                }),
                _vm._v(" 其他设置 "),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticStyle: { width: "16px" } }),
      _vm.setCur === 1
        ? _c(
            "div",
            { staticClass: "form-set-pane fl" },
            [
              _c(
                "el-row",
                { staticStyle: { margin: "0" }, attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { padding: "0" },
                      attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "setForm",
                          staticClass: "clearfix form-set-base",
                          attrs: {
                            model: _vm.setForm,
                            rules: _vm.setRules,
                            "label-width": "130px",
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "project-label",
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("起止时间")]
                              ),
                              _c("date-picker", {
                                attrs: {
                                  "disabled-date": _vm.notBeforeFillEnd,
                                  "disabled-time": _vm.notBeforeTimeEnd,
                                  placeholder: "请选择开始时间",
                                  format: "YYYY-M-D HH:mm",
                                  type: "datetime",
                                  "default-value": _vm.startDefaultValue,
                                  "value-type": "format",
                                  "minute-step": 10,
                                },
                                on: {
                                  clear: _vm.onClearFillStart,
                                  change: _vm.onChangeEdit,
                                },
                                model: {
                                  value: _vm.setForm.fillStartTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setForm, "fillStartTime", $$v)
                                  },
                                  expression: "setForm.fillStartTime",
                                },
                              }),
                              _c(
                                "span",
                                { staticStyle: { margin: "0 20px" } },
                                [_vm._v("至")]
                              ),
                              _c("date-picker", {
                                attrs: {
                                  "disabled-date": _vm.notBeforeFillStart,
                                  "disabled-time": _vm.notBeforeTimeStart,
                                  placeholder: "请选择结束时间",
                                  format: "YYYY-M-D HH:mm",
                                  type: "datetime",
                                  "default-value": _vm.endDefaultValue,
                                  "value-type": "format",
                                  "minute-step": 10,
                                },
                                on: {
                                  clear: _vm.onClearFillEnd,
                                  pick: _vm.onOpenFillEnd,
                                  change: _vm.onChangeEdit,
                                },
                                model: {
                                  value: _vm.setForm.fillEndTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setForm, "fillEndTime", $$v)
                                  },
                                  expression: "setForm.fillEndTime",
                                },
                              }),
                              _vm.setForm.fillEndTime
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticStyle: {
                                            margin: "0px 10px",
                                            display: "inline-block",
                                          },
                                          model: {
                                            value: _vm.setForm.endtimeModify,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.setForm,
                                                "endtimeModify",
                                                $$v
                                              )
                                            },
                                            expression: "setForm.endtimeModify",
                                          },
                                        },
                                        [_vm._v("到期后允许修改")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "允许多次填报",
                                prop: "allowSomeFill",
                              },
                            },
                            [
                              _c("el-switch", {
                                staticStyle: { "margin-right": "20px" },
                                attrs: { "active-color": "#3476F0" },
                                on: { change: _vm.onChangeEdit },
                                model: {
                                  value: _vm.setForm.fillRepeatedly,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setForm, "fillRepeatedly", $$v)
                                  },
                                  expression: "setForm.fillRepeatedly",
                                },
                              }),
                              _vm.setForm.fillRepeatedly
                                ? [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "1" },
                                        on: { change: _vm.onChangeEdit },
                                        model: {
                                          value: _vm.setForm.fillRepeatedlyRule,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.setForm,
                                              "fillRepeatedlyRule",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "setForm.fillRepeatedlyRule",
                                        },
                                      },
                                      [_vm._v("不限制")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "2" },
                                        on: { change: _vm.onChangeEdit },
                                        model: {
                                          value: _vm.setForm.fillRepeatedlyRule,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.setForm,
                                              "fillRepeatedlyRule",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "setForm.fillRepeatedlyRule",
                                        },
                                      },
                                      [_vm._v("每天1次")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "3" },
                                        on: { change: _vm.onChangeEdit },
                                        model: {
                                          value: _vm.setForm.fillRepeatedlyRule,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.setForm,
                                              "fillRepeatedlyRule",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "setForm.fillRepeatedlyRule",
                                        },
                                      },
                                      [_vm._v("每天2次")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "4" },
                                        on: { change: _vm.onChangeEdit },
                                        model: {
                                          value: _vm.setForm.fillRepeatedlyRule,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.setForm,
                                              "fillRepeatedlyRule",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "setForm.fillRepeatedlyRule",
                                        },
                                      },
                                      [_vm._v("每周1次")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "5" },
                                        on: { change: _vm.onChangeEdit },
                                        model: {
                                          value: _vm.setForm.fillRepeatedlyRule,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.setForm,
                                              "fillRepeatedlyRule",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "setForm.fillRepeatedlyRule",
                                        },
                                      },
                                      [_vm._v("每月1次")]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "填报人数上限", prop: "fillNum" },
                            },
                            [
                              _c("el-switch", {
                                staticStyle: { "margin-right": "20px" },
                                attrs: { "active-color": "#3476F0" },
                                on: { change: _vm.onChangeEdit },
                                model: {
                                  value: _vm.setForm.allowFillMax,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setForm, "allowFillMax", $$v)
                                  },
                                  expression: "setForm.allowFillMax",
                                },
                              }),
                              _c("el-input-number", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.setForm.allowFillMax,
                                    expression: "setForm.allowFillMax",
                                  },
                                ],
                                attrs: {
                                  min: 1,
                                  max: _vm.setForm.allowFillMax
                                    ? _vm.type == "1"
                                      ? 500
                                      : Infinity
                                    : Infinity,
                                },
                                on: { change: _vm.onHandleFillNum },
                                model: {
                                  value: _vm.setForm.fillNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setForm, "fillNum", $$v)
                                  },
                                  expression: "setForm.fillNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公开填报人数",
                                prop: "isPublic",
                              },
                            },
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#3476F0" },
                                on: { change: _vm.onChangeEdit },
                                model: {
                                  value: _vm.setForm.fillNumPublic,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setForm, "fillNumPublic", $$v)
                                  },
                                  expression: "setForm.fillNumPublic",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否开启批量导入",
                                prop: "isPublic",
                              },
                            },
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#3476F0" },
                                on: { change: _vm.onChangeEdit },
                                model: {
                                  value: _vm.setForm.isBatchFill,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setForm, "isBatchFill", $$v)
                                  },
                                  expression: "setForm.isBatchFill",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "开始填报按钮文字",
                                prop: "isPublic",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: { maxlength: 6, size: "mini" },
                                on: { change: _vm.onChangeEdit },
                                model: {
                                  value: _vm.setForm.startFillText,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setForm, "startFillText", $$v)
                                  },
                                  expression: "setForm.startFillText",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("commitSetting", {
                attrs: { formId: _vm.formId, agencyId: _vm.agencyId },
                model: {
                  value: _vm.customTip,
                  callback: function ($$v) {
                    _vm.customTip = $$v
                  },
                  expression: "customTip",
                },
              }),
              _c("agreementSetting", {
                attrs: { formId: _vm.formId, agencyId: _vm.agencyId },
                model: {
                  value: _vm.agreement,
                  callback: function ($$v) {
                    _vm.agreement = $$v
                  },
                  expression: "agreement",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.setCur === 2 && _vm.type != "1"
        ? _c(
            "div",
            { staticClass: "form-set-pane fl" },
            [
              _c(
                "el-row",
                { staticStyle: { margin: "0" }, attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { padding: "0" },
                      attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "setForm",
                          staticClass: "clearfix form-set-base",
                          attrs: {
                            model: _vm.setForm,
                            rules: _vm.setRules,
                            "label-width": "120px",
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "设置填报支付", prop: "pay" } },
                            [
                              _c("el-switch", {
                                staticStyle: { "margin-right": "20px" },
                                attrs: { "active-color": "#3476F0" },
                                on: { change: _vm.onChangeEdit },
                                model: {
                                  value: _vm.setForm.pay,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setForm, "pay", $$v)
                                  },
                                  expression: "setForm.pay",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.setForm.pay
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "1" },
                                      on: { change: _vm.onRadioPayType },
                                      model: {
                                        value: _vm.setForm.payType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setForm, "payType", $$v)
                                        },
                                        expression: "setForm.payType",
                                      },
                                    },
                                    [_vm._v("单个支付金额")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "2" },
                                      on: { change: _vm.onRadioPayType },
                                      model: {
                                        value: _vm.setForm.payType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setForm, "payType", $$v)
                                        },
                                        expression: "setForm.payType",
                                      },
                                    },
                                    [_vm._v("根据选项项目设置支付金额")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.setForm.pay && _vm.setForm.payType == "1"
                            ? _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.setForm.pay,
                                        expression: "setForm.pay",
                                      },
                                    ],
                                    staticStyle: {
                                      width: "200px",
                                      display: "inline-block",
                                    },
                                    attrs: {
                                      type: "text",
                                      placeholder: "请输入支付金额",
                                    },
                                    on: { change: _vm.onBlurPayPrice },
                                    model: {
                                      value: _vm.setForm.payPrice,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.setForm, "payPrice", $$v)
                                      },
                                      expression: "setForm.payPrice",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.setForm.pay,
                                          expression: "setForm.pay",
                                        },
                                      ],
                                      staticStyle: { "margin-left": "10px" },
                                    },
                                    [_vm._v("元")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.setForm.pay && _vm.setForm.payType == "2"
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.onSelectPayOption },
                                      model: {
                                        value: _vm.setForm.formFieldId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.setForm,
                                            "formFieldId",
                                            $$v
                                          )
                                        },
                                        expression: "setForm.formFieldId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.setForm.payPriceFiledArr,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item,
                                          attrs: {
                                            label: item.label,
                                            value: item.formFieldId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _vm.setForm.pay2OptionShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "option-list",
                                          staticStyle: {
                                            width: "100%",
                                            display: "-webkit-flex",
                                            "flex-direction": "column",
                                            "margin-top": "15px",
                                          },
                                        },
                                        _vm._l(
                                          _vm.setForm.payFieldList,
                                          function (optionItem, optionIndex) {
                                            return _c(
                                              "div",
                                              {
                                                staticClass: "option-list-item",
                                                staticStyle: {
                                                  width: "100%",
                                                  "margin-bottom": "15px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "选项" +
                                                        _vm._s(
                                                          optionIndex + 1
                                                        ) +
                                                        "：" +
                                                        _vm._s(optionItem.label)
                                                    ),
                                                  ]
                                                ),
                                                _c("el-input", {
                                                  staticStyle: { width: "30%" },
                                                  attrs: {
                                                    placeholder:
                                                      "请输入支付金额",
                                                  },
                                                  on: {
                                                    change: _vm.onChangeEdit,
                                                  },
                                                  model: {
                                                    value: optionItem.payPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        optionItem,
                                                        "payPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "optionItem.payPrice",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                  },
                                                  [_vm._v("元")]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0px" },
                              attrs: { label: "支付接口设置", prop: "pay" },
                            },
                            [
                              _c("el-switch", {
                                staticStyle: { "margin-right": "20px" },
                                attrs: {
                                  "active-color": "#3476F0",
                                  disabled: "",
                                },
                                on: { change: _vm.onChangeEdit },
                                model: {
                                  value: _vm.setForm.mch,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.setForm, "mch", $$v)
                                  },
                                  expression: "setForm.mch",
                                },
                              }),
                              _vm.setForm.mchName
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.setForm.mchName)),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("el-form-item", [
                            _c("div", { staticStyle: { color: "#3476F0" } }, [
                              _vm._v(
                                " 此功能为高级版功能。开启后，提交人费用可不经过鲸鱼填报，实时支付到机构指定的微信商户。开启此功能需要技术人员后台配置，若要了解更多，请直接与我们市场与客服联系。"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.setCur === 3 && _vm.type != "1"
        ? _c("div", { staticClass: "form-set-pane fl" }, [
            _c(
              "div",
              { staticClass: "fill-set-wrapper" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    on: { change: _vm.onRadioFillType },
                    model: {
                      value: _vm.setForm.fillAuth,
                      callback: function ($$v) {
                        _vm.$set(_vm.setForm, "fillAuth", $$v)
                      },
                      expression: "setForm.fillAuth",
                    },
                  },
                  [_vm._v("所有人可填")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "2" },
                    on: { change: _vm.onRadioFillType },
                    model: {
                      value: _vm.setForm.fillAuth,
                      callback: function ($$v) {
                        _vm.$set(_vm.setForm, "fillAuth", $$v)
                      },
                      expression: "setForm.fillAuth",
                    },
                  },
                  [_vm._v("机构用户可填")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "3" },
                    on: { change: _vm.onRadioFillType },
                    model: {
                      value: _vm.setForm.fillAuth,
                      callback: function ($$v) {
                        _vm.$set(_vm.setForm, "fillAuth", $$v)
                      },
                      expression: "setForm.fillAuth",
                    },
                  },
                  [_vm._v("指定成员可填")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "4" },
                    on: { change: _vm.onRadioFillType },
                    model: {
                      value: _vm.setForm.fillAuth,
                      callback: function ($$v) {
                        _vm.$set(_vm.setForm, "fillAuth", $$v)
                      },
                      expression: "setForm.fillAuth",
                    },
                  },
                  [_vm._v("历史表单人员可填")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "5" },
                    on: { change: _vm.onRadioFillType },
                    model: {
                      value: _vm.setForm.fillAuth,
                      callback: function ($$v) {
                        _vm.$set(_vm.setForm, "fillAuth", $$v)
                      },
                      expression: "setForm.fillAuth",
                    },
                  },
                  [_vm._v("指定手机号可填")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "fill-set-pane" },
              [
                _vm.setForm.fillAuth == "3"
                  ? [
                      _vm.selectAllFillArr &&
                      _vm.selectAllFillArr.length > 0 &&
                      _vm.isFillConfirmBtn
                        ? _c(
                            "ul",
                            {
                              staticClass: "select-list",
                              on: { click: _vm.onFillSelectMemberClick },
                            },
                            _vm._l(_vm.selectAllFillArr, function (item) {
                              return _c(
                                "li",
                                { staticClass: "select-item" },
                                [
                                  item.type == "member"
                                    ? [
                                        _c("svg-icon", {
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                            "vertical-align": "-5px",
                                            "margin-right": "5px",
                                          },
                                          attrs: { "icon-class": "memberIcon" },
                                        }),
                                      ]
                                    : _vm._e(),
                                  item.type == "dept"
                                    ? [
                                        _c("svg-icon", {
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                            "vertical-align": "-5px",
                                            "margin-right": "5px",
                                          },
                                          attrs: { "icon-class": "deptIcon" },
                                        }),
                                      ]
                                    : _vm._e(),
                                  item.type == "role"
                                    ? [
                                        _c("svg-icon", {
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                            "vertical-align": "-5px",
                                            "margin-right": "5px",
                                          },
                                          attrs: { "icon-class": "roleIcon" },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                ],
                                2
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "designated-member",
                              on: { click: _vm.onFillSelectMemberClick },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "member-empty" },
                                [
                                  _c("svg-icon", {
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                      "margin-right": "5px",
                                      color: "#5e6d82",
                                      "vertical-align": "-5px",
                                    },
                                    attrs: { "icon-class": "add" },
                                  }),
                                  _vm._v("点击添加指定成员 "),
                                ],
                                1
                              ),
                            ]
                          ),
                    ]
                  : _vm._e(),
                _vm.setForm.fillAuth == "4"
                  ? [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "50%" },
                          attrs: {
                            placeholder: "请选择历史表单",
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            "remote-method": _vm.onRemoteSearch,
                            clearable: "true",
                          },
                          on: { change: _vm.onSelectHistoryFormName },
                          model: {
                            value: _vm.setForm.fillAuthHistoryFormId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setForm,
                                "fillAuthHistoryFormId",
                                $$v
                              )
                            },
                            expression: "setForm.fillAuthHistoryFormId",
                          },
                        },
                        _vm._l(_vm.historyFormList, function (item) {
                          return _c("el-option", {
                            key: item.formId,
                            attrs: {
                              label: item.projectName
                                ? item.projectName + " / " + item.formName
                                : item.formName,
                              value: item.formId,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "fill-set-history-select" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              on: { change: _vm.onRadioHistorySelect },
                              model: {
                                value: _vm.setForm.fillAuthHistorySelect,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.setForm,
                                    "fillAuthHistorySelect",
                                    $$v
                                  )
                                },
                                expression: "setForm.fillAuthHistorySelect",
                              },
                            },
                            [_vm._v("已填报人")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              on: { change: _vm.onRadioHistorySelect },
                              model: {
                                value: _vm.setForm.fillAuthHistorySelect,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.setForm,
                                    "fillAuthHistorySelect",
                                    $$v
                                  )
                                },
                                expression: "setForm.fillAuthHistorySelect",
                              },
                            },
                            [_vm._v("审核通过")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "3" },
                              on: { change: _vm.onRadioHistorySelect },
                              model: {
                                value: _vm.setForm.fillAuthHistorySelect,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.setForm,
                                    "fillAuthHistorySelect",
                                    $$v
                                  )
                                },
                                expression: "setForm.fillAuthHistorySelect",
                              },
                            },
                            [_vm._v("审核不通过")]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.setForm.fillAuth == "5"
                  ? [
                      _c("div", { staticClass: "fill-set-item-title" }, [
                        _vm._v("录入手机号 (每行一个)"),
                      ]),
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 5, maxRows: 25 },
                            },
                            model: {
                              value: _vm.setForm.fillAuthAllowPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.setForm, "fillAuthAllowPhone", $$v)
                              },
                              expression: "setForm.fillAuthAllowPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("div", { staticClass: "fl" }, [
              _c(
                "div",
                { staticClass: "form-data-switch" },
                [
                  _c("label", [_vm._v("匿名填报")]),
                  _c("el-switch", {
                    attrs: { "active-color": "#3476F0" },
                    model: {
                      value: _vm.setForm.fillAnony,
                      callback: function ($$v) {
                        _vm.$set(_vm.setForm, "fillAnony", $$v)
                      },
                      expression: "setForm.fillAnony",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.setCur === 4 && _vm.type != "1"
        ? _c("div", { staticClass: "form-set-pane fl" }, [
            _c(
              "div",
              { staticClass: "form-data-switch" },
              [
                _c("label", [_vm._v("开启审核功能")]),
                _c("el-switch", {
                  attrs: { "active-color": "#3476F0" },
                  on: { change: _vm.onChangeEdit },
                  model: {
                    value: _vm.formAuditAuth.auditAuth,
                    callback: function ($$v) {
                      _vm.$set(_vm.formAuditAuth, "auditAuth", $$v)
                    },
                    expression: "formAuditAuth.auditAuth",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formAuditAuth.auditAuth,
                    expression: "formAuditAuth.auditAuth",
                  },
                ],
                staticClass: "review-flow-pane",
              },
              [
                _c("setAuditLevel", {
                  ref: "auditLevel",
                  attrs: {
                    agencyId: _vm.agencyId,
                    fieldList: _vm.fieldList,
                    getFormField: _vm.getAuditFormField,
                    formId: _vm.formId,
                  },
                  on: { change: _vm.onAuditChangeEdit },
                }),
              ],
              1
            ),
            _vm.formAuditAuth.auditAuth
              ? _c(
                  "div",
                  {
                    staticClass: "form-data-switch",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _c("label", [_vm._v("手写签名")]),
                    _c("el-switch", {
                      attrs: { "active-color": "#3476F0" },
                      on: { change: _vm.onChangeEdit },
                      model: {
                        value: _vm.formAuditAuth.auditAuthSign,
                        callback: function ($$v) {
                          _vm.$set(_vm.formAuditAuth, "auditAuthSign", $$v)
                        },
                        expression: "formAuditAuth.auditAuthSign",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formAuditAuth.auditAuth
              ? _c(
                  "div",
                  {
                    staticClass: "form-data-switch",
                    staticStyle: { "margin-top": "20px" },
                    style: {
                      marginTop1: _vm.formAuditAuth.auditAuth ? "" : "20px",
                    },
                  },
                  [
                    _c("label", [_vm._v("是否允许修改审核状态")]),
                    _c("el-switch", {
                      attrs: { "active-color": "#3476F0" },
                      on: { change: _vm.onChangeEdit },
                      model: {
                        value: _vm.formAuditAuth.auditAuthReUpdate,
                        callback: function ($$v) {
                          _vm.$set(_vm.formAuditAuth, "auditAuthReUpdate", $$v)
                        },
                        expression: "formAuditAuth.auditAuthReUpdate",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formAuditAuth.auditAuth
              ? _c(
                  "div",
                  {
                    staticClass: "form-data-switch",
                    staticStyle: { "margin-top": "20px" },
                    style: {
                      marginTop1: _vm.formAuditAuth.auditAuth ? "" : "20px",
                    },
                  },
                  [
                    _c("label", [_vm._v("审核意见必填")]),
                    _c("el-switch", {
                      attrs: { "active-color": "#3476F0" },
                      on: { change: _vm.onChangeEdit },
                      model: {
                        value: _vm.formAuditAuth.auditAuthReviewRemark,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formAuditAuth,
                            "auditAuthReviewRemark",
                            $$v
                          )
                        },
                        expression: "formAuditAuth.auditAuthReviewRemark",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.formAuditAuth.auditAuth && _vm.formAuditAuth.auditAuthSign
              ? _c(
                  "div",
                  {
                    staticClass: "form-data-switch",
                    staticStyle: { "margin-top": "20px" },
                    style: {
                      marginTop1: _vm.formAuditAuth.auditAuth ? "" : "20px",
                    },
                  },
                  [
                    _c("label", [_vm._v("审核签字必填")]),
                    _c("el-switch", {
                      attrs: { "active-color": "#3476F0" },
                      on: { change: _vm.onChangeEdit },
                      model: {
                        value: _vm.formAuditAuth.auditAuthReviewSign,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formAuditAuth,
                            "auditAuthReviewSign",
                            $$v
                          )
                        },
                        expression: "formAuditAuth.auditAuthReviewSign",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.setCur === 5 && _vm.type != "1"
        ? _c(
            "div",
            { staticClass: "form-set-pane fl" },
            [
              _c(
                "div",
                { staticClass: "form-data-switch" },
                [
                  _c("label", [_vm._v("仅开启数据管理权限")]),
                  _c("el-switch", {
                    attrs: { "active-color": "#3476F0" },
                    on: { change: _vm.onChangeEdit },
                    model: {
                      value: _vm.formViewAuth.viewAuth,
                      callback: function ($$v) {
                        _vm.$set(_vm.formViewAuth, "viewAuth", $$v)
                      },
                      expression: "formViewAuth.viewAuth",
                    },
                  }),
                ],
                1
              ),
              _vm.formViewAuth.viewAuth
                ? _c(
                    "div",
                    { staticClass: "data-view-select" },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          staticStyle: { display: "inline-flex" },
                          on: { change: _vm.onDataManageTypeChecked },
                          model: {
                            value: _vm.formViewAuth.viewAuthType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formViewAuth, "viewAuthType", $$v)
                            },
                            expression: "formViewAuth.viewAuthType",
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "1", value: "1" } },
                            [_vm._v("指定成员查看")]
                          ),
                          _c(
                            "el-checkbox",
                            { attrs: { label: "2", value: "2" } },
                            [_vm._v("部门管理员查看")]
                          ),
                          _c(
                            "el-checkbox",
                            { attrs: { label: "3", value: "3" } },
                            [_vm._v("根据选项设置管理员")]
                          ),
                        ],
                        1
                      ),
                      Array.isArray(_vm.formViewAuth.viewAuthType) &&
                      _vm.formViewAuth.viewAuthType.find(function (x) {
                        return x === "1"
                      })
                        ? _c("div", { staticClass: "dept-admin" }, [
                            _vm.selectAllViewArr &&
                            _vm.selectAllViewArr.length > 0 &&
                            _vm.isViewConfirmBtn
                              ? _c(
                                  "ul",
                                  {
                                    staticClass: "select-list",
                                    on: { click: _vm.onViewSelectMemberClick },
                                  },
                                  _vm._l(_vm.selectAllViewArr, function (item) {
                                    return _c(
                                      "li",
                                      { staticClass: "select-item" },
                                      [
                                        item.type == "member"
                                          ? [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px",
                                                  "vertical-align": "-5px",
                                                  "margin-right": "5px",
                                                },
                                                attrs: {
                                                  "icon-class": "memberIcon",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        item.type == "dept"
                                          ? [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px",
                                                  "vertical-align": "-5px",
                                                  "margin-right": "5px",
                                                },
                                                attrs: {
                                                  "icon-class": "deptIcon",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        item.type == "role"
                                          ? [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px",
                                                  "vertical-align": "-5px",
                                                  "margin-right": "5px",
                                                },
                                                attrs: {
                                                  "icon-class": "roleIcon",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        _c("span", [_vm._v(_vm._s(item.name))]),
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "designated-member",
                                    on: { click: _vm.onViewSelectMemberClick },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "member-empty" },
                                      [
                                        _c("svg-icon", {
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                            "margin-right": "5px",
                                            color: "#5e6d82",
                                            "vertical-align": "-5px",
                                          },
                                          attrs: { "icon-class": "add" },
                                        }),
                                        _vm._v("点击添加指定成员 "),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ])
                        : _vm._e(),
                      Array.isArray(_vm.formViewAuth.viewAuthType) &&
                      _vm.formViewAuth.viewAuthType.find(function (x) {
                        return x === "2"
                      })
                        ? _c(
                            "div",
                            { staticClass: "dept-admin" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  on: { change: _vm.onChangeEdit },
                                  model: {
                                    value:
                                      _vm.formViewAuth
                                        .viewAuthAgencyLeaderLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formViewAuth,
                                        "viewAuthAgencyLeaderLevel",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formViewAuth.viewAuthAgencyLeaderLevel",
                                  },
                                },
                                [_vm._v("一级部门主管")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "2" },
                                  on: { change: _vm.onChangeEdit },
                                  model: {
                                    value:
                                      _vm.formViewAuth
                                        .viewAuthAgencyLeaderLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formViewAuth,
                                        "viewAuthAgencyLeaderLevel",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formViewAuth.viewAuthAgencyLeaderLevel",
                                  },
                                },
                                [_vm._v("二级部门主管")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "3" },
                                  on: { change: _vm.onChangeEdit },
                                  model: {
                                    value:
                                      _vm.formViewAuth
                                        .viewAuthAgencyLeaderLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formViewAuth,
                                        "viewAuthAgencyLeaderLevel",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formViewAuth.viewAuthAgencyLeaderLevel",
                                  },
                                },
                                [_vm._v("三级部门主管")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "4" },
                                  on: { change: _vm.onChangeEdit },
                                  model: {
                                    value:
                                      _vm.formViewAuth
                                        .viewAuthAgencyLeaderLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formViewAuth,
                                        "viewAuthAgencyLeaderLevel",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formViewAuth.viewAuthAgencyLeaderLevel",
                                  },
                                },
                                [_vm._v("四级部门主管")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "5" },
                                  on: { change: _vm.onChangeEdit },
                                  model: {
                                    value:
                                      _vm.formViewAuth
                                        .viewAuthAgencyLeaderLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formViewAuth,
                                        "viewAuthAgencyLeaderLevel",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formViewAuth.viewAuthAgencyLeaderLevel",
                                  },
                                },
                                [_vm._v("五级部门主管")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      Array.isArray(_vm.formViewAuth.viewAuthType) &&
                      _vm.formViewAuth.viewAuthType.find(function (x) {
                        return x === "3"
                      })
                        ? _c(
                            "div",
                            { staticClass: "dept-admin" },
                            [
                              _vm.managefieldList.length > 0
                                ? _c("setAuditByUserSelect", {
                                    attrs: {
                                      value: _vm.formViewAuth,
                                      fields: _vm.managefieldList,
                                      tip: "管理员",
                                      fieldList: _vm.managefieldList,
                                      agencyId: _vm.agencyId,
                                    },
                                    on: {
                                      change: _vm.onManageUserSelectByField,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "form-data-switch" },
                [
                  _c("label", [_vm._v("开启全部管理权限 ")]),
                  _c("el-switch", {
                    attrs: { "active-color": "#3476F0" },
                    on: { change: _vm.onChangeEdit },
                    model: {
                      value: _vm.formAdminAuth.adminAuth,
                      callback: function ($$v) {
                        _vm.$set(_vm.formAdminAuth, "adminAuth", $$v)
                      },
                      expression: "formAdminAuth.adminAuth",
                    },
                  }),
                ],
                1
              ),
              _vm.formAdminAuth.adminAuth
                ? _c("el-alert", {
                    staticStyle: {
                      "margin-top": "10px",
                      "margin-bottom": "-15px",
                    },
                    attrs: {
                      type: "warning",
                      closable: false,
                      title: "仅能选择机构中的子管理员用户",
                    },
                  })
                : _vm._e(),
              _vm.formAdminAuth.adminAuth
                ? _c("div", { staticClass: "dept-admin" }, [
                    _vm.selectAllAdminArr && _vm.selectAllAdminArr.length > 0
                      ? _c(
                          "ul",
                          {
                            staticClass: "select-list",
                            on: { click: _vm.onAdminSelectMemberClick },
                          },
                          _vm._l(_vm.selectAllAdminArr, function (item, index) {
                            return _c(
                              "li",
                              { key: index, staticClass: "select-item" },
                              [
                                item.type == "member"
                                  ? [
                                      _c("svg-icon", {
                                        staticStyle: {
                                          width: "20px",
                                          height: "20px",
                                          "vertical-align": "-5px",
                                          "margin-right": "5px",
                                        },
                                        attrs: { "icon-class": "memberIcon" },
                                      }),
                                    ]
                                  : _vm._e(),
                                item.type == "dept"
                                  ? [
                                      _c("svg-icon", {
                                        staticStyle: {
                                          width: "20px",
                                          height: "20px",
                                          "vertical-align": "-5px",
                                          "margin-right": "5px",
                                        },
                                        attrs: { "icon-class": "deptIcon" },
                                      }),
                                    ]
                                  : _vm._e(),
                                item.type == "role"
                                  ? [
                                      _c("svg-icon", {
                                        staticStyle: {
                                          width: "20px",
                                          height: "20px",
                                          "vertical-align": "-5px",
                                          "margin-right": "5px",
                                        },
                                        attrs: { "icon-class": "roleIcon" },
                                      }),
                                    ]
                                  : _vm._e(),
                                _c("span", [_vm._v(_vm._s(item.name))]),
                              ],
                              2
                            )
                          }),
                          0
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "designated-member",
                            on: { click: _vm.onAdminSelectMemberClick },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "member-empty" },
                              [
                                _c("svg-icon", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                    "margin-right": "5px",
                                    color: "#5e6d82",
                                    "vertical-align": "-5px",
                                  },
                                  attrs: { "icon-class": "add" },
                                }),
                                _vm._v("点击添加指定成员 "),
                              ],
                              1
                            ),
                          ]
                        ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.setCur === 6 && _vm.type != "1"
        ? _c("div", { staticClass: "form-set-pane fl" }, [
            _c("div", { staticClass: "setting-title" }, [
              _c(
                "div",
                {
                  staticClass: "setting-title-upload",
                  staticStyle: { "flex-wrap": "nowrap" },
                },
                [
                  _c("span", [_vm._v("模板设置")]),
                  _c(
                    "div",
                    { staticStyle: { display: "inline-block" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "inline-block" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "exportUpload",
                              staticClass: "upload-demo",
                              attrs: {
                                action: "",
                                accept: ".doc, .docx",
                                "file-list": _vm.exportSetting.fileList,
                                "show-file-list": false,
                                "before-upload": _vm.onBeforeExportFileUpload,
                                "http-request": _vm.onHttpExportFileUpload,
                                "on-change": _vm.onHandleExportFileChange,
                                "on-success": function (params) {
                                  return _vm.onHandleExportFileSuccess(params)
                                },
                                "on-remove": function (response) {
                                  return _vm.onHandleExportFileRemove(
                                    _vm.params
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "medium",
                                    icon: "el-icon-upload",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.exportSetting.fileList &&
                                        _vm.exportSetting.fileList.length > 0
                                        ? "更换模板"
                                        : "添加模板"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.exportSetting.fileList &&
                      _vm.exportSetting.fileList.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "setting-title-filename",
                              staticStyle: {
                                display: "inline",
                                "margin-left": "20px",
                                "margin-right": "50px",
                                "word-break": "break-all",
                                "white-space": "normal",
                              },
                              on: { click: _vm.onExportTemplate },
                            },
                            [_vm._v(_vm._s(_vm.exportSetting.fileList[0].name))]
                          )
                        : _vm._e(),
                      _vm.isHasExportTemplate
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { height: "36px" },
                              attrs: { type: "text", size: "mini" },
                              on: { click: _vm.onDeleteExportTemplate },
                            },
                            [_vm._v("删除模板")]
                          )
                        : _vm._e(),
                      _vm.isHasExportTemplate
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { height: "36px" },
                              attrs: { type: "text", size: "mini" },
                              on: { click: _vm.onExportTemplate },
                            },
                            [_vm._v("模板下载")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            !_vm.noExportFile
              ? _c(
                  "div",
                  { staticClass: "my-export-switch" },
                  [
                    _c("label", [_vm._v("导出格式")]),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.exportSetting.exportIsPdf,
                          callback: function ($$v) {
                            _vm.$set(_vm.exportSetting, "exportIsPdf", $$v)
                          },
                          expression: "exportSetting.exportIsPdf",
                        },
                      },
                      [
                        _c(
                          "el-radio",
                          {
                            staticStyle: { width: "70px", margin: "0px" },
                            attrs: { label: false },
                          },
                          [_vm._v("Word")]
                        ),
                        _c(
                          "el-radio",
                          {
                            staticStyle: { width: "60px", margin: "0px" },
                            attrs: { label: true },
                          },
                          [_vm._v("Pdf")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.noExportFile
              ? _c(
                  "div",
                  { staticClass: "my-export-switch" },
                  [
                    _c("label", [_vm._v("仅能在审核之后导出")]),
                    _c("el-switch", {
                      attrs: { "active-color": "#3476F0" },
                      model: {
                        value: _vm.exportSetting.exportAfterAudit,
                        callback: function ($$v) {
                          _vm.$set(_vm.exportSetting, "exportAfterAudit", $$v)
                        },
                        expression: "exportSetting.exportAfterAudit",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.exportSetting.exportIsPdf
              ? _c(
                  "div",
                  { staticClass: "my-export-switch" },
                  [
                    _c("label", [_vm._v("PDF水印文字")]),
                    _vm.exportSetting.exportIsPdf
                      ? _c("el-input", {
                          staticStyle: { width: "180px" },
                          attrs: {
                            disabled: _vm.noExportFile,
                            size: "mini",
                            placeholder: "如不需要水印可为空",
                          },
                          model: {
                            value: _vm.exportSetting.waterMark,
                            callback: function ($$v) {
                              _vm.$set(_vm.exportSetting, "waterMark", $$v)
                            },
                            expression: "exportSetting.waterMark",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.exportSetting.fileList && _vm.exportSetting.fileList.length > 0
              ? _c(
                  "div",
                  { staticClass: "my-export-switch" },
                  [
                    _c("label", [_vm._v("导出文件名格式")]),
                    _c("exportFileName", {
                      attrs: {
                        "widget-list": _vm.fieldList,
                        "system-field-list": _vm.exportSystemField,
                      },
                      model: {
                        value: _vm.exportSetting.exportName,
                        callback: function ($$v) {
                          _vm.$set(_vm.exportSetting, "exportName", $$v)
                        },
                        expression: "exportSetting.exportName",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "my-export-switch" },
              [
                _c("label", [_vm._v("允许填报人导出")]),
                _c("el-switch", {
                  attrs: {
                    disabled: _vm.noExportFile,
                    "active-color": "#3476F0",
                  },
                  on: { change: _vm.onCheckPersonExport },
                  model: {
                    value: _vm.exportSetting.exportIsPerson,
                    callback: function ($$v) {
                      _vm.$set(_vm.exportSetting, "exportIsPerson", $$v)
                    },
                    expression: "exportSetting.exportIsPerson",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "setting-export-style" },
              [
                _vm._m(0),
                _c(
                  "el-collapse",
                  { staticStyle: { width: "100%" } },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "系统字段", name: "1" } },
                      [
                        _c("div", { staticClass: "system-field" }, [
                          _c("div", { staticClass: "system-field-table" }, [
                            _c("p", { staticClass: "field-head" }, [
                              _c("span", [_vm._v("系统字段")]),
                              _c("span", [_vm._v("word模板字段")]),
                            ]),
                            _c(
                              "ul",
                              { staticClass: "field-body" },
                              _vm._l(_vm.exportSystemField, function (item) {
                                return _c("li", [
                                  _c("span", [_vm._v(_vm._s(item.field))]),
                                  _c(
                                    "span",
                                    {
                                      attrs: { title: "点击复制" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCopyTemplateField(
                                            item.templateField
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.templateField))]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm.fieldList && _vm.fieldList.length > 0
                      ? _c(
                          "el-collapse-item",
                          { attrs: { title: "表单字段", name: "2" } },
                          [
                            _c("div", { staticClass: "form-field" }, [
                              _c(
                                "table",
                                { attrs: { border: "" } },
                                [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "row",
                                        staticStyle: {
                                          "background-color": "#f0f2f5",
                                        },
                                        attrs: { width: "250" },
                                      },
                                      [_vm._v("表单字段")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "display-name",
                                        staticStyle: {
                                          "background-color": "#f0f2f5",
                                        },
                                      },
                                      [_vm._v("word模板字段")]
                                    ),
                                  ]),
                                  _vm._l(_vm.fieldList, function (item, index) {
                                    return _c(
                                      "tr",
                                      { key: index, class: [item.type] },
                                      [
                                        _c(
                                          "td",
                                          { attrs: { align: "center" } },
                                          [_vm._v(_vm._s(item.displayName))]
                                        ),
                                        _c(
                                          "td",
                                          { class: [item.type, "copyable"] },
                                          [
                                            item.type != "sub-form" &&
                                            item.type != "fill-select"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "display-name",
                                                        attrs: {
                                                          title: "点击复制",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.onCopyTemplateField,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.brachText(item)
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : item.type == "sub-form"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-flex",
                                                      "overflow-x": "auto",
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "subform-column",
                                                      },
                                                      [
                                                        _c(
                                                          "tr",
                                                          _vm._l(
                                                            item.widgetList,
                                                            function (
                                                              subItem,
                                                              subIndex
                                                            ) {
                                                              return _c(
                                                                "td",
                                                                {
                                                                  key: subIndex,
                                                                  staticClass:
                                                                    "subform-display-name",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      subItem.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        _c(
                                                          "tr",
                                                          _vm._l(
                                                            item.widgetList,
                                                            function (
                                                              subItem,
                                                              subIndex
                                                            ) {
                                                              return _c(
                                                                "td",
                                                                {
                                                                  key: subIndex,
                                                                  staticClass:
                                                                    "subform-name",
                                                                  attrs: {
                                                                    title:
                                                                      "点击复制",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.onCopyTemplateField,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.brachText(
                                                                        item,
                                                                        item.widgetList,
                                                                        subIndex
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : item.type == "fill-select"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-flex",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.fields,
                                                      function (
                                                        fillItem,
                                                        fillIndex
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: fillIndex,
                                                            staticClass:
                                                              "subform-column",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "subform-display-name",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    fillItem.displayName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "subform-name",
                                                                attrs: {
                                                                  title:
                                                                    "点击复制",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.brachText(
                                                                      item,
                                                                      item.fields,
                                                                      fillIndex
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "subform-column",
                                                      },
                                                      [
                                                        _c(
                                                          "tr",
                                                          _vm._l(
                                                            item.fields,
                                                            function (
                                                              fillItem,
                                                              fillIndex
                                                            ) {
                                                              return _c(
                                                                "td",
                                                                {
                                                                  key: fillIndex,
                                                                  staticClass:
                                                                    "subform-display-name",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      fillItem.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        _c(
                                                          "tr",
                                                          _vm._l(
                                                            item.widgetList,
                                                            function (
                                                              fillItem,
                                                              fillIndex
                                                            ) {
                                                              return _c(
                                                                "td",
                                                                {
                                                                  key: fillIndex,
                                                                  staticClass:
                                                                    "subform-name",
                                                                  attrs: {
                                                                    title:
                                                                      "点击复制",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.onCopyTemplateField,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.brachText(
                                                                        item,
                                                                        item.fields,
                                                                        fillIndex
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "form-field",
                                staticStyle: { display: "none" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-display-name" },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _vm._v("表单字段"),
                                    ]),
                                    _vm._l(
                                      _vm.fieldList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "row",
                                            class: [item.type],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.displayName) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-name" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: { "text-align": "left" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "display-name" },
                                          [_vm._v("word模板字段")]
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.fieldList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "row field-name",
                                            class: [item.type],
                                            on: {
                                              click: _vm.onCopyTemplateField,
                                            },
                                          },
                                          [
                                            item.type != "sub-form" &&
                                            item.type != "fill-select"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "display-name",
                                                        attrs: {
                                                          title: "点击复制",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.brachText(item)
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : item.type == "sub-form"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-flex",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.widgetList,
                                                    function (
                                                      subItem,
                                                      subIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: subIndex,
                                                          staticClass:
                                                            "subform-column",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "subform-display-name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  subItem.displayName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "subform-name",
                                                              attrs: {
                                                                title:
                                                                  "点击复制",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.brachText(
                                                                    item,
                                                                    item.widgetList,
                                                                    subIndex
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : item.type == "fill-select"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-flex",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.fields,
                                                    function (
                                                      fillItem,
                                                      fillIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: fillIndex,
                                                          staticClass:
                                                            "subform-column",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "subform-display-name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  fillItem.displayName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "subform-name",
                                                              attrs: {
                                                                title:
                                                                  "点击复制",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.brachText(
                                                                    item,
                                                                    item.fields,
                                                                    fillIndex
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c("div", {
                                  staticStyle: {
                                    "flex-grow": "1",
                                    "flex-shrink": "5",
                                    display: "inline-block",
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "system-field",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticStyle: {
                                  height: "48px",
                                  "line-height": "48px",
                                  color: "#303133",
                                  "font-size": "13px",
                                  "font-weight": "500",
                                  "border-bottom": "1px solid #EBEEF5",
                                  padding: "0",
                                  margin: "0",
                                },
                              },
                              [_vm._v(" 表单字段")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "font-size": "14px",
                                  padding: "20px",
                                },
                              },
                              [_vm._v("未查询到字段信息")]
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.setCur === 7 && _vm.type != "1"
        ? _c("div", { staticClass: "form-set-pane fl" }, [
            _c(
              "div",
              { staticClass: "my-export-switch" },
              [
                _c("label", [_vm._v("启用视图")]),
                _c("el-switch", {
                  attrs: { "active-color": "#3476F0" },
                  model: {
                    value: _vm.otherSetting.enableView,
                    callback: function ($$v) {
                      _vm.$set(_vm.otherSetting, "enableView", $$v)
                    },
                    expression: "otherSetting.enableView",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("memberSelect", {
        ref: "fillUserSelect",
        attrs: { agencyId: _vm.agencyId },
      }),
      _c("memberSelect", {
        ref: "memberSelect",
        attrs: { agencyId: _vm.agencyId },
      }),
      _c("memberSelect", {
        ref: "adminMemberSelect",
        attrs: { agencyId: _vm.agencyId, listUserType: "2", showType: [1] },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.field9Dialog.open,
            width: "360px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.field9Dialog, "open", $event)
            },
            close: _vm.onCloseField9Dialog,
          },
        },
        [
          _c(
            "h5",
            { staticStyle: { "margin-top": "-30px", "font-size": "14px" } },
            [_vm._v(_vm._s(_vm.field9Dialog.title))]
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.field9Dialog,
                "label-width": "70px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "图片宽度" } },
                [
                  _c("el-input", {
                    on: { change: _vm.onChangeField9Width },
                    model: {
                      value: _vm.field9Dialog.fieldWidth,
                      callback: function ($$v) {
                        _vm.$set(_vm.field9Dialog, "fieldWidth", $$v)
                      },
                      expression: "field9Dialog.fieldWidth",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片高度" } },
                [
                  _c("el-input", {
                    on: { change: _vm.onChangeField9Height },
                    model: {
                      value: _vm.field9Dialog.fieldHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.field9Dialog, "fieldHeight", $$v)
                      },
                      expression: "field9Dialog.fieldHeight",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-40px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmCopyField9 },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "export-style-title" }, [
      _vm._v("字段设置"),
      _c("span", [
        _vm._v("（对表单内的字段进行自由的排版用于日常单据的导出及打印）"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }