var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auditSelect" },
    [
      _c("div", { staticClass: "auditProcessWrapper" }, [
        _c("div", { staticClass: "auditProcessItemTitle" }, [
          _vm._v(" 可选项 "),
        ]),
        _c(
          "div",
          { staticClass: "auditProcessItemUser" },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                on: { change: _vm.onSelectField },
                model: {
                  value: _vm.selectField,
                  callback: function ($$v) {
                    _vm.selectField = $$v
                  },
                  expression: "selectField",
                },
              },
              _vm._l(_vm.fields, function (item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item.label, value: item.formFieldId },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "auditProcessItemUser",
            staticStyle: {
              width: "200px",
              "text-align": "right",
              "flex-shrink": "0",
            },
          },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onExportClick } },
              [_vm._v("导出")]
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onImportClick } },
              [_vm._v("导入")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        _vm._l(_vm.selectFieldOptions, function (option, index) {
          return _c("div", { key: index, staticClass: "auditProcessWrapper" }, [
            _c("div", { staticClass: "auditProcessItemTitle" }, [
              _vm._v(
                " 选项 " + _vm._s(option.label) + " 的" + _vm._s(_vm.tip) + " "
              ),
            ]),
            _c("div", { staticClass: "auditProcessItemUser" }, [
              !option.auditMember || option.auditMember.length == 0
                ? _c(
                    "div",
                    {
                      staticClass: "designated-member",
                      on: {
                        click: function ($event) {
                          return _vm.selectProcessor(option)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "member-empty" },
                        [
                          _c("svg-icon", {
                            staticStyle: {
                              width: "20px",
                              height: "20px",
                              "margin-right": "5px",
                              color: "#5e6d82",
                              "vertical-align": "-5px",
                            },
                            attrs: { "icon-class": "add" },
                          }),
                          _vm._v("点击添加指定成员 "),
                        ],
                        1
                      ),
                    ]
                  )
                : _c(
                    "ul",
                    {
                      staticClass: "select-list",
                      on: {
                        click: function ($event) {
                          return _vm.selectProcessor(option)
                        },
                      },
                    },
                    _vm._l(option.auditMember, function (item, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "select-item" },
                        [
                          item.type == "member"
                            ? [
                                _c("svg-icon", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                    "vertical-align": "-4px",
                                    "margin-right": "5px",
                                  },
                                  attrs: { "icon-class": "memberIcon" },
                                }),
                              ]
                            : _vm._e(),
                          item.type == "dept"
                            ? [
                                _c("svg-icon", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                    "vertical-align": "-4px",
                                    "margin-right": "5px",
                                  },
                                  attrs: { "icon-class": "deptIcon" },
                                }),
                              ]
                            : _vm._e(),
                          item.type == "role"
                            ? [
                                _c("svg-icon", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                    "vertical-align": "-4px",
                                    "margin-right": "5px",
                                  },
                                  attrs: { "icon-class": "roleIcon" },
                                }),
                              ]
                            : _vm._e(),
                          _c("span", [
                            _vm._v(
                              _vm._s(item.name) +
                                "(" +
                                _vm._s(item.number) +
                                ")"
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "remove-btn",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onAudit1RemoveSelect(
                                    option.auditMember,
                                    item,
                                    index
                                  )
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  width: "12px",
                                  height: "12px",
                                  "vertical-align": "-1px",
                                },
                                attrs: { "icon-class": "del" },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    }),
                    0
                  ),
            ]),
          ])
        }),
        0
      ),
      _c("memberSelect", {
        ref: "memberSelect",
        attrs: { agencyId: _vm.agencyId },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入",
            "append-to-body": "",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: { action: "#", "auto-upload": false },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "trigger", type: "primary" },
                      slot: "trigger",
                    },
                    [_vm._v("选取文件")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("只能上传Excel文件")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.importAndClose },
                },
                [_vm._v("导入并关闭")]
              ),
              _c("el-button", { on: { click: _vm.cancelImport } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }