var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container app-info",
      staticStyle: { "max-width": "100%" },
    },
    [
      _c(
        "el-row",
        { staticStyle: { margin: "0", width: "100%" }, attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { padding: "0" },
              attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
            },
            [
              _c(
                "el-form",
                {
                  ref: "designForm",
                  attrs: {
                    model: _vm.designForm,
                    rules: _vm.designRules,
                    size: "small",
                    "label-width": "80px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "封面图片", prop: "topImg" } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            padding: "0",
                            width: "200px",
                            height: "120px",
                          },
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom",
                                title: "请选择一个封面",
                                width: "500",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "252px",
                                    "overflow-y": "auto",
                                  },
                                },
                                _vm._l(_vm.defaultCover, function (src, index) {
                                  return _c("img", {
                                    key: index,
                                    staticStyle: {
                                      width: "90px",
                                      height: "60px",
                                      padding: "2px 5px",
                                      "border-radius": "8px",
                                      cursor: "pointer",
                                    },
                                    attrs: { src: src },
                                    on: {
                                      click: function ($event) {
                                        _vm.designForm.cover = src
                                      },
                                    },
                                  })
                                }),
                                0
                              ),
                              _c("div", {
                                staticStyle: {
                                  width: "100%",
                                  height: "1px",
                                  border: "0.5px solid #e8e8e8",
                                  margin: "10px 0",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "uploadCover",
                                  on: { click: _vm.editCropper },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "uploadCover-icon" },
                                    [_c("i", { staticClass: "el-icon-upload" })]
                                  ),
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "uploadCover-title" },
                                      [_vm._v("上传封面")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#cecece",
                                          "font-size": "12px",
                                        },
                                      },
                                      [_vm._v(" 600*360px（JPG或PNG）最大2M ")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "200px",
                                    height: "120px",
                                  },
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("div", {
                                    staticClass: "avatar-uploader-hover",
                                  }),
                                  _c("img", {
                                    staticClass: "cover",
                                    attrs: { src: _vm.designForm.cover },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                title: _vm.title,
                                visible: _vm.open,
                                width: "1000px",
                                "append-to-body": "",
                                "close-on-click-modal": false,
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.open = $event
                                },
                              },
                            },
                            [
                              _c("ImgCutter", {
                                ref: "imgCutterModal",
                                attrs: {
                                  isModal: false,
                                  boxWidth: 500,
                                  boxHeight: 400,
                                  cutWidth: 300,
                                  cutHeight: 204,
                                  sizeChange: true,
                                  moveAble: true,
                                  tool: false,
                                  previewMode: true,
                                  crossOrigin: true,
                                  crossOriginHeader: "*",
                                  scaleAble: false,
                                  imgMove: false,
                                  rate: "5:3",
                                },
                                on: {
                                  cutDown: _vm.onCutDownCover,
                                  onPrintImg: _vm.onPrintImg,
                                  onClearAll: _vm.onClearAll,
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    right: "20px",
                                    top: "85px",
                                    border: "1px solid #eee",
                                    width: "450px",
                                    height: "400px",
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "auto",
                                      height: "auto",
                                      float: "right",
                                    },
                                    attrs: { src: _vm.cutDownUrl },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.userType != "1",
                          expression: "userType != '1'",
                        },
                      ],
                      attrs: { label: "创建人" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入创建人名称",
                        },
                        on: { change: _vm.onChangeEdit },
                        model: {
                          value: _vm.designForm.founder,
                          callback: function ($$v) {
                            _vm.$set(_vm.designForm, "founder", $$v)
                          },
                          expression: "designForm.founder",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签" } },
                    [
                      _c("formTagEditor", {
                        attrs: { readonly: false, tagList: _vm.tagList },
                        model: {
                          value: _vm.designForm.tags,
                          callback: function ($$v) {
                            _vm.$set(_vm.designForm, "tags", $$v)
                          },
                          expression: "designForm.tags",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3476f0",
                              cursor: "pointer",
                              "border-bottom": "dashed 1px #bababa",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onProjectLabelClick(1)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.designForm.detailsLabel))]
                        ),
                      ]),
                      _c("richEditor", {
                        attrs: {
                          formId: _vm.formId,
                          agencyId: _vm.agencyId,
                          uploadPath:
                            "fillFile/{agencyId}/{formId}/formDescription/",
                        },
                        model: {
                          value: _vm.designForm.details,
                          callback: function ($$v) {
                            _vm.$set(_vm.designForm, "details", $$v)
                          },
                          expression: "designForm.details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3476f0",
                              cursor: "pointer",
                              "border-bottom": "dashed 1px #bababa",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onProjectLabelClick(2)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.designForm.fileLabel))]
                        ),
                      ]),
                      _c(
                        "el-upload",
                        {
                          ref: "uploader",
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            headers: { Authorization: _vm.Authorization },
                            action: "/oss/endpoint/put-file",
                            multiple: "",
                            data: _vm.uploadParam,
                            "auto-upload": true,
                            "show-file-list": false,
                            "file-list": _vm.designForm.fileList,
                            limit: 5,
                            "http-request": _vm.formFilesUpload.elFileUpload,
                            "before-upload": _vm.onBeforeFileUpload,
                            "on-exceed": _vm.onHandleFileExceed,
                            "on-success": _vm.onHandleFileSuccess,
                            "before-remove": function (params) {
                              return _vm.onBeforeFileRemove(params)
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "border-color": "#3476f0",
                                color: "#3476f0",
                              },
                              attrs: { icon: "el-icon-plus", size: "mini" },
                            },
                            [_vm._v("添加附件")]
                          ),
                        ],
                        1
                      ),
                      _c("fileUploadList", {
                        attrs: {
                          showSize: false,
                          uploader: _vm.uploaderRef,
                          resumeUploadHandler: _vm.uploadResume,
                        },
                        model: {
                          value: _vm.fileList,
                          callback: function ($$v) {
                            _vm.fileList = $$v
                          },
                          expression: "fileList",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "启用设计" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce66" },
                        model: {
                          value: _vm.designForm.enableDesign,
                          callback: function ($$v) {
                            _vm.$set(_vm.designForm, "enableDesign", $$v)
                          },
                          expression: "designForm.enableDesign",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("designView", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.designForm.enableDesign,
                        expression: "designForm.enableDesign",
                      },
                    ],
                    ref: "designView",
                    attrs: { type: "2", businessId: _vm.formId },
                    on: {
                      change: function ($event) {
                        _vm.isEdit = true
                      },
                    },
                  }),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享图标" } },
                    [
                      !_vm.designForm.shareIcon
                        ? _c(
                            "el-upload",
                            {
                              ref: "shareUploader",
                              staticStyle: { "text-align": "left" },
                              attrs: {
                                headers: { Authorization: _vm.Authorization },
                                action: "/oss/endpoint/put-file",
                                multiple: "",
                                data: _vm.uploadParam,
                                "auto-upload": true,
                                "show-file-list": false,
                                "file-list": _vm.shareFileList,
                                limit: 1,
                                "http-request":
                                  _vm.shareFilesUpload.elFileUpload,
                                "on-success": _vm.onHandleShareFileSuccess,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "border-color": "#3476f0",
                                    color: "#3476f0",
                                  },
                                  attrs: { icon: "el-icon-plus", size: "mini" },
                                },
                                [_vm._v("上传")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.designForm.shareIcon
                        ? _c(
                            "div",
                            {
                              staticClass: "preview-share-icon",
                              style: {
                                backgroundImage:
                                  "url('" + _vm.designForm.shareIcon + "')",
                              },
                            },
                            [
                              _c("span", {
                                staticClass: "el-icon-circle-close",
                                on: {
                                  click: function ($event) {
                                    return _vm.clearShareIcon()
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "标题",
            visible: _vm.labelOpen,
            width: "400px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.labelOpen = $event
            },
            close: _vm.onCloseClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "labelForm",
              attrs: {
                model: _vm.selectLabelModel,
                rules: _vm.selectLabelRules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "标题名称", prop: "label" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入标题名称(1-8个字)" },
                            model: {
                              value: _vm.selectLabelModel.label,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectLabelModel, "label", $$v)
                              },
                              expression: "selectLabelModel.label",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onSubmitClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }