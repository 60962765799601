<template>
    <el-row :gutter="20" style="margin: 0">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding: 0;padding-right:20px;box-sizing:border-box">
            <el-form ref="setForm" label-width="130px" class="clearfix form-set-base" label-position="left">
                 <el-form-item label="开启协议">
                    <el-switch v-model="agreement.startFillAgreementStatus" :active-value="true" :inactive-value="false" active-color="#3476F0">
                    </el-switch>
                </el-form-item>
                 <el-form-item label="协议标题" v-if="agreement.startFillAgreementStatus">
                    <el-input v-model="agreement.agreementTitle" placeholder="请输入协议标题" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="协议文本" v-if="agreement.startFillAgreementStatus">
                   <RichEditor :agencyId="agencyId" :formId="formId" uploadPath="fillFile/{agencyId}/{formId}/contact/" v-model="agreement.agreementContent" ></RichEditor>
                </el-form-item>
                <el-form-item label="协议确认方式" v-if="agreement.startFillAgreementStatus">
                    <el-select placeholder="请选择" v-model="agreement.agreementConfirmationMethod">
                        <el-option v-for="item in agreementConfirmationMethod" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>

                </el-form-item>
            </el-form>
        </el-col>
    </el-row>
</template>
<script>
import RichEditor from '@/components/RichEditor'
export default {
    components:{
        RichEditor
    },
    props:{
        value:Object,
        agencyId:"",
        formId:"",
    },
    watch:{
        value:function(val){
            this.agreement=val;
        }
    },
    data(){
        return {
            agreement:{
                agreementContent:"",
                agreementConfirmationMethod:1,
                agreementTitle:"",
                formId:"",
                startFillAgreementStatus:false,
            },
            agreementConfirmationMethod:[
                {label:'同意按钮',value:1},
                {label:'同意按钮(倒计时5秒)',value:2},
                {label:'同意按钮(倒计时15秒)',value:21},
                // {label:'参与人手动输入',value:3}
            ]
        }
    },
    mounted() {
        this.agreement=this.value;
    },
    methods:{
    }
}
</script>
<style scoped>
</style>
