var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticStyle: { margin: "0" }, attrs: { gutter: 20 } },
    [
      _c(
        "el-col",
        {
          staticStyle: {
            padding: "0",
            "padding-right": "20px",
            "box-sizing": "border-box",
          },
          attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
        },
        [
          _c(
            "el-form",
            {
              ref: "setForm",
              staticClass: "clearfix form-set-base",
              attrs: { "label-width": "130px", "label-position": "left" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "提交成功提示" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-color": "#3476F0",
                    },
                    model: {
                      value: _vm.customTip.commitTipShow,
                      callback: function ($$v) {
                        _vm.$set(_vm.customTip, "commitTipShow", $$v)
                      },
                      expression: "customTip.commitTipShow",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.customTip.commitTipShow
            ? _c("RichEditor", {
                attrs: {
                  agencyId: _vm.agencyId,
                  formId: _vm.formId,
                  uploadPath: "fillFile/{agencyId}/{formId}/commitTip/",
                },
                on: { count: _vm.commitTipWordCount },
                model: {
                  value: _vm.customTip.commitTipContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.customTip, "commitTipContent", $$v)
                  },
                  expression: "customTip.commitTipContent",
                },
              })
            : _vm._e(),
          _c(
            "el-form",
            {
              ref: "setForm",
              staticClass: "clearfix form-set-base",
              attrs: { "label-width": "130px", "label-position": "left" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "支付成功提示" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-color": "#3476F0",
                    },
                    model: {
                      value: _vm.customTip.payTipShow,
                      callback: function ($$v) {
                        _vm.$set(_vm.customTip, "payTipShow", $$v)
                      },
                      expression: "customTip.payTipShow",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.customTip.payTipShow
            ? _c("RichEditor", {
                attrs: {
                  agencyId: _vm.agencyId,
                  formId: _vm.formId,
                  uploadPath: "fillFile/{agencyId}/{formId}/payTip/",
                },
                on: { count: _vm.payTipWordCount },
                model: {
                  value: _vm.customTip.payTipContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.customTip, "payTipContent", $$v)
                  },
                  expression: "customTip.payTipContent",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }