<template v-cloak>
  <div class="clearfix app-form-set">
    <div class="form-set-menu fl left-menu">
      <div class="menu-item" @click="onTabClick(1)" :class="{ active: setCur === 1 }">
        <svg-icon icon-class="myFormSubmit" class="tab-title-icon" />
        提交设置
      </div>
      <div class="menu-item" @click="onTabClick(2)" :class="{ active: setCur === 2 }" v-if="type != '1'">
        <svg-icon icon-class="myFormPay" class="tab-title-icon" />
        支付设置
      </div>
      <div class="menu-item" @click="onTabClick(3)" :class="{ active: setCur === 3 }" v-if="type != '1'">
        <svg-icon icon-class="myFormPower" class="tab-title-icon" />
        填报设置
      </div>
      <div class="menu-item" @click="onTabClick(4)" :class="{ active: setCur === 4 }" v-if="type != '1'">
        <svg-icon icon-class="myFormReview" class="tab-title-icon" />
        审核设置
      </div>
      <div class="menu-item" @click="onTabClick(5)" :class="{ active: setCur === 5 }" v-if="type != '1'">
        <svg-icon icon-class="myFormSee" class="tab-title-icon" />
        管理权限
      </div>
      <div class="menu-item" @click="onTabClick(6)" :class="{ active: setCur === 6 }" v-if="type != '1'">
        <svg-icon icon-class="myFormExport" class="tab-title-icon" />
        导出设置
      </div>
      <div class="menu-item" @click="onTabClick(7)" :class="{ active: setCur === 7 }" v-if="type != '1'">
        <svg-icon icon-class="myOtherSet" class="tab-title-icon" />
        其他设置
      </div>
    </div>
    <div style="width:16px"></div>
    <div class="form-set-pane fl" v-if="setCur === 1">
      <el-row :gutter="20" style="margin: 0">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding: 0">
          <el-form ref="setForm" :model="setForm" :rules="setRules" label-width="130px" class="clearfix form-set-base" label-position="left">
            <el-form-item>
              <span slot="label" class="project-label">起止时间</span>
              <date-picker v-model="setForm.fillStartTime" :disabled-date="notBeforeFillEnd" :disabled-time="notBeforeTimeEnd" placeholder="请选择开始时间" format="YYYY-M-D HH:mm" type="datetime" :default-value="startDefaultValue" value-type="format" :minute-step="10" @clear="onClearFillStart"
                           @change="onChangeEdit"></date-picker>
              <span style="margin: 0 20px;">至</span>
              <date-picker v-model="setForm.fillEndTime" :disabled-date="notBeforeFillStart" :disabled-time="notBeforeTimeStart" placeholder="请选择结束时间" format="YYYY-M-D HH:mm" type="datetime" :default-value="endDefaultValue" value-type="format" :minute-step="10" @clear="onClearFillEnd"
                           @pick="onOpenFillEnd" @change="onChangeEdit"></date-picker>

              <span v-if="setForm.fillEndTime">
                <el-checkbox v-model="setForm.endtimeModify" style="margin:0px 10px;display:inline-block;">到期后允许修改</el-checkbox>
              </span>
            </el-form-item>
            <el-form-item label="允许多次填报" prop="allowSomeFill">
              <el-switch v-model="setForm.fillRepeatedly" active-color="#3476F0" style="margin-right: 20px" @change="onChangeEdit"></el-switch>
              <template v-if="setForm.fillRepeatedly">
                <el-radio v-model="setForm.fillRepeatedlyRule" label="1" @change="onChangeEdit">不限制</el-radio>
                <el-radio v-model="setForm.fillRepeatedlyRule" label="2" @change="onChangeEdit">每天1次</el-radio>
                <el-radio v-model="setForm.fillRepeatedlyRule" label="3" @change="onChangeEdit">每天2次</el-radio>
                <el-radio v-model="setForm.fillRepeatedlyRule" label="4" @change="onChangeEdit">每周1次</el-radio>
                <el-radio v-model="setForm.fillRepeatedlyRule" label="5" @change="onChangeEdit">每月1次</el-radio>
              </template>
            </el-form-item>
            <el-form-item label="填报人数上限" prop="fillNum">
              <el-switch v-model="setForm.allowFillMax" active-color="#3476F0" style="margin-right: 20px" @change="onChangeEdit"></el-switch>
              <el-input-number v-model="setForm.fillNum" @change="onHandleFillNum" :min="1" :max="setForm.allowFillMax ? (type == '1' ? 500 : Infinity) : Infinity" v-show="setForm.allowFillMax"></el-input-number>
            </el-form-item>
            <el-form-item label="公开填报人数" prop="isPublic">
              <el-switch v-model="setForm.fillNumPublic" active-color="#3476F0" @change="onChangeEdit"></el-switch>
            </el-form-item>
            <el-form-item label="是否开启批量导入" prop="isPublic">
              <el-switch v-model="setForm.isBatchFill" active-color="#3476F0" @change="onChangeEdit"></el-switch>
            </el-form-item>
            <el-form-item label="开始填报按钮文字" prop="isPublic">
              <el-input v-model="setForm.startFillText" @change="onChangeEdit" style="width:100px" :maxlength="6" size="mini"></el-input>
            </el-form-item>
            <!-- <el-form-item label="加密规则配置" prop="allowSomeFill">
              <el-switch v-model="setForm.enableEncryptRules" active-color="#3476F0" style="margin-right: 20px"
                @change="onChangeEdit"></el-switch>
                <encrypt-rules v-if="setForm.enableEncryptRules" :formId="formId"></encrypt-rules>
            </el-form-item> -->
          </el-form>
        </el-col>
      </el-row>
      <commitSetting :formId="formId" :agencyId="agencyId" v-model="customTip"></commitSetting>
      <agreementSetting :formId="formId" :agencyId="agencyId" v-model="agreement"></agreementSetting>
    </div>

    <div class="form-set-pane fl" v-if="setCur === 2 && type != '1'">
      <el-row :gutter="20" style="margin: 0">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding: 0">
          <el-form ref="setForm" :model="setForm" :rules="setRules" label-width="120px" class="clearfix form-set-base" label-position="left">
            <el-form-item label="设置填报支付" prop="pay">
              <el-switch v-model="setForm.pay" active-color="#3476F0" style="margin-right: 20px" @change="onChangeEdit"></el-switch>
            </el-form-item>
            <el-form-item v-if="setForm.pay">
              <el-radio v-model="setForm.payType" label="1" @change="onRadioPayType">单个支付金额</el-radio>
              <el-radio v-model="setForm.payType" label="2" @change="onRadioPayType">根据选项项目设置支付金额</el-radio>
            </el-form-item>
            <el-form-item v-if="setForm.pay && setForm.payType == '1'">
              <el-input type="text" placeholder="请输入支付金额" v-model="setForm.payPrice" v-show="setForm.pay" style="width:200px; display: inline-block;" @change="onBlurPayPrice"></el-input>
              <span style="margin-left: 10px" v-show="setForm.pay">元</span>
            </el-form-item>
            <el-form-item v-if="setForm.pay && setForm.payType == '2'">
              <el-select placeholder="请选择" v-model="setForm.formFieldId" @change="onSelectPayOption">
                <el-option v-for="item in setForm.payPriceFiledArr" :key="item" :label="item.label" :value="item.formFieldId"></el-option>
              </el-select>
              <div class="option-list" v-if="setForm.pay2OptionShow" style="width: 100%; display: flex; display: -webkit-flex; flex-direction: column; margin-top: 15px;">
                <div class="option-list-item" v-for="(optionItem, optionIndex) in setForm.payFieldList" style="width: 100%; margin-bottom: 15px;">
                  <label style="margin-right: 10px;">选项{{ optionIndex + 1 }}：{{ optionItem.label }}</label>
                  <el-input v-model="optionItem.payPrice" placeholder="请输入支付金额" style="width: 30%;" @change="onChangeEdit"></el-input>
                  <span style="margin-left: 10px;">元</span>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="支付接口设置" prop="pay" style="margin-bottom:0px">
              <el-switch v-model="setForm.mch" active-color="#3476F0" style="margin-right: 20px" @change="onChangeEdit" disabled></el-switch>
              <span v-if="setForm.mchName">{{ setForm.mchName }}</span>
            </el-form-item>
            <el-form-item>
              <div style="color: #3476F0;">
                此功能为高级版功能。开启后，提交人费用可不经过鲸鱼填报，实时支付到机构指定的微信商户。开启此功能需要技术人员后台配置，若要了解更多，请直接与我们市场与客服联系。</div>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>

    <div class="form-set-pane fl" v-if="setCur === 3 && type != '1'">

      <div class="fill-set-wrapper">
        <el-radio v-model="setForm.fillAuth" label="1" @change="onRadioFillType">所有人可填</el-radio>
        <el-radio v-model="setForm.fillAuth" label="2" @change="onRadioFillType">机构用户可填</el-radio>
        <el-radio v-model="setForm.fillAuth" label="3" @change="onRadioFillType">指定成员可填</el-radio>
        <el-radio v-model="setForm.fillAuth" label="4" @change="onRadioFillType">历史表单人员可填</el-radio>
        <el-radio v-model="setForm.fillAuth" label="5" @change="onRadioFillType">指定手机号可填</el-radio>
      </div>

      <div class="fill-set-pane">

        <template v-if="setForm.fillAuth == '3'">
          <ul v-if="selectAllFillArr && selectAllFillArr.length > 0 && isFillConfirmBtn" class="select-list" @click="onFillSelectMemberClick">
            <li v-for="item in selectAllFillArr" class="select-item">
              <template v-if="item.type == 'member'">
                <svg-icon icon-class="memberIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
              </template>
              <template v-if="item.type == 'dept'">
                <svg-icon icon-class="deptIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
              </template>
              <template v-if="item.type == 'role'">
                <svg-icon icon-class="roleIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
              </template>
              <span>{{ item.name }}</span>
            </li>
          </ul>
          <div class="designated-member" @click="onFillSelectMemberClick" v-else>
            <span class="member-empty">
              <svg-icon icon-class="add" style="width: 20px; height: 20px; margin-right: 5px; color: #5e6d82; vertical-align: -5px;" />点击添加指定成员
            </span>
          </div>
        </template>

        <template v-if="setForm.fillAuth == '4'">
          <el-select v-model="setForm.fillAuthHistoryFormId" placeholder="请选择历史表单" filterable remote reserve-keyword :remote-method="onRemoteSearch" clearable="true" @change="onSelectHistoryFormName" style="width: 50%">
            <el-option v-for="item in historyFormList" :key="item.formId" :label="item.projectName ? item.projectName + ' / ' + item.formName : item.formName" :value="item.formId"></el-option>
          </el-select>
          <div class="fill-set-history-select">
            <el-radio v-model="setForm.fillAuthHistorySelect" label="1" @change="onRadioHistorySelect">已填报人</el-radio>
            <el-radio v-model="setForm.fillAuthHistorySelect" label="2" @change="onRadioHistorySelect">审核通过</el-radio>
            <el-radio v-model="setForm.fillAuthHistorySelect" label="3" @change="onRadioHistorySelect">审核不通过</el-radio>
          </div>
        </template>

        <template v-if="setForm.fillAuth == '5'">
          <div class="fill-set-item-title">录入手机号 (每行一个)</div>
          <div>
            <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 25 }" v-model="setForm.fillAuthAllowPhone">

            </el-input>
          </div>
        </template>
      </div>

      <div class="fl">
        <div class="form-data-switch">
          <label>匿名填报</label>
          <el-switch v-model="setForm.fillAnony" active-color="#3476F0"></el-switch>
        </div>
      </div>
    </div>

    <div class="form-set-pane fl" v-if="setCur === 4 && type != '1'">

      <div class="form-data-switch">
        <label>开启审核功能</label>
        <el-switch v-model="formAuditAuth.auditAuth" active-color="#3476F0" @change="onChangeEdit"></el-switch>
      </div>
      <div class="review-flow-pane" v-show="formAuditAuth.auditAuth">
        <setAuditLevel ref="auditLevel" :agencyId="agencyId" :fieldList="fieldList" :getFormField="getAuditFormField" :formId="formId" @change="onAuditChangeEdit"></setAuditLevel>
      </div>
      <div class="form-data-switch" style="margin-top:20px" v-if="formAuditAuth.auditAuth">
        <label>手写签名</label>
        <el-switch v-model="formAuditAuth.auditAuthSign" active-color="#3476F0" @change="onChangeEdit"></el-switch>
      </div>
      <div class="form-data-switch" style="margin-top:20px" v-if="formAuditAuth.auditAuth" :style="{ 'marginTop1': (formAuditAuth.auditAuth ? '' : '20px') }">
        <label>是否允许修改审核状态</label>
        <el-switch v-model="formAuditAuth.auditAuthReUpdate" active-color="#3476F0" @change="onChangeEdit"></el-switch>
      </div>
      <div class="form-data-switch" style="margin-top:20px" v-if="formAuditAuth.auditAuth" :style="{ 'marginTop1': (formAuditAuth.auditAuth ? '' : '20px') }">
        <label>审核意见必填</label>
        <el-switch v-model="formAuditAuth.auditAuthReviewRemark" active-color="#3476F0" @change="onChangeEdit"></el-switch>
      </div>
      <div class="form-data-switch" style="margin-top:20px" v-if="formAuditAuth.auditAuth && formAuditAuth.auditAuthSign" :style="{ 'marginTop1': (formAuditAuth.auditAuth ? '' : '20px') }">
        <label>审核签字必填</label>
        <el-switch v-model="formAuditAuth.auditAuthReviewSign" active-color="#3476F0" @change="onChangeEdit"></el-switch>
      </div>
    </div>

    <div class="form-set-pane fl" v-if="setCur === 5 && type != '1'">

      <div class="form-data-switch">
        <label>仅开启数据管理权限</label>
        <el-switch v-model="formViewAuth.viewAuth" active-color="#3476F0" @change="onChangeEdit"></el-switch>
      </div>

      <div class="data-view-select" v-if="formViewAuth.viewAuth">
        <el-checkbox-group v-model="formViewAuth.viewAuthType" @change="onDataManageTypeChecked" style="display:inline-flex">
          <el-checkbox label="1" value="1">指定成员查看</el-checkbox>
          <el-checkbox label="2" value="2">部门管理员查看</el-checkbox>
          <el-checkbox label="3" value="3">根据选项设置管理员</el-checkbox>
        </el-checkbox-group>
        <!-- 指定成员查看 -->
        <div class="dept-admin" v-if="Array.isArray(formViewAuth.viewAuthType) && formViewAuth.viewAuthType.find(x => x === '1')">
          <ul v-if="selectAllViewArr && selectAllViewArr.length > 0 && isViewConfirmBtn" class="select-list" @click="onViewSelectMemberClick">
            <li v-for="item in selectAllViewArr" class="select-item">
              <template v-if="item.type == 'member'">
                <svg-icon icon-class="memberIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
              </template>
              <template v-if="item.type == 'dept'">
                <svg-icon icon-class="deptIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
              </template>
              <template v-if="item.type == 'role'">
                <svg-icon icon-class="roleIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
              </template>
              <span>{{ item.name }}</span>
            </li>
          </ul>
          <div class="designated-member" @click="onViewSelectMemberClick" v-else>
            <span class="member-empty">
              <svg-icon icon-class="add" style="width: 20px; height: 20px; margin-right: 5px; color: #5e6d82; vertical-align: -5px;" />点击添加指定成员
            </span>
          </div>
        </div>

        <!-- 部门管理员查看 -->
        <div class="dept-admin" v-if="Array.isArray(formViewAuth.viewAuthType) && formViewAuth.viewAuthType.find(x => x === '2')">
          <el-radio v-model="formViewAuth.viewAuthAgencyLeaderLevel" label="1" @change="onChangeEdit">一级部门主管</el-radio>
          <el-radio v-model="formViewAuth.viewAuthAgencyLeaderLevel" label="2" @change="onChangeEdit">二级部门主管</el-radio>
          <el-radio v-model="formViewAuth.viewAuthAgencyLeaderLevel" label="3" @change="onChangeEdit">三级部门主管</el-radio>
          <el-radio v-model="formViewAuth.viewAuthAgencyLeaderLevel" label="4" @change="onChangeEdit">四级部门主管</el-radio>
          <el-radio v-model="formViewAuth.viewAuthAgencyLeaderLevel" label="5" @change="onChangeEdit">五级部门主管</el-radio>
        </div>

        <!-- 根据选项设置管理员 -->
        <div class="dept-admin" v-if="Array.isArray(formViewAuth.viewAuthType) && formViewAuth.viewAuthType.find(x => x === '3')">
          <setAuditByUserSelect :value="formViewAuth" :fields="managefieldList" v-if="managefieldList.length > 0" tip="管理员" :fieldList="managefieldList" :agencyId="agencyId" @change="onManageUserSelectByField"></setAuditByUserSelect>
        </div>

      </div>

      <div class="form-data-switch">
        <label>开启全部管理权限
          <!-- <help tipText="仅管理员用户"></help> -->
        </label>
        <el-switch v-model="formAdminAuth.adminAuth" active-color="#3476F0" @change="onChangeEdit"></el-switch>
      </div>
      <el-alert type="warning" v-if="formAdminAuth.adminAuth" :closable="false" style="margin-top:10px;margin-bottom:-15px" title="仅能选择机构中的子管理员用户"></el-alert>
      <div class="dept-admin" v-if="formAdminAuth.adminAuth">
        <ul v-if="selectAllAdminArr && selectAllAdminArr.length > 0" class="select-list" @click="onAdminSelectMemberClick">
          <li v-for="(item,index) in selectAllAdminArr" :key="index" class="select-item">
            <template v-if="item.type == 'member'">
              <svg-icon icon-class="memberIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
            </template>
            <template v-if="item.type == 'dept'">
              <svg-icon icon-class="deptIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
            </template>
            <template v-if="item.type == 'role'">
              <svg-icon icon-class="roleIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
            </template>
            <span>{{ item.name }}</span>
          </li>
        </ul>
        <div class="designated-member" @click="onAdminSelectMemberClick" v-else>
          <span class="member-empty">
            <svg-icon icon-class="add" style="width: 20px; height: 20px; margin-right: 5px; color: #5e6d82; vertical-align: -5px;" />点击添加指定成员
          </span>
        </div>
      </div>

    </div>

    <div class="form-set-pane fl" v-if="setCur === 6 && type != '1'">

      <div class="setting-title">
        <div class="setting-title-upload" style="flex-wrap:nowrap;">
          <span>模板设置</span>
          <div style="display:inline-block;">
            <div style="display:inline-block;">
              <el-upload class="upload-demo" ref="exportUpload" action="" accept=".doc, .docx" :file-list="exportSetting.fileList" :show-file-list="false" :before-upload="onBeforeExportFileUpload" :http-request="onHttpExportFileUpload" :on-change="onHandleExportFileChange"
                         :on-success="(params) => onHandleExportFileSuccess(params)" :on-remove="(response) => onHandleExportFileRemove(params)">
                <el-button size="medium" icon="el-icon-upload">{{ exportSetting.fileList && exportSetting.fileList.length >
                  0 ? '更换模板' : '添加模板' }}</el-button>
              </el-upload>
            </div>
            <div style="display:inline;margin-left:20px;margin-right:50px;word-break:break-all;white-space:normal;" class="setting-title-filename" v-if="exportSetting.fileList && exportSetting.fileList.length > 0" @click="onExportTemplate">{{ exportSetting.fileList[0].name }}</div>
            <!-- </div>
                <div> -->
            <el-button type="text" size="mini" style="height: 36px;" @click="onDeleteExportTemplate" v-if="isHasExportTemplate">删除模板</el-button>
            <el-button type="text" size="mini" style="height: 36px;" @click="onExportTemplate" v-if="isHasExportTemplate">模板下载</el-button>
          </div>
        </div>
      </div>

      <div class="my-export-switch" v-if="!noExportFile">
        <label>导出格式</label>
        <el-radio-group v-model="exportSetting.exportIsPdf">
          <el-radio :label="false" style="width:70px;margin:0px;">Word</el-radio>
          <el-radio :label="true" style="width:60px;margin:0px;">Pdf</el-radio>
        </el-radio-group>
        <!-- <el-switch v-model="exportSetting.exportAfterAudit" active-color="#3476F0"></el-switch> -->
        
      </div>
      <div class="my-export-switch" v-if="!noExportFile">
        <label>仅能在审核之后导出</label>
        <el-switch v-model="exportSetting.exportAfterAudit" active-color="#3476F0"></el-switch>        
      </div>
      <div class="my-export-switch" v-if="exportSetting.exportIsPdf">
        <label>PDF水印文字</label>
        <el-input v-if="exportSetting.exportIsPdf" v-model="exportSetting.waterMark" :disabled="noExportFile" size="mini" style="width:180px" placeholder="如不需要水印可为空"></el-input>
      </div>
      <div class="my-export-switch" v-if="exportSetting.fileList && exportSetting.fileList.length > 0">
        <label>导出文件名格式</label>
        <exportFileName v-model="exportSetting.exportName" :widget-list="fieldList" :system-field-list="exportSystemField"></exportFileName>
      </div>
      <div class="my-export-switch">
        <label>允许填报人导出</label>
        <el-switch v-model="exportSetting.exportIsPerson" :disabled="noExportFile" active-color="#3476F0" @change="onCheckPersonExport"></el-switch>
      </div>
      <div class="setting-export-style">

        <h1 class="export-style-title">字段设置<span>（对表单内的字段进行自由的排版用于日常单据的导出及打印）</span></h1>
        <!-- accordion -->
        <el-collapse style="width: 100%;">
          <el-collapse-item title="系统字段" name="1">
            <div class="system-field">
              <div class="system-field-table">
                <p class="field-head">
                  <span>系统字段</span>
                  <span>word模板字段</span>
                </p>
                <ul class="field-body">
                  <li v-for="item in exportSystemField">
                    <span>{{ item.field }}</span>
                    <span title="点击复制" @click="onCopyTemplateField(item.templateField)">{{ item.templateField }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="表单字段" name="2" v-if="fieldList && fieldList.length > 0">
            <div class="form-field">
              <table border="">
                <tr>
                  <td width="250" class="row" style="background-color:#f0f2f5">表单字段</td>
                  <td class="display-name" style="background-color:#f0f2f5">word模板字段</td>
                </tr>
                <tr :class="[item.type]" v-for="(item, index) in fieldList" :key="index">
                  <td align="center">{{ item.displayName }}</td>
                  <td :class="[item.type,'copyable']">
                    <div style="text-align:left;" v-if="item.type != 'sub-form' && item.type != 'fill-select'">
                      <div title="点击复制" class="display-name" @click="onCopyTemplateField">{{ brachText(item) }}</div>
                    </div>
                    <div v-else-if="item.type == 'sub-form'" style="display:inline-flex;overflow-x:auto;width:100%">
                      <table class="subform-column">
                        <tr>
                          <td class="subform-display-name" v-for="(subItem, subIndex) in item.widgetList" :key="subIndex">{{ subItem.displayName }}</td>
                        </tr>
                        <tr>
                          <td title="点击复制" @click="onCopyTemplateField" class="subform-name" v-for="(subItem, subIndex) in item.widgetList" :key="subIndex">{{ brachText(item, item.widgetList, subIndex) }}</td>
                        </tr>
                      </table>
                    </div>
                    <div v-else-if="item.type == 'fill-select'" style="display:inline-flex;">
                      <div v-for="(fillItem, fillIndex) in item.fields" :key="fillIndex" class="subform-column">
                        <div class="subform-display-name">{{ fillItem.displayName }}</div>
                        <div title="点击复制" class="subform-name">{{ brachText(item, item.fields, fillIndex) }}</div>
                      </div>
                      <table class="subform-column">
                        <tr>
                          <td class="subform-display-name" v-for="(fillItem, fillIndex) in item.fields" :key="fillIndex">{{ fillItem.displayName }}</td>
                        </tr>
                        <tr>
                          <td title="点击复制" @click="onCopyTemplateField" class="subform-name" v-for="(fillItem, fillIndex) in item.widgetList" :key="fillIndex">{{ brachText(item, item.fields, fillIndex) }}</td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="form-field" style="display:none;">
              <div class="col-display-name">
                <div class="row">表单字段</div>
                <!-- {{fieldList}} -->
                <div class="row" :class="[item.type]" v-for="(item, index) in fieldList" :key="index">{{ item.displayName }}
                </div>
              </div>
              <div class="col-name">
                <div class="row" style="text-align:left;">
                  <div class="display-name">word模板字段</div>
                </div>
                <div class="row field-name" :class="[item.type]" v-for="(item, index) in fieldList" :key="index" @click="onCopyTemplateField">
                  <div style="text-align:left;" v-if="item.type != 'sub-form' && item.type != 'fill-select'">
                    <div title="点击复制" class="display-name">{{ brachText(item) }}</div>
                  </div>
                  <div v-else-if="item.type == 'sub-form'" style="display:inline-flex;">
                    <div v-for="(subItem, subIndex) in item.widgetList" :key="subIndex" class="subform-column">
                      <div class="subform-display-name">{{ subItem.displayName }}</div>
                      <div title="点击复制" class="subform-name">{{ brachText(item, item.widgetList, subIndex) }}</div>
                    </div>
                  </div>
                  <div v-else-if="item.type == 'fill-select'" style="display:inline-flex;">
                    <div v-for="(fillItem, fillIndex) in item.fields" :key="fillIndex" class="subform-column">
                      <div class="subform-display-name">{{ fillItem.displayName }}</div>
                      <div title="点击复制" class="subform-name">{{ brachText(item, item.fields, fillIndex) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex-grow:1;flex-shrink:5;display:inline-block"></div>
            </div>
          </el-collapse-item>
          <div class="system-field" v-else style="width: 100%">
            <h3 style="height: 48px; line-height: 48px; color: #303133; font-size: 13px; font-weight: 500;border-bottom: 1px solid #EBEEF5; padding: 0; margin: 0;">
              表单字段</h3>
            <div style="text-align: center; font-size: 14px; padding: 20px">未查询到字段信息</div>
          </div>
        </el-collapse>

      </div>
    </div>

    <div class="form-set-pane fl" v-if="setCur === 7 && type != '1'">
      <div class="my-export-switch">
        <label>启用视图</label>
        <el-switch v-model="otherSetting.enableView" active-color="#3476F0"></el-switch>
      </div>
    </div>


    <memberSelect ref="fillUserSelect" :agencyId="agencyId"></memberSelect>
    <!--新版 3.0.0.1 填报人设置---指定成员可填写-->

    <!--新版 3.0.0.1 查看人设置---指定成员可填写-->
    <memberSelect ref="memberSelect" :agencyId="agencyId"></memberSelect>
    <!--新版 4.19 管理员成员设置---指定成员可填写-->
    <memberSelect ref="adminMemberSelect" :agencyId="agencyId" listUserType="2" :showType="[1]"></memberSelect>
    <!--新版 4.19 管理员成员设置---指定成员可填写-->

    <!--模板导出 针对type==9 图片字段复制-->
    <el-dialog :visible.sync="field9Dialog.open" width="360px" append-to-body @close="onCloseField9Dialog">
      <h5 style="margin-top: -30px; font-size: 14px;">{{ field9Dialog.title }}</h5>
      <el-form ref="form" :model="field9Dialog" label-width="70px" label-position="left">
        <el-form-item label="图片宽度">
          <el-input v-model="field9Dialog.fieldWidth" @change="onChangeField9Width"></el-input>
        </el-form-item>
        <el-form-item label="图片高度">
          <el-input v-model="field9Dialog.fieldHeight" @change="onChangeField9Height"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -40px;">
        <el-button type="primary" @click="onConfirmCopyField9">确 定</el-button>
      </div>
    </el-dialog>
    <!--模板导出 针对type==9 图片字段复制-->
  </div>
</template>

<script>
function fn (option) {
  var json = {}
  var arr = []
  option.forEach((item) => {
    if (!json[item.name]) {
      json[item.name] = true
      arr.push(item)
    }
  })
  return arr
}
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-cn'
import { globalBus } from '@/utils/globalBus'
import { getSessionStorageObj, setLocalStorageObj, setSessionStorageObj } from '@/utils/db'
import { formDetail, historyFormList, formFieldList, formPayInfo, formFillAuthDetail, formViewAuthDetail, formAuditAuthDetail, formAuditAuthFieldDetail, httpFormSubmit, httpFormPay, httpFormFillAuth, httpFormAuditAuth, httpFormViewAuth, httpFormAdminAuth, httpFormExport, httpFormOtherSetting, httpFormTipSave, getCustomTip, saveFormAgreement, formAgreementInfo, fillAuthAllowPhone } from '@/api/system/form'
import { agencyDeptList, agencyRoleList, agencyFillUserList, addAllFillUser, agencyUserList, agencyUserMemberList, agencySubAdminList } from '@/api/system/agency'
import { getDicts } from '@/api/system/config'
import { getToken } from '@/utils/auth'
import commitSetting from './setTabs/commitSetting.vue'
import agreementSetting from './setTabs/agreementSetting.vue'
import EncryptRules from './setTabs/encryptRules.vue'
import setAuditBySelect from './setAuditBySelect.vue';
import setAuditLevel from './setAuditLevel.vue'
import { deepClone } from '@/utils/deepClone'
import memberSelect from './setAuditMemberSelect.vue'
import exportFileName from './exportFileName'
import setAuditByUserSelect from './setAuditBySelect.vue';

import help from '@/components/Help'
export default {
  props: {
    formId: {
      type: String
    },
    isEdit: {
      type: Boolean
    },
    agencyId: {
      type: String | Number
    },
    projectId: {
      type: String | Number
    }
  },
  components: {
    Treeselect,
    DatePicker,
    commitSetting,
    agreementSetting,
    EncryptRules,
    setAuditBySelect,
    setAuditByUserSelect,
    setAuditLevel,
    memberSelect,
    exportFileName,
    help
  },
  inject: ['reload'],
  data () {
    return {
      cur: 1,
      minuteOptions: ['00', '10', '20', '30', '40', '50'],
      userInfo: {},
      setForm: {
        fillStartTime: '',
        fillEndTime: '',
        fillRepeatedly: true, // 是否可以多次填报
        fillRepeatedlyRule: '1',
        allowFillMax: false, // 是否设置填报人数上限
        fillNum: null, // 填报人数上限
        fillNumPublic: false, // 是否公开填报人数
        isBatchFill: false, // 是否开启批量导入设置
        pay: false, // 是否开启支付
        payType: '1', // 开启支付类型，1-单个支付金额，2-根据选项设置支付金额
        payPrice: null, // 开启支付, 对应支付类型中的1，单个支付金额
        formFieldId: null, // 开启支付, 对应支付类型中的2，选择某个单选或下拉字段
        payPriceFiledArr: [], // 支付金额对应支付类型中的2，即单选和下拉字段必填的数组集合
        pay2OptionShow: false,
        payFieldList: [], // 选择某个单选或下拉字段，然后组合金额的数组
        mch: false, // 是否开通自定义商户
        mchName: null, // 商户名
        startFillText: "",//填报按钮文字
        // 新版 3.0.0.1 填报人设置
        fillAuth: '1', // 所有人可填、机构用户可填、指定成员可填、历史表单人员可填
        fillAuthHistoryFormId: '', // 历史表单ID
        fillAuthHistorySelect: '', // 历史表单选择人员：1不限制、2审核通过、3审核不通过
        fillAuthAllowPhone: '',
        managerUserId: '',
        // 是否开启匿名填报
        fillAnony: false,
        // 是否开启加密规则配置
        enableEncryptRules: false,
        // 允许到期后修改
        endtimeModify: false
      },
      selectCur: 1,
      setRules: {},
      fillPickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
        onPick (dateRange) {
          if (!dateRange.maxDate) {
            return
          }
        }
      },
      type: null,
      startDefaultValue: new Date().setHours(0, 0),
      endDefaultValue: new Date().setHours(0, 0),
      // 3.0.0 监听是否表单有改动
      isSetEdit: false,
      // 新版 3.0.0
      setCur: 1,
      // 新版 3.0.0.1 填报人设置
      memberSelect: {
        open: false
      },
      FillCur: 1,
      // 新版 3.0.0.1 填报人设置---历史表单人员可填
      historyFormList: [],
      // 新版 3.0.0.1 填报人设置---选择的所有填报人
      selectAllFillArr: [],
      // 填报人设置---选择成员搜索框宽度变化标识
      isFillFocus: false,
      isFillConfirmBtn: false,
      // 所有成员列表
      memberParams: {
        agencyId: null,
        searchTxt: ''
      },
      memberList: [],
      // 组织架构树形
      fillDeptOptions: [],
      deptOptions: [],
      audit1DeptOptions: [],
      audit2DeptOptions: [],
      deptTreeProps: {
        children: 'children',
        label: 'deptName'
      },
      // 角色架构树形
      fillRoleOptions: [],
      roleOptions: [],
      audit1RoleOptions: [],
      audit2RoleOptions: [],
      roleTreeProps: {
        children: 'children',
        label: 'roleName'
      },
      // 新版 3.0.0.1 查看人设置
      formViewAuth: {
        viewAuth: false,
        viewAuthType: '',
        viewAuthAgencyLeaderLevel: '',
        viewAuthFieldList: []
      },
      formAdminAuth: {
        adminAuth: false
      },
      viewMemberSelect: {
        open: false
      },
      viewCur: 1,
      // 查看人设置---选择的所有查看人
      selectAllViewArr: [],
      // 管理人设置---选择的所有查看人
      selectAllAdminArr: [],
      // 查看人设置---选择成员搜索框宽度变化标识
      isViewFocus: false,
      isViewConfirmBtn: false,
      isAdminConfirmBtn: false,
      // 新版 3.0.0.1 审核设置
      formAuditAuth: {
        auditAuth: false,
        auditAuthSign: false,
        auditAuthReviewRemark: false,
        auditAuthReviewSign: false,
        // 一级审核
        auditAuth1Show: false,
        auditAuth1: '1', // 一级审核类型：1创建人审核全部、2指定成员审核、3部门负责人审核
        auditAuth1AgencyLeaderLevel: '', // 一级审核类型---部门负责人审核---部门管理员级别
        // 二级审核
        auditAuth2Show: false,
        auditAuth2: '', // 二级审核类型：1创建人审核全部、2指定成员审核、3部门负责人审核
        auditAuth2AgencyLeaderLevel: '', // 二级审核类型---部门负责人审核---部门管理员级别
        auditAuthReUpdate: false, // 是否允许修改审核状态
        auditAuthLevel: null, // 代表几级审核
        clickNum: 0, // 代表添加几级审核
      },
      // 审核人设置---一级审核
      audit1MemberSelect: {
        open: false
      },
      // 审核人设置---一级审核---指定成员审核---选择的所有审核人
      audit1Cur: 1,
      selectAllAudit1Arr: [],
      isAudit1Focus: false,
      isAudit1ConfirmBtn: false,
      // 审核人设置---二级审核
      audit2MemberSelect: {
        open: false
      },
      // 审核人设置---二级审核---指定成员审核---选择的所有审核人
      audit2Cur: 1,
      selectAllAudit2Arr: [],
      isAudit2Focus: false,
      isAudit2ConfirmBtn: false,
      // 新版 3.0.0.1 导出设置
      exportSetting: {
        file: {}, // 提交file
        fileList: [], // 显示上传fileList
        exportIsPerson: false, // 允许个人导出
        exportIsPdf: false, // 允许导出pdf
        waterMark: '', // 水印
        exportName: [], // 导出文件名格式
        exportAfterAudit: false, // 仅能审核后导出文件
      },
      exportSystemField: [
        {
          field: '填报人',
          templateField: '${填报人}'
        },
        {
          field: '填报时间',
          templateField: '${填报时间}'
        },
        {
          field: '填报人编号',
          templateField: '${填报人编号}'
        },
        {
          field: '修改时间',
          templateField: '${修改时间}'
        },
        {
          field: '导出人',
          templateField: '${导出人}'
        },
        {
          field: '导出时间',
          templateField: '${导出时间}'
        },
        {
          field: '导出人',
          templateField: '${导出人}'
        },
        {
          field: '填报人一级部门',
          templateField: '${填报人一级部门}'
        },
        {
          field: '填报人二级部门',
          templateField: '${填报人二级部门}'
        },
        {
          field: '填报人三级部门',
          templateField: '${填报人三级部门}'
        },
        {
          field: '填报人四级部门',
          templateField: '${填报人四级部门}'
        },
        {
          field: '填报人五级部门',
          templateField: '${填报人五级部门}'
        }
      ],
      exportFormField: [],
      // 针对type==9 图片字段复制
      field9Dialog: {
        title: '',
        open: false,
        field: '',
        fieldWidth: '150',
        fieldHeight: '200'
      },
      isHasExportTemplate: false, // 是否设置导出模板
      isEditCur: {},
      history: {},
      customTip: {
        commitTipShow: 0,
        commitTipContent: "",
        payTipShow: 0,
        payTipContent: "",
      },
      agreement: {},
      managefieldList: [],
      encryptRulesData: [],
      otherSetting:{
        enableView: false
      }
    }
  },
  computed: {
    selectMemberLen () {
      const member = this.memberList.filter((item) => item.checked)
      return fn(member).length
    },
    // 设置监听变化的对象
    watchObjectChanged () {
      const { setForm, formAuditAuth, formViewAuth, formAdminAuth, exportSetting, otherSetting, customTip, agreement, selectAllFillArr, selectAllViewArr, selectAllAudit1Arr, selectAllAudit2Arr } = this
      return { setForm, formAuditAuth, formViewAuth, formAdminAuth, exportSetting, otherSetting, customTip, agreement, selectAllFillArr, selectAllViewArr, selectAllAudit1Arr, selectAllAudit2Arr }
    },
    noExportFile () {
      return !this.exportSetting.fileList || this.exportSetting.fileList.length == 0
    }
  },
  watch: {
    watchObjectChanged: {
      deep: true,
      handler (val, old) {
        this.isEditCur[this.setCur] = true

        this.isSetEdit = true
      }
    },
    'setForm.fillStartTime': {
      handler (curVal, oldVal) {
        if (!this.isBlank(this.setForm.fillStartTime)) {
          let hour = new Date(this.setForm.fillStartTime).getHours()
          let minute = new Date(this.setForm.fillStartTime).getMinutes()
          if (hour == 23 && minute == 50) {
            hour = 0
            minute = 0
          } else if (minute == 50) {
            hour = hour + 1
            minute = 0
          } else {
            minute = minute + 10
          }
          this.endDefaultValue = new Date(this.setForm.fillStartTime).setHours(hour, minute)
        }
      }
    },
    'exportSetting.fileList': {
      deep: true,
      handler (val) {
        if (val && val.length > 0) {
          this.isHasExportTemplate = true
        } else {
          this.isHasExportTemplate = false
        }
      }
    }
  },
  mounted () { },
  created () {
    // let sessionStorageUser = getSessionStorageObj('users')
    // this.userInfo = sessionStorageUser
    // this.type = this.userInfo.type
    this.$store.dispatch('GetUserInfo').then((res) => {
      this.userInfo = res.data
      this.type = res.data.type
    })

    if (!this.isBlank(this.formId)) {
      this.getFormDetail()
    }
  },
  methods: {
    onChangeEdit () {
      this.isSetEdit = true
    },
    getEncryptRules (formId) {

    },
    onAuditChangeEdit () {
      this.isSetEdit = true
      this.$set(this.isEditCur, "4", true)
    },
    // 填报开始时间和结束时间限制
    notBeforeFillEnd (date) {
      if (!this.isBlank(this.setForm.fillEndTime)) {
        let time = new Date(this.setForm.fillEndTime).setHours(0, 0, 0, 0)
        const hour = new Date(this.setForm.fillEndTime).getHours()
        const minute = new Date(this.setForm.fillEndTime).getMinutes()
        if (hour == 0 && minute == 0) {
          time = time - 24 * 60 * 60 * 1000
        }
        return date.getTime() > time
      }
    },
    notBeforeTimeEnd (date) {
      if (!this.isBlank(this.setForm.fillEndTime)) {
        return date.getTime() >= new Date(this.setForm.fillEndTime).getTime()
      }
    },
    notBeforeFillStart (date) {
      if (!this.isBlank(this.setForm.fillStartTime)) {
        let time = new Date(this.setForm.fillStartTime).setHours(0, 0, 0, 0)
        const hour = new Date(this.setForm.fillStartTime).getHours()
        const minute = new Date(this.setForm.fillStartTime).getMinutes()
        if (hour === 23 && minute === 50) {
          time = time + 24 * 60 * 60 * 1000
        }
        return date.getTime() < time
      }
    },
    notBeforeTimeStart (date) {
      if (!this.isBlank(this.setForm.fillStartTime)) {
        if (new Date(this.setForm.fillStartTime).getDate() < new Date(date).getDate()) {
          this.endDefaultValue = date.setHours(0, 0)
        } else {
          return date.getTime() <= new Date(this.setForm.fillStartTime).getTime()
        }
      }
    },
    onClearFillStart () {
      this.startDefaultValue = new Date().setHours(0, 0)
    },
    onClearFillEnd () {
      if (!this.isBlank(this.setForm.fillStartTime)) {
        let hour = new Date(this.setForm.fillStartTime).getHours()
        let minute = new Date(this.setForm.fillStartTime).getMinutes()
        if (hour == 23 && minute == 50) {
          hour = 0
          minute = 0
        } else if (minute == 50) {
          hour = hour + 1
          minute = 0
        } else {
          minute = minute + 10
        }
        this.endDefaultValue = new Date(this.setForm.fillStartTime).setHours(hour, minute)
      }
      this.setForm.endtimeModify = false;
    },
    onOpenFillEnd (date, type) { },
    onHandleFillNum () {
      this.isSetEdit = true
    },

    setFormDetailData (data) {
      const _this = this
      _this.setForm.fillStartTime = data.fillStartTime
      _this.setForm.fillEndTime = data.fillEndTime
      _this.setForm.endtimeModify = data.endtimeModify
      _this.setForm.fillRepeatedly = data.fillRepeatedly
      _this.setForm.fillRepeatedlyRule = data.fillRepeatedlyRule
      _this.setForm.fillNum = data.fillNum
      _this.setForm.allowFillMax = !!data.fillNum
      _this.setForm.fillNumPublic = data.fillNumPublic
      _this.setForm.isBatchFill = data.isBatchFill
      _this.setForm.startFillText = data.startFillText
      _this.setForm.fillAnony = data.fillAnony
      _this.setForm.enableEncryptRules = data.enableEncryptRules

      if (!_this.isBlank(data.workFlowNode)) {
        _this.workFlowNode = data.workFlowNode
      }

      /* 设置支付信息 */
      // _this.setForm.pay = data.pay
      // _this.setForm.payType = !_this.isBlank(data.payType) ? data.payType + '' : null
      // if (_this.setForm.pay) {
      //   /* console.log('是否开启填报') */
      //   if (data.payType == 1) {
      //     _this.setForm.payPrice = !_this.isBlank(data.payPrice) ? data.payPrice / 100 : null
      //   } else {
      //       setTimeout(_this.getFormPayInfo,1000)
      //   }
      // }
      // _this.setForm.mch = data.mch
      // _this.setForm.mchName = !_this.isBlank(data.mchName) ? data.mchName : null
      /* 设置支付信息结束 */

      // 填报设置回显
      _this.setForm.fillAuth = data.fillAuth + ''
      if (_this.setForm.fillAuth == '3') {
        _this.getFormFillAuth()
      }
      if (_this.setForm.fillAuth == '4') {
        _this.getHistoryFormList()
        // 历史表单
        if (!_this.isBlank(data.fillAuthHistoryFormId)) {
          _this.setForm.fillAuthHistoryFormId = data.fillAuthHistoryFormId
          if (!_this.isBlank(data.fillAuthHistorySelect)) {
            _this.setForm.fillAuthHistorySelect = data.fillAuthHistorySelect + ''
          }
        }
      }
      if (_this.setForm.fillAuth == '5') {
        _this.getFillAuthAllowPhone()
      }
      // 审核设置回显
      _this.formAuditAuth.auditAuth = data.auditAuth
      _this.formAuditAuth.auditAuthSign = data.auditAuthSign
      _this.formAuditAuth.auditAuthReUpdate = data.auditAuthReUpdate
      _this.formAuditAuth.auditAuthReviewSign = data.auditAuthReviewSign
      _this.formAuditAuth.auditAuthReviewRemark = data.auditAuthReviewRemark
      // 查看设置回显
      _this.formViewAuth.viewAuth = data.viewAuth

      if (data.viewAuthType) {
        let viewAuthType = (data.viewAuthType || '').split(',')
        _this.formViewAuth.viewAuthType = viewAuthType
        // 如果设置了指定成员查看或者根据选项设置管理员，读取更详细数据
        if (viewAuthType.findIndex(x => x === '1' || x === '3') >= 0) {
          _this.getFormViewAuth()
        }
        if (viewAuthType.findIndex(x => x === '2') >= 0) {
          if (!_this.isBlank(data.viewAuthAgencyLeaderLevel)) {
            _this.formViewAuth.viewAuthAgencyLeaderLevel = data.viewAuthAgencyLeaderLevel + ''
          }
        }
        // 如果设置了根据选项设置管理员，触发表单json，获取可选字段
        if (viewAuthType.findIndex(x => x === '3') >= 0) {
          this.onDataManageTypeChecked(viewAuthType);
        }
      } else {
        _this.formViewAuth.viewAuthType = [];
      }
      // 回显管理权限--全部管理权限
      _this.setForm.managerUserId = data.managerUserId


      // console.log('查看设置回显',_this.agencyId)
      _this.memberParams.agencyId = _this.agencyId
      // 导出设置回显
      _this.exportSetting.exportIsPerson = data.exportIsPerson
      _this.exportSetting.exportIsPdf = data.exportIsPdf
      _this.exportSetting.waterMark = data.waterMark || ''
      _this.exportSetting.exportName = data.exportName
      _this.exportSetting.exportAfterAudit = data.exportAfterAudit|| false

      if (!_this.isBlank(data.exportTemplate)) {
        // 从data.exportTemplate的url中取出文件名
        const tmpSplits = data.exportTemplate.split('/');
        var name = "";
        if (tmpSplits.length > 0) {
          const fileNameIndex = tmpSplits.length - 1;
          name = tmpSplits[fileNameIndex];
        }

        _this.exportSetting.fileList.push({
          name,
          url: decodeURIComponent(data.exportTemplate)
        })
        _this.isHasExportTemplate = true
      }

      _this.$nextTick(() => {
        _this.isEditCur = {}
        _this.isSetEdit = false
      })
    },
    // 表单详情
    getFormDetail () {
      const _this = this
      formDetail(_this.formId).then((response) => {
        if (response.code == 200) {
          _this.history = response.data
          _this.setFormDetailData(response.data)

          //获取表单后根据agreementId字段获取协议信息
          if (response.data.formAgreementId) {
            formAgreementInfo(response.data.formAgreementId).then((agreeResp) => {
              if (agreeResp.code == 200) {
                agreeResp.data.startFillAgreementStatus = true;
                _this.agreement = agreeResp.data
              }
            });
          }

          // 启用视图
          this.otherSetting.enableView= response.data.enableView;

        } else {
          _this.msgError(agreeResp.msg)
        }
      })
      getCustomTip(_this.formId).then((response) => {
        if (response.code == 200) {
          _this.customTip = response.data

          _this.$nextTick(() => {
            _this.isEditCur = {}
            _this.isSetEdit = false
          })
        }
      });
    },
    recoverHistory () {
      this.setFormDetailData(this.history)
      this.isEdit = false
    },
    // 获取表单设置支付信息
    getFormPayInfo () {
      const _this = this

      _this.getFormField()
      _this.$forceUpdate()
      formPayInfo(_this.formId).then((response) => {
        if (response.code == 200) {
          if (!_this.isBlank(response.data)) {
            _this.setForm.pay = response.data.pay
            _this.setForm.payType = !_this.isBlank(response.data.payType) ? response.data.payType + '' : null
            if (_this.setForm.pay) {
              if (response.data.payType == 1) {
                _this.setForm.payPrice = !_this.isBlank(response.data.payPrice) ? response.data.payPrice / 100 : null
              } else {
                _this.setForm.formFieldId = response.data.formFieldId
                _this.onSelectPayOption(_this.setForm.formFieldId)

                for (let i = 0; i < _this.setForm.payFieldList.length; i++) {
                  _this.setForm.payFieldList[i].payPrice = response.data.formFieldJson[_this.setForm.payFieldList[i].value] / 100
                }
              }
            }
            _this.setForm.mch = response.data.mch
            _this.setForm.mchName = !_this.isBlank(response.data.mchName) ? response.data.mchName : null
          }
          _this.$nextTick(() => {
            delete _this.isEditCur['2']
            _this.$forceUpdate()
          })
        } else {
          _this.msgError(response.msg)
        }
      })
    },
    // 新版---获取表单填报人详细信息
    getFormFillAuth () {
      const _this = this
      formFillAuthDetail(_this.formId).then((response) => {
        if (response.code == 200) {
          const fillAuth = response.data.fillAuth
          const selectAllFillArr = []
          const fillAuthAgencyUserList = response.data.fillAuthAgencyUserList
          const fillAuthAgencyDeptList = response.data.fillAuthAgencyDeptList
          const fillAuthAgencyRoleList = response.data.fillAuthAgencyRoleList
          if (!_this.isBlank(fillAuth) && fillAuth == 3) {
            _this.isFillConfirmBtn = true
            if (!_this.isBlank(fillAuthAgencyUserList)) {
              for (let i = 0; i < fillAuthAgencyUserList.length; i++) {
                selectAllFillArr.push({
                  type: 'member',
                  number: fillAuthAgencyUserList[i].number,
                  name: fillAuthAgencyUserList[i].agencyUserName,
                  agencyUserId: fillAuthAgencyUserList[i].agencyUserId
                })
              }
            }
            if (!_this.isBlank(fillAuthAgencyDeptList)) {
              for (let k = 0; k < fillAuthAgencyDeptList.length; k++) {
                selectAllFillArr.push({
                  type: 'dept',
                  name: fillAuthAgencyDeptList[k].agencyDeptName,
                  agencyDeptId: fillAuthAgencyDeptList[k].agencyDeptId
                })
              }
            }
            if (!_this.isBlank(fillAuthAgencyRoleList)) {
              for (let j = 0; j < fillAuthAgencyRoleList.length; j++) {
                selectAllFillArr.push({
                  type: 'role',
                  name: fillAuthAgencyRoleList[j].agencyRoleName,
                  agencyRoleId: fillAuthAgencyRoleList[j].agencyRoleId
                })
              }
            }
          }
          // else if(!_this.isBlank(fillAuth) && fillAuth==5){
          //   this.setForm.fillAuthAllowPhone=response.data
          // }
          _this.selectAllFillArr = selectAllFillArr
        } else {
          _this.msgError(response.msg)
        }

        _this.$nextTick(() => {
          _this.isEditCur = {}
          _this.isSetEdit = false
        })
      })
    },
    // 新版---获取表单查看人详细信息
    getFormViewAuth () {
      const _this = this
      formViewAuthDetail(_this.formId).then((response) => {
        if (response.code == 200) {
          const viewAuthType = (response.data.viewAuthType || '').split(',')
          const selectAllViewArr = []
          const viewAuthAgencyUserList = response.data.viewAuthAgencyUserList
          const viewAuthAgencyDeptList = response.data.viewAuthAgencyDeptList
          const viewAuthAgencyRoleList = response.data.viewAuthAgencyRoleList
          if (viewAuthType.length > 0) {
            _this.isViewConfirmBtn = true
            if (!_this.isBlank(viewAuthAgencyUserList)) {
              for (let i = 0; i < viewAuthAgencyUserList.length; i++) {
                selectAllViewArr.push({
                  type: 'member',
                  number: viewAuthAgencyUserList[i].agencyUserNumber,
                  name: viewAuthAgencyUserList[i].agencyUserName,
                  agencyUserId: viewAuthAgencyUserList[i].agencyUserId
                })
              }
            }
            if (!_this.isBlank(viewAuthAgencyDeptList)) {
              for (let k = 0; k < viewAuthAgencyDeptList.length; k++) {
                selectAllViewArr.push({
                  type: 'dept',
                  name: viewAuthAgencyDeptList[k].agencyDeptName,
                  agencyDeptId: viewAuthAgencyDeptList[k].agencyDeptId
                })
              }
            }
            if (!_this.isBlank(viewAuthAgencyRoleList)) {
              for (let j = 0; j < viewAuthAgencyRoleList.length; j++) {
                selectAllViewArr.push({
                  type: 'role',
                  name: viewAuthAgencyRoleList[j].agencyRoleName,
                  agencyRoleId: viewAuthAgencyRoleList[j].agencyRoleId
                })
              }
            }
          }

          _this.selectAllViewArr = selectAllViewArr
          _this.$forceUpdate()

          // 根据选项设置管理员
          if(response.data.viewAuthFieldList){
            _this.formViewAuth.viewAuthFieldList = response.data.viewAuthFieldList.map(x => {
              const selectAllViewArrOptions = []
              if (!_this.isBlank(x.auditAuthAgencyUserList)) {
                for (let g = 0; g < x.auditAuthAgencyUserList.length; g++) {
                  selectAllViewArrOptions.push({
                    type: 'member',
                    number: x.auditAuthAgencyUserList[g].agencyUserName,
                    name: x.auditAuthAgencyUserList[g].agencyUserName,
                    agencyUserId: x.auditAuthAgencyUserList[g].agencyUserId
                  })
                }
              }
              if (!_this.isBlank(x.auditAuthAgencyDeptList)) {
                for (let p = 0; p < x.auditAuthAgencyDeptList.length; p++) {
                  selectAllViewArrOptions.push({
                    type: 'dept',
                    name: x.auditAuthAgencyDeptList[p].agencyDeptName,
                    agencyDeptId: x.auditAuthAgencyDeptList[p].agencyDeptId
                  })
                }
              }
              if (!_this.isBlank(x.auditAuthAgencyRoleList)) {
                for (let q = 0; q < x.auditAuthAgencyRoleList.length; q++) {
                  selectAllViewArrOptions.push({
                    type: 'role',
                    name: x.auditAuthAgencyRoleList[q].agencyRoleName,
                    agencyRoleId: x.auditAuthAgencyRoleList[q].agencyRoleId
                  })
                }
              }

              return {
                fieldValue: x.fieldValue,
                formFieldId: x.formFieldId,
                viewAuthAgencyDeptId: (x.auditAuthAgencyDeptList || []).map(y => y.agencyDeptId).toString(),
                viewAuthAgencyRoleId: (x.auditAuthAgencyRoleList || []).map(y => y.agencyRoleId).toString(),
                viewAuthAgencyUserId: (x.auditAuthAgencyUserList || []).map(y => y.agencyUserId).toString(),
                selectAllAuditArr: selectAllViewArrOptions
              }
            })
          }
          
          // const selectAllAuditArr = []
          // if (!_this.isBlank(x.auditAuthAgencyUserId)) {
          //   for (let g = 0; g < x.auditAuthAgencyUserId.length; g++) {
          //     selectAllAuditArr.push({
          //       type: 'member',
          //       name: x.auditAuthAgencyUserId[g].agencyUserName,
          //       agencyUserId: x.auditAuthAgencyUserId[g].agencyUserId
          //     })
          //   }
          // }
          // if (!_this.isBlank(x.auditAuthAgencyDeptList)) {
          //   for (let p = 0; p < x.auditAuthAgencyDeptList.length; p++) {
          //     selectAllAuditArr.push({
          //       type: 'dept',
          //       name: x.auditAuthAgencyDeptList[p].agencyDeptName,
          //       agencyDeptId: x.auditAuthAgencyDeptList[p].agencyDeptId
          //     })
          //   }
          // }
          // if (!_this.isBlank(x.auditAuthAgencyRoleId)) {
          //   for (let q = 0; q < x.auditAuthAgencyRoleId.length; q++) {
          //     selectAllAuditArr.push({
          //       type: 'role',
          //       name: x.auditAuthAgencyRoleId[q].agencyRoleName,
          //       agencyRoleId: x.auditAuthAgencyRoleId[q].agencyRoleId
          //     })
          //   }
          // }

        } else {
          _this.msgError(response.msg)
        }
        _this.$nextTick(() => {
          _this.isEditCur = {}
          _this.isSetEdit = false
        })
      })
    },
    // 读取表单管理指定成员可见
    getFormAdmin () {
      if (this.setForm.managerUserId && this.setForm.managerUserId.split(',').length > 0) {
        this.formAdminAuth.adminAuth = true
      }
      agencySubAdminList().then((response) => {
        let adminArr = response.rows.filter(x => (this.setForm.managerUserId || []).indexOf(x.agencyUserId) >= 0).map(x => {
          return {
            name: x.name,
            type: 'member',
            agencyUserId: x.agencyUserId
          }
        })
        this.selectAllAdminArr = adminArr
      })
    },
    // 新版---获取表单审核人详细信息
    getFormAuditAuth () {
      const _this = this
      formAuditAuthDetail(_this.formId).then((response) => {
        if (response.code == 200) {
          this.formAuditAuth.auditAuth = response.data.auditAuth;
          this.$refs.auditLevel.setConfig(response.data);
          return;
          // 一级审核
          const auditAuth1 = response.data.auditAuth1
          const selectAllAudit1Arr = []
          const auditAuth1AgencyUserList = response.data.auditAuth1AgencyUserList
          const auditAuth1AgencyDeptList = response.data.auditAuth1AgencyDeptList
          const auditAuth1AgencyRoleList = response.data.auditAuth1AgencyRoleList
          if (!_this.isBlank(auditAuth1) && auditAuth1 == 2) {
            _this.isAudit1ConfirmBtn = true
            if (!_this.isBlank(auditAuth1AgencyUserList)) {
              for (let i = 0; i < auditAuth1AgencyUserList.length; i++) {
                selectAllAudit1Arr.push({
                  type: 'member',
                  name: auditAuth1AgencyUserList[i].agencyUserName,
                  agencyUserId: auditAuth1AgencyUserList[i].agencyUserId
                })
              }
            }
            if (!_this.isBlank(auditAuth1AgencyDeptList)) {
              for (let k = 0; k < auditAuth1AgencyDeptList.length; k++) {
                selectAllAudit1Arr.push({
                  type: 'dept',
                  name: auditAuth1AgencyDeptList[k].agencyDeptName,
                  agencyDeptId: auditAuth1AgencyDeptList[k].agencyDeptId
                })
              }
            }
            if (!_this.isBlank(auditAuth1AgencyRoleList)) {
              for (let j = 0; j < auditAuth1AgencyRoleList.length; j++) {
                selectAllAudit1Arr.push({
                  type: 'role',
                  name: auditAuth1AgencyRoleList[j].agencyRoleName,
                  agencyRoleId: auditAuth1AgencyRoleList[j].agencyRoleId
                })
              }
            }
          }
          _this.selectAllAudit1Arr = selectAllAudit1Arr
          // 二级审核
          const auditAuth2 = response.data.auditAuth2
          const selectAllAudit2Arr = []
          const auditAuth2AgencyUserList = response.data.auditAuth2AgencyUserList
          const auditAuth2AgencyDeptList = response.data.auditAuth2AgencyDeptList
          const auditAuth2AgencyRoleList = response.data.auditAuth2AgencyRoleList
          if (!_this.isBlank(auditAuth2) && auditAuth2 == 2) {
            _this.isAudit2ConfirmBtn = true
            if (!_this.isBlank(auditAuth2AgencyUserList)) {
              for (let g = 0; g < auditAuth2AgencyUserList.length; g++) {
                selectAllAudit2Arr.push({
                  type: 'member',
                  name: auditAuth2AgencyUserList[g].agencyUserName,
                  agencyUserId: auditAuth2AgencyUserList[g].agencyUserId
                })
              }
            }
            if (!_this.isBlank(auditAuth2AgencyDeptList)) {
              for (let p = 0; p < auditAuth2AgencyDeptList.length; p++) {
                selectAllAudit2Arr.push({
                  type: 'dept',
                  name: auditAuth2AgencyDeptList[p].agencyDeptName,
                  agencyDeptId: auditAuth2AgencyDeptList[p].agencyDeptId
                })
              }
            }
            if (!_this.isBlank(auditAuth2AgencyRoleList)) {
              for (let q = 0; q < auditAuth2AgencyRoleList.length; q++) {
                selectAllAudit2Arr.push({
                  type: 'role',
                  name: auditAuth2AgencyRoleList[q].agencyRoleName,
                  agencyRoleId: auditAuth2AgencyRoleList[q].agencyRoleId
                })
              }
            }
          }
          _this.selectAllAudit2Arr = selectAllAudit2Arr


          _this.$nextTick(() => {
            _this.isEditCur = {}
            _this.isSetEdit = false
          })
        }


      })
    },
    // 获取表单的字段权限
    getFormAuthFieldAuth () {
      const _this = this
      this.fieldAuths = [];
      formAuditAuthFieldDetail(_this.formId).then((response) => {
        if (response.code == 200) {
          this.formAuditAuth.fieldAuths = response.data;
          this.$refs.auditLevel.setFieldAuthConfig(this.formAuditAuth.fieldAuths);
        }
      })
    },
    // 从字典中读取系统字段
    getSystemField () {
      getDicts('word_export_sys_template').then((res) => {
        this.exportSystemField = res.data.map((x) => {
          return { field: x.dictLabel, templateField: x.dictValue }
        })
      })
    },
    // 新版 3.0.0.1 导出设置 表单模板字段
    getFormField () {
      const _this = this

      const formJson = this.$parent.$refs.vformDesigner.getFormJson()

      this.fieldList = formJson.fieldList
      const selFields = this.fieldList.filter((x) => (x.type == 'select' || x.type == 'radio') && !!x.isRequired)

      this.setForm.payPriceFiledArr.splice(0, this.setForm.payPriceFiledArr.length)
      selFields.forEach((x) => {
        this.setForm.payPriceFiledArr.push({
          label: x.displayName,
          formFieldId: x.name
        })
      })

      // this.exportFormField.splice(0,this.exportFormField.length);
      // this.fieldList.forEach(x=>{
      //   this.exportFormField.push({
      //     label:x.displayName
      //   })
      // })
    },
    // 新版 3.0.0.1 导出设置 表单模板字段
    getAuditFormField () {
      const _this = this

      const formJson = this.$parent.$refs.vformDesigner.getFormJson()

      this.fieldList = formJson.fieldList
      const selFields = this.fieldList.filter((x) => (x.type == 'user-select') || ((x.type == 'select' || x.type == 'radio' || x.type == 'cascader' || x.type == 'region' || x.type == 'address') && !!x.isRequired))

      let auditFieldTrans = [];
      selFields.forEach((x) => {
        auditFieldTrans.push({
          label: x.displayName,
          formFieldId: x.name,
          ...x
        })
      })
      return auditFieldTrans;
    },

    onRadioPayType () {
      if (this.setForm.payType == '2') {
        this.getFormField()
      }
      this.isSetEdit = true
    },
    // 开启支付功能 失去焦点
    onBlurPayPrice () {
      return
      if (this.isBlank(this.setForm.payPrice)) {
        this.$message({
          type: 'warning',
          message: '设置填报支付开关已开， 请先设置支付金额～'
        })
        return
      }
      // return false
      const n = Number(this.setForm.payPrice)
      if (isNaN(n)) {
        this.$message({
          type: 'warning',
          message: '支付金额格式有误～'
        })
        return
      } else if (n < 0.01) {
        this.$message({
          type: 'warning',
          message: '设置支付金额最小为0.01元～'
        })
        return
        // } else if (n > 3000) {
        //   this.$message({
        //     type: 'warning',
        //     message: '设置支付金额不能超过3000元～'
        //   })
        //   return
      } else {
        this.isReg2(n)
      }
      this.isSetEdit = true
    },
    isReg2 (totle) {
      const dot = String(totle).indexOf('.')
      if (dot != -1) {
        const dotCnt = String(totle).substring(dot + 1, totle.length)
        if (dotCnt.length > 2) {
          totle = totle.toFixed(2)
          this.$message({
            type: 'warning',
            message: '设置支付金额只能保留两位小数哦～'
          })
          this.setForm.payPrice = totle
        }
      }
    },
    onSelectPayOption (value) {
      const optionItem = this.fieldList.find((item) => item.name == value)
      if (!this.isBlank(optionItem)) {
        this.setForm.payFieldList = optionItem.filterOptions
      }
      this.setForm.pay2OptionShow = true
      this.isSetEdit = true
    },
    // 新版
    onTabClick (index) {
      // console.log('上一次==' + this.setCur + '现在点击==' + index + '是否修改==' + this.isSetEdit)
      this.setCur = index
      if (index == 2 || index == 6) {
        this.getFormPayInfo()
      }
      if (index == 4) {
        this.getFormField()
        this.getFormAuditAuth()
        this.getFormAuthFieldAuth()
      }
      if (index == 5) {
        this.getFormAdmin()
      }
      if (index == 6) {
        // 读取系统字段
        this.getSystemField()
      }
    },
    reCheckField () {
      this.getFormField()
      this.msgSuccess('字段刷新完成')
    },
    getSetInfo(){
      return {
        ...this.setForm,
        ...this.formAdminAuth,
        ...this.formViewAuth,
        adminAuth: (this.setForm.managerUserId && this.setForm.managerUserId.split(',').length > 0)
      }
    },
    saveFormSubmit (succ, fail) {
      const _this = this
      const actions = []

      const checkActionMap = new Map()
      checkActionMap.set('1', this.checkSaveCustomTip)
      checkActionMap.set('2', this.checkPaySetting)
      checkActionMap.set('3', this.checkFillSetting)
      checkActionMap.set('4', this.checkAuditSetting)
      checkActionMap.set('5', this.checkViewSetting)

      const saveActionMap = new Map()
      saveActionMap.set('1', [this.saveSubmitSetting, this.saveCustomTip, this.saveAgreement])
      saveActionMap.set('2', this.savePaySetting)
      saveActionMap.set('3', this.saveFillSetting)
      saveActionMap.set('4', this.saveAuditSetting)
      saveActionMap.set('5', this.saveViewSetting)
      saveActionMap.set('6', this.saveExportSetting)
      saveActionMap.set('7', this.saveOtherSetting)

      try {
        Object.keys(this.isEditCur).forEach((cur) => {
          const checker = checkActionMap.get(cur)
          if (checker && !checker()) {
            fail()
            throw new Error(`Check ${cur} Failed`)
          } else {
            let actionMap = saveActionMap.get(cur);
            if (Array.isArray(actionMap)) {
              actionMap.forEach(x => {
                actions.push(x())
              })
            } else {
              actions.push(saveActionMap.get(cur)())
            }
          }
        })
      } catch (e) {
        console.error(e)
        return
      }

      if (actions.length > 0) {
        Promise.all(actions)
          .then((values) => {
            this.isSetEdit = false
            this.isEditCur = {}
            if (succ) succ()
            else this.msgSuccess('保存成功')
          })
          .catch((reason) => {
            fail()
            console.error(reason)
          })
      }
    },
    createParam () {
      const param = {
        projectId: this.projectId,
        formId: this.isBlank(this.formId) ? undefined : this.formId
      }
      return {
        param: param,
        setInfo: this.setForm
      }
    },
    saveSubmitSetting () {
      const { param, setInfo } = this.createParam()
      param.fillStartTime = !this.isBlank(setInfo.fillStartTime) ? setInfo.fillStartTime + ':00' : ''
      param.fillEndTime = !this.isBlank(setInfo.fillEndTime) ? setInfo.fillEndTime + ':00' : ''
      param.endtimeModify = setInfo.endtimeModify
      param.fillRepeatedly = setInfo.fillRepeatedly
      param.fillRepeatedlyRule = setInfo.fillRepeatedlyRule
      param.fillNum = setInfo.allowFillMax ? setInfo.fillNum : null
      param.fillNumPublic = setInfo.fillNumPublic
      param.isBatchFill = setInfo.isBatchFill ? setInfo.isBatchFill : false
      param.startFillText = setInfo.startFillText
      param.enableEncryptRules = setInfo.enableEncryptRules
      return httpFormSubmit(JSON.stringify(param))
    },
    savePaySetting () {
      const { param, setInfo } = this.createParam()
      param.pay = setInfo.pay
      if (setInfo.pay) {
        param.payType = Number(setInfo.payType)
        if (setInfo.payType == '1') {
          param.payPrice = this.regYuanToFen(setInfo.payPrice, 100)
        } else {
          param.formFieldId = setInfo.formFieldId
          param.formFieldJson = {}
          for (let k = 0; k < setInfo.payFieldList.length; k++) {
            const payPrice = this.regYuanToFen(setInfo.payFieldList[k].payPrice, 100)
            param.formFieldJson[setInfo.payFieldList[k].value] = payPrice
          }
        }
      }
      return httpFormPay(JSON.stringify(param))
    },
    saveFillSetting () {
      const { param, setInfo } = this.createParam()
      param.fillAuth = parseInt(setInfo.fillAuth)
      param.fillAnony = setInfo.fillAnony
      if (setInfo.fillAuth == '3') {
        const selectAllFillArr = this.selectAllFillArr
        const fillAuthAgencyUserIdArr = []
        const fillAuthAgencyDeptIdArr = []
        const fillAuthAgencyRoleIdArr = []
        for (let i = 0; i < selectAllFillArr.length; i++) {
          if (selectAllFillArr[i].type == 'member') {
            fillAuthAgencyUserIdArr.push(selectAllFillArr[i].agencyUserId)
          }
          if (selectAllFillArr[i].type == 'dept') {
            fillAuthAgencyDeptIdArr.push(selectAllFillArr[i].agencyDeptId)
          }
          if (selectAllFillArr[i].type == 'role') {
            fillAuthAgencyRoleIdArr.push(selectAllFillArr[i].agencyRoleId)
          }
        }
        if (!this.isBlank(fillAuthAgencyUserIdArr)) {
          param.fillAuthAgencyUserId = fillAuthAgencyUserIdArr.toString()
        }
        if (!this.isBlank(fillAuthAgencyDeptIdArr)) {
          param.fillAuthAgencyDeptId = fillAuthAgencyDeptIdArr.toString()
        }
        if (!this.isBlank(fillAuthAgencyRoleIdArr)) {
          param.fillAuthAgencyRoleId = fillAuthAgencyRoleIdArr.toString()
        }
      }
      if (setInfo.fillAuth == '4') {
        param.fillAuthHistoryFormId = setInfo.fillAuthHistoryFormId
        param.fillAuthHistorySelect = parseInt(setInfo.fillAuthHistorySelect)
      }
      if (setInfo.fillAuth == '5') {
        var regu = /^1(\d{10})$/mg;
        const matches = setInfo.fillAuthAllowPhone.match(regu);
        const lines = setInfo.fillAuthAllowPhone.split(/\n|,|;/g);

        if (setInfo.fillAuthAllowPhone) {
          if (!matches || (matches.length != lines.length)) {
            this.msgError('请检查手机号是否正确')
            return;
          }
        }

        param.fillAuthAllowPhone = setInfo.fillAuthAllowPhone.replace(/\n|;/g, ',');
      }
      // return false;
      return httpFormFillAuth(JSON.stringify(param))
    },
    saveAuditSetting () {
      if (!this.$refs.auditLevel) return;
      const { auditInfos, fieldAuths } = deepClone(this.$refs.auditLevel.getConfig());

      const formAuditAuth = this.formAuditAuth
      const { param, setInfo } = this.createParam()
      param.auditAuth = formAuditAuth.auditAuth
      param.auditAuthSign = formAuditAuth.auditAuthSign
      param.auditAuthReviewSign = formAuditAuth.auditAuthReviewSign
      param.auditAuthReviewRemark = formAuditAuth.auditAuthReviewRemark
      param.auditAuthReUpdate = formAuditAuth.auditAuthReUpdate
      param.auditInfos = auditInfos
      param.fieldAuths = fieldAuths
      return httpFormAuditAuth(JSON.stringify(param))

    },
    // saveAuditSetting() {
    //   const { param, setInfo } = this.createParam()
    //   const formAuditAuth = this.formAuditAuth

    //   param.auditAuth = formAuditAuth.auditAuth
    //   if (formAuditAuth.auditAuth) {
    //     param.auditAuth1 = parseInt(formAuditAuth.auditAuth1)
    //     if (parseInt(formAuditAuth.auditAuth1) == 2) {
    //       const selectAllAudit1Arr = this.selectAllAudit1Arr
    //       const auditAuth1AgencyUserIdArr = []
    //       const auditAuth1AgencyDeptIdArr = []
    //       const auditAuth1AgencyRoleIdArr = []
    //       for (let i = 0; i < selectAllAudit1Arr.length; i++) {
    //         if (selectAllAudit1Arr[i].type == 'member') {
    //           auditAuth1AgencyUserIdArr.push(selectAllAudit1Arr[i].agencyUserId)
    //         }
    //         if (selectAllAudit1Arr[i].type == 'dept') {
    //           auditAuth1AgencyDeptIdArr.push(selectAllAudit1Arr[i].agencyDeptId)
    //         }
    //         if (selectAllAudit1Arr[i].type == 'role') {
    //           auditAuth1AgencyRoleIdArr.push(selectAllAudit1Arr[i].agencyRoleId)
    //         }
    //       }
    //       if (!this.isBlank(auditAuth1AgencyUserIdArr)) {
    //         param.auditAuth1AgencyUserId = auditAuth1AgencyUserIdArr.toString()
    //       }
    //       if (!this.isBlank(auditAuth1AgencyDeptIdArr)) {
    //         param.auditAuth1AgencyDeptId = auditAuth1AgencyDeptIdArr.toString()
    //       }
    //       if (!this.isBlank(auditAuth1AgencyRoleIdArr)) {
    //         param.auditAuth1AgencyRoleId = auditAuth1AgencyRoleIdArr.toString()
    //       }
    //     }
    //     if (parseInt(formAuditAuth.auditAuth1) == 3) {
    //       param.auditAuth1AgencyLeaderLevel = parseInt(formAuditAuth.auditAuth1AgencyLeaderLevel)
    //     }
    //     // 二级审核打开
    //     if (formAuditAuth.auditAuth2Show) {
    //       param.auditAuth2 = parseInt(formAuditAuth.auditAuth2)
    //       if (parseInt(formAuditAuth.auditAuth2) == 2) {
    //         const selectAllAudit2Arr = this.selectAllAudit2Arr
    //         const auditAuth2AgencyUserIdArr = []
    //         const auditAuth2AgencyDeptIdArr = []
    //         const auditAuth2AgencyRoleIdArr = []
    //         for (let i = 0; i < selectAllAudit2Arr.length; i++) {
    //           if (selectAllAudit2Arr[i].type == 'member') {
    //             auditAuth2AgencyUserIdArr.push(selectAllAudit2Arr[i].agencyUserId)
    //           }
    //           if (selectAllAudit2Arr[i].type == 'dept') {
    //             auditAuth2AgencyDeptIdArr.push(selectAllAudit2Arr[i].agencyDeptId)
    //           }
    //           if (selectAllAudit2Arr[i].type == 'role') {
    //             auditAuth2AgencyRoleIdArr.push(selectAllAudit2Arr[i].agencyRoleId)
    //           }
    //         }
    //         if (!this.isBlank(auditAuth2AgencyUserIdArr)) {
    //           param.auditAuth2AgencyUserId = auditAuth2AgencyUserIdArr.toString()
    //         }
    //         if (!this.isBlank(auditAuth2AgencyDeptIdArr)) {
    //           param.auditAuth2AgencyDeptId = auditAuth2AgencyDeptIdArr.toString()
    //         }
    //         if (!this.isBlank(auditAuth2AgencyRoleIdArr)) {
    //           param.auditAuth2AgencyRoleId = auditAuth2AgencyRoleIdArr.toString()
    //         }
    //       }
    //       if (parseInt(formAuditAuth.auditAuth2) == 3) {
    //         param.auditAuth2AgencyLeaderLevel = parseInt(formAuditAuth.auditAuth2AgencyLeaderLevel)
    //       }
    //     }
    //   }
    //   param.auditAuthReUpdate = formAuditAuth.auditAuthReUpdate
    //   // return
    //   return httpFormAuditAuth(JSON.stringify(param))
    // },
    saveViewSetting () {
      const { param } = this.createParam()
      const formViewAuth = this.formViewAuth
      param.viewAuth = formViewAuth.viewAuth
      if (formViewAuth.viewAuth) {
        param.viewAuthType = formViewAuth.viewAuthType.toString()
        if (formViewAuth.viewAuthType.find(x => x == '1')) {
          const selectAllViewArr = this.selectAllViewArr

          const viewAuthAgencyUserIdArr = []
          const viewAuthAgencyDeptIdArr = []
          const viewAuthAgencyRoleIdArr = []
          for (let i = 0; i < selectAllViewArr.length; i++) {
            if (selectAllViewArr[i].type == 'member') {
              viewAuthAgencyUserIdArr.push(selectAllViewArr[i].agencyUserId)
            }
            if (selectAllViewArr[i].type == 'dept') {
              viewAuthAgencyDeptIdArr.push(selectAllViewArr[i].agencyDeptId)
            }
            if (selectAllViewArr[i].type == 'role') {
              viewAuthAgencyRoleIdArr.push(selectAllViewArr[i].agencyRoleId)
            }
          }
          if (!this.isBlank(viewAuthAgencyUserIdArr)) {
            param.viewAuthAgencyUserId = viewAuthAgencyUserIdArr.toString()
          }
          if (!this.isBlank(viewAuthAgencyDeptIdArr)) {
            param.viewAuthAgencyDeptId = viewAuthAgencyDeptIdArr.toString()
          }
          if (!this.isBlank(viewAuthAgencyRoleIdArr)) {
            param.viewAuthAgencyRoleId = viewAuthAgencyRoleIdArr.toString()
          }
        }
        if (formViewAuth.viewAuthType.find(x => x == '2')) {
          param.viewAuthAgencyLeaderLevel = parseInt(formViewAuth.viewAuthAgencyLeaderLevel)
        }
        if (formViewAuth.viewAuthType.find(x => x == '3')) {
          param.viewAuthFieldList = formViewAuth.viewAuthFieldList
        }
      }

      const formAdminAuth = this.formAdminAuth
      if (formAdminAuth.adminAuth) {
        const viewAuthAgencyUserIdArr = this.selectAllAdminArr.map(x => x.agencyUserId).join(',');
        param.managerUserId = viewAuthAgencyUserIdArr
      }

      return new Promise((resolve, reject) => {
        Promise.all([httpFormViewAuth(JSON.stringify(param)), httpFormAdminAuth(JSON.stringify(param))]).then(res => {
          if (formViewAuth.viewAuth && !formAdminAuth.adminAuth && res[0].code == 200) {
            resolve(res[0])
          }

          if (!formViewAuth.viewAuth && formAdminAuth.adminAuth && res[1].code == 200) {
            resolve(res[1])
          }

          if (formViewAuth.viewAuth && formAdminAuth.adminAuth && res[0].code == 200 && res[1].code == 200) {
            resolve(res[0])
          }

          reject(res[0])
        }).catch(err => {
          reject(err)
        })

      })
    },
    saveExportSetting () {
      const { param } = this.createParam()
      let exportFile = null
      if (!this.isBlank(this.exportSetting.file) && this.exportSetting.file.uid) {
        exportFile = this.exportSetting.file
      } else {
        if (this.exportSetting.fileList.length > 0)
          param.exportTemplate = this.exportSetting.fileList[0].url;
      }
      param.exportIsPerson = this.exportSetting.exportIsPerson

      param.exportIsPdf = this.exportSetting.exportIsPdf;
      param.waterMark = this.exportSetting.waterMark;
      
      param.exportAfterAudit = this.exportSetting.exportAfterAudit||false;

      // 增加参数也需要到api/xxx.js对应的修改
      console.log(' this.exportSetting.exportName', JSON.stringify(this.exportSetting.exportName))
      return httpFormExport(param, exportFile, this.exportSetting.exportName)
    },
    saveOtherSetting(){
      const { param } = this.createParam()
      
      param.enableView = this.otherSetting.enableView;
      return httpFormOtherSetting(param)
    },

    saveCustomTip () {
      if (Object.keys(this.customTip).length == 0) return;
      const { param } = this.createParam()

      let paramObj = {
        ...this.customTip,
        formId: param.formId
      };
      return httpFormTipSave(paramObj)
    },

    saveAgreement () {
      if (Object.keys(this.agreement).length == 0) return;
      const { param } = this.createParam()

      let paramObj = {
        formId: param.formId,
        ...this.agreement
      };

      return saveFormAgreement(paramObj)
    },

    checkPaySetting () {
      const setInfo = this.setForm
      if (setInfo.pay) {
        if (setInfo.payType == '1') {
          if (this.isBlank(setInfo.payPrice)) {
            this.cur = 3

            this.msgWarning('设置填报支付开关已开启，请填写完整选项项目')
            return false
          } else {
            const n = Number(setInfo.payPrice)
            if (isNaN(n)) {
              this.msgWarning('支付金额格式有误～')
              return false
            } else if (n < 0.01) {
              this.msgWarning('设置支付金额最小为0.01元～')
              return false
              // } else if (n > 3000) {
              //   this.msgWarning('设置支付金额不能超过3000元～')
              //   return false
            } else {
              const flag = this.isReg2(n)
              if (flag) {
                this.msgWarning('设置支付金额只能保留两位小数哦～')
                return false
              }
            }
          }
        } else {
          if (this.isBlank(setInfo.formFieldId)) {
            this.msgWarning('设置填报支付开关已开启，请填写完整选项项目')
            this.cur = 3
            return false
          }
          for (let k = 0; k < setInfo.payFieldList.length; k++) {
            if (this.isBlank(setInfo.payFieldList[k].payPrice)) {
              const errorMessage = setInfo.payFieldList[k].label + '价格不能为空'
              this.msgWarning(errorMessage)
              this.cur = 3
              return false
            } else {
              const n = Number(setInfo.payFieldList[k].payPrice)
              if (isNaN(n)) {
                this.msgWarning(setInfo.payFieldList[k].label + '支付金额格式设置有误～')
                return false
              } else if (n < 0.01) {
                this.msgWarning(setInfo.payFieldList[k].label + '支付金额设置最小为0.01元～')
                return false
                // } else if (n > 3000) {
                //   this.msgWarning(setInfo.payFieldList[k].label + '支付金额设置不能超过3000元～')
                //   return false
              } else {
                const flag = this.isReg2(n)
                if (flag) {
                  this.msgWarning(setInfo.payFieldList[k].label + '支付金额设置只能保留两位小数哦～')
                  return false
                }
              }
            }
          }
        }
      }
      return true
    },
    checkFillSetting () {
      const setInfo = this.setForm
      if (setInfo.fillAuth == '3') {
        const selectAllFillArr = this.selectAllFillArr
        if (this.isBlank(selectAllFillArr)) {
          // this.$message.warning('填报人设置指定成员不完整，请继续填写完整')
          this.msgWarning('填报人设置不完整，请继续填写完整')
          return false
        }
        const fillAuthAgencyUserIdArr = []
        const fillAuthAgencyDeptIdArr = []
        const fillAuthAgencyRoleIdArr = []
        for (let i = 0; i < selectAllFillArr.length; i++) {
          if (selectAllFillArr[i].type == 'member') {
            fillAuthAgencyUserIdArr.push(selectAllFillArr[i].agencyUserId)
          }
          if (selectAllFillArr[i].type == 'dept') {
            fillAuthAgencyDeptIdArr.push(selectAllFillArr[i].agencyDeptId)
          }
          if (selectAllFillArr[i].type == 'role') {
            fillAuthAgencyRoleIdArr.push(selectAllFillArr[i].agencyRoleId)
          }
        }
      }
      if (setInfo.fillAuth == '4') {
        if (this.isBlank(setInfo.fillAuthHistoryFormId) || this.isBlank(setInfo.fillAuthHistorySelect)) {
          // this.$message.warning('填报人设置不完整，请继续填写完整')
          this.msgWarning('填报人设置不完整，请继续填写完整')
          return false
        }
      }
      return true
    },
    checkAuditSetting () {
      // const formAuditAuth = this.formAuditAuth
      if (!this.$refs.auditLevel) return true;
      const { auditInfos: formAuditAuth, fieldAuths } = this.$refs.auditLevel.getConfig();
      let level = ['', '一', '二', '三', '四', '五']

      for (let i = 0; i < formAuditAuth.length; i++) {
        if (formAuditAuth[i].auditAuthType == null) {
          this.msgWarning(level[formAuditAuth[i].auditLevel] + '级审核设置不完整，请继续填写完整')
          return false;
        }

        if (formAuditAuth[i].auditAuthType == 2) {
          if (
            this.isBlank(formAuditAuth[i].auditAuthAgencyDeptId) &&
            this.isBlank(formAuditAuth[i].auditAuthAgencyRoleId) &&
            this.isBlank(formAuditAuth[i].auditAuthAgencyUserId)
          ) {
            this.msgWarning(level[formAuditAuth[i].auditLevel] + '级审核设置不完整，请继续填写完整')
            return false
          }
        }

        if (formAuditAuth[i].auditAuthType == 3) {
          if (this.isBlank(formAuditAuth[i].auditAuthAgencyLeaderLevel)) {
            this.msgWarning(level[formAuditAuth[i].auditLevel] + '级审核人设置部门管理员不完整，请继续填写完整')
            return false
          }
        }

        if (formAuditAuth[i].auditAuthType == 4) {
          if (!formAuditAuth[i].auditAuthFieldList || formAuditAuth[i].auditAuthFieldList.length == 0) {
            this.msgWarning(level[formAuditAuth[i].auditLevel] + '未设置字段，请继续填写完整')
            return false
          }
        }

        if (formAuditAuth[i].auditAuthType == 5) {
          if (this.isBlank(formAuditAuth[i].auditUserFieldId)) {
            this.msgWarning(level[formAuditAuth[i].auditLevel] + '级审核人未选择审核人，请继续填写完整')
            return false
          }
        }

      }

      if (formAuditAuth.auditAuth) {
        if (this.isBlank(formAuditAuth.auditAuth1)) {
          this.msgWarning('一级审核设置不完整，请继续填写完整')
          return false
        }
        if (parseInt(formAuditAuth.auditAuth1) == 2) {
          if (this.isBlank(this.selectAllAudit1Arr)) {
            this.msgWarning('一级审核设置不完整，请继续填写完整')
            return false
          }
        }
        if (parseInt(formAuditAuth.auditAuth1) == 3) {
          if (this.isBlank(formAuditAuth.auditAuth1AgencyLeaderLevel)) {
            this.msgWarning('审核人设置部门管理员不完整，请继续填写完整')
            return false
          }
        }
        // 二级审核打开
        if (formAuditAuth.auditAuth2Show) {
          if (this.isBlank(formAuditAuth.auditAuth2)) {
            this.msgWarning('二级审核设置不完整，请继续填写完整')
            return false
          }
          if (parseInt(formAuditAuth.auditAuth2) == 2) {
            if (this.isBlank(this.selectAllAudit2Arr)) {
              this.msgWarning('二级审核设置不完整，请继续填写完整')
              return false
            }
          }
          if (parseInt(formAuditAuth.auditAuth2) == 3) {
            if (this.isBlank(formAuditAuth.auditAuth2AgencyLeaderLevel)) {
              this.msgWarning('审核人设置部门管理员不完整，请继续填写完整')
              return false
            }
          }
        }
      }
      return true
    },
    checkViewSetting () {
      const formViewAuth = this.formViewAuth
      if (formViewAuth.viewAuth) {
        if (this.isBlank(formViewAuth.viewAuthType)) {
          this.msgWarning('查看人设置不完整，请继续填写完整')
          return false
        } else {
          if (formViewAuth.viewAuthType.find(x => x == '1')) {
            const selectAllViewArr = this.selectAllViewArr
            if (this.isBlank(selectAllViewArr)) {
              this.msgWarning('查看人设置指定成员不完整，请继续填写完整')
              return false
            }
            const viewAuthAgencyUserIdArr = []
            const viewAuthAgencyDeptIdArr = []
            const viewAuthAgencyRoleIdArr = []
            for (let i = 0; i < selectAllViewArr.length; i++) {
              if (selectAllViewArr[i].type == 'member') {
                viewAuthAgencyUserIdArr.push(selectAllViewArr[i].agencyUserId)
              }
              if (selectAllViewArr[i].type == 'dept') {
                viewAuthAgencyDeptIdArr.push(selectAllViewArr[i].agencyDeptId)
              }
              if (selectAllViewArr[i].type == 'role') {
                viewAuthAgencyRoleIdArr.push(selectAllViewArr[i].agencyRoleId)
              }
            }
          }
          if (formViewAuth.viewAuthType.find(x => x == '2')) {
            if (this.isBlank(formViewAuth.viewAuthAgencyLeaderLevel)) {
              this.msgWarning('查看人设置部门管理员不完整，请继续填写完整')
              return false
            }
          }
        }
      }

      const formAdminAuth = this.formAdminAuth
      if (formAdminAuth.adminAuth) {
        const selectAllAdminArr = this.selectAllAdminArr
        if (this.isBlank(selectAllAdminArr)) {
          this.msgWarning('管理权限设置指定成员不完整，请继续填写完整')
          return false
        }
      }

      return true
    },
    checkSaveCustomTip () {
      if (this.customTip.commitTipShow && !this.customTip.commitTipContent) {
        this.msgWarning('请填写表单提交成功提示内容')
        return false
      }
      if (this.customTip.commitTipShow && this.customTip.commitTipWordCount > 500) {
        this.msgWarning('不能超过500字')
        return false
      }
      if (this.customTip.payTipShow && !this.customTip.payTipContent) {
        this.msgWarning('请填写支付成功提示内容')
        return false
      }
      if (this.customTip.payTipShow && this.customTip.payTipWordCount > 500) {
        this.msgWarning('不能超过500字')
        return false
      }
      return true;
    },
    regYuanToFen (yuan, digit) {
      var m = 0
      var s1 = yuan.toString()
      var s2 = digit.toString()
      try {
        m += s1.split('.')[1].length
      } catch (e) { }
      try {
        m += s2.split('.')[1].length
      } catch (e) { }
      return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m)
    },
    // 新版 3.0.0.1 填报人设置
    onRadioFillType () {
      const _this = this
      _this.isSetEdit = true
      if (_this.setForm.fillAuth == '4') {
        _this.getHistoryFormList()

        _this.$nextTick(() => {
          _this.isEditCur = {}
          _this.isSetEdit = false
        })
      }
    },
    onFillSelectMemberClick () {
      this.memberSelect.open = true
      this.memberParams.searchTxt = ''
      this.memberParams.agencyDeptId = undefined
      this.isFillFocus = false
      
      this.$refs.fillUserSelect.show(this.selectAllFillArr, (members) => {
        this.selectAllFillArr = members
        this.isFillConfirmBtn = true
        this.$forceUpdate();
      });
      this.getListDept()
      this.getListMember()
      this.getListRole()
    },
    onFillMemberClick () {
      this.memberParams.agencyDeptId = undefined
      this.getListMember()
    },
    onSelectTabClick (index) {
      this.selectCur = index
    },
    onCheckBoxUser (index) {
      this.memberList[index].checked = !this.memberList[index].checked
      if (this.memberList[index].checked) {
        this.selectAllFillArr.push({
          type: 'member',
          name: this.memberList[index].name,
          agencyUserId: this.memberList[index].agencyUserId
        })
      } else {
        const findIndex = this.selectAllFillArr.findIndex((value) => value.name == this.memberList[index].name)
        if (findIndex != -1) {
          this.selectAllFillArr.splice(findIndex, 1)
        }
      }
    },
    onFillRemoveSelect (item, index) {
      this.selectAllFillArr.splice(index, 1)
      if (item.type == 'member') {
        const findIndex = this.memberList.findIndex((value) => value.name == item.name)
        this.memberList[findIndex].checked = !this.memberList[findIndex].checked
      }
      if (item.type == 'dept') {
        this.$refs.fillDeptTree.setChecked(item.agencyDeptId, false, false)
      }
      if (item.type == 'role') {
        this.$refs.fillRoleTree.setChecked(item.agencyRoleId, false, false)
      }
    },
    onFillCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllFillArr.push({
          type: 'dept',
          name: data.deptName,
          agencyDeptId: data.agencyDeptId,
          level: data.level
        })
      } else {
        const findIndex = this.selectAllFillArr.findIndex((value) => value.agencyDeptId == data.agencyDeptId)
        if (findIndex != -1) {
          this.selectAllFillArr.splice(findIndex, 1)
        }
      }
    },
    onFillRoleCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllFillArr.push({
          type: 'role',
          name: data.roleName,
          agencyRoleId: data.agencyRoleId
        })
      } else {
        const findIndex = this.selectAllFillArr.findIndex((value) => value.agencyRoleId == data.agencyRoleId)
        if (findIndex != -1) {
          this.selectAllFillArr.splice(findIndex, 1)
        }
      }
    },
    onFillChangeSearchTxt () {
      this.getListMember()
    },
    onFillConfirm () {
      this.memberSelect.open = false
      this.isFillConfirmBtn = true
    },
    onFillCancel () {
      this.memberSelect.open = false
    },
    onFillClose () {
      this.memberSelect.open = false
    },
    // 新版 3.0.0.1 填报人设置---历史表单人员可填
    getHistoryFormList () {
      const _this = this
      historyFormList(_this.formId).then((response) => {
        _this.historyFormList = response.data


      })
    },
    getFillAuthAllowPhone () {
      const _this = this
      fillAuthAllowPhone(_this.formId).then(res => {
        if (res.data)
          _this.setForm.fillAuthAllowPhone = res.data.replace(/,/g, '\n')
      })
    },
    // 新版 3.0.0.1 填报人设置---历史表单人员远程搜索
    onRemoteSearch (searchName) {
      const _this = this
      if (!this.isBlank(searchName)) {
        historyFormList(_this.formId, searchName).then((response) => {
          _this.historyFormList = response.data
        })
      }
    },
    onSelectHistoryFormName () {
      const _this = this
      _this.isSetEdit = true
    },
    onRadioHistorySelect () {
      const _this = this
      _this.isSetEdit = true
    },
    // 请求组织架构列表
    getListDept () {
      const _this = this
      agencyDeptList().then((response) => {
        const treeList = response.data
        // 填报人 选中状态
        if (_this.setCur == 3) {
          _this.fillDeptOptions = _this.handleTree(treeList, 'agencyDeptId')
          if (!_this.isBlank(_this.selectAllFillArr)) {
            const fillArr = []
            for (let j = 0; j < _this.selectAllFillArr.length; j++) {
              for (let k = 0; k < treeList.length; k++) {
                if (_this.selectAllFillArr[j].agencyDeptId == treeList[k].agencyDeptId) {
                  fillArr.push(_this.selectAllFillArr[j].agencyDeptId)
                  _this.$refs.fillDeptTree.setCheckedKeys(fillArr)
                }
              }
            }
          }
        }
        // 审核人 选中状态
        if (_this.setCur == 4) {
          if (_this.formAuditAuth.auditAuthLevel == 1) {
            _this.audit1DeptOptions = _this.handleTree(treeList, 'agencyDeptId')
            if (!_this.isBlank(_this.selectAllAudit1Arr)) {
              const auditArr = []
              for (let j = 0; j < _this.selectAllAudit1Arr.length; j++) {
                for (let k = 0; k < treeList.length; k++) {
                  if (_this.selectAllAudit1Arr[j].agencyDeptId == treeList[k].agencyDeptId) {
                    auditArr.push(_this.selectAllAudit1Arr[j].agencyDeptId)
                    _this.$refs.audit1DeptTree.setCheckedKeys(auditArr)
                  }
                }
              }
            }
          } else {
            _this.audit2DeptOptions = _this.handleTree(treeList, 'agencyDeptId')
            if (!_this.isBlank(_this.selectAllAudit2Arr)) {
              const audit2Arr = []
              for (let j = 0; j < _this.selectAllAudit2Arr.length; j++) {
                for (let k = 0; k < treeList.length; k++) {
                  if (_this.selectAllAudit2Arr[j].agencyDeptId == treeList[k].agencyDeptId) {
                    audit2Arr.push(_this.selectAllAudit2Arr[j].agencyDeptId)
                    _this.$refs.audit2DeptTree.setCheckedKeys(audit2Arr)
                  }
                }
              }
            }
          }
        }
        // 查看人 选中状态
        if (_this.setCur == 5) {
          _this.deptOptions = _this.handleTree(treeList, 'agencyDeptId')
          if (!_this.isBlank(_this.selectAllViewArr)) {
            const arr = []
            for (let j = 0; j < _this.selectAllViewArr.length; j++) {
              for (let k = 0; k < treeList.length; k++) {
                if (_this.selectAllViewArr[j].agencyDeptId == treeList[k].agencyDeptId) {
                  arr.push(_this.selectAllViewArr[j].agencyDeptId)
                  _this.$refs.viewDeptTree.setCheckedKeys(arr)
                }
              }
            }
          }
        }
      })
    },
    getDeptTree (tree = []) {
      const arr = []
      if (tree.length !== 0) {
        tree.forEach((item) => {
          const obj = {}
          obj.label = item.deptName
          obj.id = item.agencyDeptId
          if (item.children) {
            obj.children = this.getDeptTree(item.children)
          }
          arr.push(obj)
        })
      }
      return arr
    },
    // 请求成员列表
    getListMember () {
      const _this = this
      const agencyId = !_this.isBlank(_this.memberParams.agencyId) ? _this.memberParams.agencyId : _this.agencyId
      agencyUserMemberList(agencyId, _this.memberParams.searchTxt, _this.memberParams.agencyDeptId).then((response) => {
        if (response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].checked = false
          }
          _this.memberList = response.data
          // 填报人 选中状态
          if (_this.setCur == 3) {
            if (!_this.isBlank(_this.selectAllFillArr)) {
              for (let j = 0; j < _this.selectAllFillArr.length; j++) {
                for (let k = 0; k < _this.memberList.length; k++) {
                  if (_this.selectAllFillArr[j].name == _this.memberList[k].name) {
                    _this.memberList[k].checked = true
                  }
                }
              }
            }
          }
          // 审核人 选中状态
          if (_this.setCur == 4) {
            if (_this.formAuditAuth.auditAuthLevel == 1) {
              if (!_this.isBlank(_this.selectAllAudit1Arr)) {
                for (let j = 0; j < _this.selectAllAudit1Arr.length; j++) {
                  for (let k = 0; k < _this.memberList.length; k++) {
                    if (_this.selectAllAudit1Arr[j].agencyUserId == _this.memberList[k].agencyUserId) {
                      _this.memberList[k].checked = true
                    }
                  }
                }
              }
            } else {
              if (!_this.isBlank(_this.selectAllAudit2Arr)) {
                for (let j = 0; j < _this.selectAllAudit2Arr.length; j++) {
                  for (let k = 0; k < _this.memberList.length; k++) {
                    if (_this.selectAllAudit2Arr[j].agencyUserId == _this.memberList[k].agencyUserId) {
                      _this.memberList[k].checked = true
                    }
                  }
                }
              }
            }
          }
          // 查看人 选中状态
          if (_this.setCur == 5) {
            if (!_this.isBlank(_this.selectAllViewArr)) {
              for (let j = 0; j < _this.selectAllViewArr.length; j++) {
                for (let k = 0; k < _this.memberList.length; k++) {
                  if (_this.selectAllViewArr[j].agencyUserId == _this.memberList[k].agencyUserId) {
                    _this.memberList[k].checked = true
                  }
                }
              }
            }
          }
        } else {
          _this.msgError(response.msg)
        }
      })
    },
    // 请求角色列表
    getListRole () {
      const _this = this
      agencyRoleList().then((response) => {
        const treeList = response.data
        // 填报人 选中状态
        if (_this.setCur == 3) {
          _this.fillRoleOptions = _this.handleTree(treeList, 'agencyRoleId')
          if (!_this.isBlank(_this.selectAllFillArr)) {
            const fillArr = []
            for (let j = 0; j < _this.selectAllFillArr.length; j++) {
              for (let k = 0; k < treeList.length; k++) {
                if (_this.selectAllFillArr[j].agencyRoleId == treeList[k].agencyRoleId) {
                  fillArr.push(_this.selectAllFillArr[j].agencyRoleId)
                  _this.$refs.fillRoleTree.setCheckedKeys(fillArr)
                }
              }
            }
          }
        }
        // 审核人 选中状态
        if (_this.setCur == 4) {
          if (_this.formAuditAuth.auditAuthLevel == 1) {
            _this.audit1RoleOptions = _this.handleTree(treeList, 'agencyRoleId')
            if (!_this.isBlank(_this.selectAllAudit1Arr)) {
              const audit1Arr = []
              for (let j = 0; j < _this.selectAllAudit1Arr.length; j++) {
                for (let k = 0; k < treeList.length; k++) {
                  if (_this.selectAllAudit1Arr[j].agencyRoleId == treeList[k].agencyRoleId) {
                    audit1Arr.push(_this.selectAllAudit1Arr[j].agencyRoleId)
                    _this.$refs.audit1RoleTree.setCheckedKeys(audit1Arr)
                  }
                }
              }
            }
          } else {
            _this.audit2RoleOptions = _this.handleTree(treeList, 'agencyRoleId')
            if (!_this.isBlank(_this.selectAllAudit2Arr)) {
              const audit2Arr = []
              for (let j = 0; j < _this.selectAllAudit2Arr.length; j++) {
                for (let k = 0; k < treeList.length; k++) {
                  if (_this.selectAllAudit2Arr[j].agencyRoleId == treeList[k].agencyRoleId) {
                    audit2Arr.push(_this.selectAllAudit2Arr[j].agencyRoleId)
                    _this.$refs.audit2RoleTree.setCheckedKeys(audit2Arr)
                  }
                }
              }
            }
          }
        }
        // 查看人 选中状态
        if (_this.setCur == 5) {
          _this.roleOptions = _this.handleTree(treeList, 'agencyRoleId')
          if (!_this.isBlank(_this.selectAllViewArr)) {
            const arr = []
            for (let j = 0; j < _this.selectAllViewArr.length; j++) {
              for (let k = 0; k < treeList.length; k++) {
                if (_this.selectAllViewArr[j].agencyRoleId == treeList[k].agencyRoleId) {
                  arr.push(_this.selectAllViewArr[j].agencyRoleId)
                  _this.$refs.viewRoleTree.setCheckedKeys(arr)
                }
              }
            }
          }
        }
      })
    },
    // 组织架构树形节点点击
    onHandleNodeClick (data) {
      this.memberParams.agencyDeptId = data.agencyDeptId
      this.getListMember()
    },
    // 新版 3.0.0.1 导出设置
    onBeforeExportFileUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      const extension1 = fileSuffix === 'doc'
      const extension2 = fileSuffix === 'docx'
      if (!extension1 && !extension2) {
        this.$message({
          message: '上传文件只能是 doc、docx!',
          type: 'warning'
        })
        return false
      }
    },
    onHttpExportFileUpload ({ file }) {
      this.isSetEdit = true
      this.exportSetting.file = file
    },
    onHandleExportFileSuccess (file) { },
    onHandleExportFileChange (file, fileList) {
      this.exportSetting.fileList = fileList.slice(-1)
    },
    onHandleExportFileRemove (file) {
      this.exportSetting.fileList = []
      this.exportSetting.file = ''
    },
    // 导出模板下载
    onExportTemplate () {
      const fileList = this.exportSetting.fileList
      if (fileList[0].url) {
        const url = fileList[0].url
        window.location.href = url
      } else {
        const file = fileList[0]
        const reader = new FileReader();
        reader.onload = function (event) {
          // 创建一个新的 a 标签
          const a = document.createElement('a')

          // 设置 href 属性为 File 对象的 data URL
          a.href = event.target.result

          // 设置 download 属性为文件名
          a.download = file.name

          // 将 a 标签添加到页面
          document.body.appendChild(a)

          // 模拟点击 a 标签，开始下载
          a.click()

          // 下载完成后，从页面移除 a 标签
          document.body.removeChild(a)
        };
        reader.readAsDataURL(fileList[0].raw);
      }

    },
    // 导出模板下载
    onDeleteExportTemplate () {
      this.$confirm('确定要删除Word导出模板吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { param } = this.createParam()
        this.exportSetting.exportIsPerson = false;
        param.exportIsPerson = this.exportSetting.exportIsPerson
        param.exportIsPdf = false;
        param.waterMark = "";
        param.exportAfterAudit = this.exportSetting.exportAfterAudit  
        let response = await httpFormExport(param, null);
        if (response.code === 200) {
          this.exportSetting.fileList = [];
          this.msgSuccess('删除成功!')
        }
      }).catch(() => {
      });
    },
    async onCopyTemplateField () {
      const value = event.target.innerText
      const oInput = document.createElement('input') // 创建input节点
      oInput.value = value // 给input的value赋值
      document.body.appendChild(oInput) // 向页面插入input节点
      oInput.select() // 选中input
      try {
        await document.execCommand('Copy') // 执行浏览器复制命令
        this.$message({
          type: 'success',
          message: '复制成功'
        })
      } catch {
        this.$message({
          type: 'success',
          message: '复制失败'
        })
      }
      document.body.removeChild(oInput)
    },
    brachText (item, subItem, subIndex) {
      const isPicPrefix = (ele) => {
        if (ele.type == 'picture-upload') {
          return `fe:`
        } else if (ele.type == 'sign') {
          return `fe:`
        } else {
          return ``
        }
      }
      if (!subItem) {
        // 非子表单字段
        return `{{${isPicPrefix(item)}${item.name}}}`
      } else {
        if (subIndex == 0) {
          return `{{$fe:${item.name} t.${subItem[subIndex].name}`
        } else if (subIndex == subItem.length - 1) {
          return `${isPicPrefix(subItem[subIndex])}t.${subItem[subIndex].name}}}`
        } else {
          return `${isPicPrefix(subItem[subIndex])}t.${subItem[subIndex].name}`
        }
      }
    },
    onCopyTemplate9Field (field) {
      this.field9Dialog.title = '该模版字段为图片，仅支持docx版本。'
      this.field9Dialog.open = true
      this.field9Dialog.field = field
    },
    onChangeField9Width () {
      if (!/^\d+$/.test(this.field9Dialog.fieldWidth)) {
        this.$message({
          message: '宽度格式设置有误!',
          type: 'warning'
        })
        return false
      }
    },
    onChangeField9Height () {
      if (!/^\d+$/.test(this.field9Dialog.fieldHeight)) {
        this.$message({
          message: '高度格式设置有误!',
          type: 'warning'
        })
        return false
      }
    },
    async onConfirmCopyField9 () {
      if (!/^\d+$/.test(this.field9Dialog.fieldWidth)) {
        return false
      }
      if (!/^\d+$/.test(this.field9Dialog.fieldHeight)) {
        return false
      }
      const value = '${img-' + this.field9Dialog.field + '-' + this.field9Dialog.fieldWidth + '-' + this.field9Dialog.fieldHeight + '}'
      const oInput = document.createElement('input') // 创建input节点
      oInput.value = value // 给input的value赋值
      document.body.appendChild(oInput) // 向页面插入input节点
      oInput.select() // 选中input
      try {
        await document.execCommand('Copy') // 执行浏览器复制命令
        this.$message({
          type: 'success',
          message: '复制成功'
        })
      } catch {
        this.$message({
          type: 'success',
          message: '复制失败'
        })
      }
      document.body.removeChild(oInput)
      this.field9Dialog.open = false
      this.field9Dialog.fieldWidth = '150'
      this.field9Dialog.fieldHeight = '200'
    },
    onCloseField9Dialog () {
      this.field9Dialog.open = false
      this.field9Dialog.fieldWidth = '150'
      this.field9Dialog.fieldHeight = '200'
    },
    // 新版 3.0.0.1 查看人设置
    onViewSelectMemberClick () {
      // this.viewMemberSelect.open = true
      // this.memberParams.searchTxt = ''
      // this.memberParams.agencyDeptId = undefined
      // this.isViewFocus = false
      // this.getListDept()
      // this.getListMember()
      // this.getListRole()
      this.$refs.memberSelect.show(this.selectAllViewArr, (members) => {
        this.selectAllViewArr = members
        this.isViewConfirmBtn = true
        this.$forceUpdate();
      })

    },
    onAdminSelectMemberClick () {
      this.$refs.adminMemberSelect.show(this.selectAllAdminArr, (members) => {
        this.selectAllAdminArr = members
        this.isAdminConfirmBtn = true
        this.$forceUpdate();
      })
    },
    onViewMemberClick () {
      this.memberParams.agencyDeptId = undefined
      this.getListMember()
    },
    onViewSelectTabClick (index) {
      this.viewCur = index
    },
    onViewCheckBoxUser (index) {
      this.memberList[index].checked = !this.memberList[index].checked
      if (this.memberList[index].checked) {
        this.selectAllViewArr.push({
          type: 'member',
          name: this.memberList[index].name,
          agencyUserId: this.memberList[index].agencyUserId
        })
      } else {
        const findIndex = this.selectAllViewArr.findIndex((value) => value.name == this.memberList[index].name)
        if (findIndex != -1) {
          this.selectAllViewArr.splice(findIndex, 1)
        }
      }
    },
    onViewRemoveSelect (item, index) {
      this.selectAllViewArr.splice(index, 1)
      if (item.type == 'member') {
        const findIndex = this.memberList.findIndex((value) => value.name == item.name)
        this.memberList[findIndex].checked = !this.memberList[findIndex].checked
      }
      if (item.type == 'dept') {
        this.$refs.viewDeptTree.setChecked(item.agencyDeptId, false, false)
      }
      if (item.type == 'role') {
        this.$refs.viewRoleTree.setChecked(item.agencyRoleId, false, false)
      }
    },
    onViewCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllViewArr.push({
          type: 'dept',
          name: data.deptName,
          agencyDeptId: data.agencyDeptId
        })
      } else {
        const findIndex = this.selectAllViewArr.findIndex((value) => value.agencyDeptId == data.agencyDeptId)
        if (findIndex != -1) {
          this.selectAllViewArr.splice(findIndex, 1)
        }
      }
    },
    onViewRoleCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllViewArr.push({
          type: 'role',
          name: data.roleName,
          agencyRoleId: data.agencyRoleId
        })
      } else {
        const findIndex = this.selectAllViewArr.findIndex((value) => value.agencyRoleId == data.agencyRoleId)
        if (findIndex != -1) {
          this.selectAllViewArr.splice(findIndex, 1)
        }
      }
    },
    onViewChangeSearchTxt () {
      this.getListMember()
    },
    onViewFocusSearchTxt () {
      // 数据填报设置
      if (this.setCur === 3) {
        this.isFillFocus = true
      }
      // 数据审核设置
      if (this.setCur === 4) {
        if ((this.formAuditAuth.auditAuthLevel = 1)) {
          this.isAudit1Focus = true
        } else {
          this.isAudit2Focus = true
        }
      }
      // 数据查看设置
      if (this.setCur === 5) {
        this.isViewFocus = true
      }
    },
    onViewClearSearchTxt () {
      if (!this.isBlank(this.memberParams.searchTxt)) {
        if (this.setCur === 3) {
          this.isFillFocus = false
        }
        if (this.setCur === 4) {
          if ((this.formAuditAuth.auditAuthLevel = 1)) {
            this.isAudit1Focus = false
          } else {
            this.isAudit2Focus = false
          }
        }
        if (this.setCur === 5) {
          this.isViewFocus = false
        }
        this.memberParams.searchTxt = ''
        this.getListMember()
      }
    },
    onViewConfirm () {
      this.viewMemberSelect.open = false
      this.isViewConfirmBtn = true
    },
    onViewCancel () {
      // this.selectAllViewArr = []
      this.viewMemberSelect.open = false
      // this.$refs.viewDeptTree.setCheckedKeys([])
      // this.$refs.viewRoleTree.setCheckedKeys([])
    },
    onViewClose () {
      // this.selectAllViewArr = []
      this.viewMemberSelect.open = false
      // this.$refs.viewDeptTree.setCheckedKeys([])
      // this.$refs.viewRoleTree.setCheckedKeys([])
    },
    // 新版 3.0.0.1 一级审核人设置
    onReviewAddClick () {
      const _this = this
      _this.isSetEdit = true
      if (_this.formAuditAuth.clickNum == 1) {
        if (_this.isBlank(_this.formAuditAuth.auditAuth1)) {
          this.msgWarning('一级审核设置不完整，请继续填写完整')
          return false
        }
        if (_this.formAuditAuth.auditAuth1 == 2) {
          if (_this.isBlank(_this.selectAllAudit1Arr)) {
            this.msgWarning('一级审核设置不完整，请继续填写完整')
            return false
          }
        }
        _this.formAuditAuth.auditAuth2Show = true
      }
      if (_this.formAuditAuth.clickNum == 2) {
        this.msgWarning('暂只支持二级审核哦～')
        return false
      }
      _this.formAuditAuth.auditAuth1Show = true
      _this.formAuditAuth.clickNum++
    },
    onAudit1SelectMemberClick () {
      this.audit1MemberSelect.open = true
      this.memberParams.searchTxt = ''
      this.memberParams.agencyDeptId = undefined
      this.isAudit1Focus = false
      this.getListDept()
      this.getListMember()
      this.getListRole()
      this.formAuditAuth.auditAuthLevel = 1
    },
    onAudit1SelectTabClick (index) {
      this.audit1Cur = index
    },
    onAudit1CheckBoxUser (index) {
      this.memberList[index].checked = !this.memberList[index].checked
      if (this.memberList[index].checked) {
        this.selectAllAudit1Arr.push({
          type: 'member',
          name: this.memberList[index].name,
          agencyUserId: this.memberList[index].agencyUserId
        })
      } else {
        const findIndex = this.selectAllAudit1Arr.findIndex((value) => value.name == this.memberList[index].name)
        if (findIndex != -1) {
          this.selectAllAudit1Arr.splice(findIndex, 1)
        }
      }
    },
    onAudit1CheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllAudit1Arr.push({
          type: 'dept',
          name: data.deptName,
          agencyDeptId: data.agencyDeptId,
          level: data.level
        })
      } else {
        const findIndex = this.selectAllAudit1Arr.findIndex((value) => value.agencyDeptId == data.agencyDeptId)
        if (findIndex != -1) {
          this.selectAllAudit1Arr.splice(findIndex, 1)
        }
      }
    },
    onAudit1RoleCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllAudit1Arr.push({
          type: 'role',
          name: data.roleName,
          agencyRoleId: data.agencyRoleId
        })
      } else {
        const findIndex = this.selectAllAudit1Arr.findIndex((value) => value.agencyRoleId == data.agencyRoleId)
        if (findIndex != -1) {
          this.selectAllAudit1Arr.splice(findIndex, 1)
        }
      }
    },
    onAudit1RemoveSelect (item, index) {
      this.selectAllAudit1Arr.splice(index, 1)
      if (item.type == 'member') {
        const findIndex = this.memberList.findIndex((value) => value.name == item.name)
        this.memberList[findIndex].checked = !this.memberList[findIndex].checked
      }
      if (item.type == 'dept') {
        this.$refs.audit1DeptTree.setChecked(item.agencyDeptId, false, false)
      }
      if (item.type == 'role') {
        this.$refs.audit1RoleTree.setChecked(item.agencyRoleId, false, false)
      }
    },
    onAudit1Cancel () {
      this.audit1MemberSelect.open = false
    },
    onAudit1Confirm () {
      this.audit1MemberSelect.open = false
      this.isAudit1ConfirmBtn = true
    },
    onAudit1Close () {
      this.audit1MemberSelect.open = false
    },
    // 二级审核
    onAudit2SelectMemberClick () {
      this.audit2MemberSelect.open = true
      this.memberParams.searchTxt = ''
      this.memberParams.agencyDeptId = undefined
      this.isAudit2Focus = false
      this.getListDept()
      this.getListMember()
      this.getListRole()
      this.formAuditAuth.auditAuthLevel = 2
    },
    onAudit2SelectTabClick (index) {
      this.audit2Cur = index
    },
    onAudit2CheckBoxUser (index) {
      this.memberList[index].checked = !this.memberList[index].checked
      if (this.memberList[index].checked) {
        this.selectAllAudit2Arr.push({
          type: 'member',
          name: this.memberList[index].name,
          agencyUserId: this.memberList[index].agencyUserId
        })
      } else {
        const findIndex = this.selectAllAudit2Arr.findIndex((value) => value.name == this.memberList[index].name)
        if (findIndex != -1) {
          this.selectAllAudit2Arr.splice(findIndex, 1)
        }
      }
    },
    onAudit2CheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllAudit2Arr.push({
          type: 'dept',
          name: data.deptName,
          agencyDeptId: data.agencyDeptId,
          level: data.level
        })
      } else {
        const findIndex = this.selectAllAudit2Arr.findIndex((value) => value.agencyDeptId == data.agencyDeptId)
        if (findIndex != -1) {
          this.selectAllAudit2Arr.splice(findIndex, 1)
        }
      }
    },
    onAudit2RoleCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllAudit2Arr.push({
          type: 'role',
          name: data.roleName,
          agencyRoleId: data.agencyRoleId
        })
      } else {
        const findIndex = this.selectAllAudit2Arr.findIndex((value) => value.agencyRoleId == data.agencyRoleId)
        if (findIndex != -1) {
          this.selectAllAudit2Arr.splice(findIndex, 1)
        }
      }
    },
    onAudit2RemoveSelect (item, index) {
      this.selectAllAudit2Arr.splice(index, 1)
      if (item.type == 'member') {
        const findIndex = this.memberList.findIndex((value) => value.name == item.name)
        this.memberList[findIndex].checked = !this.memberList[findIndex].checked
      }
      if (item.type == 'dept') {
        this.$refs.audit2DeptTree.setChecked(item.agencyDeptId, false, false)
      }
      if (item.type == 'role') {
        this.$refs.audit2RoleTree.setChecked(item.agencyRoleId, false, false)
      }
    },
    onAudit2Cancel () {
      this.audit2MemberSelect.open = false
    },
    onAudit2Confirm () {
      this.audit2MemberSelect.open = false
      this.isAudit2ConfirmBtn = true
    },
    onAudit2Close () {
      this.audit2MemberSelect.open = false
    },
    onCheckPersonExport (val) {
      if (val && (!this.exportSetting.fileList || this.exportSetting.fileList.length == 0)) {
        this.msgWarning('请先设置导出模板')
        this.exportSetting.exportIsPerson = false;
        this.exportSetting.exportIsPdf = false;
        this.onChangeEdit();
      }

    },
    // 开启数据管理权限的逻辑处理
    /// 如果选择了根据选项设置管理员，处理一下选项
    onDataManageTypeChecked (val) {
      this.onChangeEdit();

      // 如果根据选项设置管理员
      if (val.indexOf('3') >= 0) {

        var formJson = {
          widgetList: []
        };
        if (formJson.widgetList.length == 0) {
          var tryTimes = 0;
          var tryInterval = setTimeout(() => {
            tryTimes++;

            formJson = this.$parent.$refs.vformDesigner.getFormJson();
            if (formJson.widgetList.length > 0) {
              this.managefieldList = formJson.fieldList.filter((x) => ((x.type == 'select' || x.type == 'radio' || x.type == 'cascader') && !!x.isRequired)).map(x => {
                return {
                  label: x.displayName,
                  formFieldId: x.name,
                  ...x
                }
              })
            }

            if (tryTimes > 10 || formJson.widgetList.length > 0) {
              clearTimeout(tryInterval);
            }
          }, 500)
        }
      }
    },
    // 选择了根据选项设置管理员，处理一下选项
    onManageUserSelectByField (val) {
      let _this = this;
      this.formViewAuth.viewAuthFieldList = val.fieldConditions.map(x => {
        return {
          fieldValue: x.fieldValue,
          formFieldId: x.formFieldId,
          viewAuthAgencyDeptId: x.auditAuthAgencyDeptId,
          viewAuthAgencyRoleId: x.auditAuthAgencyRoleId,
          viewAuthAgencyUserId: x.auditAuthAgencyUserId
        }
      })
      this.$forceUpdate()
    }
  }
}
</script>

<style lang='scss' scoped>
@import '../../../../assets/styles/workflow.css';

.app-form-set {
  box-sizing: border-box;
  // background: #ffffff;
  margin: auto;
  display: flex;
  display: -webkit-flex;
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;

  .form-set-menu {
    min-height: 444px;
    padding: 20px 16px;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 4px;
    background: #ffffff;

    .menu-item {
      width: 100%;
      // text-align: center;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      margin: 0 0 8px;
      list-style: none;
      font-size: 14px;
      color: #333333;
      background-color: #fafbfb;
      font-weight: 500 !important;
      border-radius: 6px;
      font-weight: 500;
      position: relative;

      &.active {
        background: rgba(0, 92, 238, 0.08);
        color: #3476f0;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 4px;
          height: 30px;
          top: 50%;
          left: 0;
          margin-top: -15px;
          background: rgba(0, 92, 238, 0.4);
          border-radius: 0 3px 3px 0;
        }
      }

      &:hover {
        background: rgba(0, 92, 238, 0.08);
        color: #3476f0;
      }

      .tab-title-icon {
        width: 18px;
        height: 18px;
        margin-left: 10px;
        margin-right: 8px;
        vertical-align: -5px;
      }
    }
  }

  .form-set-pane {
    // border-left: 1px solid #e7eaf1;
    box-sizing: border-box;
    flex: 1;
    -webkit-flex: 1;
    padding: 20px;
    background-color: white;
    border-radius: 4px;
    overflow-y: auto;

    .form-set-title {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      align-items: center;

      .set-title {
        color: #394349;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        margin-right: 20px;
      }

      .set-line-box {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        -o-flex: 1;
        -moz-flex: 1;
        flex: 1;
        height: 1px;
        background: #e5e5e5;
        margin-right: 20px;
      }
    }

    .fill-set-pane {
      width: 100%;
      margin-top: 20px;

      .fill-set-history-select {
        width: 100%;
        margin-top: 20px;
      }

      .fill-set-item-title {
        height: 30px;
        line-height: 30px;
      }
    }

    .form-data-switch {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      align-items: center;

      label {
        margin-right: 20px;
        font-weight: 500;
        font-size: 14px;
      }

      // &:last-of-type{
      margin-top: 20px;
      // }
    }

    .review-flow-pane {
      width: 100%;
      margin: 20px auto;
      text-align: center;

      .node-wrap-container {
        width: 100%;
        margin-top: 20px;

        .auditAuth-title {
          font-size: 15px;
          margin-bottom: 20px;
        }
      }
    }

    .data-view-select {
      width: 100%;
      // display: flex;
      // display: -webkit-flex;
      // align-items: center;
      margin-top: 20px;
    }

    .dept-admin {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      margin-top: 20px;

      .auditSelectEmpty {
        width: 100%;
        line-height: 30px;
        color: #666666;
        border: 1px solid #e0e0e0;
        padding: 10px 0px;
        font-size: 14px;

        span {
          cursor: pointer;
          font-size: 12px;
          color: #989898;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .setting-title {
      white-space: nowrap;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      width: 100%;
      max-width: 900px;

      span {
        margin-right: 20px;
        line-height: 36px;
      }

      &-upload {
        display: inline-flex;
        flex-wrap: wrap;
      }

      &-filename {
        margin-left: 75px;
        width: 300px;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        color: #3476f0;
      }
    }

    .my-export-switch {
      width: 100%;
      height: 25px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      margin-top: 20px;

      label {
        margin-right: 20px;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .setting-export-style {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      margin-top: 20px;
      flex-direction: column;

      .export-style-title {
        font-size: 14px;
        margin-bottom: 20px;
        flex-direction: column;

        span {
          color: #999999;
          font-size: 12px;
          margin-left: 10px;
        }
      }

      .system-field {
        margin-right: 40px;

        .system-field-title {
          font-size: 14px;
          line-height: 20px;
        }

        .system-field-table {
          margin-top: 20px;

          .field-head {
            width: 50%;
            display: flex;
            display: -webkit-flex;
            background: #f0f2f5;
            padding: 0;
            margin: 0;

            span {
              font-size: 14px;
              line-height: 40px;
              width: 50%;
              display: inline-block;
              text-align: center;
            }
          }

          .field-body {
            width: 50%;
            margin: 0;
            padding: 0;
            border: 1px solid #e7eaf1;
            box-sizing: border-box;
            cursor: pointer;

            li {
              width: 100%;
              list-style: none;
              border-bottom: 1px solid #e7eaf1;
              box-sizing: border-box;

              &:last-child {
                border-bottom: 0;
              }

              span {
                display: inline-block;
                width: 50%;
                text-align: center;
                font-size: 14px;
                line-height: 40px;
                border-right: 1px solid #e7eaf1;
                box-sizing: border-box;

                &:nth-child(2n) {
                  border-right: 0;
                }
              }
            }
          }
        }
      }

      .form-field {
        width: 100%;
        padding-right: 40px;
        box-sizing: border-box;
        display: flex;

        table {
          border-collapse: collapse;
          width: 100%;
        }

        th,
        td {
          border: solid 1px #e7eaf1;
          text-align: left;
          padding: 8px;

          &.sub-form,
          &.fill-select {
            padding: 0px;
          }
          &.copyable {
            cursor: pointer;
          }
        }

        div.col-display-name,
        td.col-display-name,
        div.col-name td.col-name {
          width: 25%;
          line-height: 40px;
          display: inline-block;
          border: solid 1px #e7eaf1;
          border-bottom: none;
          border-left: none;

          .row {
            font-size: 14px;
            line-height: 40px;
            text-align: center;
            border-left: solid 1px #e7eaf1;
            border-bottom: solid 1px #e7eaf1;

            // user-select: none;
            &:first-of-type {
              background: #f0f2f5;
            }
          }

          .display-name {
            max-width: 252px;
            width: 100%;
            display: inline-block;
            text-align: center;
            cursor: pointer;
          }

          .field-name {
            position: relative;
            overflow: hidden;
            box-sizing: border-box;

            &.sub-form,
            &.fill-select {
              text-align: left;
              line-height: 38px;
              overflow-x: scroll;
              user-select: none;
            }

            &::-webkit-scrollbar {
              height: 4px;
            }

            /* 滚动槽 */
            &::-webkit-scrollbar-track {
              background-color: #f6f6f6;
              border-radius: 10px;
            }
          }

          .sub-form,
          .fill-select {
            line-height: 72px;
            height: 84px;
            overflow: hidden;
          }
        }

        div.col-name {
          width: auto;
          min-width: 25%;
          // max-width: 74%;
          max-width: 995px;

          .row {
            border-left: none;
          }
        }

        .subform-display-name {
          height: 40px;
          background: #f0f2f5;
          border-bottom: solid 1px #e7eaf1;
          padding: 0px 20px;
          box-sizing: border-box;
          box-sizing: border-box;
          user-select: none;
        }

        .subform-name {
          height: 40px;
          box-sizing: border-box;
          padding: 0px 20px;
          box-sizing: border-box;
          cursor: pointer;
          user-select: none;
        }

        .subform-column {
          border-right: solid 1px #e7eaf1;
          flex-shrink: 0;
          user-select: none;

          &:last-of-type {
            // border-right: none;
          }
        }

        .brace {
          &:before {
            content: '{{';
          }

          &:after {
            content: '}}';
          }
        }

        .subform-brace {
          &:before {
            content: 't.';
          }
        }
      }
    }

    .designated-member {
      width: 100%;
      border: 1px solid #e0e0e0;
      cursor: pointer;

      .member-empty {
        cursor: pointer;
        line-height: 78px;
        text-align: center;
        color: #989898;
        display: block;
        font-size: 14px;
      }
    }
  }
}

.set-tab-title {
  padding: 0;
  margin: 0;

  li {
    width: 100%;
    text-align: center;
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    text-align: center;
    margin: 0;
    list-style: none;
    border: 1px solid #dfdfdf;
    font-size: 14px;

    &:last-child {
      border-top: 0;
    }

    &.active {
      background-color: rgba(97, 175, 254, 0.1);
      color: #61affe;
      border: 1px solid #61affe;
    }
  }
}

.userSelect-container {
  width: calc(100% + 40px);
  margin-top: -30px;
  margin-left: -20px;
  margin-right: -20px;

  .userSelect-group {
    display: inline-block;
    background: #eee;
    width: 400px;
    min-height: 800px;
    float: left;

    .user-select-name-title {
      width: 100%;
      padding: 0 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 18px;
      margin: 20px auto 10px;
    }

    .user-select-total {
      width: 100%;
      padding: 0 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 30px;
      margin: 0;
      cursor: pointer;
    }

    .select-agency-name {
      font-size: 16px;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      line-height: 30px;
    }

    .userSelect-title {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }

    .selected-item {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      cursor: pointer;

      span {
        float: left;
      }
    }
  }

  .userSelect-right {
    display: inline-block;
    width: 800px;
    padding: 0 20px;
    box-sizing: border-box;
    min-height: 800px;

    .select-list {
      height: 80px;
      border: 1px solid #e0e0e0;
      margin-bottom: 10px;
      overflow: auto;
      margin-top: 0;
      padding: 0;
      list-style: none;

      li {
        display: inline-block;
        line-height: 30px;
        margin: 5px 0 0 5px;
        padding: 0 10px;
        border-radius: 1px;
        background: #f3f6fc;
        cursor: pointer;
      }
    }
  }
}

.review-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  width: 900px;
  overflow-x: scroll;
  padding: 15px 15px 20px;

  .review-item {
    width: 180px;
    display: inline-block;
    position: relative;
    margin-right: 30px;
    text-align: center;

    .fillNames-item {
      width: 180px;
      height: 110px;
      box-shadow: 0 0 6px 0 rgba(50, 150, 250, 0.3);
      //margin-left: -27px;
      padding: 10px 5px;
      box-sizing: border-box;
      position: relative;

      .fillNames-div {
        height: 50px;
        line-height: 22px;
        width: 100%;
        padding: 5px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .agency-user-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }

    .reviewer {
      width: 180px;
      text-align: center;
      //margin-left: -27px;
      position: relative;

      .review-select {
        width: 100%;
        box-shadow: 0 0 6px 0 rgba(50, 150, 250, 0.3);
        -webkit-box-shadow: 0 0 6px 0 rgba(50, 150, 250, 0.3);
        -moz-box-shadow: 0 0 6px 0 rgba(50, 150, 250, 0.3);
        -ms-box-shadow: 0 0 6px 0 rgba(50, 150, 250, 0.3);
        -o-box-shadow: 0 0 6px 0 rgba(50, 150, 250, 0.3);
        height: 110px;
        position: relative;
        cursor: pointer;

        .title {
          height: 24px;
          line-height: 24px;
        }

        .agency-user-list {
          height: 50px;
          line-height: 22px;
          width: 100%;
          padding: 5px;
          box-sizing: border-box;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;

          span {
            line-height: 16px;
            display: inline-block;
          }
        }

        .agency-user-btn {
          width: 100%;
          line-height: 36px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      .add-icon {
        position: absolute;
        bottom: -28px;
        left: 50%;
        margin-left: -15px;
      }
    }
  }
}

/*新版 3.0.0.1 填报人设置、查看人设置---指定成员可填写*/
.fx_member_select {
  margin-top: -30px;

  .select-menu {
    position: relative;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #e0e0e0;

    .select-btn {
      text-align: center;
      display: inline-block;
      cursor: pointer;
      line-height: 38px;
      height: 38px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 5px;
      margin: 0 10px;

      &.select {
        color: #3476f0;
        border-bottom: solid 4px #3476f0;
      }
    }

    .select-search-pane {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 114px;
      background: #fff;
      padding: 0 10px;
      box-sizing: border-box;
      transition: width 0.2s;

      input {
        line-height: 26px !important;
        height: 26px !important;
        width: 100%;
        border: 0;
        background: #f4f4f4;
        border-radius: 13px;
        margin-top: 6px;
        padding: 0 10px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: 0;

        &:focus {
          border: none;
          outline: 0;
        }
      }
    }
  }

  .select-pane {
    border: 1px solid #e0e0e0;
    border-top: none;
    height: 320px;

    .member-pane {
      .select-department {
        border-right: solid 1px #e0e0e0;
        width: 50%;
        box-sizing: border-box;
        height: 320px;

        .depart-root {
          background: #ebf8f7;
          text-indent: 10px;
          line-height: 30px;
          cursor: pointer;
        }

        .x-department-tree {
          height: 290px;
          overflow-y: auto;
        }
      }

      .member-wrapper {
        width: 50%;

        .empty-tips {
          display: inline-block;
          margin: 10px;
          color: #989898;
        }

        .select-all-item {
          cursor: pointer;
          height: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: space-between;
          -ms-flex-pack: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          padding: 0 8px 0 12px;
          border-bottom: solid 1px #e9e9e9;

          .count-label {
            color: #3476f0;
          }
        }

        .select-member {
          height: 290px;
          overflow-y: auto;

          ul {
            margin: 0;
            padding: 0;

            li {
              cursor: pointer;
              padding: 0 10px 0 10px;
              position: relative;
              line-height: 30px;
              width: 100%;
              box-sizing: border-box;

              &:hover {
                background: #ebf8fb;
              }

              .user-name {
                width: calc(100% - 20px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .select-check {
                cursor: pointer;
                width: 16px;

                .icon-blank {
                  display: inline-block;
                  width: 14px;
                  height: 14px;
                  border: 1px solid #91a1b7;
                  position: relative;
                  background: #fff;
                  -webkit-transition: all 218ms ease-in-out;
                  -moz-transition: all 218ms ease-in-out;
                  -o-transition: all 218ms ease-in-out;
                  transition: all 218ms ease-in-out;

                  &::before {
                    content: '';
                    display: block;
                    opacity: 0;
                    -webkit-transition: opacity 218ms ease-in-out;
                    -moz-transition: opacity 218ms ease-in-out;
                    -o-transition: opacity 218ms ease-in-out;
                    transition: opacity 218ms ease-in-out;
                    height: 6px;
                    width: 10px;
                    border-left: solid 2px #fff;
                    border-bottom: solid 2px #fff;
                    position: absolute;
                    top: 2px;
                    left: 1px;
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                  }
                }

                &.select {
                  .icon-blank {
                    border-color: #3476f0;
                    background: #3476f0;

                    &::before {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .tree-wrapper {
        width: 100%;
        height: 320px;
        box-sizing: border-box;
        overflow-y: auto;
        padding-top: 10px;
      }
    }
  }
}

.fx_member_close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.select-list {
  width: 100%;
  height: 80px;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
  overflow: auto;
  padding: 0;

  .select-item {
    display: inline-block;
    line-height: 30px;
    margin: 5px 0 0 5px;
    padding: 0 10px;
    border-radius: 1px;
    background: #f3f6fc;
    list-style: none;
    cursor: pointer;

    .remove-btn {
      margin-left: 10px;
      padding: 3px;
    }
  }
}

/* 选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #3476f0;
}

/* 选中后小圆点的颜色 */
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #3476f0;
  border-color: #3476f0;
}
</style>
