var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline" } },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "light", content: _vm.tipText, placement: "top" },
        },
        [_c("i", { staticClass: "el-icon-question" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }