<template>
  <div class="app-container app-info" style="max-width: 100%">
    <el-row :gutter="24" style="margin: 0; width: 100%">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding: 0">
        <el-form ref="designForm" :model="designForm" :rules="designRules" size="small" label-width="80px" label-position="right">
          <el-form-item label="封面图片" prop="topImg">
            <el-col :span="6" style="padding: 0; width: 200px; height: 120px">
              <!-- <div class="" @click="editCropper" style="width: 200px; height: 120px;">
                <div class="avatar-uploader-hover"></div>
                <img :src="designForm.cover" class="avatar" v-if="designForm.cover" />
                <i class="el-icon-plus avatar-uploader-icon" v-else></i>
              </div> -->
              <el-popover placement="bottom" title="请选择一个封面" width="500" trigger="click">
                <div style="height: 252px; overflow-y: auto">
                  <img v-for="(src, index) in defaultCover" :key="index" :src="src" @click="designForm.cover = src" style="
                      width: 90px;
                      height: 60px;
                      padding: 2px 5px;
                      border-radius: 8px;
                      cursor: pointer;
                    " />
                </div>
                <div style="
                    width: 100%;
                    height: 1px;
                    border: 0.5px solid #e8e8e8;
                    margin: 10px 0;
                  "></div>
                <div class="uploadCover" @click="editCropper">
                  <div class="uploadCover-icon">
                    <i class="el-icon-upload"></i>
                  </div>
                  <div>
                    <div class="uploadCover-title">上传封面</div>
                    <div style="color: #cecece; font-size: 12px">
                      600*360px（JPG或PNG）最大2M
                    </div>
                  </div>
                </div>
                <div slot="reference" style="width: 200px; height: 120px">
                  <div class="avatar-uploader-hover"></div>
                  <img :src="designForm.cover" class="cover" />
                </div>
              </el-popover>
              <el-dialog :title="title" :visible.sync="open" width="1000px" append-to-body :close-on-click-modal="false">
                <ImgCutter ref="imgCutterModal" :isModal="false" :boxWidth="500" :boxHeight="400" :cutWidth="300" :cutHeight="204" :sizeChange="true" :moveAble="true" :tool="false" :previewMode="true" :crossOrigin="true" crossOriginHeader="*" :scaleAble="false" :imgMove="false" rate="5:3"
                           @cutDown="onCutDownCover" @onPrintImg="onPrintImg" @onClearAll="onClearAll"></ImgCutter>
                <div style="
                    position: absolute;
                    right: 20px;
                    top: 85px;
                    border: 1px solid #eee;
                    width: 450px;
                    height: 400px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  ">
                  <img :src="cutDownUrl" style="
                      display: inline-block;
                      width: auto;
                      height: auto;
                      float: right;
                    " />
                </div>
              </el-dialog>
            </el-col>
            <!-- <el-col class="line" :span="10" style="padding: 0; text-align: left; margin-left: 30px;">
              <span>建议上传高清图片</span>
              <span>仅支持格式：JPG、PNG、JPEG</span>
              <span>建议上传尺寸： 600 * 360</span>
            </el-col> -->
          </el-form-item>
          <el-form-item label="创建人" v-show="userType != '1'">
            <el-input type="text" placeholder="请输入创建人名称" v-model="designForm.founder" @change="onChangeEdit"></el-input>
          </el-form-item>
          <el-form-item label="标签">
            <formTagEditor v-model="designForm.tags" :readonly="false" :tagList="tagList"></formTagEditor>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span style="
                  color: #3476f0;
                  cursor: pointer;
                  border-bottom: dashed 1px #bababa;
                " @click="onProjectLabelClick(1)">{{ designForm.detailsLabel }}</span>
            </span>
            <richEditor :formId="formId" :agencyId="agencyId" uploadPath="fillFile/{agencyId}/{formId}/formDescription/" v-model="designForm.details"></richEditor>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span style="
                  color: #3476f0;
                  cursor: pointer;
                  border-bottom: dashed 1px #bababa;
                " @click="onProjectLabelClick(2)">{{ designForm.fileLabel }}</span>
            </span>
            <el-upload style="text-align: left" ref="uploader" :headers="{ Authorization: Authorization }" action="/oss/endpoint/put-file" multiple :data="uploadParam" :auto-upload="true" :show-file-list="false" :file-list="designForm.fileList" :limit="5" :http-request="formFilesUpload.elFileUpload"
                       :before-upload="onBeforeFileUpload" :on-exceed="onHandleFileExceed" :on-success="onHandleFileSuccess" :before-remove="(params) => onBeforeFileRemove(params)">
              <el-button style="border-color: #3476f0; color: #3476f0" icon="el-icon-plus" size="mini">添加附件</el-button>
            </el-upload>
            <fileUploadList :showSize="false" :uploader="uploaderRef" :resumeUploadHandler="uploadResume" v-model="fileList"></fileUploadList>
          </el-form-item>
          <el-form-item label="启用设计">
            <el-switch v-model="designForm.enableDesign" active-color="#13ce66" />
          </el-form-item>
          <designView ref="designView" v-show="designForm.enableDesign" :type="'2'" :businessId="formId" @change="isEdit=true"></designView>
          <el-form-item label="分享图标">
            <el-upload v-if="!designForm.shareIcon" style="text-align: left" ref="shareUploader" :headers="{ Authorization: Authorization }" action="/oss/endpoint/put-file" multiple :data="uploadParam" :auto-upload="true" :show-file-list="false" :file-list="shareFileList" :limit="1"
                       :http-request="shareFilesUpload.elFileUpload" :on-success="onHandleShareFileSuccess">
              <el-button style="border-color: #3476f0; color: #3476f0" icon="el-icon-plus" size="mini">上传</el-button>
            </el-upload>
            <div class="preview-share-icon" v-if="designForm.shareIcon" :style="{backgroundImage: `url('${designForm.shareIcon}')`}">
              <span class="el-icon-circle-close" @click="clearShareIcon()"></span>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 修改label 弹框-->
    <el-dialog title="标题" :visible.sync="labelOpen" width="400px" append-to-body @close="onCloseClick" :close-on-click-modal="false">
      <el-form ref="labelForm" :model="selectLabelModel" :rules="selectLabelRules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="标题名称" prop="label">
              <el-input v-model="selectLabelModel.label" placeholder="请输入标题名称(1-8个字)" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitClick">确 定</el-button>
        <el-button @click="onCancelClick">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { globalBus } from "@/utils/globalBus";
import { deepClone } from "@/utils/deepClone";
import { getSessionStorageObj, setLocalStorageObj } from "@/utils/db";
// import wangEditor from 'wangeditor'
import ImgCutter from "vue-img-cutter";
import xss from "xss";
import {
  aliDelete,
  formDetail,
  customFormFieldList,
  formFilesList,
  getTagListByUserId,
} from "@/api/system/form";
import { putFile, setOssConfig } from "@/utils/ossUtils";
import { agencyInfo } from "@/api/system/agency";
import { getToken } from "@/utils/auth";
import { httpFormBase } from "@/api/system/form";

import richEditor from "@/components/RichEditor";
import formTagEditor from "./formTag/editor.vue";

import { aliUploader } from "@/utils/oss";
// import fileUploadMixin from '../fileUploadMixin'
import fileUploadList from "./fileUploadList.vue";
import DesignView from "./designView.vue";

export default {
  name: "List",
  components: {
    ImgCutter,
    richEditor,
    formTagEditor,
    fileUploadList,
    DesignView,
  },
  // mixins: [fileUploadMixin],
  props: {
    formId: {
      type: String,
    },
    userType: {
      // 用户类型 普通用户、机构用户
      type: String,
    },
    agencyId: {
      type: String | Number,
    },
    onFormDetail: {
      type: Function,
    },
  },
  data () {
    return {
      defaultCover: [
        require(`@/assets/cover/cover-1.jpg`),
        require(`@/assets/cover/cover-2.jpg`),
        require(`@/assets/cover/cover-3.jpg`),
        require(`@/assets/cover/cover-4.jpg`),
        require(`@/assets/cover/cover-5.jpg`),
        require(`@/assets/cover/cover-6.jpg`),
        require(`@/assets/cover/cover-7.jpg`),
        require(`@/assets/cover/cover-8.jpg`),
        require(`@/assets/cover/cover-9.jpg`),
        require(`@/assets/cover/cover-10.jpg`),
        require(`@/assets/cover/cover-11.jpg`),
        require(`@/assets/cover/cover-12.jpg`),
        require(`@/assets/cover/cover-13.jpg`),
        require(`@/assets/cover/cover-14.jpg`),
        require(`@/assets/cover/cover-15.jpg`),
        require(`@/assets/cover/cover-16.jpg`),
        require(`@/assets/cover/cover-17.jpg`),
        require(`@/assets/cover/cover-18.jpg`),
        require(`@/assets/cover/cover-19.jpg`),
        require(`@/assets/cover/cover-20.jpg`),
        require(`@/assets/cover/cover-21.jpg`),
        require(`@/assets/cover/cover-22.jpg`),
        require(`@/assets/cover/cover-23.jpg`),
        require(`@/assets/cover/cover-24.jpg`),
        require(`@/assets/cover/cover-25.jpg`),
        require(`@/assets/cover/cover-26.jpg`),
        require(`@/assets/cover/cover-27.jpg`),
        require(`@/assets/cover/cover-28.jpg`),
        require(`@/assets/cover/cover-29.jpg`),
        require(`@/assets/cover/cover-30.jpg`),
        require(`@/assets/cover/cover-31.jpg`),
        require(`@/assets/cover/cover-32.jpg`),
        require(`@/assets/cover/cover-33.jpg`),
        require(`@/assets/cover/cover-34.jpg`),
        require(`@/assets/cover/cover-35.jpg`),
        require(`@/assets/cover/cover-36.jpg`),
        require(`@/assets/cover/cover-37.jpg`),
        require(`@/assets/cover/cover-38.jpg`),
        require(`@/assets/cover/cover-39.jpg`),
        require(`@/assets/cover/cover-40.jpg`),
        require(`@/assets/cover/cover-41.jpg`),
        require(`@/assets/cover/cover-42.jpg`),
        require(`@/assets/cover/cover-43.jpg`),
        require(`@/assets/cover/cover-44.jpg`),
        require(`@/assets/cover/cover-45.jpg`),
        require(`@/assets/cover/cover-46.jpg`),
        require(`@/assets/cover/cover-47.jpg`),
        require(`@/assets/cover/cover-48.jpg`),
        require(`@/assets/cover/cover-49.jpg`),
        require(`@/assets/cover/cover-50.jpg`)
      ],
      designForm: {
        name: "",
        cover: undefined,
        founder: "",
        detailsLabel: "详细介绍",
        details: "",
        fileLabel: "相关附件",
        fileList: [],
        tags: [],
        formId: this.formId,
        enableDesign: false,
        shareIcon: ''
      },
      designRules: {
        name: [
          { required: true, message: "请输入表单名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
      editor: null,
      open: false,
      title: "封面图片修改",
      cutDownUrl: null,
      labelOpen: false,
      selectLabelModel: {
        label: "",
      },
      selectLabelRules: {
        label: [
          { required: true, message: "名称不能为空", trigger: "change" },
          { max: 8, message: "最多支持8个字符", trigger: "change" },
        ],
      },
      labelType: null,
      createTime: "",
      createName: "",
      Authorization: "",
      // 上传附件传值给后台参数
      uploadParam: {
        folder: "formFile",
      },
      isEdit: false, // 判断页面是否做了修改
      history: {},
      tagList: [],
      uploaderName: "",
      formFilesUpload: null,
      shareFilesUpload: null,
      fileList: [],
      shareFileList: []
    };
  },
  watch: {
    designForm: {
      deep: true,
      handler () {
        this.isEdit = true;
        this.fileList = this.designForm.fileList;
      },
    },
  },
  mounted () {
    if (!this.designForm.cover) {
      this.designForm.cover =
        this.defaultCover[
          Math.floor(Math.random() * this.defaultCover.length + 1) - 1
        ];
    }

    // const editor = new wangEditor(`#wangEditor`)
    // // 配置 onchange 回调函数，将数据同步到 vue 中
    // editor.config.onchange = (newHtml) => {
    //   this.designForm.details = newHtml
    // }
    // // 默认情况下，显示所有菜单
    // editor.config.menus = ['head', 'bold', 'fontSize', 'italic', 'underline', 'strikeThrough', 'indent', 'lineHeight', 'foreColor', 'backColor', 'link', 'list', 'justify', 'quote', 'emoticon', 'image', 'table', 'splitLine', 'undo', 'redo']
    // // placeholder 的提示文字
    // editor.config.placeholder = '请输入表单详情'
    // // 配置 z-index
    // editor.config.zIndex = 1999
    // // 隐藏插入网络图片的功能
    // editor.config.showLinkImg = false
    // editor.config.customUploadImg = function(resultFiles, insertImgFn) {
    //   // resultFiles 是 input 中选中的文件列表, insertImgFn 是获取图片 url 后，插入到编辑器的方法
    //   setOssConfig() // 请求配置信息
    //   const sessionStorageUser = getSessionStorageObj('users')
    //   const fileName = sessionStorageUser.userId + '/' + Date.parse(new Date()) + '_' + resultFiles[0].name
    //   putFile(fileName, resultFiles[0])
    //     .then(function(url) {
    //       // 上传图片，返回结果，将图片插入到编辑器中
    //       insertImgFn(url)
    //     })
    //     .catch(function(err) {})
    // }
    // // 创建编辑器
    // editor.create()
    // this.editor = editor

    if (!this.isBlank(this.formId)) {
      this.getFormDetail();
      this.uploadParam.belongId = this.formId;
    } else {
      // 创建表单
      if (this.userType == "2") {
        agencyInfo().then((response) => {
          if (response.code == 200) {
            this.designForm.founder = response.data.name;
          }
        });
      }
    }

    this.Authorization = getToken();

    this.getTagListByUserId();
  },
  beforeDestroy () {
    // 调用销毁 API 对当前编辑器实例进行销毁
    // this.editor.destroy()
    // this.editor = null
  },
  created () {
    this.formFilesUpload = aliUploader(
      false,
      {
        formId: this.formId,
        uploadPath:
          "fillFile/" + this.agencyId + "/" + this.formId + "/formInfoFiles/",
      },
      () => {
        return this.designForm.fileList;
      }
    );
    this.shareFilesUpload = aliUploader(
      false,
      {
        formId: this.formId,
        uploadPath:
          "fillFile/" + this.agencyId + "/" + this.formId + "/formInfoFiles/",
      },
      () => {
        return this.shareFileList;
      }
    );
  },
  computed: {},
  methods: {
    uploaderRef: function () {
      return this.$refs.uploader;
    },
    // shareUploaderRef: function(){
    //   return this.$refs.shareUploader
    // },
    onEditorChange (e) {
      this.isEdit = true;
    },
    // 远程、跨域裁剪（兼容IE9）
    editCropper () {
      const _this = this;
      _this.open = true;
      setOssConfig();
      _this.$nextTick(function () {
        _this.$refs.imgCutterModal.handleOpen({
          name: "form_default.jpeg",
          src: _this.designForm.cover,
        });
      });
    },
    // 裁剪预览图片
    onPrintImg (e) {
      const cutDownUrl = e.dataURL;
      this.cutDownUrl = cutDownUrl;
    },
    // 裁剪取消图片
    onClearAll (e) {
      this.open = false;
      this.cutDownUrl = null;
    },
    // 确认裁剪图片并上传
    onCutDownCover (e) {
      const _this = this;
      const sessionStorageUser = getSessionStorageObj("users");
      const fileName =
        sessionStorageUser.userId +
        "/" +
        Date.parse(new Date()) +
        "_" +
        e.fileName;
      putFile(fileName, e.file).then((url) => {
        _this.isEdit = true;
        _this.open = false;
        _this.designForm.cover = url;
        _this.msgSuccess("修改成功");
      });
    },
    // 修改label
    onProjectLabelClick (type) {
      let selectLabelModelLabel = "";
      if (type == "1") {
        selectLabelModelLabel = this.designForm.detailsLabel;
      } else if (type == "2") {
        selectLabelModelLabel = this.designForm.fileLabel;
      }
      this.selectLabelModel.label = selectLabelModelLabel;
      this.labelOpen = true;
      this.labelType = type;
    },
    // 修改label 确定事件
    onSubmitClick () {
      const self = this;
      self.$refs["labelForm"].validate((valid) => {
        if (valid) {
          switch (this.labelType) {
            case 1:
              self.designForm.detailsLabel = self.selectLabelModel.label;
              break;
            case 2:
              self.designForm.fileLabel = self.selectLabelModel.label;
              break;
          }
          self.isEdit = true;
          self.labelOpen = false;
          self.selectLabelModel.label = "";
          self.labelType = null;
        }
      });
    },
    onCancelClick () {
      this.labelOpen = false;
      this.labelType = null;
    },
    onCloseClick () {
      this.labelOpen = false;
      this.labelType = null;
    },
    onHandleFileExceed () {
      this.$message.warning(`当前限制添加5个附件！`);
    },
    onBeforeFileUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.warning(`上传附件大小不能超过20MB！`);
      }
      if (isLt2M) {
        this.$emit("saveBtnClick", true);
      } else {
        return isLt2M;
      }
    },
    onHandleFileSuccess (response, file, fileList) {
      const _this = this;
      if (!_this.isBlank(response)) {
        // delete file.response;
        // delete file.status;
      }
      _this.$emit("saveBtnClick", false);
      _this.isEdit = true;
    },
    onHandleShareFileSuccess (response, file, fileList) {
      const _this = this;
      _this.designForm.shareIcon = file.url;
      _this.$emit("saveBtnClick", false);
      _this.isEdit = true;
    },
    clearShareIcon () {
      this.designForm.shareIcon = '';
      this.shareFileList.splice(0);
      this.$emit("saveBtnClick", false);
      this.isEdit = true;
    },
    onBeforeFileRemove (params) {
      const tmp_path = params.name;
      const index = this.designForm.fileList.findIndex(
        (item) => item.name == tmp_path
      );
      if (index != -1) {
        this.designForm.fileList.splice(index, 1);
      }
      for (let i = 0; i < this.designForm.fileList.length; i++) {
        this.designForm.fileList[i].sort = i + 1;
      }
      this.$emit("saveBtnClick", false);
      this.isEdit = true;
      // 调用删除接口
      // if(!this.isBlank(params.response)) {
      //   aliDelete(params.response.data).then((res)=>{})
      // }
    },

    //设置表单基本信息
    setFormDetailData (data) {
      this.designForm.name = data.namesf;
      this.designForm.cover = data.cover;
      this.designForm.founder = !this.isBlank(this.agencyId)
        ? !this.isBlank(data.founder)
          ? data.founder
          : data.createName
        : "";
      this.designForm.details = data.details;
      //  if (this.editor) {
      //    this.editor.txt.html('<p>' + this.designForm.details + '</p>')
      //  }
      this.designForm.tags = data.tagList;
      this.designForm.enableDesign = data.enableDesign;
      this.designForm.shareIcon = data.shareIcon;
      this.createTime = data.createTime;
      this.createName = data.founder ? data.founder : data.createName;
      //  this.$forceUpdate();
    },
    //设置自定义表单字段
    setCustomFormFieldListData (data) {
      this.designForm.detailsLabel = !this.isBlank(data.details)
        ? data.details
        : "详细介绍";
      this.designForm.fileLabel = !this.isBlank(data.file)
        ? data.file
        : "相关附件";
    },
    // 读取表单详情
    getFormDetail () {
      const _this = this;
      _this.isEdit = false;
      let pFormDetail = formDetail(_this.formId);
      let pFormField = customFormFieldList(_this.formId);
      let pFileList = formFilesList(_this.formId);

      Promise.all([pFormDetail, pFormField, pFileList]).then((values) => {
        //历史记录,用于不保存时的恢复
        _this.history = { ..._this.history, ...deepClone(values[0].data) };
        _this.setFormDetailData(values[0].data);
        if (_this.onFormDetail) {
          _this.onFormDetail(values[0].data);

          // 给aliOSS设置agencyId
          _this.formFilesUpload.config = {
            formId: values[0].data.formId,
            agencyId: values[0].data.agencyId,
          };
        }

        _this.setCustomFormFieldListData(values[1].data);
        _this.designForm.fileList.splice(0); // values[2].data
        values[2].data.forEach((item) => {
          _this.designForm.fileList.push(item);
        });

        //富文本接收数据可能会产生调整，因此延迟将isEdit改为false
        setTimeout(() => {
          _this.isEdit = false;
        }, 200);
      });
      // formDetail(_this.formId).then((response) => {
      //   //历史记录,用于不保存时的恢复
      //   _this.history={..._this.history,...deepClone(response.data)};
      //   _this.setFormDetailData(response.data);
      //   if(_this.onFormDetail){
      //     _this.onFormDetail(response.data);
      //   }
      // })
      // customFormFieldList(_this.formId).then((response) => {
      //   if (response.code == 200) {
      //     //历史记录,用于不保存时的恢复
      //     _this.history={..._this.history,...deepClone(response.data)};
      //     _this.setCustomFormFieldListData(response.data);
      //   }
      // })
      // formFilesList(_this.formId).then((response) => {
      //   if (response.code == 200) {
      //     //历史记录,用于不保存时的恢复
      //     _this.history={..._this.history,fileList:deepClone(response.data)};
      //     _this.designForm.fileList = response.data
      //   }
      // })
    },
    recoverHistory () {
      this.setFormDetailData(this.history);
      this.setCustomFormFieldListData(this.history);

      this.designForm.fileList = this.history.fileList;
      this.isEdit = false;
    },
    onChangeEdit () {
      this.isEdit = true;
      // 表单创建人字数限制
      if (!this.isBlank(this.designForm.founder)) {
        if (this.designForm.founder.length > 20) {
          this.$message({
            type: "warning",
            message: "表单创建人仅支持输入1-20个字！",
          });
          return;
        }
      }
    },
    saveFormBase (formBaseInfo, callback) {
      let formInfo = this.designForm;
      // 表单创建人字数限制
      if (!this.isBlank(formInfo.founder)) {
        if (formInfo.founder.length > 20) {
          this.$message({
            type: "warning",
            message: "表单创建人仅支持输入1-20个字！",
          });
          return;
        }
      }
      let param = {
        ...formBaseInfo,
        cover: formInfo.cover,
        founder: formInfo.founder,
        formCustomAddParam: {
          details: formInfo.detailsLabel,
          file: formInfo.fileLabel,
        },
        tagList: formInfo.tags,
        details: formInfo.details,
        enableDesign: formInfo.enableDesign,
        fileList: !this.isBlank(formInfo.fileList) ? formInfo.fileList : [],
        shareIcon: this.designForm.shareIcon || ''
      };

      if (!this.isBlank(this.formId)) {
        param.formId = this.formId;
      }

      Promise.all([
        httpFormBase(JSON.stringify(param)),
        this.saveDesignView()
      ]).then((res) => {
        const response = res[0];
        if (response.code === 200) {
          let formId = response.data;
          this.getFormDetail();

          if (callback) callback(formId);
          //保存完再读取出来，更新保存的历史
        } else {
          this.msgError(response.msg);
        }
      });
    },
    async getTagListByUserId () {
      let res = await getTagListByUserId();
      this.tagList = res.data.tagList;
    },
    uploadResume (index, fileList) {
      this.formFilesUpload.resume(index);
    },
    // shareUploadResume(index, fileList) {
    //   this.shareFilesUpload.resume(index);
    // },
    saveDesignView () {
      return new Promise((resolve) => {
        if (this.$refs.designView) {
          this.$refs.designView.saveDesign().then(_ => {
            resolve();
          })
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.app-info {
  padding: 32px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  // margin: 10px auto;
  margin: 0px auto;
  height: 100%;
  background: #ffffff;
  overflow-y: auto;
  border-radius: 4px;
  .el-form-item {
    ::v-deep .el-form-item__content {
      text-align: left;
    }
  }
}
.form-editor-wrapper {
  .form-item-label-wrapper {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 14px;
    border-bottom: 1px solid #eff1f8;
    margin-bottom: 13px;
    cursor: pointer;
    .form-item-label {
      color: #646d7a;
      line-height: 36px;
      font-weight: 700;
    }
  }
}
.form-file-wrapper {
  .form-file-label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eff1f8;
    margin-bottom: 13px;
    .form-file-label {
      display: flex;
      align-items: center;
      justify-content: left;
      font-size: 14px;
      cursor: pointer;
      span {
        color: #646d7a;
        line-height: 36px;
        font-weight: 700;
      }
      svg {
        margin-left: 4px;
        margin-top: -4px;
      }
    }
    .upload-btn-wrapper {
      width: 84px;
      height: 24px;
      background: #3476f0;
      border-radius: 3px;
      font-size: 13px;
      color: #ffffff;
      line-height: 24px;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-hover {
  position: absolute;
  width: 200px;
  height: 120px;
  line-height: 120px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.avatar-uploader-hover:hover {
  background: rgba(0, 0, 0, 0.35);
}
.avatar-uploader-hover:hover::after {
  content: '修改封面图片';
  font-size: 14px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}
.avatar {
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}
.line {
  text-align: center;
  span {
    display: block;
    line-height: 20px;
    font-size: 13px;
    color: #999999;
  }
}
.i-dialog-footer {
  width: 960px !important;
}
.project-label {
  position: relative;
  cursor: pointer;
  .project-label-edit {
    position: absolute;
    right: -13px;
    top: -20px;
  }
}
.project-label-tip {
  font-size: 13px;
  color: rgb(57, 67, 73);
  font-weight: 400;
  margin-left: 20px;
}
.form-set-tip {
  font-size: 13px;
  font-weight: 400;
  color: #bababa;
  line-height: 18px;
  margin-left: 30px;
}
// ::v-deep .el-form-item__label {
//   color: #646d7a !important;
// }

.cover {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 8px;
}
.uploadCover {
  display: flex;
  padding: 8px 0px;
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
    border-radius: 4px;
    cursor: pointer;
  }

  .uploadCover-icon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    background-color: #f7f7f7;
    border-radius: 4px;
  }
}
.preview-share-icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: solid 1px #efefef;
  margin-top: 10px;
  position: relative;
  &:hover {
    .el-icon-circle-close {
      display: inline-block;
    }
  }

  .el-icon-circle-close {
    display: none;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 18px;
    height: 18.5px;
    border-radius: 50%;
    font-size: 18px;
    background-color: #d95e5e;
    color: white;
    text-align: center;
    line-height: 18px;
    box-sizing: border-box;
  }
}
</style>
