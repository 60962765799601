<template v-cloak>

  <div class="config-pane">

    <div class="form-widget-left">
      <div class="widget-cate">常用字段</div>
      <draggable v-model="commonFields" :options="{group:{name: 'itxst',pull:'clone', put: false},sort: false}" animation="300" tag="ul" class="clearfix form-inner-list" ghost-class="ghost" chosen-class="chosen"> <!--@choose="onDraggableLeftChoose"-->
        <transition-group>
          <li class="list-item" v-for="(item, index) in commonFields" :key="index">
            <svg-icon :icon-class="item.iconClass" class="filed-icon"/>
            <span style="display: inline-block;">{{item.label}}</span>
          </li>
        </transition-group>
      </draggable>
      <div class="widget-cate">基础字段</div>
      <draggable v-model="basicFields" :options="{group:{name: 'itxst',pull:'clone', put: false},sort: false}" animation="300" tag="ul" filter=".forbid" class="clearfix form-inner-list" ghost-class="ghost" chosen-class="chosen"> <!--@choose="onDraggableLeftChoose"-->
        <transition-group>
          <li class="list-item" v-for="(item, index) in basicFields" :key="index">
            <svg-icon :icon-class="item.iconClass" class="filed-icon"/>
            <span style="display: inline-block; ">{{item.label}}</span>
          </li>
        </transition-group>
      </draggable>
      <div class="widget-cate" v-if="advancedFields && advancedFields.length > 0">高级字段</div>
      <draggable v-model="advancedFields" :options="{group:{name: 'itxst',pull:'clone', put: false},sort: false}" animation="300" tag="ul" filter=".undraggable" class="clearfix form-inner-list" ghost-class="ghost" chosen-class="chosen"> <!--@choose="onDraggableLeftChoose"-->
        <transition-group>
          <!--:class="item.type == '11' || item.type == '13' ?'list-item forbid':'list-item'"-->
          <li class="list-item" v-for="(item, index) in advancedFields" :key="index" v-if="item.type != 15 && item.type != 16" :class="item.type == '14' && !canMeetingDrag ? 'undraggable' : 'draggable'">
            <svg-icon :icon-class="item.iconClass" :class="[item.type == '14' ? 'meeting-icon' : 'filed-icon']"/>
            <span style="display: inline-block;">{{item.label}}</span>
          </li>
        </transition-group>
      </draggable>
    </div>

    <div class="form-widget-center">
      <div v-if="arr2.length == 0" class="center-tips">从左侧拖拽来添加字段</div>
      <el-form :model="arr2" ref="centerFormData">
        <div class="yes-drag-list" :style="[{margin: modelCur == 2 ? '0 auto 20px' : '0 auto 20px'}, {width: modelCur == 2 ? '375px' : 'calc(100% - 40px)'}, {maxHeight: modelCur == 2 ? '667px' : ''}, {borderRadius: modelCur == 2 ? '26px' : '0px'}]">
          <div class="quickOperationBtn">
            <el-button type="warning" icon="el-icon-upload" size="mini" @click="onHandleImportClick" v-show="modelCur == 1">导入表格</el-button>
            <el-button type="danger" icon="el-icon-delete"  size="mini" @click.stop.prevent="onClearAllClick" v-show="modelCur == 1">一键清空</el-button>
            <div class="switch-show-btn">
              <span @click="onSwitchModelClick(1)" :class="{selected:modelCur == 1}"><svg-icon icon-class="computer" style="width: 20px; height: 20px"/></span>
              <span @click="onSwitchModelClick(2)" :class="{selected:modelCur == 2}"><svg-icon icon-class="phone" style="width: 20px; height: 20px"/></span>
            </div>
          </div>
          <draggable v-model="arr2" :options="{group:{name: 'itxst'},sort: true, handle: '.inner-list-group,.label-title'}" animation="300" tag="ul" @add="onDraggableFinish" @update="onDraggableUpdate" @choose="onDraggableChoose" class="frame-inner-list">
            <transition-group class="inner-list-group">
              <li v-for="(element,index) in arr2" :key="index" :index="index" class="group-item" :class="{'active': index == n}" :style="[{padding: element.type == 15 || element.type == 16 ? '0' : '7px 12px 12px'}]"> 
                <!--@mouseenter="onRowMouseEnter(element)" @mouseleave="onRowMouseLeave(element)"-->
                <div class="actions__position" v-if="element.type != 15 && element.type != 16">
                  <!--v-show="element.isShowBtn"-->
                  <span @click="onCopyFiledClick(element, index)"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/filedCopy.png"/></span>
                  <span @click="onRemoveFiledClick(index)"><img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/filedDel.png"/></span>
                </div>
                <label class="label-title" v-if="element.type != 15 && element.type != 16">
                  <span style="color: #f00000; margin-right: 5px; margin-left: -10px" v-if="element.required">*</span>
                  <span>{{element.label ? element.label : '请点击设置字段名称'}}</span>
                  <!--<span slot="tip" class="el-upload__tip" v-if="element.type === '9'">（只能上传jpg/png/jpeg/gif文件）</span>-->
                </label>
                <!--1：单行文本-->
                <el-input v-if="element.type === '1'" type="text" :placeholder="element.placeholder" readonly="true"></el-input>
                <!--2：多行文本-->
                <el-input v-if="element.type === '2'" type="textarea" :rows="3" :placeholder="element.placeholder" readonly="true"></el-input>
                <!--3：数字-->
                <el-input v-if="element.type === '3'" type="number" :placeholder="element.placeholder" style="width: 100%;" readonly="true"></el-input>
                <!--4：日期-->
                <el-date-picker v-if="element.type === '4' && element.format == 'yyyy-MM-dd'" type="date" :placeholder="element.placeholder" style="width: 100%;" readonly="true"></el-date-picker>
                <el-date-picker v-else-if="element.type === '4' && element.format == 'yyyy-MM-dd hh:mm'" type="datetime" :placeholder="element.placeholder" style="width: 100%;" readonly="true"></el-date-picker>
                <el-date-picker v-else-if="element.type === '4' && element.format == 'yyyy-MM-dd hh:mm:ss'" type="datetime" :placeholder="element.placeholder" style="width: 100%;" readonly="true"></el-date-picker>
                <el-time-picker v-else-if="element.type === '4' && element.format == 'hh:mm'" :placeholder="element.placeholder" style="width: 100%;" readonly="true"></el-time-picker>
                <el-time-picker v-else-if="element.type === '4' && element.format == 'hh:mm:ss'" :placeholder="element.placeholder" style="width: 100%;" readonly="true"></el-time-picker>
                <el-date-picker v-if="element.type === '4' && element.attribute == '日期区间'" type="datetimerange" start-placeholder="日期" end-placeholder="日期" style="width: 100%;" readonly="true"></el-date-picker>
                <!--5：省市区-->
                <template v-if="element.type === '5'">
                  <div style="width: 100%;display: flex; flex-direction: row" v-if="element.attribute == '省'">
                    <el-select v-model="value" placeholder="请选择省" style="width: 100%;" :disabled="true"></el-select>
                  </div>
                  <div style="width: 100%; display: flex; flex-direction: row" v-else-if="element.attribute == '省市'">
                    <el-select v-model="value" placeholder="请选择省" style="width: 49%;margin-right: 2%" :disabled="true"></el-select>
                    <el-select v-model="value" placeholder="请选择市" style="width: 49%;" :disabled="true"></el-select>
                  </div>
                  <div style="width: 100%;display: flex; flex-direction: row" v-else-if="element.attribute == '省市区'">
                    <el-select v-model="value" placeholder="请选择省" style="width: 32%; margin-right: 2%" :disabled="true"></el-select>
                    <el-select v-model="value" placeholder="请选择市" style="width: 32%; margin-right: 2%" :disabled="true"></el-select>
                    <el-select v-model="value" placeholder="请选择区" style="width: 32%;"></el-select>
                  </div>
                  <div v-if="element.content == 'true'" style="margin-top: 10px;width: 100%;">
                    <el-input type="text" placeholder="请输入详细地址"></el-input>
                  </div>
                </template>
                <!--6：单项选择-->
                <el-radio-group v-if="element.type === '6'" style="width: 100%; display: flex; display: -webkit-flex; flex-direction: column;">
                  <el-radio :label="radio.name" v-for="(radio, index) in element.contentArr" :key="index">
                    <span>{{radio.name}}</span>
                    <input type="text" v-if="radio.type == 'other' && isOtherClick" style="background-color: #F6F7F7; outline: 0; border: 1px solid #D9D9D9; height: 32px; margin-left: 10px; border-radius: 2px; max-width: 200px;"></input>
                  </el-radio>
                </el-radio-group>
                <!--7：多项选择-->
                <template v-if="element.type === '7' && element.content && element.content.length > 0">
                  <el-checkbox-group v-model="selectCheckbox" disabled> 
                   <!--  @change="handleCheckedCitiesChange" -->
                    <el-checkbox :label="checkbox" v-for="(checkbox,index) in element.contentArr" :key="index">{{checkbox.name}}</el-checkbox>
                  </el-checkbox-group>
                </template>
                <!--8：下拉框-->
                <el-select v-if="element.type === '8'" :placeholder="element.placeholder" v-model="temp[element.sort]" style="width: 100%;" :disabled="true">
                  <el-option v-for="(option, index) in element.contentArr" :label="option" :value="option.name" :key="index">
                  </el-option>
                </el-select>
                <!--9：上传图片-->
                <el-upload v-if="element.type === '9'" action="#" list-type="picture">
                  <el-button size="small" type="primary" slot="tip">点击上传</el-button>
                </el-upload>
                <!--10：上传附件-->
                <el-upload v-if="element.type === '10'" action="#">
                  <el-button size="small" type="primary" slot="tip">点击上传</el-button>
                </el-upload>
                <!--11：组织架构-->
                <template v-if="element.type === '11'">
                  <treeselect options="" :multiple="false" :flat="false" :show-count="true" :default-expand-level="1" :placeholder="element.placeholder" :disabled="true"/>
                </template>
                <!--12：说明文字-->
                <template v-if="element.type === '12'">
                  <span style="word-break: break-word;">{{element.content}}</span>
                </template>
                <!--13：多级下拉-->
                <template v-if="element.type === '13'">
                 <!--  <el-tree :data="element.content" :props="treeShowProps" :expand-on-click-node="false" ref="tree" :default-expand-all="defaultExpandAll">
                    <span class="custom-tree-node" slot-scope="{ node, data }" style="width: 100%;">
                      <span class="fl node-label">{{ node.label }}</span>
                    </span>
                  </el-tree> -->
                   <el-cascader options="" :placeholder="element.placeholder" style="width: 100%;" :disabled="true"></el-cascader>
                </template>
                <!--14：会议预约-->
                <el-radio-group v-if="element.type === '14'" style="width: 100%;">
                  <el-radio :label="radio" v-for="(radio, index) in reserveList" :key="index">{{radio.name}}</el-radio>
                </el-radio-group>
                <template v-if="element.type === '15'" style="display: none"></template>
                <template v-if="element.type === '16'" style="display: none"></template>
              </li>
            </transition-group>
          </draggable>
        </div>
      </el-form>
    </div>

    <div class="form-widget-right">
      <div class="config-tab">字段设置</div>
      <div v-if="selectFiled && selectFiled.type" class="field-box">
        <el-form :model="selectFiled" label-width="80px" label-position="right">
          <!--字段名称-->
          <el-form-item>
            <span slot="label"><i style="margin-right: 5px; color: #f56c6c; font-style: normal;">*</i>字段名称</span>
            <el-input placeholder="请输入标题" v-model="selectFiled.label" @change="onLabelChange" @input="onLabelChange"></el-input>
          </el-form-item>
          <!--字段类型-->
          <el-form-item>
            <span slot="label"><i style="margin-right: 5px; color: #f56c6c; font-style: normal;">*</i>字段类型</span>
            <el-select v-model="selectFiled.type" clearable placeholder="请选择字段" style="width: 100%;" @change="onTypeChange" :disabled="true">
              <el-option v-for="item in rightFiledType" :label="item.label" :value="item.type" :key="item.type"></el-option>
            </el-select>
          </el-form-item>
          <!--字段属性-->
          <el-form-item v-if="selectFiled.type == '1'" style="display: flex; flex-direction: column;">
            <span slot="label"><i style="margin-right: 5px; color: #f56c6c; font-style: normal;">*</i>字段属性</span>
            <el-radio-group v-model="selectFiled.attribute" style="margin-left: -70px;" @change="onSelectAttribute">
              <el-radio label="文本" style="margin-right: 4px;">文本</el-radio>
              <el-radio label="手机号" style="margin-right: 4px;">手机号</el-radio>
              <el-radio label="邮箱" style="margin-right: 4px;">邮箱</el-radio>
              <el-radio label="身份证号" style="margin-top: 10px">身份证号</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="selectFiled.type == '4'">
            <span slot="label"><i style="margin-right: 5px; color: #f56c6c; font-style: normal;">*</i>字段属性</span>
            <el-radio-group v-model="selectFiled.attribute" @change="onSelectDate">
              <el-radio label="日期" style="margin-right: 10px;">日期</el-radio>
              <el-radio label="日期区间">日期区间</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="selectFiled.type == '4' && selectFiled.attribute== '日期'">
            <span slot="label"><i style="margin-right: 5px; color: #f56c6c; font-style: normal;">*</i>选择类型</span>
            <el-select v-model="selectFiled.format" placeholder="请选择" @change="onSelectDateOption">
              <el-option v-for="item in dateArr" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="selectFiled.type == '4' && selectFiled.attribute== '日期区间'">
            <span slot="label"><i style="margin-right: 5px; color: #f56c6c; font-style: normal;">*</i>选择类型</span>
            <el-select v-model="selectFiled.format" placeholder="请选择" @change="onSelectDateRangeOption">
              <el-option v-for="item in dateRangeArr" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="selectFiled.type == '5'" style="display: flex; flex-direction: column;">
            <span slot="label"><i style="margin-right: 5px; color: #f56c6c; font-style: normal;">*</i>字段属性</span>
            <el-radio-group v-model="selectFiled.attribute" style="margin-left: -70px;" @change="onSelectArea">
              <el-radio label="省" style="margin-right: 10px;" key="省">省</el-radio>
              <el-radio label="省市" style="margin-right: 10px;" key="省市">省市</el-radio>
              <el-radio label="省市区" key="省市区">省市区</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="详细地址" v-if="selectFiled.type == '5'">
              <span v-model="selectFiled.content" @click="onAddressRequiredClick">
                <svg-icon :icon-class="selectFiled.content == 'true' ? 'checked' : 'check'"/>
              </span>
          </el-form-item>
          <!--限制字数-->
          <el-form-item label="限制字数" v-if="selectFiled.type == '1' && selectFiled.attribute == '文本'">
            <el-input type="number" placeholder="请输入限制字数" v-model.number="selectFiled.maxLength" @change="onChangeMaxLength"></el-input>
          </el-form-item>
          <el-form-item label="限制字数" v-if="selectFiled.type == '2'" style="display: flex; flex-direction: column;">
            <div style="margin-left: -70px">
              <el-col :span="11" :xs="11">
                <el-input type="number" placeholder="最小限制" v-model.number="selectFiled.minLength" @change="onChangeMinLength"></el-input>
              </el-col>
              <el-col :span="2" :xs="2" style="text-align: center">至</el-col>
              <el-col :span="11" :xs="11">
                <el-input type="number" placeholder="最大限制" v-model.number="selectFiled.maxLength" @change="onChangeMaxLength"></el-input>
              </el-col>
            </div>
          </el-form-item>
          <!--占位提示-->
          <el-form-item label="提示信息" v-if="selectFiled.type != '6' && selectFiled.type != '7' && selectFiled.type != '12' && selectFiled.type != '14'">
            <el-input placeholder="请输入提示信息" v-model="selectFiled.placeholder" @change="onPlaceChange"></el-input>
          </el-form-item>
          <!--开关-->
          <el-form-item label="是否必填" v-if="selectFiled.type != '12' && selectFiled.type != '14'">
            <span @click="onRightRequiredClick" v-model="selectFiled.required">
              <svg-icon :icon-class="selectFiled.required ? 'checked' : 'check'"/>
            </span>
          </el-form-item>
          <!--内容，根据各个属性不同，展示不同-->
          <el-form-item v-if="selectFiled.type === '6' || selectFiled.type === '7' || selectFiled.type === '8'" style="display: flex; display: -webkit-flex; flex-direction: column">
            <span slot="label"><i style="margin-right: 5px; color: #f56c6c; font-style: normal;">*</i>选项内容</span>
            <draggable v-model="selectFiled.contentArr" :options="{group:{name: 'itxst'},sort: true, handle: '.mover'}" animation="300" tag="ul" class="choice-list" @update="onOptionUpdate">
              <transition-group class="choice-list-group">
                <li v-for="(element,index) in selectFiled.contentArr" :key="index" :index="index" class="choice-item">
                  <svg-icon icon-class="drag" style="width: 20px; height: 20px; margin-right: 5px;" class="mover" v-if="element.type != 'other'"/>
                  <el-input v-model="element.name" :disabled="element.type && element.type == 'other' ? true : false" @input="onTextareaBlur"></el-input>
                  <svg-icon icon-class="meetDel" style="width: 20px; height: 20px; cursor: pointer; margin-left: 5px;" @click="onContentOtherDel(index)"/>
                </li>
              </transition-group>
            </draggable>
            <label style="margin-top: -10px; margin-left: -60px; display: flex; display: -webkit-flex; justify-content: space-between;">
              <el-button type="text" @click="onAddOptionsClick">添加选项</el-button> <!--style="flex: 1;"-->
              <!-- <el-button type="text" @click="onAddOtherClick" style="flex: 1;" :disabled="isOtherClick">添加其他选项</el-button> -->
              <el-button type="text" @click="onEditTextArea">批量编辑</el-button> <!--style="flex: 1;"-->
            </label>
          </el-form-item>
          <!--说明文字-->
          <el-form-item v-if="selectFiled.type === '12'">
            <span slot="label"><i style="margin-right: 5px; color: #f56c6c; font-style: normal;">*</i>选项内容</span>
            <el-input type="textarea" :rows="5" :placeholder="selectFiled.placeholder" v-model="selectFiled.content" @input="onTextareaChange"></el-input>
          </el-form-item>
          <el-form-item style="margin-left: -70px;" v-if="selectFiled.type === '13'">
            <label>
              <span style="color: #606266; margin-right: 10px">节点</span>
              <el-button type="text" @click="addSelectNodeClick">添加</el-button>
              <el-button type="text" @click="onBatchAddNodeClick">批量添加</el-button>
            </label>
            <el-tree :data="selectNodeTree" :props="treeProps" :expand-on-click-node="false" ref="tree" :default-expand-all="defaultExpandAll">
                <span class="custom-tree-node" slot-scope="{ node, data }" style="width: 100%;">
                  <span class="fl node-label">{{ node.label }}</span>
                  <span class="fr node-btn">
                    <span type="text" size="mini" @click="onEditNodeClick(node, data)" style="padding-left: 10px">编辑</span>
                    <span type="text" size="mini" @click="onDelNodeClick(node,data)" style="color: #F80F0F; padding-left: 10px">删除</span>
                    <span @click="onAddNodeClick(data)" style="padding-left: 10px">添加</span>
                  </span>
                </span>
            </el-tree>
          </el-form-item>
          <el-form-item v-if="selectFiled.type === '14'" style="display: flex; display: -webkit-flex; flex-direction: column;">
            <span slot="label"><i style="margin-right: 5px; color: #f56c6c; font-style: normal;">*</i>选项内容</span>
            <draggable v-model="reserveList" :options="{group:{name: 'itxst'},sort: true, handle: '.mover'}" animation="300" tag="ul" class="choice-list">
              <transition-group class="choice-list-group">
                <li v-for="(element,index) in reserveList" :key="index" :index="index" class="choice-item">
                  <svg-icon icon-class="drag" style="width: 20px; height: 20px; margin-right: 5px;" class="mover"/>
                  <el-input v-model="element.name"></el-input>
                  <svg-icon icon-class="meetSet" style="width: 20px; height: 20px; margin: 0 5px; cursor: pointer" @click="onMeetingRoomEdit(index)"/>
                  <svg-icon icon-class="meetDel" style="width: 20px; height: 20px; cursor: pointer" @click="onMeetingRoomDel(index)"/>
                </li>
              </transition-group>
            </draggable>
            <div style="margin-top: 10px;">
              <el-button type="primary" @click="onMeetingRoomAdd">添加选项</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div v-else class="config-empty-tip">请添加字段</div>
    </div>

    <!-- 多级下拉 添加节点 弹框-->
    <el-dialog title="新增多级下拉节点" :visible.sync="nodeOpen" width="400px" append-to-body @close="onCloseClick" @submit.native.prevent :close-on-click-modal="false">
      <el-form ref="nodeForm" :model="selectNodeModel" :rules="selectNodeRules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="节点名称" prop="label">
              <el-input v-model="selectNodeModel.label" placeholder="请输入节点名称" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitClick">确 定</el-button>
        <el-button @click="onCancelClick">取 消</el-button>
      </div>
    </el-dialog>

    <!--多级下拉 批量添加 弹框-->
    <el-dialog title="批量添加多级下拉节点" :visible.sync="batchNodeOpen" width="440px" append-to-body @close="onBatchNodeCloseClick" @submit.native.prevent :close-on-click-modal="false">
      <p style="margin-top: -20px; color: #f00;display: flex; display: -webkit-flex; flex-direction: column;">
        <span>注：</span>
        <span style="line-height: 24px;">1：每行对应一个选项，多级选项之间用-隔开。例：文学院-文学系,文学院-对外汉语,计算机学院-项目管理</span>
        <span style="line-height: 24px;">2：多级选项（3级以下）名称尽量不重复，否则批量添加失败</span>
        <span style="line-height: 24px;">3：点击确定后，表单多级下拉的原有设置将失效</span>
      </p>
      <el-form ref="batchNodeForm">
        <el-row>
          <el-col :span="24">
            <el-form-item label="" prop="label">
              <el-input type="textarea" :rows="4" placeholder="请输入多级下拉节点内容" v-model="batchNodeStr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onBatchNodeSubmitClick">确 定</el-button>
        <el-button @click="onBatchNodeCancelClick">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 导入表格 弹框-->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body @close="onCloseUploadDialogClick">
      <el-upload ref="upload" :limit="1" action :multiple="false"
                 accept="csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                 :on-remove="handleRemove" :on-exceed="handleExceed" :http-request="httpRequest" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text"><em>点击上传文件</em></div>
        <div class="el-upload__tip" slot="tip" style="margin-top: 5px; line-height: 16px;">
          <el-button type="text" style="font-size: 12px;"><a href='https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/%E8%A1%A8%E5%8D%95%E8%AE%BE%E8%AE%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx' download="鲸鱼填报-表单设计-导入模板.xlsx">下载模板</a></el-button>
          <!--          <el-link type="info" style="font-size:12px" href='https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/%E8%A1%A8%E5%8D%95%E8%AE%BE%E8%AE%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx' download="鲸鱼填报-表单设计-导入模板.xlsx">下载模板</el-link>-->
          <div class="el-upload__tip" style="color:red; display: inline-block; margin-top: 0; margin-left: 5px;" slot="tip">提示：仅允许导入“xls”或“xlsx”格式文件！</div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmUploadClick">确 定</el-button>
        <el-button @click="onCancelUploadClick">取 消</el-button>
      </div>
    </el-dialog>

    <!--选项内容 添加修改 弹框-->
    <el-dialog title="批量编辑" :visible.sync="designContentOpen" width="400px" append-to-body @close="onCloseContentClick" @submit.native.prevent :close-on-click-modal="false">
      <p style="margin-top: -20px;">每个选项请单列一行，单个选项长度不能超过50个字</p>
      <el-form ref="nodeForm" :model="designContentModel" label-width="80px">
        <el-input type="textarea" :rows="5" v-model="designContentModel.designContent"></el-input>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmContentClick">确 定</el-button>
        <el-button @click="onCancelContentClick">取 消</el-button>
      </div>
    </el-dialog>

    <!--预约 添加修改相关 弹框-->
    <el-dialog :visible.sync="meetingDialog.open" width="800px" append-to-body @close="onCloseMeetingClick">
      <el-form label-width="80px" label-position="right">
        <el-form-item label="选项名称">
          <el-input type="text" v-model="meetingDialog.meetingRoomName"></el-input>
        </el-form-item>
      </el-form>
      <div class="meeting-time-slot">
        <h5 class="time-slot-label">选择不可预约的时间段：</h5>
        <ul class="time-slot-list">
          <li v-for="(item,index) in meetingDialog.meetingRoomTime" :class="[item.checked ? 'is-disabled' : '']" @click="onSelectTimeRange(index)">{{item.time}}</li>
        </ul>
        <h5 class="time-slot-label" style="margin-top: 0;">最多可连续预约时间段：</h5>
        <div class="time-slot-hour">
          <el-radio v-model="meetingDialog.maxTime" label="1">0.5小时</el-radio>
          <el-radio v-model="meetingDialog.maxTime" label="2">1小时</el-radio>
          <el-radio v-model="meetingDialog.maxTime" label="3">1.5小时</el-radio>
          <el-radio v-model="meetingDialog.maxTime" label="4">2小时</el-radio>
          <el-radio v-model="meetingDialog.maxTime" label="5">2.5小时</el-radio>
          <el-radio v-model="meetingDialog.maxTime" label="6">3小时</el-radio>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmMeetingClick">确 定</el-button>
        <el-button @click="onCancelMeetingClick">取 消</el-button>
      </div>
    </el-dialog>

  </div>

</template>
<script>
let id = 0
import {globalBus} from "@/utils/globalBus"
import Treeselect from "@riophae/vue-treeselect"
import draggable from 'vuedraggable'
import XLSX from 'xlsx'
import {list} from "@/api/system/filed"
import {getSessionStorageObj} from "@/utils/db"
import {formDetail, formFieldList, formReserves, reserveTimes} from "@/api/system/form"
export default {
  //注册draggable组件
  components: {
    draggable,
    Treeselect
  },
  props: {
    formId: {
      type: String
    },
    userType:{ // 用户类型 普通用户、机构用户
      type: String
    }
  },
  data() {
    return {
      rightFiledType: [],
      // 定义要被拖拽对象的数组
      commonFields: [],
      basicFields: [],
      advancedFields: [],
      arr2: [],
      temp: {},
      selectCheckbox: {},
      n: null,
      selectFiled: {},
      dateArr: ['yyyy-MM-dd','yyyy-MM-dd hh:mm','hh:mm'],
      dateRangeArr: ['yyyy-MM-dd至yyyy-MM-dd','yyyy-MM-dd hh:mm至yyyy-MM-dd hh:mm','hh:mm至hh:mm'],
      // 多级下拉
      nodeOpen: false,
      nodeOpenType: 1,
      selectNodeModel: {
        label: ''
      },
      selectNodeRules: {
        label: [
          { required: true, message: "节点名称不能为空", trigger: "blur" }
        ],
      },
      selectNodeTree: [],
      treeShowProps: {
        children: "children",
        label: "label"
      },
      treeProps: {
        children: "children",
        label: "label"
      },
      defaultExpandAll: true, // 是否默认展开所有节点
      nodeData: {},
      isEditNode: false,
      children: [],
      index: null,
      upload: {
        open: false,
        title: "",
        file: null
      },
      merchantList: [],
      modelCur: 1,
      // 多级下拉 批量添加
      batchNodeOpen: false,
      batchNodeStr: '',
      agencyDeptOptions: [], //组织架构
      isEditContent: true,
      designContentOpen: false,
      designContentModel: {
        designContent: ''
      },
      isDesignEdit: false,
      // 新版---预约
      reserveList: [
        {
          maxTime: '',
          name: '会议室',
          reserveId: "",
          timeList: [
            {
              reserveTimeId: '',
              time: '8:00-8:30',
              checked: false,
              sort: 1
            },
            {
              reserveTimeId: '',
              time: '8:30-9:00',
              checked: false,
              sort: 2
            },
            {
              reserveTimeId: '',
              time: '9:00-9:30',
              checked: false,
              sort: 3
            },
            {
              reserveTimeId: '',
              time: '9:30-10:00',
              checked: false,
              sort: 4
            },
            {
              reserveTimeId: '',
              time: '10:00-10:30',
              checked: false,
              sort: 5
            },
            {
              reserveTimeId: '',
              time: '10:30-11:00',
              checked: false,
              sort: 6
            },
            {
              reserveTimeId: '',
              time: '11:00-11:30',
              checked: false,
              sort: 7
            },
            {
              reserveTimeId: '',
              time: '11:30-12:00',
              checked: false,
              sort: 8
            },
            {
              reserveTimeId: '',
              time: '12:00-12:30',
              checked: false,
              sort: 9
            },
            {
              reserveTimeId: '',
              time: '12:30-13:00',
              checked: false,
              sort: 10
            },
            {
              reserveTimeId: '',
              time: '13:00-13:30',
              checked: false,
              sort: 11
            },
            {
              reserveTimeId: '',
              time: '13:30-14:00',
              checked: false,
              sort: 12
            },
            {
              reserveTimeId: '',
              time: '14:00-14:30',
              checked: false,
              sort: 13
            },
            {
              reserveTimeId: '',
              time: '14:30-15:00',
              checked: false,
              sort: 14
            },
            {
              reserveTimeId: '',
              time: '15:00-15:30',
              checked: false,
              sort: 15
            },
            {
              reserveTimeId: '',
              time: '15:30-16:00',
              checked: false,
              sort: 16
            },
            {
              reserveTimeId: '',
              time: '16:00-16:30',
              checked: false,
              sort: 17
            },
            {
              reserveTimeId: '',
              time: '16:30-17:00',
              checked: false,
              sort: 18
            },
            {
              reserveTimeId: '',
              time: '17:00-17:30',
              checked: false,
              sort: 19
            },
            {
              reserveTimeId: '',
              time: '17:30-18:00',
              checked: false,
              sort: 20
            },
            {
              reserveTimeId: '',
              time: '18:00-18:30',
              checked: false,
              sort: 21
            },
            {
              reserveTimeId: '',
              time: '18:30-19:00',
              checked: false,
              sort: 22
            },
            {
              reserveTimeId: '',
              time: '19:00-19:30',
              checked: false,
              sort: 23
            },
            {
              reserveTimeId: '',
              time: '19:30-20:00',
              checked: false,
              sort: 24
            },
          ],
          sort: ''
        },
      ],
      canMeetingDrag: true, // 预约字段是否可以拖拽
      meetingDialog: {
        open: false,
        meetingRoomName: '',
        meetingRoomTime: [],
        maxTime: '',
        meetingRoomIndex: null
      },
      isReserve: false
    };
  },
  watch: {},
  mounted() {
    if (!this.isBlank(this.formId)) {
      this.getFormDetail()
    }
  },
  created() {
    this.initFiled()
  },
  methods: {
    isContinuityNum(num){
      let array=[];
      if(num instanceof Array){
        array = [...num];
      }else{
        array = Array.from(num.toString()) // 转换为数组
      }

      var i=array[0];
      var isContinuation=true;
      for(var e in array){
        if(array[e]!=i){
          isContinuation=false;
          break;
        }
        i++;
      }
      return isContinuation;
    },
    getFormDetail() {
      let _this = this
      formDetail(_this.formId).then(response => {
        if (response.code == 200) {
          _this.isReserve = response.data.isReserve
          if(_this.isReserve) {
            _this.canMeetingDrag = false
          }
          _this.getFormField()
        }
      })
    },
    initFiled() {
      list().then(r =>{
        for (let i = 0; i < r.data.length; i++) {
          if (r.data[i].type == '1') {
            r.data[i].attribute = '文本'
            r.data[i].content = r.data[i].content
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = true
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = 60
            r.data[i].minLength = 1
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userText'
          }
          if (r.data[i].type == '2') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = true
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = 500
            r.data[i].minLength = 0
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userTextarea'
          }
          if (r.data[i].type == '3') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userNumber'
          }
          if (r.data[i].type== '4') {
            r.data[i].attribute = '日期'
            r.data[i].content = r.data[i].content
            r.data[i].format = "yyyy-MM-dd"
            r.data[i].isFormat = true
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userCalendar'
          }
          if (r.data[i].type == '5') {
            r.data[i].attribute = "省市区"
            r.data[i].content = r.data[i].content
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userAddress'
          }
          if (r.data[i].type == '6') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            let splitContentArr = r.data[i].content.split('{JYDSP}')
            let contentArr = []
            for(let i = 0; i < splitContentArr.length; i++) {
              contentArr.push({
                name: splitContentArr[i],
                sort: i + 1
              })
            }
            r.data[i].contentArr = contentArr
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userRadio'
          }
          if (r.data[i].type == '7') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            let splitContentArr = r.data[i].content.split('{JYDSP}')
            let contentArr = []
            for(let i = 0; i < splitContentArr.length; i++) {
              contentArr.push({
                name: splitContentArr[i],
                sort: i + 1
              })
            }
            r.data[i].contentArr = contentArr
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userCheckbox'
          }
          if (r.data[i].type == '8') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            let splitContentArr = r.data[i].content.split('{JYDSP}')
            let contentArr = []
            for(let i = 0; i < splitContentArr.length; i++) {
              contentArr.push({
                name: splitContentArr[i],
                sort: i + 1
              })
            }
            r.data[i].contentArr = contentArr
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userSelect'
          }
          if (r.data[i].type == '9') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = '图片仅支持jpg、png、jpeg、gif格式'
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userUploadImg'
          }
          if (r.data[i].type == '10') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = '文件仅支持word、excel、pdf格式'
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userUploadFile'
          }
          if (r.data[i].type == '11') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userAgency'
          }
          if (r.data[i].type == '12') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userCaption'
          }
          if (r.data[i].type == '13') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort =  r.data[i].sort
            r.data[i].type =  r.data[i].type
            r.data[i].iconClass = 'userMultiSelect'
          }
          // 会议预约
          if (r.data[i].type == '14') {
            r.data[i].attribute = null
            r.data[i].content = r.data[i].content
            r.data[i].format = null
            r.data[i].isFormat = false
            r.data[i].isLength = false
            r.data[i].isRegExp = false
            r.data[i].label = r.data[i].label
            r.data[i].maxLength = null
            r.data[i].minLength = null
            r.data[i].placeholder = r.data[i].placeholder
            r.data[i].regExp = null
            r.data[i].required = r.data[i].required
            r.data[i].sort = r.data[i].sort
            r.data[i].type = r.data[i].type
            r.data[i].iconClass = 'userMeeting'
          }
        }
        this.rightFiledType = r.data
        let gender = '男{JYDSP}女{JYDSP}保密'
        let splitContentArr = gender.split('{JYDSP}')
        let genderContentArr = []
        for(let i = 0; i < splitContentArr.length; i++) {
          genderContentArr.push({
            name: splitContentArr[i],
            sort: i + 1
          })
        }
        let commonFields = [
          {
            attribute: '文本',
            content: null,
            format: null,
            isFormat: false,
            isLength: true,
            isRegExp: false,
            label: '姓名',
            maxLength: 60,
            minLength: 1,
            placeholder: '请输入姓名',
            regExp: null,
            required: false,
            sort: 1,
            type: '1',
            iconClass: 'userName'
          },
          {
            attribute: '邮箱',
            content: null,
            format: null,
            isFormat: false,
            isLength: false,
            isRegExp: true,
            label: '邮箱',
            maxLength: null,
            minLength: null,
            placeholder: '请输入邮箱',
            regExp: '[\\w!#$%&\'*+/=?^_`{|}~-]+(?:\\.[\\w!#$%&\'*+/=?^_`{|}~-]+)*@(?:[\\w](?:[\\w-]*[\\w])?\\.)+[\\w](?:[\\w-]*[\\w])?',
            required: false,
            sort: 2,
            type: '1',
            iconClass: 'userEmail'
          },
          {
            attribute: '手机号',
            content: null,
            format: null,
            isFormat: false,
            isLength: true,
            isRegExp: true,
            label: '手机号',
            maxLength: 11,
            minLength: 11,
            placeholder: '请输入手机号',
            regExp: '(?:0|86|\\+86)?1[3456789]\\d{9}',
            required: false,
            sort: 3,
            type: '1',
            iconClass: 'userPhone'
          },
          {
            attribute: '身份证号',
            content: null,
            format: null,
            isFormat: false,
            isLength: true,
            isRegExp: true,
            label: '身份证号',
            maxLength: 18,
            minLength: 15,
            placeholder: '请输入身份证号',
            regExp: '/^\\d{6}(18|19|20)\\d{2}(0\\d|10|11|12)([0-2]\\d|30|31)\\d{3}(\\d|X|x)$/',
            required: false,
            sort: 4,
            type: '1',
            iconClass: 'userCard'
          },
          {
            attribute: null,
            content: gender,
            contentArr: genderContentArr,
            format: null,
            isFormat: false,
            isLength: false,
            isRegExp: false,
            label: '性别',
            maxLength: null,
            minLength: null,
            placeholder: '请选择性别',
            regExp: null,
            required: false,
            sort: 2,
            type: '6',
            iconClass: 'userSex'
          },
        ]
        this.commonFields = commonFields // 常用字段
        this.basicFields = r.data.slice(0, 9) // 基础字段
        if(this.userType != '1') {
          this.advancedFields = r.data.slice(9) // 高级字段
        } else {
          this.advancedFields = r.data.slice(9, 12)
        }
      })
    },
    // A组 鼠标点击选中要拖拽元素时的事件
    onDraggableLeftChoose(e) {
      let oldIndex = e.oldIndex
      let draggableItem = Object.assign({}, this.basicFields[oldIndex])
      this.arr2.push(draggableItem)
      this.n = this.arr2.length - 1
      for (let i = 0; i < this.arr2.length; i++) {
        this.arr2[i].sort = i + 1
      }
      this.selectFiled = draggableItem
    },
    // 从A组拖动到B组完成的事件
    onDraggableFinish(e) {
      // console.log('从A组拖动到B组完成的事件')
      // console.log(e)
      // console.log(this.arr2)
      let newIndex = e.newIndex
      this.n = newIndex
      let ObjectAssignItem = this.arr2[this.n]
      if (!this.isBlank(this.formId)) {
        ObjectAssignItem.formFieldId = null
      }
      if (ObjectAssignItem.type == '6' || ObjectAssignItem.type == '7' || ObjectAssignItem.type == '8') {
        let splitContentArr = ObjectAssignItem.content.split('{JYDSP}')
        let assignContentArr = []
        for(let j = 0; j < splitContentArr.length; j++) {
          assignContentArr.push({
            name: splitContentArr[j],
            sort: j + 1
          })
        }
        ObjectAssignItem.contentArr = assignContentArr
      }
      let draggableItem = Object.assign({}, ObjectAssignItem)
      this.arr2[this.n] = draggableItem
      this.selectFiled = draggableItem
      this.selectNodeTree = []
      this.isDesignEdit = true
      if(e.oldIndex == 4 && e.item.innerText == '预约') {
        let isHasMeetingField = this.arr2.find((item) => item.type == '14')
        if (!this.isBlank(isHasMeetingField)) {
          this.canMeetingDrag = false
          let reserveIndex = this.arr2.findIndex((item) => item.type == '14')
          if(reserveIndex != -1) {
            let field15 = this.advancedFields.find((item) => item.type == '15')
            let field16 = this.advancedFields.find((item) => item.type == '16')
            this.arr2.splice(reserveIndex + 1, 0, field15)
            this.arr2.splice(reserveIndex + 2, 0, field16)
          }
        } else {
          this.canMeetingDrag = true
        }
      }
      for (let i = 0; i < this.arr2.length; i++) {
        this.arr2[i].sort = i + 1
      }
    },
    // 切换模式
    onSwitchModelClick(index) {
      this.modelCur = index
    },
    // 中间拖拽变换位置时触发的事件
    onDraggableUpdate(evt) {
      this.n = evt.newIndex
      for (let i = 0; i < this.arr2.length; i++) {
        this.arr2[i].sort = i + 1
      }
      this.isDesignEdit = true
    },
    // 鼠标点击选中要拖拽元素时的事件
    onDraggableChoose(e) {
      this.n = e.oldIndex
      let item = this.arr2[this.n]
      let newItem = Object.assign({}, item)
      // console.log('newItem', newItem)
      this.selectFiled = newItem
      // console.log(this.selectFiled)
      // 多级下拉
      if (this.selectFiled.type == '13') {
        this.selectNodeTree = !this.isBlank(this.selectFiled.content) ? this.selectFiled.content : []
      }
    },
    // 鼠标滑过
    onRowMouseEnter(element) {
      this.$set(element, 'isShowBtn', true)
    },
    // 鼠标离开
    onRowMouseLeave(element) {
      this.$set(element, 'isShowBtn', false)
    },
    // 监听字段名称
    onLabelChange(value) {
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.label = value
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.isDesignEdit = true
    },
    // 监听提示信息
    onPlaceChange(value) {
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.placeholder = value
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.isDesignEdit = true
    },
    // 监听字段类型修改
    onTypeChange(value) {},
    // type == 1 单行文本 字段属性修改
    onSelectAttribute(value) {
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.attribute = value
      if (copy.attribute == '文本') {
        copy.minLength = 1
        copy.maxLength = 60
        copy.isRegExp = false
        copy.regExp = null
        copy.label = '文本'
        copy.placeholder = '请输入'
        if (copy.format == '学院') {
          copy.label = '学院'
          copy.placeholder = '请输入学院名称'
        }
        if (copy.format == '专业') {
          copy.label = '专业'
          copy.placeholder = '请输入专业名称'
        }
        if (copy.format == '班级') {
          copy.label = '班级'
          copy.placeholder = '请输入班级名称'
        }
      } else if (copy.attribute == '手机号') {
        copy.minLength = 11
        copy.maxLength = 11
        copy.isRegExp = true
        copy.regExp = '(?:0|86|\\+86)?1[3456789]\\d{9}'
        copy.label = '手机号'
        copy.placeholder = '请输入手机号'
      } else if (copy.attribute == '邮箱') {
        copy.minLength = null
        copy.maxLength = null
        copy.isRegExp = true
        copy.regExp = '[\\w!#$%&\'*+/=?^_`{|}~-]+(?:\\.[\\w!#$%&\'*+/=?^_`{|}~-]+)*@(?:[\\w](?:[\\w-]*[\\w])?\\.)+[\\w](?:[\\w-]*[\\w])?'
        copy.label = '邮箱'
        copy.placeholder = '请输入邮箱'
      } else if (copy.attribute == '身份证号') {
        copy.minLength = 15
        copy.maxLength = 18
        copy.isRegExp = true
        copy.regExp = '/^\\d{6}(18|19|20)\\d{2}(0\\d|10|11|12)([0-2]\\d|30|31)\\d{3}(\\d|X|x)$/'
        copy.label = '身份证号'
        copy.placeholder = '请输入身份证号'
      }
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.isDesignEdit = true
    },
    // type == 4 时间/日期 字段属性修改
    onSelectDate(value) {
      this.$forceUpdate()
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.attribute = value
      if (copy.attribute == '日期') {
        copy.format = 'yyyy-MM-dd'
      } else if (copy.attribute = '日期区间') {
        copy.format = 'yyyy-MM-dd至yyyy-MM-dd'
      }
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.isDesignEdit = true
    },
    // type == 4 时间/日期 日期选择类型修改
    onSelectDateOption(value) {
      this.$forceUpdate()
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.format = value
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.isDesignEdit = true
    },
    // type == 4 时间/日期 日期区间选择类型修改
    onSelectDateRangeOption(value) {
      this.$forceUpdate()
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.format = value
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.isDesignEdit = true
    },
    // type == 5 省市区 字段属性修改
    onSelectArea(value) {
      this.$forceUpdate()
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.attribute = value
      copy.label = value
      let placeholder = ''
      switch (copy.attribute) {
        case '省市区':
          placeholder = '请选择省市区'
              break
        case '省市':
          placeholder = '请选择省市'
          break
        case '省':
          placeholder = '请选择省'
          break
      }
      copy.placeholder = placeholder
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.isDesignEdit = true
    },
    // type == 5 详细地址 开关
    onAddressRequiredClick() {
      // return false
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.content = copy.content == 'true' ? 'false' : 'true'
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.isDesignEdit = true
    },
    // type == 12 说明文字内容修改
    onTextareaChange(value) {
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.content = value
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.$forceUpdate()
      this.isDesignEdit = true
    },
    // type == 6 or type == 7 or type == 8  失去焦点内容修改
    onTextareaBlur(value) {
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      // console.log(copy)
      // return 
      if (copy.type == '6' || copy.type == '7' || copy.type == '8') {
        let splitContent = [];
        for(let i = 0; i < copy.contentArr.length; i++) {
          splitContent.push(copy.contentArr[i].name)
        }
        // 重新拼成字符串
        copy.content = splitContent.join('{JYDSP}');
      } else {
        copy.content = value
      }
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.$forceUpdate()
      this.isDesignEdit = true
    },
    // type == 6 || type == 7 || type == 8 添加选项
    onAddOptionsClick() {
      let item = this.arr2[this.n]
      let otherIndex = item.contentArr.findIndex(item => item.name == '其他' && item.type == 'other')
      let copy = Object.assign({}, item)
      if (otherIndex != -1) {
        // console.log('包括其他选项', otherIndex)
        copy.contentArr.splice(otherIndex, 0, {
          name: '选项'
        })
      } else {
        // console.log('不包括其他选项')
        copy.contentArr.push({
          name: '选项'
        })
      }
      let copyContentArr = []
      for (let i = 0; i < copy.contentArr.length; i++) {
        copy.contentArr[i].sort = i + 1
        copyContentArr.push(copy.contentArr[i].name)
      }
      copy.content = copyContentArr.join('{JYDSP}')
      this.arr2[this.n] = copy
      this.selectFiled = copy
      console.log(this.arr2)
    },
    // type == 6 || type == 7 || type == 8 添加其他选项
    onAddOtherClick() {
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.contentArr.push({
        name: '其他',
        sort: copy.contentArr.length + 1,
        type: 'other'
      })
      let originContent = copy.content.split('\n')
      originContent.push('其他')
      copy.content = originContent.join('{JYDSP}')
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.isOtherClick = true
    },
    // type == 6 || type == 7 || type == 8 删除某一选项
    onContentOtherDel(index) {
      // console.log(index, this.selectFiled)
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.contentArr.splice(index, 1)
      // console.log(copy.contentArr)
      let arr = []
      for (let i = 0; i < copy.contentArr.length; i++) {
        arr.push(copy.contentArr[i].name)
      }
      copy.content = arr.join('{JYDSP}')
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.$forceUpdate()
      this.isDesignEdit = true
    },
    // type == 6 || type == 7 || type == 8 批量编辑修改选项内容
    onEditTextArea() {
      this.designContentOpen = true
      // console.log('批量编辑修改选项内容===')
      // console.log(this.selectFiled.content)
      let modelContentArr = this.selectFiled.contentArr
      // console.log('modelContentArr==', modelContentArr)
      let otherIndex = modelContentArr.findIndex(item => item.name == '其他' && item.type == 'other')
      // console.log('otherIndex==', otherIndex)
      let editContentArr = []
      if (otherIndex != -1) {
        for (let i = 0; i < modelContentArr.length - 1; i++) {
          editContentArr.push(modelContentArr[i].name)
        }
      } else {
        for (let i = 0; i < modelContentArr.length; i++) {
          editContentArr.push(modelContentArr[i].name)
        }
      }
      console.log('editContentArr==', editContentArr)
      this.designContentModel.designContent = editContentArr.join('\n')
    },
    // type == 6 || type == 7 || type == 8 批量编辑 弹框确认修改选项内容
    onConfirmContentClick () {
      if(this.isBlank(this.designContentModel.designContent)) {
        this.$message({
          message: '选项内容不能为空',
          type: 'warning'
        })
        return false
      }
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      // console.log('copy', copy)
      // console.log('输入框填写的内容', this.designContentModel.designContent)
      let textareaContent = this.designContentModel.designContent //输入框所填写的值
      let otherIndex = copy.contentArr.findIndex(item => item.name == '其他' && item.type == 'other')
      // console.log('otherIndex==', otherIndex)
      let splitContent = this.designContentModel.designContent.split('\n')
      // console.log('splitContent==', splitContent)
      let contentArr = []
      if (otherIndex != -1) {
        for (let i = 0; i < splitContent.length; i++) {
          contentArr.push({
            name: splitContent[i],
            sort: i + 1
          })
        }
        contentArr.push({
          name: '其他',
          sort: contentArr.length + 1,
          type: 'other'
        })
      } else {
        for (let i = 0; i < splitContent.length; i++) {
          contentArr.push({
            name: splitContent[i],
            sort: i + 1
          })
        }
      }
      // console.log('contentArr==', contentArr)
      let newContentArr = []
      for (let j = 0; j < contentArr.length; j++) {
        newContentArr.push(contentArr[j].name)
      }
      copy.contentArr = contentArr
      copy.content = newContentArr.join('{JYDSP}')
      console.log('copy==', copy)
      // return 
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.designContentOpen = false
      this.isDesignEdit = true
    },
    onCancelContentClick() {
      this.designContentOpen = false
      this.designContentModel.designContent = ''
    },
    onCloseContentClick() {
      this.designContentOpen = false
      this.designContentModel.designContent = ''
    },
    // type == 6 || type == 7 || type == 8 拖动完成的事件
    onOptionUpdate(e) {
      // console.log(e)
      // console.log(this.selectFiled)
      // console.log(this.n, this.arr2)
      let copyDraggableItem = Object.assign({}, this.selectFiled)
      console.log('copyDraggableItem==', copyDraggableItem)
      let arr = []
      for (let i = 0; i < copyDraggableItem.contentArr.length; i++) {
        arr.push(copyDraggableItem.contentArr[i].name)
      }
      copyDraggableItem.content = arr.join('\n')
      this.arr2[this.n] = copyDraggableItem
      this.selectFiled = copyDraggableItem
    },
    // 限制最小字数 修改
    onChangeMinLength(value){
      if(value == '') {
        value = 0
      }
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.minLength = Number(value)
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.$forceUpdate()
      this.isDesignEdit = true
    },
    // 限制最大字数 修改
    onChangeMaxLength(value){
      if(value == '') {
        if(this.selectFiled.type == '1' && this.selectFiled.attribute == '文本') {
          value = 60
        } else if (this.selectFiled.type == '2'){
          value = 500
        }
      }
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.maxLength = Number(value)
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.$forceUpdate()
      this.isDesignEdit = true
    },
    // 修改必填项
    onRightRequiredClick() {
      let item = this.arr2[this.n]
      let copy = Object.assign({}, item)
      copy.required = !copy.required
      this.arr2[this.n] = copy
      this.selectFiled = copy
      this.isDesignEdit = true
    },
    // 多级下拉 添加节点
    addSelectNodeClick() {
      this.nodeOpen = true
      this.nodeOpenType = 1
    },
    // 多级下拉 添加节点 确定
    onSubmitClick() {
      let self = this
      this.$refs["nodeForm"].validate(valid => {
        if (valid) {
          if (!self.isEditNode) {
            if (self.nodeOpenType == 1) {
              self.selectNodeTree.push({
                id: id++,
                label: self.selectNodeModel.label,
                children: [],
                rang: 1
              })
            } else {
              let newChild = { id: id++, label: self.selectNodeModel.label, children: [], rang: self.nodeData.rang + 1 }
              if (!self.nodeData.children) {
                self.$set(self.nodeData, 'children', [])
              }
              self.nodeData.children.push(newChild)
            }
          } else {
            self.children[self.index].label = self.selectNodeModel.label
          }
          self.nodeOpen = false
          self.nodeOpenType = 1
          self.selectNodeModel.label = ''
          let item = self.arr2[this.n]
          let copy = Object.assign({}, item)
          copy.content = self.selectNodeTree
          self.arr2[this.n] = copy
          self.selectFiled = copy
          if (this.isEditNode) {
            self.children = []
            self.index = null
          }
          self.isEditNode = false
          this.isDesignEdit = true
        }
      })
    },
    // 多级下拉 添加父节点 取消
    onCancelClick() {
      this.nodeOpen = false
      this.nodeOpenType = 1
      this.selectNodeModel.label = ''
    },
    // 多级下拉 添加父节点 监听弹框关闭
    onCloseClick() {
      this.nodeOpen = false
      this.nodeOpenType = 1
      this.selectNodeModel.label = ''
    },
    // 多级下拉 添加子节点
    onAddNodeClick(data) {
      console.log(data)
      if (data.rang == 4) {
        this.$message({
          message: '暂支持4级哦～',
          type: 'warning'
        })
        return
      }
      this.nodeData = data
      this.nodeOpen = true
      this.nodeOpenType = 2
      this.isEditNode = false
    },
    // 多级下拉 删除节点
    onDelNodeClick(node, data) {
      console.log(node, data)
      let parent = node.parent
      let children = parent.data.children || parent.data
      let index = children.findIndex(d => d.id === data.id)
      this.$confirm('是否确认删除' + data.label + '节点？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        children.splice(index, 1)
      }).then(() => {
        this.msgSuccess("删除成功")
        this.isDesignEdit = true
      }).catch(function() {})
    },
    // 多级下拉 编辑节点
    onEditNodeClick(node, data) {
      this.nodeOpen = true
      this.isEditNode = true
      let parent = node.parent
      let children = parent.data.children || parent.data
      let index = children.findIndex(d => d.id === data.id)
      this.children = children
      this.index = index
      if (data.rang != 1) {
        this.nodeOpenType = 2
        this.selectNodeModel.label = data.label
      } else {
        this.nodeOpenType = 1
        this.selectNodeModel.label = data.label
      }
    },
    // 多级下拉 批量添加
    onBatchAddNodeClick() {
      this.batchNodeOpen = true
    },
    // 多级下拉 批量添加 确定
    onBatchNodeSubmitClick() {
      let _this = this
      if(_this.isBlank(_this.batchNodeStr)) {
        this.$message.warning('节点内容不能为空！')
      }
      let selectArr = _this.batchNodeStr.split('\n')
      let data = []
      let id = 0
      let lastArr = []
      for(let j = 0; j < selectArr.length; j++) {
        let splitArr = selectArr[j].split('-')
        // console.log('splitArr', splitArr)
        let parentId = 0
        for (let i = 0; i < splitArr.length; i++) {
          let flag = data.some(item => item.label == splitArr[i] && item.rang == i + 1)
          if (!flag) {
            if (i == 0) {
              parentId = 0
            } else {
              let flagA = data.find(item => item.label == splitArr[i - 1] && item.rang == i)
              parentId = flagA.id
            }
            id = id + 1
            data.push({
              id: id,
              label: splitArr[i],
              rang: i + 1,
              parentId: parentId,
              children: []
            })
          }
        }
      }
      // console.log(data)
      // return
      lastArr = this.handleTree(data, 'id', 'parentId', 'children', 0)
      // console.log(lastArr)
      _this.selectNodeTree = lastArr
      let item = _this.arr2[_this.n]
      let copy = Object.assign({}, item)
      copy.content = _this.selectNodeTree
      _this.arr2[_this.n] = copy
      _this.selectFiled = copy
      _this.batchNodeOpen = false
      _this.batchNodeStr = ''
    },
    // 多级下拉 批量添加 取消
    onBatchNodeCancelClick() {
      this.batchNodeOpen = false
      this.batchNodeStr = ''
    },
    onBatchNodeCloseClick() {
      this.batchNodeOpen = false
      this.batchNodeStr = ''
    },
    // 复制字段
    onCopyFiledClick(element, index) {
      if(element.type == '14') {
        this.$message.warning('该预约字段暂不支持复制')
        return false
      }
      let objectAssignItem = {
        attribute: element.attribute,
        content: element.content,
        fieldTypeId: element.fieldTypeId,
        format: element.format,
        isFormat: element.isFormat,
        isLength: element.isLength,
        isRegExp: element.isRegExp,
        label: element.label ? "【Copy】" + element.label : '',
        maxLength: element.maxLength,
        minLength: element.minLength,
        placeholder: element.placeholder,
        regExp: element.regExp,
        required: element.required,
        sort: element.sort + 1,
        type: element.type,
      }
      // console.log(element)
      if (element.type == '6' || element.type == '7' || element.type == '8') {
        objectAssignItem.contentArr = element.contentArr
      }
      if (!this.isBlank(this.formId)) {
        objectAssignItem.formFieldId = null
      }
      let copy = Object.assign({}, objectAssignItem)
      this.n = this.n + 1
      this.arr2.splice(index + 1,0, copy)
      this.isDesignEdit = true
    },
    // 删除每一项字段信息
    onRemoveFiledClick(index) {
      // 新版---预约
      if(this.arr2[index].type == '14') {
        this.reserveList = [
          {
            maxTime: '',
            name: '会议室',
            reserveId: "",
            timeList: [
              {
                reserveTimeId: '',
                time: '8:00-8:30',
                checked: false,
                sort: 1
              },
              {
                reserveTimeId: '',
                time: '8:30-9:00',
                checked: false,
                sort: 2
              },
              {
                reserveTimeId: '',
                time: '9:00-9:30',
                checked: false,
                sort: 3
              },
              {
                reserveTimeId: '',
                time: '9:30-10:00',
                checked: false,
                sort: 4
              },
              {
                reserveTimeId: '',
                time: '10:00-10:30',
                checked: false,
                sort: 5
              },
              {
                reserveTimeId: '',
                time: '10:30-11:00',
                checked: false,
                sort: 6
              },
              {
                reserveTimeId: '',
                time: '11:00-11:30',
                checked: false,
                sort: 7
              },
              {
                reserveTimeId: '',
                time: '11:30-12:00',
                checked: false,
                sort: 8
              },
              {
                reserveTimeId: '',
                time: '12:00-12:30',
                checked: false,
                sort: 9
              },
              {
                reserveTimeId: '',
                time: '12:30-13:00',
                checked: false,
                sort: 10
              },
              {
                reserveTimeId: '',
                time: '13:00-13:30',
                checked: false,
                sort: 11
              },
              {
                reserveTimeId: '',
                time: '13:30-14:00',
                checked: false,
                sort: 12
              },
              {
                reserveTimeId: '',
                time: '14:00-14:30',
                checked: false,
                sort: 13
              },
              {
                reserveTimeId: '',
                time: '14:30-15:00',
                checked: false,
                sort: 14
              },
              {
                reserveTimeId: '',
                time: '15:00-15:30',
                checked: false,
                sort: 15
              },
              {
                reserveTimeId: '',
                time: '15:30-16:00',
                checked: false,
                sort: 16
              },
              {
                reserveTimeId: '',
                time: '16:00-16:30',
                checked: false,
                sort: 17
              },
              {
                reserveTimeId: '',
                time: '16:30-17:00',
                checked: false,
                sort: 18
              },
              {
                reserveTimeId: '',
                time: '17:00-17:30',
                checked: false,
                sort: 19
              },
              {
                reserveTimeId: '',
                time: '17:30-18:00',
                checked: false,
                sort: 20
              },
              {
                reserveTimeId: '',
                time: '18:00-18:30',
                checked: false,
                sort: 21
              },
              {
                reserveTimeId: '',
                time: '18:30-19:00',
                checked: false,
                sort: 22
              },
              {
                reserveTimeId: '',
                time: '19:00-19:30',
                checked: false,
                sort: 23
              },
              {
                reserveTimeId: '',
                time: '19:30-20:00',
                checked: false,
                sort: 24
              },
            ],
            sort: ''
          }
        ]
        this.canMeetingDrag = true
        this.arr2.splice(index + 1, 1)
        this.arr2.splice(index + 1, 1)
      }
      this.arr2.splice(index, 1)
      this.selectFiled = {}
      this.isDesignEdit = true
    },
    // 批量导入字段信息
    onHandleImportClick(){
      this.upload.title = "批量导入字段信息"
      this.upload.open = true
    },
    // 超出最大上传文件数量时的处理方法
    handleExceed(){
      this.$message({
        type:'warning',
        message:'超出最大上传文件数量的限制！'
      })
      return
    },
    // 移除文件的操作方法
    handleRemove(file,fileList) {
      this.fileTemp = null
    },
    httpRequest(e) {
      let file = e.file // 文件信息
      if (!file) {
        // 没有文件
        return false
      } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        // 格式根据自己需求定义
        this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false
      }
      this.upload.file = file
      const fileReader = new FileReader()
      let this_ = this
      fileReader.onload = ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: "binary" // 以字符编码的方式解析
          })
          let xlsxLth = workbook.Sheets[workbook.SheetNames[0]]["!ref"]
          let stopX = xlsxLth.substr(xlsxLth.indexOf(':')+1,workbook.Sheets[workbook.SheetNames[0]]["!ref"].length)
          workbook.Sheets[workbook.SheetNames[0]]["!ref"] = "A2:" + stopX
          const exlName = workbook.SheetNames[0] // 取第一张表
          let exl = XLSX.utils.sheet_to_json(workbook.Sheets[exlName]) // 生成json表格内容
          let merchantList = this_.dateTransition(exl)
          this_.merchantList = merchantList
        } catch (e) {
          return false
        }
      };
      fileReader.readAsBinaryString(file)
    },
    dateTransition(data){
      let list = []
      let obj = {}
      for(let i = 0; i < data.length; i++) {
        obj = {}
        for(let key in data[i]) {
          if(key.indexOf('字段名称') >= 0) {
            obj['label'] = data[i][key].trim()
          } else if(key.indexOf('字段类型') >= 0) {
            obj['typeText'] = data[i][key].trim()
          } else if(key.indexOf('必填') >= 0) {
            obj['required'] = data[i][key].trim()
          } else if(key == '内容') {
            obj['content'] = data[i][key]
          }
        }
        list.push(obj)
      }
      return list
    },
    onConfirmUploadClick() {
      let arr = []
      let merchantList = this.merchantList
      if (merchantList.length == 0) {
        this.$message({
          message: '请先导入文件～',
          type: 'warning'
        })
        return
      }
      for (let i = 0; i < merchantList.length; i++) {
        let item = merchantList[i]
        if (this.isBlank(item.label) && this.isBlank(item.typeText) && this.isBlank(item.required)) {
          continue //跳过本次循环
        }
        if (this.isBlank(item.label) || this.isBlank(item.typeText) || this.isBlank(item.required)) {
          this.$message({
            message: '第'+(i+3)+'行没有选项值',
            type: 'warning'
          })
          return
        }
        let sessionStorageUser = getSessionStorageObj('users')
        if (item.label == '组织架构') {
          if (sessionStorageUser.type == '1') {
            this.$message({
              message: '您暂时对第' + (i+3) + '行' + item.label + '无权限导入哦～',
              type: 'warning'
            })
            return
          }
        }
        let obj = {}
        let type = null
        let content = null
        let attribute = null
        let isLength = false
        let minLength = null
        let maxLength = null
        let isFormat = false
        let format = null
        let isRegExp = false
        let regExp = null
        switch (item.typeText) {
          case '单行文本':
            type = "1"
            attribute = '文本'
            isLength = true
            minLength = 1
            maxLength = 60
            break;
          case '多行文本':
            type = "2";
            break;
          case '数字输入':
          case '数字':
            type = "3";
            break;
          case '日期/时间':
          case '日期':
          case '时间':
            type = "4"
            attribute = '日期'
            isFormat = true
            format = 'yyyy-MM-dd'
            break;
          case '省市区':
            type = "5"
            attribute = '省市区'
            break;
          case '单项选择':
            type = "6"
            if (this.isBlank(item.content)) {
              this.$message({
                message: '字段名称为'+ item.label+'的内容不能为空',
                type: 'warning'
              })
              return
            }
            content = item.content
            break;
          case '多项选择':
            type = "7"
            if (this.isBlank(item.content)) {
              this.$message({
                message: '字段名称为'+ item.label+'的内容不能为空',
                type: 'warning'
              })
              return
            }
            content = item.content
            break;
          case '下拉框':
            type = "8"
            if (this.isBlank(item.content)) {
              this.$message({
                message: '字段名称为'+ item.label+'的内容不能为空',
                type: 'warning'
              })
              return
            }
            content = item.content
            break;
          case '上传图片':
            type = "9"
            break;
          case '上传附件':
            type = "10"
            break;
          case '组织架构':
            type = "11"
            break;
          case '说明文字':
            type = "12"
            // if (this.isBlank(item.content)) {
            //   this.$message({
            //     message: '字段名称为'+ item.label+'的内容不能为空',
            //     type: 'warning'
            //   })
            //   return
            // }
            content = item.content
            break;
          case '多级下拉框':
            type = "13"
            if (this.isBlank(item.content)) {
              this.$message({
                message: '第'+(i+4)+'行内容不能为空',
                type: 'warning'
              })
              return
            }
            let selectArr = item.content.split(',')
            let data = []
            let id = 0
            let lastArr = []
            for(let j = 0; j < selectArr.length; j++) {
              let splitArr = selectArr[j].split('-')
              let parentId = 0
              for (let i = 0; i < splitArr.length; i++) {
                let flag = data.some(item => item.label == splitArr[i] && item.rang == i + 1)
                if (!flag) {
                  if (i == 0) {
                    parentId = 0
                  } else {
                    let flagA = data.find(item => item.label == splitArr[i - 1] && item.rang == i)
                    parentId = flagA.id
                  }
                  id = id + 1
                  data.push({
                    id: id,
                    label: splitArr[i],
                    rang: i + 1,
                    parentId: parentId,
                    children: []
                  })
                }
              }
            }
            lastArr = this.handleTree(data, 'id', 'parentId', 'children', 0)
            content = lastArr
            break;
        }
        obj.content = content
        obj.label = item.label
        obj.placeholder = null
        obj.required = item.required == '是' ? true : false
        obj.sort = i + 1
        obj.type = type
        obj.attribute = attribute
        obj.isLength = isLength
        obj.minLength = minLength
        obj.maxLength = maxLength
        obj.format = format
        obj.isFormat = isFormat
        obj.isRegExp = isRegExp
        obj.regExp = regExp
        obj.typeText = item.typeText
        arr.push(obj)
      }
      let arr2 = []
      if (!this.isBlank(this.arr2)) {
        arr2 = this.arr2.concat(arr)
      } else {
        arr2 = arr
      }
      this.arr2 = arr2
      this.upload.open = false
      this.isDesignEdit = true
    },
    onCancelUploadClick() {
      this.upload = {
        open: false,
        title: "",
        file: null
      }
      this.$refs.upload.clearFiles()
    },
    onCloseUploadDialogClick() {
      this.upload = {
        open: false,
        title: "",
        file: null
      }
      this.$refs.upload.clearFiles()
    },
    // 一键清空
    onClearAllClick() {
      let _this = this
      if (!_this.isBlank(this.arr2)) {
        _this.$confirm('确认清空所有数据？', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          _this.arr2 = []
          _this.reserveList = [
            {
              maxTime: '',
              name: '会议室',
              reserveId: "",
              timeList: [
                {
                  reserveTimeId: '',
                  time: '8:00-8:30',
                  checked: false,
                  sort: 1
                },
                {
                  reserveTimeId: '',
                  time: '8:30-9:00',
                  checked: false,
                  sort: 2
                },
                {
                  reserveTimeId: '',
                  time: '9:00-9:30',
                  checked: false,
                  sort: 3
                },
                {
                  reserveTimeId: '',
                  time: '9:30-10:00',
                  checked: false,
                  sort: 4
                },
                {
                  reserveTimeId: '',
                  time: '10:00-10:30',
                  checked: false,
                  sort: 5
                },
                {
                  reserveTimeId: '',
                  time: '10:30-11:00',
                  checked: false,
                  sort: 6
                },
                {
                  reserveTimeId: '',
                  time: '11:00-11:30',
                  checked: false,
                  sort: 7
                },
                {
                  reserveTimeId: '',
                  time: '11:30-12:00',
                  checked: false,
                  sort: 8
                },
                {
                  reserveTimeId: '',
                  time: '12:00-12:30',
                  checked: false,
                  sort: 9
                },
                {
                  reserveTimeId: '',
                  time: '12:30-13:00',
                  checked: false,
                  sort: 10
                },
                {
                  reserveTimeId: '',
                  time: '13:00-13:30',
                  checked: false,
                  sort: 11
                },
                {
                  reserveTimeId: '',
                  time: '13:30-14:00',
                  checked: false,
                  sort: 12
                },
                {
                  reserveTimeId: '',
                  time: '14:00-14:30',
                  checked: false,
                  sort: 13
                },
                {
                  reserveTimeId: '',
                  time: '14:30-15:00',
                  checked: false,
                  sort: 14
                },
                {
                  reserveTimeId: '',
                  time: '15:00-15:30',
                  checked: false,
                  sort: 15
                },
                {
                  reserveTimeId: '',
                  time: '15:30-16:00',
                  checked: false,
                  sort: 16
                },
                {
                  reserveTimeId: '',
                  time: '16:00-16:30',
                  checked: false,
                  sort: 17
                },
                {
                  reserveTimeId: '',
                  time: '16:30-17:00',
                  checked: false,
                  sort: 18
                },
                {
                  reserveTimeId: '',
                  time: '17:00-17:30',
                  checked: false,
                  sort: 19
                },
                {
                  reserveTimeId: '',
                  time: '17:30-18:00',
                  checked: false,
                  sort: 20
                },
                {
                  reserveTimeId: '',
                  time: '18:00-18:30',
                  checked: false,
                  sort: 21
                },
                {
                  reserveTimeId: '',
                  time: '18:30-19:00',
                  checked: false,
                  sort: 22
                },
                {
                  reserveTimeId: '',
                  time: '19:00-19:30',
                  checked: false,
                  sort: 23
                },
                {
                  reserveTimeId: '',
                  time: '19:30-20:00',
                  checked: false,
                  sort: 24
                },
              ],
              sort: ''
            }
          ]
          _this.canMeetingDrag = true
        }).then(() => {

        }).catch(function() {})

      } else {
        _this.$message({
          type:'warning',
          message:'暂无表单数据，不可清空！'
        })
      }
      _this.selectFiled = {}
      _this.isDesignEdit = true
    },
    getFormField() {
      formFieldList(this.formId).then(response => {
        if (response.code == 200) {
          let data = response.data
          let arr2 = [] //表格中字段，需要重新赋值
          if(!this.isBlank(data)) {
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              if (item.type == '13') {
                if (typeof item.content == 'string') {
                  console.log('修改2')
                  item.content = JSON.parse(item.content)
                }
              }
              let filed = {
                attribute: item.attribute,
                content: item.content,
                isLength: item.isLength,
                minLength: item.minLength,
                maxLength: item.maxLength,
                label: item.label,
                placeholder: item.placeholder,
                required: item.required,
                sort: item.sort,
                type: item.type,
                format: item.format,
                isFormat: item.isFormat,
                isRegExp: item.isRegExp,
                regExp: item.regExp,
                formFieldId: item.formFieldId
              }
              // 单项、多项、下拉框数据处理
              if (item.type == '6' || item.type == '7' || item.type == '8') {
                let contentArr = []
                for (let j = 0; j < item.content.split('{JYDSP}').length; j++) {
                  contentArr.push({
                    name: item.content.split('{JYDSP}')[j],
                    sort: j + 1
                  })
                }
                item.contentArr = contentArr
                filed.contentArr = item.contentArr
              }
              arr2.push(filed)
              this.arr2 = arr2
              console.log('this.arr2', this.arr2)
            }
          }
          // 新版---获取预约信息
          if (this.isReserve) {
            this.getFormReserves()
          }
        } else{
          this.msgError(response.msg)
        }
      })
    },
    // 新版---获取预约信息
    getFormReserves() {
      formReserves(this.formId).then(response => {
        if (response.code == 200) {
          for(let i = 0 ; i < response.data.length; i++) {
            response.data[i].maxTime = !this.isBlank(response.data[i].maxTime) ? response.data[i].maxTime.toString() : ''
            response.data[i].name = response.data[i].name
            response.data[i].reserveId = response.data[i].reserveId
            response.data[i].timeList = [
              {
                reserveTimeId: '',
                time: '8:00-8:30',
                checked: false,
                sort: 1
              },
              {
                reserveTimeId: '',
                time: '8:30-9:00',
                checked: false,
                sort: 2
              },
              {
                reserveTimeId: '',
                time: '9:00-9:30',
                checked: false,
                sort: 3
              },
              {
                reserveTimeId: '',
                time: '9:30-10:00',
                checked: false,
                sort: 4
              },
              {
                reserveTimeId: '',
                time: '10:00-10:30',
                checked: false,
                sort: 5
              },
              {
                reserveTimeId: '',
                time: '10:30-11:00',
                checked: false,
                sort: 6
              },
              {
                reserveTimeId: '',
                time: '11:00-11:30',
                checked: false,
                sort: 7
              },
              {
                reserveTimeId: '',
                time: '11:30-12:00',
                checked: false,
                sort: 8
              },
              {
                reserveTimeId: '',
                time: '12:00-12:30',
                checked: false,
                sort: 9
              },
              {
                reserveTimeId: '',
                time: '12:30-13:00',
                checked: false,
                sort: 10
              },
              {
                reserveTimeId: '',
                time: '13:00-13:30',
                checked: false,
                sort: 11
              },
              {
                reserveTimeId: '',
                time: '13:30-14:00',
                checked: false,
                sort: 12
              },
              {
                reserveTimeId: '',
                time: '14:00-14:30',
                checked: false,
                sort: 13
              },
              {
                reserveTimeId: '',
                time: '14:30-15:00',
                checked: false,
                sort: 14
              },
              {
                reserveTimeId: '',
                time: '15:00-15:30',
                checked: false,
                sort: 15
              },
              {
                reserveTimeId: '',
                time: '15:30-16:00',
                checked: false,
                sort: 16
              },
              {
                reserveTimeId: '',
                time: '16:00-16:30',
                checked: false,
                sort: 17
              },
              {
                reserveTimeId: '',
                time: '16:30-17:00',
                checked: false,
                sort: 18
              },
              {
                reserveTimeId: '',
                time: '17:00-17:30',
                checked: false,
                sort: 19
              },
              {
                reserveTimeId: '',
                time: '17:30-18:00',
                checked: false,
                sort: 20
              },
              {
                reserveTimeId: '',
                time: '18:00-18:30',
                checked: false,
                sort: 21
              },
              {
                reserveTimeId: '',
                time: '18:30-19:00',
                checked: false,
                sort: 22
              },
              {
                reserveTimeId: '',
                time: '19:00-19:30',
                checked: false,
                sort: 23
              },
              {
                reserveTimeId: '',
                time: '19:30-20:00',
                checked: false,
                sort: 24
              },
            ]
            response.data[i].sort = response.data[i].sort
          }
          this.reserveList = response.data
        } else {
          this.msgError(response.msg)
        }
      })
    },
    // 新版---预约 添加会议室
    onMeetingRoomAdd() {
      this.reserveList.push({
        maxTime: '',
        name: '会议室',
        reserveId: "",
        timeList: [
          {
            reserveTimeId: '',
            time: '8:00-8:30',
            checked: false,
            sort: 1
          },
          {
            reserveTimeId: '',
            time: '8:30-9:00',
            checked: false,
            sort: 2
          },
          {
            reserveTimeId: '',
            time: '9:00-9:30',
            checked: false,
            sort: 3
          },
          {
            reserveTimeId: '',
            time: '9:30-10:00',
            checked: false,
            sort: 4
          },
          {
            reserveTimeId: '',
            time: '10:00-10:30',
            checked: false,
            sort: 5
          },
          {
            reserveTimeId: '',
            time: '10:30-11:00',
            checked: false,
            sort: 6
          },
          {
            reserveTimeId: '',
            time: '11:00-11:30',
            checked: false,
            sort: 7
          },
          {
            reserveTimeId: '',
            time: '11:30-12:00',
            checked: false,
            sort: 8
          },
          {
            reserveTimeId: '',
            time: '12:00-12:30',
            checked: false,
            sort: 9
          },
          {
            reserveTimeId: '',
            time: '12:30-13:00',
            checked: false,
            sort: 10
          },
          {
            reserveTimeId: '',
            time: '13:00-13:30',
            checked: false,
            sort: 11
          },
          {
            reserveTimeId: '',
            time: '13:30-14:00',
            checked: false,
            sort: 12
          },
          {
            reserveTimeId: '',
            time: '14:00-14:30',
            checked: false,
            sort: 13
          },
          {
            reserveTimeId: '',
            time: '14:30-15:00',
            checked: false,
            sort: 14
          },
          {
            reserveTimeId: '',
            time: '15:00-15:30',
            checked: false,
            sort: 15
          },
          {
            reserveTimeId: '',
            time: '15:30-16:00',
            checked: false,
            sort: 16
          },
          {
            reserveTimeId: '',
            time: '16:00-16:30',
            checked: false,
            sort: 17
          },
          {
            reserveTimeId: '',
            time: '16:30-17:00',
            checked: false,
            sort: 18
          },
          {
            reserveTimeId: '',
            time: '17:00-17:30',
            checked: false,
            sort: 19
          },
          {
            reserveTimeId: '',
            time: '17:30-18:00',
            checked: false,
            sort: 20
          },
          {
            reserveTimeId: '',
            time: '18:00-18:30',
            checked: false,
            sort: 21
          },
          {
            reserveTimeId: '',
            time: '18:30-19:00',
            checked: false,
            sort: 22
          },
          {
            reserveTimeId: '',
            time: '19:00-19:30',
            checked: false,
            sort: 23
          },
          {
            reserveTimeId: '',
            time: '19:30-20:00',
            checked: false,
            sort: 24
          },
        ],
        sort: ''
      })
    },
    onMeetingRoomEdit(index) {
      let _this = this
      _this.meetingDialog.meetingRoomName = _this.reserveList[index].name
      _this.meetingDialog.meetingRoomTime = _this.reserveList[index].timeList
      _this.meetingDialog.maxTime = _this.reserveList[index].maxTime
      _this.meetingDialog.meetingRoomIndex = index
      _this.meetingDialog.open = true
      if (!_this.isBlank(_this.reserveList[index].reserveId)) {
        _this.getReserveTimes(_this.reserveList[index].reserveId, index)
      }
    },
    // 新版---获取预约室时间段信息
    getReserveTimes(reserveId, index) {
      let _this = this
      reserveTimes(reserveId).then(response => {
        if (response.code == 200) {
          let timeList = [
              {
                reserveTimeId: '',
                time: '8:00-8:30',
                checked: true,
                sort: 1
              },
              {
                reserveTimeId: '',
                time: '8:30-9:00',
                checked: true,
                sort: 2
              },
              {
                reserveTimeId: '',
                time: '9:00-9:30',
                checked: true,
                sort: 3
              },
              {
                reserveTimeId: '',
                time: '9:30-10:00',
                checked: true,
                sort: 4
              },
              {
                reserveTimeId: '',
                time: '10:00-10:30',
                checked: true,
                sort: 5
              },
              {
                reserveTimeId: '',
                time: '10:30-11:00',
                checked: true,
                sort: 6
              },
              {
                reserveTimeId: '',
                time: '11:00-11:30',
                checked: true,
                sort: 7
              },
              {
                reserveTimeId: '',
                time: '11:30-12:00',
                checked: true,
                sort: 8
              },
              {
                reserveTimeId: '',
                time: '12:00-12:30',
                checked: true,
                sort: 9
              },
              {
                reserveTimeId: '',
                time: '12:30-13:00',
                checked: true,
                sort: 10
              },
              {
                reserveTimeId: '',
                time: '13:00-13:30',
                checked: true,
                sort: 11
              },
              {
                reserveTimeId: '',
                time: '13:30-14:00',
                checked: true,
                sort: 12
              },
              {
                reserveTimeId: '',
                time: '14:00-14:30',
                checked: true,
                sort: 13
              },
              {
                reserveTimeId: '',
                time: '14:30-15:00',
                checked: true,
                sort: 14
              },
              {
                reserveTimeId: '',
                time: '15:00-15:30',
                checked: true,
                sort: 15
              },
              {
                reserveTimeId: '',
                time: '15:30-16:00',
                checked: true,
                sort: 16
              },
              {
                reserveTimeId: '',
                time: '16:00-16:30',
                checked: true,
                sort: 17
              },
              {
                reserveTimeId: '',
                time: '16:30-17:00',
                checked: true,
                sort: 18
              },
              {
                reserveTimeId: '',
                time: '17:00-17:30',
                checked: true,
                sort: 19
              },
              {
                reserveTimeId: '',
                time: '17:30-18:00',
                checked: true,
                sort: 20
              },
              {
                reserveTimeId: '',
                time: '18:00-18:30',
                checked: true,
                sort: 21
              },
              {
                reserveTimeId: '',
                time: '18:30-19:00',
                checked: true,
                sort: 22
              },
              {
                reserveTimeId: '',
                time: '19:00-19:30',
                checked: true,
                sort: 23
              },
              {
                reserveTimeId: '',
                time: '19:30-20:00',
                checked: true,
                sort: 24
              },
            ]
          let data = response.data
          for (let i = 0; i < timeList.length; i++) {
            for (let j = 0; j < data.length; j++) {
              if(timeList[i].time == data[j].time){
                timeList[i].reserveTimeId = data[j].reserveTimeId
                timeList[i].checked = false
              }
            }
          }
          _this.reserveList[index].timeList = timeList
          _this.meetingDialog.meetingRoomTime = _this.reserveList[index].timeList
        }
      })
    },
    onMeetingRoomDel(index) {
      let _this = this
      _this.$confirm('确认删除该选项？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        _this.reserveList.splice(index, 1)
      }).then(() => {

      }).catch(function() {})
    },
    onSelectTimeRange(index) {
      this.meetingDialog.meetingRoomTime[index].checked = !this.meetingDialog.meetingRoomTime[index].checked
    },
    onConfirmMeetingClick() {
      let _this = this
      _this.reserveList[_this.meetingDialog.meetingRoomIndex].name = _this.meetingDialog.meetingRoomName
      _this.reserveList[_this.meetingDialog.meetingRoomIndex].maxTime = _this.meetingDialog.maxTime
      _this.meetingDialog.open = false
      _this.meetingDialog.meetingRoomName = ''
      _this.meetingDialog.meetingRoomTime = []
      _this.meetingDialog.maxTime = ''
      _this.meetingDialog.meetingRoomIndex = null
    },
    onCancelMeetingClick() {
      let _this = this
      _this.meetingDialog.open = false
      _this.meetingDialog.meetingRoomName = ''
      _this.meetingDialog.meetingRoomTime = []
      _this.meetingDialog.maxTime = ''
      _this.meetingDialog.meetingRoomIndex = null
    },
    onCloseMeetingClick() {
      let _this = this
      _this.meetingDialog.open = false
      _this.meetingDialog.meetingRoomName = ''
      _this.meetingDialog.meetingRoomTime = []
      _this.meetingDialog.maxTime = ''
      _this.meetingDialog.meetingRoomIndex = null
    },
  },
};

Array.prototype.filter = Array.prototype.filter || function(func) {
  let arr = this;
  let r = [];
  for (let i = 0; i < arr.length; i++) {
    if (func(arr[i],i,arr)) {
      r.push(arr[i]);
    }
  }
  return r
}
</script>
<style lang="scss" scoped>
  [v-cloak] {
    display: none;
  }
  .config-pane {
    width: 100%;
    min-height: 100vh;
    position: relative;
    .form-widget-left {
      width: 260px;
      background: #ffffff;
      position: absolute;
      left:0;
      top: 0;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      border-right: solid 1px #e0e0e0;
      .widget-cate {
        line-height: 22px;
        padding: 20px 15px 5px;
        font-weight: 700;
        color: #1F2D3D;
        font-size: 14px;
      }
      .form-inner-list{
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
        padding: 0 4% 5px;
        margin: 0;
        .list-item {
          font-size: 12px;
          display: inline-block;
          margin: 5px 2%;
          width: 46%;
          color: #1F2D3D;
          padding: 0 10px;
          box-sizing: border-box;
          border: 1px solid #D9D9D9;
          background: #fff;
          border-radius: 6px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 32px;
          line-height: 32px;
          &:hover{
            border: 1px dashed #0078FF;
            background: rgba(0, 120, 255, 0.08);
            color: #0078FF;
            cursor: move;
            .filed-icon {
              color: #0078FF;
            }
          }
          .filed-icon{
            width: 1.2em;
            height: 1.2em;
            vertical-align: -0.2em;
            margin-right: 5px;
          }
          .meeting-icon {
            width: 20px;
            height: 20px;
            vertical-align: -6px;
            margin-right: 5px;
          }
          &.undraggable{
            background: #f4f4f4;
            cursor: inherit;
            &:hover{
              border: 1px solid #D9D9D9;
              color: #1F2D3D;
            }
          }
        }
      }
    }
    .form-widget-center {
      position: absolute;
      top: 0;
      left: 260px;
      right: 350px;
      bottom: 0;
      overflow: hidden;
      padding: 0;
      .quickOperationBtn{
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 20px 0;
        box-sizing: border-box;
        .switch-show-btn{
          position: absolute;
          right: 40px;
          span{
            margin-left: 20px;
            color: #BABABA;
            cursor: pointer;
            &.selected{
              color: #0078F8;
            }
          }
        }
      }
      .center-tips {
        width: 150px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -75px;
        color: #91A1B7;
        font-size: 14px;
        text-align: center;
      }
      .yes-drag-list {
        width: calc(100% - 40px);
        height: 100vh;
        margin: 0 auto 20px;
        background: #FFFFFF;
        overflow-y: auto;
        .frame-inner-list{
          width: 100%;
          padding: 0;
          margin: 0;
          .inner-list-group{
            padding: 10px 12px 200px;
            margin: 0;
            width: 100%;
            display: inline-block;
            min-height: 100vh;
            .group-item {
              width: 100%;
              list-style: none;
              padding: 7px 12px 12px;
              box-sizing: border-box;
              position: relative;
              &.active{
                background-color: #ebf8fb;
              }
              &:hover {
                background: #F5F7FA;
                cursor: move;
                .actions__position {
                  display: block;
                }
              }
              .label-title{
                width: 100%;
                font-size: 14px;
                display: inline-block;
                padding: 0 0 5px;
                line-height: 20px;
                color: #1F2D3D;
                font-weight: 600;
              }
              .actions__position {
                display: none;
                position: absolute;
                right: 0;
                top: 5px;
                cursor: pointer;
                span {
                  width: 30px;
                  height: 30px;
                  line-height: 30px;
                  display: inline-block;
                  text-align: center;
                  img {
                    display: inline-block;
                    vertical-align: middle;
                    width: 13px;
                    height: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .form-widget-right{
      position: absolute;
      top: 0;
      right: 0;
      width: 350px;
      bottom: 0;
      border-left: solid 1px #e0e0e0;
      background: #fff;
      overflow-x: hidden;
      overflow-y: auto;
      .config-tab{
        width: 100%;
        padding: 20px 15px 5px;
        box-sizing: border-box;
        font-weight: 700;
        color: #1F2D3D;
        font-size: 14px;
        line-height: 22px;
      }
      .config-empty-tip{
        margin-top: 242px;
        text-align: center;
        font-size: 18px;
        color: #91A1B7;
      }
      .field-box{
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
  }

.el-radio__label {
  padding-left: 8px;
}

/*针对下拉框 禁止点击*/
.el-select .el-input.is-disabled .el-input__inner {
  background: #fff;
  cursor: move;
}

.el-select .el-input.is-disabled .el-input__icon {
  cursor: move;
}

/*针对组织架构 禁止点击*/
.vue-treeselect--disabled .vue-treeselect__control {
  background-color: #fff !important;
}

.forbid{
  background: #C0C4CC;
  border: 1px solid #C0C4CC;
  &:hover{
    border: 1px dashed  rgba(0,0,0,0);
    cursor: not-allowed;
    color: #333;
    background: #C0C4CC;
    .filed-icon {
      color: #333333;
    }
  }
}

/*针对单选、多选、下拉 文字超长*/
.el-radio {
  white-space: normal;
  margin-bottom: 10px;
}

.el-checkbox{
  white-space: normal;
  margin-bottom: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.ghost{
  border: 1px solid #0078FF;
  background: #ebf8fb;
  color: #0078FF;
}

.chosen{
  border: 1px solid #0078FF;
  background: #ebf8fb;
  color: #0078FF;
}

/*多级下拉*/
.el-tree {
  .el-tree-node {
    white-space: normal;
    .el-tree-node__content {
      height: 100%;
    }
  }
}

/*多选disabled样式更改*/
.el-checkbox__input.is-disabled .el-checkbox__inner{
  background-color: #fff;
  border-color: #DCDFE6;
  cursor: not-allowed;
}

.el-checkbox__input.is-disabled+span.el-checkbox__label{
  color: #606266;
  cursor: not-allowed;
}

/*新版---会议室预约*/
.choice-list {
  padding: 0;
  margin: 0 0 0 -80px;
  list-style: none;
  .choice-list-group{
    width: 100%;
    display: inline-block;
    .choice-item {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}

.meeting-time-slot{
  .time-slot-label{
    font-size: 16px;
  }
  .time-slot-list{
    margin: 0;
    list-style: none;
    padding: 0;
    li {
      width: 23.5%;
      padding: 20px 7px;
      border: 1px solid #ebebeb;
      border-radius: 2px;
      color: #333;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.65);
      -webkit-transition: color .2s,background-color .3s;
      transition: color .2s,background-color .3s;
      display: inline-block;
      margin-bottom: 20px;
      margin-right: 2%;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &.is-disabled {
        color: #333;
        background-color: #f4f4f4;
        cursor: no-drop;
      }
    }
  }
}

</style>

