import request from '@/utils/request'

const prefix = '/form/encrypt-rules'

// 获取表单下加密规则
export function getEncryptRulesByFormId(formId) {
  return request({
    url: prefix + '/list/' + formId,
    method: 'get'
  })
}

// 新增表单下加密规则
export function addEncryptRules(data) {
  return request({
    url: prefix,
    method: 'post',
    data
  })
}

// 获取随机AES密钥
export function generationAeskey(){
  return request({
    url: prefix + '/generation/aeskey',
    method: 'get'
  })
}

// 获取配置的AES密钥
export function getAesKeyByFormId(formId){
  return request({
    url: prefix + '/aeskey' + formId,
    method: 'get'
  })
}
