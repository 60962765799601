var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticStyle: { margin: "0" }, attrs: { gutter: 20 } },
    [
      _c(
        "el-col",
        {
          staticStyle: {
            padding: "0",
            "padding-right": "20px",
            "box-sizing": "border-box",
          },
          attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
        },
        [
          _c(
            "el-form",
            {
              ref: "setForm",
              staticClass: "clearfix form-set-base",
              attrs: { "label-width": "130px", "label-position": "left" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "开启协议" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": true,
                      "inactive-value": false,
                      "active-color": "#3476F0",
                    },
                    model: {
                      value: _vm.agreement.startFillAgreementStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.agreement, "startFillAgreementStatus", $$v)
                      },
                      expression: "agreement.startFillAgreementStatus",
                    },
                  }),
                ],
                1
              ),
              _vm.agreement.startFillAgreementStatus
                ? _c(
                    "el-form-item",
                    { attrs: { label: "协议标题" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入协议标题" },
                        model: {
                          value: _vm.agreement.agreementTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.agreement, "agreementTitle", $$v)
                          },
                          expression: "agreement.agreementTitle",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agreement.startFillAgreementStatus
                ? _c(
                    "el-form-item",
                    { attrs: { label: "协议文本" } },
                    [
                      _c("RichEditor", {
                        attrs: {
                          agencyId: _vm.agencyId,
                          formId: _vm.formId,
                          uploadPath: "fillFile/{agencyId}/{formId}/contact/",
                        },
                        model: {
                          value: _vm.agreement.agreementContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.agreement, "agreementContent", $$v)
                          },
                          expression: "agreement.agreementContent",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.agreement.startFillAgreementStatus
                ? _c(
                    "el-form-item",
                    { attrs: { label: "协议确认方式" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.agreement.agreementConfirmationMethod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.agreement,
                                "agreementConfirmationMethod",
                                $$v
                              )
                            },
                            expression: "agreement.agreementConfirmationMethod",
                          },
                        },
                        _vm._l(
                          _vm.agreementConfirmationMethod,
                          function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }