var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-pane" },
    [
      _c(
        "div",
        { staticClass: "form-widget-left" },
        [
          _c("div", { staticClass: "widget-cate" }, [_vm._v("常用字段")]),
          _c(
            "draggable",
            {
              staticClass: "clearfix form-inner-list",
              attrs: {
                options: {
                  group: { name: "itxst", pull: "clone", put: false },
                  sort: false,
                },
                animation: "300",
                tag: "ul",
                "ghost-class": "ghost",
                "chosen-class": "chosen",
              },
              model: {
                value: _vm.commonFields,
                callback: function ($$v) {
                  _vm.commonFields = $$v
                },
                expression: "commonFields",
              },
            },
            [
              _c(
                "transition-group",
                _vm._l(_vm.commonFields, function (item, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "list-item" },
                    [
                      _c("svg-icon", {
                        staticClass: "filed-icon",
                        attrs: { "icon-class": item.iconClass },
                      }),
                      _c("span", { staticStyle: { display: "inline-block" } }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c("div", { staticClass: "widget-cate" }, [_vm._v("基础字段")]),
          _c(
            "draggable",
            {
              staticClass: "clearfix form-inner-list",
              attrs: {
                options: {
                  group: { name: "itxst", pull: "clone", put: false },
                  sort: false,
                },
                animation: "300",
                tag: "ul",
                filter: ".forbid",
                "ghost-class": "ghost",
                "chosen-class": "chosen",
              },
              model: {
                value: _vm.basicFields,
                callback: function ($$v) {
                  _vm.basicFields = $$v
                },
                expression: "basicFields",
              },
            },
            [
              _c(
                "transition-group",
                _vm._l(_vm.basicFields, function (item, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "list-item" },
                    [
                      _c("svg-icon", {
                        staticClass: "filed-icon",
                        attrs: { "icon-class": item.iconClass },
                      }),
                      _c("span", { staticStyle: { display: "inline-block" } }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm.advancedFields && _vm.advancedFields.length > 0
            ? _c("div", { staticClass: "widget-cate" }, [_vm._v("高级字段")])
            : _vm._e(),
          _c(
            "draggable",
            {
              staticClass: "clearfix form-inner-list",
              attrs: {
                options: {
                  group: { name: "itxst", pull: "clone", put: false },
                  sort: false,
                },
                animation: "300",
                tag: "ul",
                filter: ".undraggable",
                "ghost-class": "ghost",
                "chosen-class": "chosen",
              },
              model: {
                value: _vm.advancedFields,
                callback: function ($$v) {
                  _vm.advancedFields = $$v
                },
                expression: "advancedFields",
              },
            },
            [
              _c(
                "transition-group",
                _vm._l(_vm.advancedFields, function (item, index) {
                  return item.type != 15 && item.type != 16
                    ? _c(
                        "li",
                        {
                          key: index,
                          staticClass: "list-item",
                          class:
                            item.type == "14" && !_vm.canMeetingDrag
                              ? "undraggable"
                              : "draggable",
                        },
                        [
                          _c("svg-icon", {
                            class: [
                              item.type == "14" ? "meeting-icon" : "filed-icon",
                            ],
                            attrs: { "icon-class": item.iconClass },
                          }),
                          _c(
                            "span",
                            { staticStyle: { display: "inline-block" } },
                            [_vm._v(_vm._s(item.label))]
                          ),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-widget-center" },
        [
          _vm.arr2.length == 0
            ? _c("div", { staticClass: "center-tips" }, [
                _vm._v("从左侧拖拽来添加字段"),
              ])
            : _vm._e(),
          _c("el-form", { ref: "centerFormData", attrs: { model: _vm.arr2 } }, [
            _c(
              "div",
              {
                staticClass: "yes-drag-list",
                style: [
                  { margin: _vm.modelCur == 2 ? "0 auto 20px" : "0 auto 20px" },
                  { width: _vm.modelCur == 2 ? "375px" : "calc(100% - 40px)" },
                  { maxHeight: _vm.modelCur == 2 ? "667px" : "" },
                  { borderRadius: _vm.modelCur == 2 ? "26px" : "0px" },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "quickOperationBtn" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.modelCur == 1,
                            expression: "modelCur == 1",
                          },
                        ],
                        attrs: {
                          type: "warning",
                          icon: "el-icon-upload",
                          size: "mini",
                        },
                        on: { click: _vm.onHandleImportClick },
                      },
                      [_vm._v("导入表格")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.modelCur == 1,
                            expression: "modelCur == 1",
                          },
                        ],
                        attrs: {
                          type: "danger",
                          icon: "el-icon-delete",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.onClearAllClick($event)
                          },
                        },
                      },
                      [_vm._v("一键清空")]
                    ),
                    _c("div", { staticClass: "switch-show-btn" }, [
                      _c(
                        "span",
                        {
                          class: { selected: _vm.modelCur == 1 },
                          on: {
                            click: function ($event) {
                              return _vm.onSwitchModelClick(1)
                            },
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: { width: "20px", height: "20px" },
                            attrs: { "icon-class": "computer" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          class: { selected: _vm.modelCur == 2 },
                          on: {
                            click: function ($event) {
                              return _vm.onSwitchModelClick(2)
                            },
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: { width: "20px", height: "20px" },
                            attrs: { "icon-class": "phone" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "draggable",
                  {
                    staticClass: "frame-inner-list",
                    attrs: {
                      options: {
                        group: { name: "itxst" },
                        sort: true,
                        handle: ".inner-list-group,.label-title",
                      },
                      animation: "300",
                      tag: "ul",
                    },
                    on: {
                      add: _vm.onDraggableFinish,
                      update: _vm.onDraggableUpdate,
                      choose: _vm.onDraggableChoose,
                    },
                    model: {
                      value: _vm.arr2,
                      callback: function ($$v) {
                        _vm.arr2 = $$v
                      },
                      expression: "arr2",
                    },
                  },
                  [
                    _c(
                      "transition-group",
                      { staticClass: "inner-list-group" },
                      _vm._l(_vm.arr2, function (element, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "group-item",
                            class: { active: index == _vm.n },
                            style: [
                              {
                                padding:
                                  element.type == 15 || element.type == 16
                                    ? "0"
                                    : "7px 12px 12px",
                              },
                            ],
                            attrs: { index: index },
                          },
                          [
                            element.type != 15 && element.type != 16
                              ? _c(
                                  "div",
                                  { staticClass: "actions__position" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.onCopyFiledClick(
                                              element,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/filedCopy.png",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.onRemoveFiledClick(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/filedDel.png",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            element.type != 15 && element.type != 16
                              ? _c("label", { staticClass: "label-title" }, [
                                  element.required
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#f00000",
                                            "margin-right": "5px",
                                            "margin-left": "-10px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      )
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        element.label
                                          ? element.label
                                          : "请点击设置字段名称"
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            element.type === "1"
                              ? _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: element.placeholder,
                                    readonly: "true",
                                  },
                                })
                              : _vm._e(),
                            element.type === "2"
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 3,
                                    placeholder: element.placeholder,
                                    readonly: "true",
                                  },
                                })
                              : _vm._e(),
                            element.type === "3"
                              ? _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "number",
                                    placeholder: element.placeholder,
                                    readonly: "true",
                                  },
                                })
                              : _vm._e(),
                            element.type === "4" &&
                            element.format == "yyyy-MM-dd"
                              ? _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    placeholder: element.placeholder,
                                    readonly: "true",
                                  },
                                })
                              : element.type === "4" &&
                                element.format == "yyyy-MM-dd hh:mm"
                              ? _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "datetime",
                                    placeholder: element.placeholder,
                                    readonly: "true",
                                  },
                                })
                              : element.type === "4" &&
                                element.format == "yyyy-MM-dd hh:mm:ss"
                              ? _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "datetime",
                                    placeholder: element.placeholder,
                                    readonly: "true",
                                  },
                                })
                              : element.type === "4" &&
                                element.format == "hh:mm"
                              ? _c("el-time-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: element.placeholder,
                                    readonly: "true",
                                  },
                                })
                              : element.type === "4" &&
                                element.format == "hh:mm:ss"
                              ? _c("el-time-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: element.placeholder,
                                    readonly: "true",
                                  },
                                })
                              : _vm._e(),
                            element.type === "4" &&
                            element.attribute == "日期区间"
                              ? _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "datetimerange",
                                    "start-placeholder": "日期",
                                    "end-placeholder": "日期",
                                    readonly: "true",
                                  },
                                })
                              : _vm._e(),
                            element.type === "5"
                              ? [
                                  element.attribute == "省"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            display: "flex",
                                            "flex-direction": "row",
                                          },
                                        },
                                        [
                                          _c("el-select", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择省",
                                              disabled: true,
                                            },
                                            model: {
                                              value: _vm.value,
                                              callback: function ($$v) {
                                                _vm.value = $$v
                                              },
                                              expression: "value",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : element.attribute == "省市"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            display: "flex",
                                            "flex-direction": "row",
                                          },
                                        },
                                        [
                                          _c("el-select", {
                                            staticStyle: {
                                              width: "49%",
                                              "margin-right": "2%",
                                            },
                                            attrs: {
                                              placeholder: "请选择省",
                                              disabled: true,
                                            },
                                            model: {
                                              value: _vm.value,
                                              callback: function ($$v) {
                                                _vm.value = $$v
                                              },
                                              expression: "value",
                                            },
                                          }),
                                          _c("el-select", {
                                            staticStyle: { width: "49%" },
                                            attrs: {
                                              placeholder: "请选择市",
                                              disabled: true,
                                            },
                                            model: {
                                              value: _vm.value,
                                              callback: function ($$v) {
                                                _vm.value = $$v
                                              },
                                              expression: "value",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : element.attribute == "省市区"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            display: "flex",
                                            "flex-direction": "row",
                                          },
                                        },
                                        [
                                          _c("el-select", {
                                            staticStyle: {
                                              width: "32%",
                                              "margin-right": "2%",
                                            },
                                            attrs: {
                                              placeholder: "请选择省",
                                              disabled: true,
                                            },
                                            model: {
                                              value: _vm.value,
                                              callback: function ($$v) {
                                                _vm.value = $$v
                                              },
                                              expression: "value",
                                            },
                                          }),
                                          _c("el-select", {
                                            staticStyle: {
                                              width: "32%",
                                              "margin-right": "2%",
                                            },
                                            attrs: {
                                              placeholder: "请选择市",
                                              disabled: true,
                                            },
                                            model: {
                                              value: _vm.value,
                                              callback: function ($$v) {
                                                _vm.value = $$v
                                              },
                                              expression: "value",
                                            },
                                          }),
                                          _c("el-select", {
                                            staticStyle: { width: "32%" },
                                            attrs: { placeholder: "请选择区" },
                                            model: {
                                              value: _vm.value,
                                              callback: function ($$v) {
                                                _vm.value = $$v
                                              },
                                              expression: "value",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  element.content == "true"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-top": "10px",
                                            width: "100%",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              placeholder: "请输入详细地址",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            element.type === "6"
                              ? _c(
                                  "el-radio-group",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      display: "-webkit-flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  _vm._l(
                                    element.contentArr,
                                    function (radio, index) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: index,
                                          attrs: { label: radio.name },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(radio.name)),
                                          ]),
                                          radio.type == "other" &&
                                          _vm.isOtherClick
                                            ? _c("input", {
                                                staticStyle: {
                                                  "background-color": "#F6F7F7",
                                                  outline: "0",
                                                  border: "1px solid #D9D9D9",
                                                  height: "32px",
                                                  "margin-left": "10px",
                                                  "border-radius": "2px",
                                                  "max-width": "200px",
                                                },
                                                attrs: { type: "text" },
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            element.type === "7" &&
                            element.content &&
                            element.content.length > 0
                              ? [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.selectCheckbox,
                                        callback: function ($$v) {
                                          _vm.selectCheckbox = $$v
                                        },
                                        expression: "selectCheckbox",
                                      },
                                    },
                                    _vm._l(
                                      element.contentArr,
                                      function (checkbox, index) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: index,
                                            attrs: { label: checkbox },
                                          },
                                          [_vm._v(_vm._s(checkbox.name))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            element.type === "8"
                              ? _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: element.placeholder,
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.temp[element.sort],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.temp, element.sort, $$v)
                                      },
                                      expression: "temp[element.sort]",
                                    },
                                  },
                                  _vm._l(
                                    element.contentArr,
                                    function (option, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: option,
                                          value: option.name,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            element.type === "9"
                              ? _c(
                                  "el-upload",
                                  {
                                    attrs: {
                                      action: "#",
                                      "list-type": "picture",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "tip",
                                          size: "small",
                                          type: "primary",
                                        },
                                        slot: "tip",
                                      },
                                      [_vm._v("点击上传")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            element.type === "10"
                              ? _c(
                                  "el-upload",
                                  { attrs: { action: "#" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "tip",
                                          size: "small",
                                          type: "primary",
                                        },
                                        slot: "tip",
                                      },
                                      [_vm._v("点击上传")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            element.type === "11"
                              ? [
                                  _c("treeselect", {
                                    attrs: {
                                      options: "",
                                      multiple: false,
                                      flat: false,
                                      "show-count": true,
                                      "default-expand-level": 1,
                                      placeholder: element.placeholder,
                                      disabled: true,
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            element.type === "12"
                              ? [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "word-break": "break-word",
                                      },
                                    },
                                    [_vm._v(_vm._s(element.content))]
                                  ),
                                ]
                              : _vm._e(),
                            element.type === "13"
                              ? [
                                  _c("el-cascader", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      options: "",
                                      placeholder: element.placeholder,
                                      disabled: true,
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            element.type === "14"
                              ? _c(
                                  "el-radio-group",
                                  { staticStyle: { width: "100%" } },
                                  _vm._l(
                                    _vm.reserveList,
                                    function (radio, index) {
                                      return _c(
                                        "el-radio",
                                        { key: index, attrs: { label: radio } },
                                        [_vm._v(_vm._s(radio.name))]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            element.type === "15" ? void 0 : _vm._e(),
                            element.type === "16" ? void 0 : _vm._e(),
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "form-widget-right" }, [
        _c("div", { staticClass: "config-tab" }, [_vm._v("字段设置")]),
        _vm.selectFiled && _vm.selectFiled.type
          ? _c(
              "div",
              { staticClass: "field-box" },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.selectFiled,
                      "label-width": "80px",
                      "label-position": "right",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _c(
                              "i",
                              {
                                staticStyle: {
                                  "margin-right": "5px",
                                  color: "#f56c6c",
                                  "font-style": "normal",
                                },
                              },
                              [_vm._v("*")]
                            ),
                            _vm._v("字段名称"),
                          ]
                        ),
                        _c("el-input", {
                          attrs: { placeholder: "请输入标题" },
                          on: {
                            change: _vm.onLabelChange,
                            input: _vm.onLabelChange,
                          },
                          model: {
                            value: _vm.selectFiled.label,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectFiled, "label", $$v)
                            },
                            expression: "selectFiled.label",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _c(
                              "i",
                              {
                                staticStyle: {
                                  "margin-right": "5px",
                                  color: "#f56c6c",
                                  "font-style": "normal",
                                },
                              },
                              [_vm._v("*")]
                            ),
                            _vm._v("字段类型"),
                          ]
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              placeholder: "请选择字段",
                              disabled: true,
                            },
                            on: { change: _vm.onTypeChange },
                            model: {
                              value: _vm.selectFiled.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectFiled, "type", $$v)
                              },
                              expression: "selectFiled.type",
                            },
                          },
                          _vm._l(_vm.rightFiledType, function (item) {
                            return _c("el-option", {
                              key: item.type,
                              attrs: { label: item.label, value: item.type },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.selectFiled.type == "1"
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      color: "#f56c6c",
                                      "font-style": "normal",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v("字段属性"),
                              ]
                            ),
                            _c(
                              "el-radio-group",
                              {
                                staticStyle: { "margin-left": "-70px" },
                                on: { change: _vm.onSelectAttribute },
                                model: {
                                  value: _vm.selectFiled.attribute,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selectFiled, "attribute", $$v)
                                  },
                                  expression: "selectFiled.attribute",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "margin-right": "4px" },
                                    attrs: { label: "文本" },
                                  },
                                  [_vm._v("文本")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "margin-right": "4px" },
                                    attrs: { label: "手机号" },
                                  },
                                  [_vm._v("手机号")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "margin-right": "4px" },
                                    attrs: { label: "邮箱" },
                                  },
                                  [_vm._v("邮箱")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: { label: "身份证号" },
                                  },
                                  [_vm._v("身份证号")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectFiled.type == "4"
                      ? _c(
                          "el-form-item",
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      color: "#f56c6c",
                                      "font-style": "normal",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v("字段属性"),
                              ]
                            ),
                            _c(
                              "el-radio-group",
                              {
                                on: { change: _vm.onSelectDate },
                                model: {
                                  value: _vm.selectFiled.attribute,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selectFiled, "attribute", $$v)
                                  },
                                  expression: "selectFiled.attribute",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { label: "日期" },
                                  },
                                  [_vm._v("日期")]
                                ),
                                _c(
                                  "el-radio",
                                  { attrs: { label: "日期区间" } },
                                  [_vm._v("日期区间")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectFiled.type == "4" &&
                    _vm.selectFiled.attribute == "日期"
                      ? _c(
                          "el-form-item",
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      color: "#f56c6c",
                                      "font-style": "normal",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v("选择类型"),
                              ]
                            ),
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.onSelectDateOption },
                                model: {
                                  value: _vm.selectFiled.format,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selectFiled, "format", $$v)
                                  },
                                  expression: "selectFiled.format",
                                },
                              },
                              _vm._l(_vm.dateArr, function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectFiled.type == "4" &&
                    _vm.selectFiled.attribute == "日期区间"
                      ? _c(
                          "el-form-item",
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      color: "#f56c6c",
                                      "font-style": "normal",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v("选择类型"),
                              ]
                            ),
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.onSelectDateRangeOption },
                                model: {
                                  value: _vm.selectFiled.format,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selectFiled, "format", $$v)
                                  },
                                  expression: "selectFiled.format",
                                },
                              },
                              _vm._l(_vm.dateRangeArr, function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectFiled.type == "5"
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      color: "#f56c6c",
                                      "font-style": "normal",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v("字段属性"),
                              ]
                            ),
                            _c(
                              "el-radio-group",
                              {
                                staticStyle: { "margin-left": "-70px" },
                                on: { change: _vm.onSelectArea },
                                model: {
                                  value: _vm.selectFiled.attribute,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selectFiled, "attribute", $$v)
                                  },
                                  expression: "selectFiled.attribute",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    key: "省",
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { label: "省" },
                                  },
                                  [_vm._v("省")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    key: "省市",
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { label: "省市" },
                                  },
                                  [_vm._v("省市")]
                                ),
                                _c(
                                  "el-radio",
                                  { key: "省市区", attrs: { label: "省市区" } },
                                  [_vm._v("省市区")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectFiled.type == "5"
                      ? _c("el-form-item", { attrs: { label: "详细地址" } }, [
                          _c(
                            "span",
                            {
                              on: { click: _vm.onAddressRequiredClick },
                              model: {
                                value: _vm.selectFiled.content,
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectFiled, "content", $$v)
                                },
                                expression: "selectFiled.content",
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.selectFiled.content == "true"
                                      ? "checked"
                                      : "check",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.selectFiled.type == "1" &&
                    _vm.selectFiled.attribute == "文本"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "限制字数" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "number",
                                placeholder: "请输入限制字数",
                              },
                              on: { change: _vm.onChangeMaxLength },
                              model: {
                                value: _vm.selectFiled.maxLength,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectFiled,
                                    "maxLength",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "selectFiled.maxLength",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectFiled.type == "2"
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                            },
                            attrs: { label: "限制字数" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "-70px" } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 11, xs: 11 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        placeholder: "最小限制",
                                      },
                                      on: { change: _vm.onChangeMinLength },
                                      model: {
                                        value: _vm.selectFiled.minLength,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.selectFiled,
                                            "minLength",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "selectFiled.minLength",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "text-align": "center" },
                                    attrs: { span: 2, xs: 2 },
                                  },
                                  [_vm._v("至")]
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 11, xs: 11 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        placeholder: "最大限制",
                                      },
                                      on: { change: _vm.onChangeMaxLength },
                                      model: {
                                        value: _vm.selectFiled.maxLength,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.selectFiled,
                                            "maxLength",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "selectFiled.maxLength",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.selectFiled.type != "6" &&
                    _vm.selectFiled.type != "7" &&
                    _vm.selectFiled.type != "12" &&
                    _vm.selectFiled.type != "14"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "提示信息" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入提示信息" },
                              on: { change: _vm.onPlaceChange },
                              model: {
                                value: _vm.selectFiled.placeholder,
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectFiled, "placeholder", $$v)
                                },
                                expression: "selectFiled.placeholder",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectFiled.type != "12" && _vm.selectFiled.type != "14"
                      ? _c("el-form-item", { attrs: { label: "是否必填" } }, [
                          _c(
                            "span",
                            {
                              on: { click: _vm.onRightRequiredClick },
                              model: {
                                value: _vm.selectFiled.required,
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectFiled, "required", $$v)
                                },
                                expression: "selectFiled.required",
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class": _vm.selectFiled.required
                                    ? "checked"
                                    : "check",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.selectFiled.type === "6" ||
                    _vm.selectFiled.type === "7" ||
                    _vm.selectFiled.type === "8"
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              display: "-webkit-flex",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      color: "#f56c6c",
                                      "font-style": "normal",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v("选项内容"),
                              ]
                            ),
                            _c(
                              "draggable",
                              {
                                staticClass: "choice-list",
                                attrs: {
                                  options: {
                                    group: { name: "itxst" },
                                    sort: true,
                                    handle: ".mover",
                                  },
                                  animation: "300",
                                  tag: "ul",
                                },
                                on: { update: _vm.onOptionUpdate },
                                model: {
                                  value: _vm.selectFiled.contentArr,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selectFiled, "contentArr", $$v)
                                  },
                                  expression: "selectFiled.contentArr",
                                },
                              },
                              [
                                _c(
                                  "transition-group",
                                  { staticClass: "choice-list-group" },
                                  _vm._l(
                                    _vm.selectFiled.contentArr,
                                    function (element, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass: "choice-item",
                                          attrs: { index: index },
                                        },
                                        [
                                          element.type != "other"
                                            ? _c("svg-icon", {
                                                staticClass: "mover",
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px",
                                                  "margin-right": "5px",
                                                },
                                                attrs: { "icon-class": "drag" },
                                              })
                                            : _vm._e(),
                                          _c("el-input", {
                                            attrs: {
                                              disabled:
                                                element.type &&
                                                element.type == "other"
                                                  ? true
                                                  : false,
                                            },
                                            on: { input: _vm.onTextareaBlur },
                                            model: {
                                              value: element.name,
                                              callback: function ($$v) {
                                                _vm.$set(element, "name", $$v)
                                              },
                                              expression: "element.name",
                                            },
                                          }),
                                          _c("svg-icon", {
                                            staticStyle: {
                                              width: "20px",
                                              height: "20px",
                                              cursor: "pointer",
                                              "margin-left": "5px",
                                            },
                                            attrs: { "icon-class": "meetDel" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onContentOtherDel(
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                            _c(
                              "label",
                              {
                                staticStyle: {
                                  "margin-top": "-10px",
                                  "margin-left": "-60px",
                                  display: "-webkit-flex",
                                  "justify-content": "space-between",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.onAddOptionsClick },
                                  },
                                  [_vm._v("添加选项")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.onEditTextArea },
                                  },
                                  [_vm._v("批量编辑")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectFiled.type === "12"
                      ? _c(
                          "el-form-item",
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      color: "#f56c6c",
                                      "font-style": "normal",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v("选项内容"),
                              ]
                            ),
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 5,
                                placeholder: _vm.selectFiled.placeholder,
                              },
                              on: { input: _vm.onTextareaChange },
                              model: {
                                value: _vm.selectFiled.content,
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectFiled, "content", $$v)
                                },
                                expression: "selectFiled.content",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectFiled.type === "13"
                      ? _c(
                          "el-form-item",
                          { staticStyle: { "margin-left": "-70px" } },
                          [
                            _c(
                              "label",
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#606266",
                                      "margin-right": "10px",
                                    },
                                  },
                                  [_vm._v("节点")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.addSelectNodeClick },
                                  },
                                  [_vm._v("添加")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: { click: _vm.onBatchAddNodeClick },
                                  },
                                  [_vm._v("批量添加")]
                                ),
                              ],
                              1
                            ),
                            _c("el-tree", {
                              ref: "tree",
                              attrs: {
                                data: _vm.selectNodeTree,
                                props: _vm.treeProps,
                                "expand-on-click-node": false,
                                "default-expand-all": _vm.defaultExpandAll,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var node = ref.node
                                      var data = ref.data
                                      return _c(
                                        "span",
                                        {
                                          staticClass: "custom-tree-node",
                                          staticStyle: { width: "100%" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fl node-label" },
                                            [_vm._v(_vm._s(node.label))]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fr node-btn" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "10px",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onEditNodeClick(
                                                        node,
                                                        data
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("编辑")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#F80F0F",
                                                    "padding-left": "10px",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onDelNodeClick(
                                                        node,
                                                        data
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "10px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onAddNodeClick(
                                                        data
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("添加")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                3510790060
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectFiled.type === "14"
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              display: "-webkit-flex",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _c(
                                  "i",
                                  {
                                    staticStyle: {
                                      "margin-right": "5px",
                                      color: "#f56c6c",
                                      "font-style": "normal",
                                    },
                                  },
                                  [_vm._v("*")]
                                ),
                                _vm._v("选项内容"),
                              ]
                            ),
                            _c(
                              "draggable",
                              {
                                staticClass: "choice-list",
                                attrs: {
                                  options: {
                                    group: { name: "itxst" },
                                    sort: true,
                                    handle: ".mover",
                                  },
                                  animation: "300",
                                  tag: "ul",
                                },
                                model: {
                                  value: _vm.reserveList,
                                  callback: function ($$v) {
                                    _vm.reserveList = $$v
                                  },
                                  expression: "reserveList",
                                },
                              },
                              [
                                _c(
                                  "transition-group",
                                  { staticClass: "choice-list-group" },
                                  _vm._l(
                                    _vm.reserveList,
                                    function (element, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass: "choice-item",
                                          attrs: { index: index },
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "mover",
                                            staticStyle: {
                                              width: "20px",
                                              height: "20px",
                                              "margin-right": "5px",
                                            },
                                            attrs: { "icon-class": "drag" },
                                          }),
                                          _c("el-input", {
                                            model: {
                                              value: element.name,
                                              callback: function ($$v) {
                                                _vm.$set(element, "name", $$v)
                                              },
                                              expression: "element.name",
                                            },
                                          }),
                                          _c("svg-icon", {
                                            staticStyle: {
                                              width: "20px",
                                              height: "20px",
                                              margin: "0 5px",
                                              cursor: "pointer",
                                            },
                                            attrs: { "icon-class": "meetSet" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onMeetingRoomEdit(
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                          _c("svg-icon", {
                                            staticStyle: {
                                              width: "20px",
                                              height: "20px",
                                              cursor: "pointer",
                                            },
                                            attrs: { "icon-class": "meetDel" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onMeetingRoomDel(
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "10px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.onMeetingRoomAdd },
                                  },
                                  [_vm._v("添加选项")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _c("div", { staticClass: "config-empty-tip" }, [
              _vm._v("请添加字段"),
            ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增多级下拉节点",
            visible: _vm.nodeOpen,
            width: "400px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.nodeOpen = $event
            },
            close: _vm.onCloseClick,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "nodeForm",
              attrs: {
                model: _vm.selectNodeModel,
                rules: _vm.selectNodeRules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "节点名称", prop: "label" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入节点名称" },
                            model: {
                              value: _vm.selectNodeModel.label,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectNodeModel, "label", $$v)
                              },
                              expression: "selectNodeModel.label",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onSubmitClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量添加多级下拉节点",
            visible: _vm.batchNodeOpen,
            width: "440px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchNodeOpen = $event
            },
            close: _vm.onBatchNodeCloseClick,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "p",
            {
              staticStyle: {
                "margin-top": "-20px",
                color: "#f00",
                display: "-webkit-flex",
                "flex-direction": "column",
              },
            },
            [
              _c("span", [_vm._v("注：")]),
              _c("span", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  "1：每行对应一个选项，多级选项之间用-隔开。例：文学院-文学系,文学院-对外汉语,计算机学院-项目管理"
                ),
              ]),
              _c("span", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  "2：多级选项（3级以下）名称尽量不重复，否则批量添加失败"
                ),
              ]),
              _c("span", { staticStyle: { "line-height": "24px" } }, [
                _vm._v("3：点击确定后，表单多级下拉的原有设置将失效"),
              ]),
            ]
          ),
          _c(
            "el-form",
            { ref: "batchNodeForm" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "label" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              placeholder: "请输入多级下拉节点内容",
                            },
                            model: {
                              value: _vm.batchNodeStr,
                              callback: function ($$v) {
                                _vm.batchNodeStr = $$v
                              },
                              expression: "batchNodeStr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onBatchNodeSubmitClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onBatchNodeCancelClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.upload.title,
            visible: _vm.upload.open,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.upload, "open", $event)
            },
            close: _vm.onCloseUploadDialogClick,
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                limit: 1,
                action: "",
                multiple: false,
                accept:
                  "csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "on-remove": _vm.handleRemove,
                "on-exceed": _vm.handleExceed,
                "http-request": _vm.httpRequest,
                drag: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _c("em", [_vm._v("点击上传文件")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  staticStyle: { "margin-top": "5px", "line-height": "16px" },
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { type: "text" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/%E8%A1%A8%E5%8D%95%E8%AE%BE%E8%AE%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx",
                            download: "鲸鱼填报-表单设计-导入模板.xlsx",
                          },
                        },
                        [_vm._v("下载模板")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      staticStyle: {
                        color: "red",
                        display: "inline-block",
                        "margin-top": "0",
                        "margin-left": "5px",
                      },
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("提示：仅允许导入“xls”或“xlsx”格式文件！")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmUploadClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelUploadClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量编辑",
            visible: _vm.designContentOpen,
            width: "400px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.designContentOpen = $event
            },
            close: _vm.onCloseContentClick,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("p", { staticStyle: { "margin-top": "-20px" } }, [
            _vm._v("每个选项请单列一行，单个选项长度不能超过50个字"),
          ]),
          _c(
            "el-form",
            {
              ref: "nodeForm",
              attrs: { model: _vm.designContentModel, "label-width": "80px" },
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 5 },
                model: {
                  value: _vm.designContentModel.designContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.designContentModel, "designContent", $$v)
                  },
                  expression: "designContentModel.designContent",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmContentClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelContentClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.meetingDialog.open,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.meetingDialog, "open", $event)
            },
            close: _vm.onCloseMeetingClick,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px", "label-position": "right" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选项名称" } },
                [
                  _c("el-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.meetingDialog.meetingRoomName,
                      callback: function ($$v) {
                        _vm.$set(_vm.meetingDialog, "meetingRoomName", $$v)
                      },
                      expression: "meetingDialog.meetingRoomName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "meeting-time-slot" }, [
            _c("h5", { staticClass: "time-slot-label" }, [
              _vm._v("选择不可预约的时间段："),
            ]),
            _c(
              "ul",
              { staticClass: "time-slot-list" },
              _vm._l(_vm.meetingDialog.meetingRoomTime, function (item, index) {
                return _c(
                  "li",
                  {
                    class: [item.checked ? "is-disabled" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.onSelectTimeRange(index)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.time))]
                )
              }),
              0
            ),
            _c(
              "h5",
              {
                staticClass: "time-slot-label",
                staticStyle: { "margin-top": "0" },
              },
              [_vm._v("最多可连续预约时间段：")]
            ),
            _c(
              "div",
              { staticClass: "time-slot-hour" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    model: {
                      value: _vm.meetingDialog.maxTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.meetingDialog, "maxTime", $$v)
                      },
                      expression: "meetingDialog.maxTime",
                    },
                  },
                  [_vm._v("0.5小时")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "2" },
                    model: {
                      value: _vm.meetingDialog.maxTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.meetingDialog, "maxTime", $$v)
                      },
                      expression: "meetingDialog.maxTime",
                    },
                  },
                  [_vm._v("1小时")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "3" },
                    model: {
                      value: _vm.meetingDialog.maxTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.meetingDialog, "maxTime", $$v)
                      },
                      expression: "meetingDialog.maxTime",
                    },
                  },
                  [_vm._v("1.5小时")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "4" },
                    model: {
                      value: _vm.meetingDialog.maxTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.meetingDialog, "maxTime", $$v)
                      },
                      expression: "meetingDialog.maxTime",
                    },
                  },
                  [_vm._v("2小时")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "5" },
                    model: {
                      value: _vm.meetingDialog.maxTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.meetingDialog, "maxTime", $$v)
                      },
                      expression: "meetingDialog.maxTime",
                    },
                  },
                  [_vm._v("2.5小时")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "6" },
                    model: {
                      value: _vm.meetingDialog.maxTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.meetingDialog, "maxTime", $$v)
                      },
                      expression: "meetingDialog.maxTime",
                    },
                  },
                  [_vm._v("3小时")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmMeetingClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelMeetingClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }