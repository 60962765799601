var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-button", {
        attrs: { type: "primary", icon: "el-icon-plus", circle: "" },
        on: { click: _vm.onAuditAddClick },
      }),
      _vm._l(_vm.auditInfos, function (auditInfo, auditLevelIdx) {
        return _c(
          "div",
          { key: auditLevelIdx, staticClass: "node-wrap-container" },
          [
            _c("div", { staticClass: "auditAuth-title" }, [
              _c("span", { staticClass: "auditAuth-title-text" }, [
                _vm._v(
                  _vm._s(_vm.auditAuthAgencyLeaderLevel[auditLevelIdx]) +
                    "级审核 "
                ),
                auditLevelIdx == _vm.auditInfos.length - 1
                  ? _c("span", {
                      staticClass:
                        "auditAuth-title-delete el-icon-circle-close",
                      on: {
                        click: function ($event) {
                          return _vm.removeAuditInfo(auditLevelIdx)
                        },
                      },
                    })
                  : _vm._e(),
                _c("span", {
                  staticClass: "auditAuth-field-auth el-icon-setting",
                  on: {
                    click: function ($event) {
                      return _vm.showFieldAuth(auditLevelIdx + 1)
                    },
                  },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "auditAuth-radio" },
              _vm._l(_vm.auditAuthType, function (type, auditAuthTypeIdx) {
                return _c(
                  "el-radio",
                  {
                    key: auditAuthTypeIdx,
                    attrs: { label: auditAuthTypeIdx + 1 },
                    on: { change: _vm.onChangeEdit },
                    model: {
                      value: auditInfo.auditAuthType,
                      callback: function ($$v) {
                        _vm.$set(auditInfo, "auditAuthType", $$v)
                      },
                      expression: "auditInfo.auditAuthType",
                    },
                  },
                  [_vm._v(_vm._s(type))]
                )
              }),
              1
            ),
            auditInfo.auditAuthType == "2"
              ? _c(
                  "div",
                  {
                    staticClass: "dept-admin",
                    staticStyle: { "text-align": "left" },
                  },
                  [
                    auditInfo.selectAllAuditArr &&
                    auditInfo.selectAllAuditArr.length > 0
                      ? _c(
                          "ul",
                          {
                            staticClass: "select-list",
                            on: {
                              click: function ($event) {
                                return _vm.onAuditSelectMemberClick(auditInfo)
                              },
                            },
                          },
                          _vm._l(
                            auditInfo.selectAllAuditArr,
                            function (item, itemIdx) {
                              return _c(
                                "li",
                                { key: itemIdx, staticClass: "select-item" },
                                [
                                  item.type == "member"
                                    ? [
                                        _c("svg-icon", {
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                            "vertical-align": "-5px",
                                            "margin-right": "5px",
                                          },
                                          attrs: { "icon-class": "memberIcon" },
                                        }),
                                      ]
                                    : _vm._e(),
                                  item.type == "dept"
                                    ? [
                                        _c("svg-icon", {
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                            "vertical-align": "-5px",
                                            "margin-right": "5px",
                                          },
                                          attrs: { "icon-class": "deptIcon" },
                                        }),
                                      ]
                                    : _vm._e(),
                                  item.type == "role"
                                    ? [
                                        _c("svg-icon", {
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                            "vertical-align": "-5px",
                                            "margin-right": "5px",
                                          },
                                          attrs: { "icon-class": "roleIcon" },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                ],
                                2
                              )
                            }
                          ),
                          0
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "designated-member",
                            on: {
                              click: function ($event) {
                                return _vm.onAuditSelectMemberClick(auditInfo)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "member-empty" },
                              [
                                _c("svg-icon", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                    "margin-right": "5px",
                                    color: "#5e6d82",
                                    "vertical-align": "-5px",
                                  },
                                  attrs: { "icon-class": "add" },
                                }),
                                _vm._v("点击添加指定成员 "),
                              ],
                              1
                            ),
                          ]
                        ),
                  ]
                )
              : _vm._e(),
            auditInfo.auditAuthType == "3"
              ? _c(
                  "div",
                  {
                    staticClass: "dept-admin",
                    staticStyle: { "justify-content": "center" },
                  },
                  _vm._l(
                    _vm.auditAuthAgencyLeaderLevel,
                    function (leaderLevel, leaderIdx) {
                      return _c(
                        "el-radio",
                        {
                          key: leaderIdx,
                          attrs: { label: leaderIdx + 1 },
                          on: { change: _vm.onChangeEdit },
                          model: {
                            value: auditInfo.auditAuthAgencyLeaderLevel,
                            callback: function ($$v) {
                              _vm.$set(
                                auditInfo,
                                "auditAuthAgencyLeaderLevel",
                                $$v
                              )
                            },
                            expression: "auditInfo.auditAuthAgencyLeaderLevel",
                          },
                        },
                        [_vm._v(_vm._s(leaderLevel) + "级部门主管")]
                      )
                    }
                  ),
                  1
                )
              : _vm._e(),
            auditInfo.auditAuthType == "4"
              ? _c(
                  "div",
                  {
                    staticClass: "dept-admin",
                    staticStyle: { "justify-content": "center" },
                  },
                  [
                    _vm.exceptUserSelectField.length > 0
                      ? _c("setAuditBySelect", {
                          attrs: {
                            value: auditInfo,
                            fields: _vm.exceptUserSelectField,
                            fieldList: _vm.exceptUserSelectField,
                            agencyId: _vm.agencyId,
                            auditIndex: auditLevelIdx,
                            isUserSelect: false,
                          },
                          on: { change: _vm.fieldConditionsChanged },
                        })
                      : _c("div", { staticClass: "auditSelectEmpty" }, [
                          _vm._v("表单中必须包含必填的单选或者下拉字段"),
                          _c("br"),
                          _c("span", { on: { click: _vm.reCheckField } }, [
                            _vm._v("重新检查"),
                          ]),
                        ]),
                  ],
                  1
                )
              : _vm._e(),
            auditInfo.auditAuthType == "5"
              ? _c(
                  "div",
                  {
                    staticClass: "dept-admin",
                    staticStyle: { "justify-content": "center" },
                  },
                  [
                    _vm.onlyUserSelectField.length > 0
                      ? _c("setAuditByUserSelect", {
                          attrs: {
                            value: auditInfo,
                            fields: _vm.onlyUserSelectField,
                            fieldList: _vm.onlyUserSelectField,
                            agencyId: _vm.agencyId,
                            auditIndex: auditLevelIdx,
                            isUserSelect: true,
                          },
                          on: {
                            userSelectFieldSelect: _vm.fieldUserSelectChanged,
                          },
                        })
                      : _c("div", { staticClass: "auditSelectEmpty" }, [
                          _vm._v("表单中必须包含用户选择字段"),
                          _c("br"),
                          _c("span", { on: { click: _vm.reCheckField } }, [
                            _vm._v("重新检查"),
                          ]),
                        ]),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "部门成员列表",
            visible: _vm.auditMemberSelectShow,
            "modal-append-to-body": false,
            width: "650px",
            "show-close": false,
          },
        },
        [
          _c("div", { staticClass: "fx_member_select" }, [
            _c(
              "ul",
              { staticClass: "select-list" },
              _vm._l(_vm.selectAllAuditArr, function (item, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "select-item" },
                  [
                    item.type == "member"
                      ? [
                          _c("svg-icon", {
                            staticStyle: {
                              width: "20px",
                              height: "20px",
                              "vertical-align": "-4px",
                              "margin-right": "5px",
                            },
                            attrs: { "icon-class": "memberIcon" },
                          }),
                        ]
                      : _vm._e(),
                    item.type == "dept"
                      ? [
                          _c("svg-icon", {
                            staticStyle: {
                              width: "20px",
                              height: "20px",
                              "vertical-align": "-4px",
                              "margin-right": "5px",
                            },
                            attrs: { "icon-class": "deptIcon" },
                          }),
                        ]
                      : _vm._e(),
                    item.type == "role"
                      ? [
                          _c("svg-icon", {
                            staticStyle: {
                              width: "20px",
                              height: "20px",
                              "vertical-align": "-4px",
                              "margin-right": "5px",
                            },
                            attrs: { "icon-class": "roleIcon" },
                          }),
                        ]
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(item.name))]),
                    _c(
                      "span",
                      {
                        staticClass: "remove-btn",
                        on: {
                          click: function ($event) {
                            return _vm.onAuditRemoveSelect(item, index)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          staticStyle: {
                            width: "12px",
                            height: "12px",
                            "vertical-align": "-1px",
                          },
                          attrs: { "icon-class": "del" },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                )
              }),
              0
            ),
            _c("div", { staticClass: "select-menu" }, [
              _c(
                "div",
                {
                  staticClass: "select-btn",
                  class: { select: _vm.auditCur === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.onAuditSelectTabClick(1)
                    },
                  },
                },
                [_vm._v("成员")]
              ),
              _c(
                "div",
                {
                  staticClass: "select-btn",
                  class: { select: _vm.auditCur === 2 },
                  on: {
                    click: function ($event) {
                      return _vm.onAuditSelectTabClick(2)
                    },
                  },
                },
                [_vm._v("部门")]
              ),
              _c(
                "div",
                {
                  staticClass: "select-btn",
                  class: { select: _vm.auditCur === 3 },
                  on: {
                    click: function ($event) {
                      return _vm.onAuditSelectTabClick(3)
                    },
                  },
                },
                [_vm._v("角色")]
              ),
              _vm.auditCur === 1
                ? _c(
                    "div",
                    {
                      staticClass: "select-search-pane",
                      style: { width: _vm.isAuditFocus ? "100%" : "114px" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberParams.searchTxt,
                            expression: "memberParams.searchTxt",
                          },
                        ],
                        attrs: { type: "text", placeholder: "搜索" },
                        domProps: { value: _vm.memberParams.searchTxt },
                        on: {
                          change: _vm.onViewChangeSearchTxt,
                          focus: _vm.onViewFocusSearchTxt,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberParams,
                              "searchTxt",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm.memberParams.searchTxt != ""
                        ? _c("svg-icon", {
                            staticStyle: {
                              width: "12px",
                              height: "12px",
                              position: "absolute",
                              right: "20px",
                              top: "12px",
                              cursor: "pointer",
                            },
                            attrs: { "icon-class": "del" },
                            on: { click: _vm.onViewClearSearchTxt },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "select-pane" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.auditCur === 1,
                      expression: "auditCur===1",
                    },
                  ],
                  staticClass: "member-pane",
                },
                [
                  _c("div", { staticClass: "select-department fl" }, [
                    _c(
                      "div",
                      {
                        staticClass: "depart-root",
                        on: { click: _vm.onViewMemberClick },
                      },
                      [_vm._v("全部成员")]
                    ),
                    _c(
                      "div",
                      { staticClass: "x-department-tree" },
                      [
                        _c("el-tree", {
                          attrs: {
                            data: _vm.auditDeptOptions,
                            props: _vm.deptTreeProps,
                          },
                          on: { "node-click": _vm.onHandleNodeClick },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.memberList && _vm.memberList.length > 0
                    ? _c("div", { staticClass: "member-wrapper fr" }, [
                        _c("div", { staticClass: "select-all-item" }, [
                          _c("div", { staticClass: "count-label" }, [
                            _vm._v("已选 "),
                            _c("span", { staticClass: "check-count" }, [
                              _vm._v(_vm._s(_vm.selectMemberLen)),
                            ]),
                            _vm._v("/" + _vm._s(_vm.memberList.length)),
                          ]),
                          _c("div", { staticClass: "check-all x-check" }, [
                            _c("i", { staticClass: "icon-blank" }),
                          ]),
                        ]),
                        _c("div", { staticClass: "select-member" }, [
                          _c(
                            "ul",
                            [
                              _c(
                                "li",
                                { staticStyle: { background: "#ebf8f7" } },
                                [
                                  _c("span", { staticClass: "user-name fl" }, [
                                    _vm._v("姓名"),
                                  ]),
                                  _c("span", { staticClass: "user-name fl" }, [
                                    _vm._v("编号"),
                                  ]),
                                  _c("span", { staticClass: "user-name fl" }, [
                                    _vm._v("部门"),
                                  ]),
                                  _c("div", { staticClass: "select-check fr" }),
                                ]
                              ),
                              _vm._l(_vm.memberList, function (item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    on: {
                                      click: function ($event) {
                                        return _vm.onAuditCheckBoxUser(index)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "user-name fl" },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "user-name fl" },
                                      [_vm._v(_vm._s(item.number))]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "user-name fl" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            (
                                              item.agencyUserDeptNames || []
                                            ).join(",")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "select-check fr",
                                        class: { select: item.checked },
                                      },
                                      [_c("i", { staticClass: "icon-blank" })]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ])
                    : _c("div", { staticClass: "member-wrapper fr" }, [
                        _c("span", { staticClass: "empty-tips" }, [
                          _vm._v("没有可选成员"),
                        ]),
                      ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.auditCur === 2,
                      expression: "auditCur===2",
                    },
                  ],
                  staticClass: "member-pane",
                },
                [
                  _c(
                    "div",
                    { staticClass: "tree-wrapper" },
                    [
                      _c("el-tree", {
                        ref: "auditDeptTree",
                        attrs: {
                          data: _vm.auditDeptOptions,
                          props: _vm.deptTreeProps,
                          "show-checkbox": "",
                          "check-strictly": true,
                          "node-key": "agencyDeptId",
                        },
                        on: { "check-change": _vm.onAuditCheckChange },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.auditCur === 3,
                      expression: "auditCur===3",
                    },
                  ],
                  staticClass: "member-pane",
                },
                [
                  _c(
                    "div",
                    { staticClass: "tree-wrapper" },
                    [
                      _c("el-tree", {
                        ref: "auditRoleTree",
                        attrs: {
                          data: _vm.auditRoleOptions,
                          props: _vm.roleTreeProps,
                          "show-checkbox": "",
                          "check-strictly": true,
                          "node-key": "agencyRoleId",
                        },
                        on: { "check-change": _vm.onAuditRoleCheckChange },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onAuditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onAuditConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "fx_member_close", on: { click: _vm.onAuditClose } },
            [
              _c("svg-icon", {
                staticStyle: {
                  width: "16px",
                  height: "16px",
                  "vertical-align": "middle",
                },
                attrs: { "icon-class": "del" },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "字段权限设置",
            visible: _vm.drawer,
            "append-to-body": true,
            "modal-append-to-body": true,
            "before-close": _vm.onSaveFieldAuth,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "fieldWidgetDrawer" },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.fieldAuth } },
                _vm._l(_vm.authWidgetList, function (widget, index) {
                  return _c(
                    "el-form-item",
                    {
                      key: index,
                      attrs: { label: widget.label, "label-width": "140px" },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { value: _vm.getFieldAuth(widget.name) },
                          on: {
                            input: function (val) {
                              return _vm.setFieldAuth(widget.name, val)
                            },
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: -2 } }, [
                            _vm._v("可编辑"),
                          ]),
                          _c("el-checkbox", { attrs: { label: -3 } }, [
                            _vm._v("显示"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "drawer-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default" },
                  on: { click: _vm.onCancelFieldAuth },
                },
                [_vm._v("关闭")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onSaveFieldAuth },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }