<template>
  <div>

    <el-table :data="data" style="width: 100%" max-height="250">
      <el-table-column prop="encryptMode" label="加密方式">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.encryptMode === 1 ? 'AES' : '其他' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="aesKey" label="AES密钥">
      </el-table-column>
      <!-- <el-table-column prop="enable" label="是否启用">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" disabled></el-switch>
        </template>
      </el-table-column> -->
      <el-table-column prop="createTime" label="创建时间">
      </el-table-column>
      <el-table-column>
        <template slot="header" slot-scope="scope">
          <el-button v-if="data.length === 0" type="primary" size="mini" @click="dialogFormVisible = true" style="float: right;">添加规则</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="配置加密规则" width="30%" :visible.sync="dialogFormVisible" append-to-body>
      <el-form :model="encryptRulesForm" label-width="80px">
        <el-form-item label="加密方式" prop="encryptMode">
          <el-select v-model="encryptRulesForm.encryptMode" disabled>
            <el-option label="AES" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="AES密钥" prop="aesKey">
          <el-input v-model="encryptRulesForm.aesKey" :maxlength="32" :minlength="32" >
            <el-button slot="append" @click="generationAeskey">随机生成</el-button>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="是否启用" prop="enable">
          <el-switch v-model="encryptRulesForm.enable"></el-switch>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEncryptRules">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEncryptRulesByFormId, addEncryptRules, generationAeskey } from '@/api/system/formEncryptRules'
export default {
  name: 'EncryptRules',
  props: {
    formId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      data: [],
      dialogFormVisible: false,
      encryptRulesForm: {
        formId: undefined,
        encryptMode: '1',
        aesKey: '',
        enable: false,
        createTime: ''
      }
    }
  },
  created () {
    this.getEncryptRules()
  },
  methods: {
    getEncryptRules () {
      getEncryptRulesByFormId(this.formId).then((res) => {
        this.data = res.data
      }).catch((err) => {

      });
    },
    generationAeskey () {
      generationAeskey().then((res) => {
        this.encryptRulesForm.aesKey = res.data
      }).catch((err) => {

      });
    },
    saveEncryptRules () {
      this.encryptRulesForm.formId = this.formId
      addEncryptRules(this.encryptRulesForm).then((res) => {
        this.getEncryptRules()
        this.dialogFormVisible = false
      }).catch((err) => {

      });
    }
  }
}
</script>
