<template>
  <div>
    <el-button type="primary" icon="el-icon-plus" circle @click="onAuditAddClick"></el-button>
    <div class="node-wrap-container" v-for="(auditInfo,auditLevelIdx) in auditInfos" :key="auditLevelIdx">
      <div class="auditAuth-title">
        <span class="auditAuth-title-text">{{auditAuthAgencyLeaderLevel[auditLevelIdx]}}级审核
          <span class="auditAuth-title-delete el-icon-circle-close" @click="removeAuditInfo(auditLevelIdx)" v-if="auditLevelIdx==auditInfos.length-1"></span>
          <span class="auditAuth-field-auth el-icon-setting" @click="showFieldAuth(auditLevelIdx+1)"></span>
        </span>
      </div>
      <div class="auditAuth-radio">
        <el-radio v-model="auditInfo.auditAuthType" :label="auditAuthTypeIdx+1" @change="onChangeEdit" v-for="(type,auditAuthTypeIdx) in auditAuthType" :key="auditAuthTypeIdx">{{type}}</el-radio>
      </div>
      <div class="dept-admin" v-if="auditInfo.auditAuthType== '2'" style="text-align: left;">
        <ul v-if="auditInfo.selectAllAuditArr && auditInfo.selectAllAuditArr.length > 0" class="select-list" @click="onAuditSelectMemberClick(auditInfo)">
          <li v-for="(item,itemIdx) in auditInfo.selectAllAuditArr" class="select-item" :key="itemIdx">
            <template v-if="item.type == 'member'">
              <svg-icon icon-class="memberIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
            </template>
            <template v-if="item.type == 'dept'">
              <svg-icon icon-class="deptIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
            </template>
            <template v-if="item.type == 'role'">
              <svg-icon icon-class="roleIcon" style="width: 20px; height: 20px; vertical-align: -5px; margin-right: 5px;" />
            </template>
            <span>{{item.name}}</span>
          </li>
        </ul>
        <div class="designated-member" @click="onAuditSelectMemberClick(auditInfo)" v-else>
          <span class="member-empty">
            <svg-icon icon-class="add" style="width: 20px; height: 20px; margin-right: 5px; color: #5e6d82; vertical-align: -5px;" />点击添加指定成员
          </span>
        </div>
      </div>
      <div class="dept-admin" v-if="auditInfo.auditAuthType == '3'" style="justify-content: center;">
        <el-radio v-model="auditInfo.auditAuthAgencyLeaderLevel" :label="leaderIdx+1" @change="onChangeEdit" v-for="(leaderLevel,leaderIdx) in auditAuthAgencyLeaderLevel" :key="leaderIdx">{{leaderLevel}}级部门主管</el-radio>
      </div>
      <div class="dept-admin" v-if="auditInfo.auditAuthType == '4'" style="justify-content: center;">
        <setAuditBySelect :value="auditInfo" :fields="exceptUserSelectField" v-if="exceptUserSelectField.length>0" :fieldList="exceptUserSelectField" :agencyId="agencyId" :auditIndex="auditLevelIdx" @change="fieldConditionsChanged" :isUserSelect="false"></setAuditBySelect>
        <div class="auditSelectEmpty" v-else>表单中必须包含必填的单选或者下拉字段<br><span @click="reCheckField">重新检查</span></div>
      </div>
      <div class="dept-admin" v-if="auditInfo.auditAuthType == '5'" style="justify-content: center;">
        <setAuditByUserSelect :value="auditInfo" :fields="onlyUserSelectField" v-if="onlyUserSelectField.length>0" :fieldList="onlyUserSelectField" :agencyId="agencyId" :auditIndex="auditLevelIdx" @userSelectFieldSelect="fieldUserSelectChanged" :isUserSelect="true"></setAuditByUserSelect>
        <div class="auditSelectEmpty" v-else>表单中必须包含用户选择字段<br><span @click="reCheckField">重新检查</span></div>
      </div>
    </div>
    <!--新版 3.0.0.1 一级审核人设置---指定成员可填写-->
    <el-dialog title="部门成员列表" :visible="auditMemberSelectShow" :modal-append-to-body="false" width="650px" :show-close="false">
      <div class="fx_member_select">
        <ul class="select-list">
          <li v-for="(item, index) in selectAllAuditArr" :key="index" class="select-item">
            <template v-if="item.type == 'member'">
              <svg-icon icon-class="memberIcon" style="width: 20px; height: 20px; vertical-align: -4px; margin-right: 5px;" />
            </template>
            <template v-if="item.type == 'dept'">
              <svg-icon icon-class="deptIcon" style="width: 20px; height: 20px; vertical-align: -4px; margin-right: 5px;" />
            </template>
            <template v-if="item.type == 'role'">
              <svg-icon icon-class="roleIcon" style="width: 20px; height: 20px; vertical-align: -4px; margin-right: 5px;" />
            </template>
            <span>{{item.name}}</span>
            <span class="remove-btn" @click="onAuditRemoveSelect(item, index)">
              <svg-icon icon-class="del" style="width: 12px; height: 12px; vertical-align: -1px;" />
            </span>
          </li>
        </ul>
        <div class="select-menu">
          <div class="select-btn" @click="onAuditSelectTabClick(1)" :class="{select:auditCur===1}">成员</div>
          <div class="select-btn" @click="onAuditSelectTabClick(2)" :class="{select:auditCur===2}">部门</div>
          <div class="select-btn" @click="onAuditSelectTabClick(3)" :class="{select:auditCur===3}">角色</div>
          <div class="select-search-pane" v-if="auditCur===1" :style="{'width':(isAuditFocus ? '100%' : '114px')}">
            <input type="text" v-model="memberParams.searchTxt" placeholder="搜索" @change="onViewChangeSearchTxt" @focus="onViewFocusSearchTxt" />
            <svg-icon icon-class="del" style="width: 12px; height: 12px; position: absolute; right: 20px; top: 12px; cursor: pointer" @click="onViewClearSearchTxt" v-if="memberParams.searchTxt != ''" />
          </div>
        </div>
        <div class="select-pane">
          <div class="member-pane" v-show="auditCur===1">
            <div class="select-department fl">
              <div class="depart-root" @click="onViewMemberClick">全部成员</div>
              <div class="x-department-tree">
                <el-tree :data="auditDeptOptions" :props="deptTreeProps" @node-click="onHandleNodeClick"></el-tree>
              </div>
            </div>
            <div class="member-wrapper fr" v-if="memberList && memberList.length > 0">
              <div class="select-all-item">
                <div class="count-label">已选 <span class="check-count">{{selectMemberLen}}</span>/{{memberList.length}}</div>
                <div class="check-all x-check">
                  <i class="icon-blank"></i>
                </div>
              </div>
              <div class="select-member">
                <ul>                  
                  <li style="background: #ebf8f7">
                    <span class="user-name fl">姓名</span>
                    <span class="user-name fl">编号</span>
                    <span class="user-name fl">部门</span>
                    <div class="select-check fr">
                    </div>
                  </li>
                  <li v-for="(item,index) in memberList" :key="index" @click="onAuditCheckBoxUser(index)">
                    <span class="user-name fl">{{item.name}}</span>
                    <span class="user-name fl">{{item.number}}</span>
                    <span class="user-name fl">{{(item.agencyUserDeptNames||[]).join(",")}}</span>
                    <div class="select-check fr" :class="{select:item.checked}">
                      <i class="icon-blank"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="member-wrapper fr" v-else>
              <span class="empty-tips">没有可选成员</span>
            </div>
          </div>
          <div class="member-pane" v-show="auditCur===2">
            <div class="tree-wrapper">
              <el-tree :data="auditDeptOptions" :props="deptTreeProps" show-checkbox :check-strictly="true" @check-change="onAuditCheckChange" ref="auditDeptTree" node-key="agencyDeptId"></el-tree>
            </div>
          </div>
          <div class="member-pane" v-show="auditCur===3">
            <div class="tree-wrapper">
              <el-tree :data="auditRoleOptions" :props="roleTreeProps" show-checkbox :check-strictly="true" @check-change="onAuditRoleCheckChange" ref="auditRoleTree" node-key="agencyRoleId"></el-tree>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: -20px;">
        <el-button @click="onAuditCancel">取 消</el-button>
        <el-button type="primary" @click="onAuditConfirm">确 定</el-button>
      </div>
      <div class="fx_member_close" @click="onAuditClose">
        <svg-icon icon-class="del" style="width: 16px; height: 16px; vertical-align:middle;" />
      </div>
    </el-dialog>

    <el-drawer title="字段权限设置" :visible.sync="drawer" :append-to-body="true" :modal-append-to-body="true" :before-close="onSaveFieldAuth">
      <div class="fieldWidgetDrawer">
        <el-form :model="fieldAuth">
          <el-form-item :label="widget.label" label-width="140px" v-for="(widget,index) in authWidgetList" :key="index">
            <el-checkbox-group :value="getFieldAuth(widget.name)" @input="(val)=>setFieldAuth(widget.name, val)">
              <!-- <el-checkbox :label="2">只读</el-checkbox>
              <el-checkbox :label="3">隐藏</el-checkbox> -->
              <el-checkbox :label="-2">可编辑</el-checkbox>
              <el-checkbox :label="-3">显示</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div class="drawer-footer">
        <el-button type="default" @click="onCancelFieldAuth">关闭</el-button>
        <el-button type="primary" @click="onSaveFieldAuth">确定</el-button>
      </div>
    </el-drawer>

  </div>
</template>
<script>
import { agencyDeptList, agencyRoleList, agencyFillUserList, addAllFillUser, agencyUserList, agencyUserMemberList } from '@/api/system/agency'
import { existUnReview } from '@/api/system/form'
import { deepClone } from '@/utils/index'
import setAuditBySelect from './setAuditBySelect.vue';
import setAuditByUserSelect from './setAuditBySelect.vue';

function fn (option) {
  var json = {}
  var arr = []
  option.forEach((item) => {
    if (!json[item.name]) {
      json[item.name] = true
      arr.push(item)
    }
  })
  return arr
}
export default {
  components: {
    setAuditBySelect,
    setAuditByUserSelect
  },
  props: {
    agencyId: {
      type: String | Number
    },
    // fieldList:{
    //     type:Array,
    //     default:()=>[]
    // },
    getFormField: {
      type: Function
    },
    formId: {
      type: String
    }
  },
  computed: {
    selectMemberLen () {
      const member = this.memberList.filter((item) => item.checked)
      return fn(member).length
    },
    exceptUserSelectField: function () {
      return this.fieldList.filter(x => x.type != "user-select")
    },
    onlyUserSelectField: function () {
      return this.fieldList.filter(x => x.type == "user-select")
    }
  },
  data () {
    return {
      auditAuthAgencyLeaderLevel: ['一', '二', '三', '四', '五'],
      auditAuthType: ["创建人审核全部", "指定成员审核", "部门管理员审核", "根据选项设置审核人", "根据用户选择字段设置审核人"],
      auditInfos: [],

      // 所有成员列表
      memberParams: {
        agencyId: null,
        searchTxt: ''
      },
      memberList: [],
      fieldList: [],
      auditDeptOptions: [],
      auditRoleOptions: [],

      //选择器
      auditMemberSelectShow: false,
      //选择的人员、部门、角色
      selectAllAuditArr: [],
      // 新版 3.0.0.1 填报人设置---选择的所有填报人
      selectAllFillArr: [],
      auditCur: 1,
      isAuditFocus: false,

      isAuditConfirmBtn: false,
      deptTreeProps: {
        children: 'children',
        label: 'deptName'
      },
      roleTreeProps: {
        children: 'children',
        label: 'roleName'
      },
      currentAuditLevel: 1,
      drawer: false,
      authWidgetList: [],
      fieldAuth: {},
      fieldAuthLevelIdx: 0
    }
  },
  mounted () {
    this.fieldList = this.getFormField()
  },
  methods: {
    onAuditAddClick () {
      if (this.auditInfos.length >= 3) {
        this.$message({
          type: 'warning',
          message: '最多设置三级审核'
        });
        return;
      }

      this.auditInfos.push({
        auditAuthType: null,
        auditLevel: (this.auditInfos.length + 1),
        // auditAuthAgencyDeptId: "",
        // auditAuthAgencyRoleId: "",
        // auditAuthAgencyUserId: "",
        // auditAuthAgencyLeaderLevel: "",
        // auditAuthFieldList: {
        //   auditAuthAgencyDeptId: "",
        //   auditAuthAgencyRoleId: "",
        //   auditAuthAgencyUserId: "",
        //   fieldValue: "",
        //   formFieldId: "",
        // },
        // auditUserFieldId: "",
        // selectAllAuditArr: []
      })
      this.$emit("change");
    },
    onAuditSelectMemberClick (auditInfo) {
      this.selectAllAuditArr = auditInfo.selectAllAuditArr || [];

      this.auditMemberSelectShow = true
      this.memberParams.searchTxt = ''
      this.memberParams.agencyDeptId = undefined
      this.isAuditFocus = false



      this.getListDept(auditInfo.auditLevel)
      this.getListMember(auditInfo.auditLevel)
      this.getListRole(auditInfo.auditLevel)

      this.currentAuditLevel = auditInfo.auditLevel
      this.$emit('change')
    },
    onAuditRemoveSelect (item, index) {
      this.selectAllAuditArr.splice(index, 1)
      if (item.type == 'member') {
        const findIndex = this.memberList.findIndex((value) => value.name == item.name)
        this.memberList[findIndex].checked = !this.memberList[findIndex].checked
      }
      if (item.type == 'dept') {
        this.$refs.auditDeptTree.setChecked(item.agencyDeptId, false, false)
      }
      if (item.type == 'role') {
        this.$refs.auditRoleTree.setChecked(item.agencyRoleId, false, false)
      }
    },

    //标记修改过了
    onChangeEdit () {
      this.isSetEdit = true
      this.$emit("change");
    },


    onViewMemberClick () {
      this.memberParams.agencyDeptId = undefined
      this.getListMember()
    },


    // 请求组织架构列表
    getListDept (auditLevel) {
      const _this = this
      agencyDeptList().then((response) => {
        const treeList = response.data

        // 审核人 选中状态
        _this.auditDeptOptions = _this.handleTree(treeList, 'agencyDeptId')

        if (!_this.isBlank(_this.selectAllAuditArr)) {
          const auditArr = []
          for (let j = 0; j < _this.selectAllAuditArr.length; j++) {
            for (let k = 0; k < treeList.length; k++) {
              if (_this.selectAllAuditArr[j].agencyDeptId == treeList[k].agencyDeptId) {
                auditArr.push(_this.selectAllAuditArr[j].agencyDeptId)
                _this.$refs.auditDeptTree.setCheckedKeys(auditArr)
              }
            }
          }
        }
      })
    },
    getDeptTree (tree = []) {
      const arr = []
      if (tree.length !== 0) {
        tree.forEach((item) => {
          const obj = {}
          obj.label = item.deptName
          obj.id = item.agencyDeptId
          if (item.children) {
            obj.children = this.getDeptTree(item.children)
          }
          arr.push(obj)
        })
      }
      return arr
    },
    // 请求成员列表
    getListMember (auditLevel) {
      const _this = this
      const agencyId = !_this.isBlank(_this.memberParams.agencyId) ? _this.memberParams.agencyId : _this.agencyId
      agencyUserMemberList(agencyId, _this.memberParams.searchTxt, _this.memberParams.agencyDeptId).then((response) => {
        if (response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].checked = false
          }
          _this.memberList = response.data

          if (!_this.isBlank(_this.selectAllAuditArr)) {
            for (let j = 0; j < _this.selectAllAuditArr.length; j++) {
              for (let k = 0; k < _this.memberList.length; k++) {
                if (_this.selectAllAuditArr[j].agencyUserId == _this.memberList[k].agencyUserId) {
                  _this.memberList[k].checked = true
                }
              }
            }
          }
        } else {
          _this.msgError(response.msg)
        }
      })
    },
    // 请求角色列表
    getListRole (auditLevel) {
      const _this = this
      agencyRoleList().then((response) => {
        const treeList = response.data

        _this.auditRoleOptions = _this.handleTree(treeList, 'agencyRoleId')
        if (!_this.isBlank(_this.selectAllAuditArr)) {
          const auditArr = []
          for (let j = 0; j < _this.selectAllAuditArr.length; j++) {
            for (let k = 0; k < treeList.length; k++) {
              if (_this.selectAllAuditArr[j].agencyRoleId == treeList[k].agencyRoleId) {
                auditArr.push(_this.selectAllAuditArr[j].agencyRoleId)
                _this.$refs.auditRoleTree.setCheckedKeys(auditArr)
              }
            }
          }
        }

      })
    },
    onAuditSelectTabClick (index) {
      this.auditCur = index
    },

    removeAuditInfo (index) {

      existUnReview(this.formId).then(res => {
        if (res.data == true) {
          this.msgError('已存在未审核的单据，不可删除审核人')
        } else {
          this.$emit("change");
          this.auditInfos.splice(index, 1)
          this.fieldAuth = {}
        }
      })
    },



    onViewChangeSearchTxt () {
      this.getListMember()
    },
    onViewFocusSearchTxt () {
      this.isAuditFocus = true
    },
    onViewClearSearchTxt () {
      if (!this.isBlank(this.memberParams.searchTxt)) {


        this.isAuditFocus = false
        this.memberParams.searchTxt = ''
        this.getListMember()
      }
    },
    onAuditCancel () {
      this.clearTree();
      this.auditMemberSelectShow = false
    },
    onAuditConfirm () {
      this.isAuditConfirmBtn = true

      let auditInfo = this.auditInfos.find(info => info.auditLevel == this.currentAuditLevel)
      auditInfo.selectAllAuditArr = this.selectAllAuditArr

      auditInfo.auditAuthAgencyUserId =
        auditInfo.selectAllAuditArr.filter(item => item.type == 'member').map(item => item.agencyUserId).toString()

      auditInfo.auditAuthAgencyDeptId =
        auditInfo.selectAllAuditArr.filter(item => item.type == 'dept').map(item => item.agencyDeptId).toString()

      auditInfo.auditAuthAgencyRoleId =
        auditInfo.selectAllAuditArr.filter(item => item.type == 'role').map(item => item.agencyRoleId).toString()

      // if(auditInfo.auditAuthAgencyUserId=="") delete auditInfo.auditAuthAgencyUserId;
      // if(auditInfo.auditAuthAgencyDeptId=="") delete auditInfo.auditAuthAgencyDeptId;
      // if(auditInfo.auditAuthAgencyRoleId=="") delete auditInfo.auditAuthAgencyRoleId;

      this.clearTree();

      this.$emit("change");
      this.auditMemberSelectShow = false
    },
    onAuditClose () {
      this.clearTree();
      this.auditMemberSelectShow = false
    },
    clearTree () {
      this.selectAllAuditArr = [];
      this.memberList = [];
      this.auditDeptOptions = [];
      this.auditRoleOptions = [];
      this.auditCur = 1;
    },

    // 组织架构树形节点点击
    onHandleNodeClick (data) {
      this.memberParams.agencyDeptId = data.agencyDeptId
      this.getListMember()
    },
    onAuditCheckBoxUser (index) {
      this.memberList[index].checked = !this.memberList[index].checked
      if (this.memberList[index].checked) {
        this.selectAllAuditArr.push({
          type: 'member',
          name: this.memberList[index].name,
          agencyUserId: this.memberList[index].agencyUserId
        })
      } else {
        const findIndex = this.selectAllAuditArr.findIndex((value) => value.name == this.memberList[index].name)
        if (findIndex != -1) {
          this.selectAllAuditArr.splice(findIndex, 1)
        }
      }
    },
    onAuditCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllAuditArr.push({
          type: 'dept',
          name: data.deptName,
          agencyDeptId: data.agencyDeptId,
          level: data.level
        })
      } else {
        const findIndex = this.selectAllAuditArr.findIndex((value) => value.agencyDeptId == data.agencyDeptId)
        if (findIndex != -1) {
          this.selectAllAuditArr.splice(findIndex, 1)
        }
      }
    },
    onAuditRoleCheckChange (data, checked, indeterminate) {
      if (checked) {
        this.selectAllAuditArr.push({
          type: 'role',
          name: data.roleName,
          agencyRoleId: data.agencyRoleId
        })
      } else {
        const findIndex = this.selectAllAuditArr.findIndex((value) => value.agencyRoleId == data.agencyRoleId)
        if (findIndex != -1) {
          this.selectAllAuditArr.splice(findIndex, 1)
        }
      }
    },
    onAuditRemoveSelect (item, index) {
      this.selectAllAuditArr.splice(index, 1)
      if (item.type == 'member') {
        const findIndex = this.memberList.findIndex((value) => value.name == item.name)
        this.memberList[findIndex].checked = !this.memberList[findIndex].checked
      }
      if (item.type == 'dept') {
        this.$refs.auditDeptTree.setChecked(item.agencyDeptId, false, false)
      }
      if (item.type == 'role') {
        this.$refs.auditRoleTree.setChecked(item.agencyRoleId, false, false)
      }
    },
    reCheckField () {
      this.fieldList = this.getFormField()
      this.msgSuccess('字段刷新完成')
    },
    setConfig (config) {
      this.auditInfos = [];
      if (!config.auditInfos) config.auditInfos = [];
      config.auditInfos.forEach(info => {
        let item = {};
        item.auditAuthType = info.auditAuthType;
        item.auditLevel = info.auditLevel;

        if (info.auditAuthType == 2) {
          item.auditAuthAgencyDeptId = (info.auditAuthAgencyDeptList || []).map(item => item.agencyDeptId).toString();
          item.auditAuthAgencyRoleId = (info.auditAuthAgencyRoleList || []).map(item => item.agencyRoleId).toString();
          item.auditAuthAgencyUserId = (info.auditAuthAgencyUserList || []).map(item => item.agencyUserId).toString();
          item.selectAllAuditArr =
            [
              ...(info.auditAuthAgencyUserList || []).map(item => {
                return {
                  type: 'member',
                  number: item.agencyUserNumber,
                  name: item.agencyUserName,
                  agencyUserId: item.agencyUserId
                }
              }),
              ...(info.auditAuthAgencyDeptList || []).map(item => {
                return {
                  type: 'dept',
                  name: item.agencyDeptName,
                  agencyUserId: item.agencyDeptId
                }
              }),
              ...(info.auditAuthAgencyRoleList || []).map(item => {
                return {
                  type: 'role',
                  name: item.agencyRoleName,
                  agencyUserId: item.agencyRoleId
                }
              })
            ]
        } else if (info.auditAuthType == 3) {
          item.auditAuthAgencyLeaderLevel = info.auditAuthAgencyLeaderLevel
        } else if (info.auditAuthType == 4) {
          item.auditAuthFieldList = info.auditAuthFieldList.map(x => {
            return {
              formFieldId: x.formFieldId,
              fieldValue: x.fieldValue,
              auditAuthAgencyDeptId: (x.auditAuthAgencyDeptList || []).map(x => x.agencyDeptId).toString(),
              auditAuthAgencyRoleId: (x.auditAuthAgencyRoleList || []).map(x => x.agencyRoleId).toString(),
              auditAuthAgencyUserId: (x.auditAuthAgencyUserList || []).map(x => x.agencyUserId).toString(),
              selectAllAuditArr:
                [
                  ...(x.auditAuthAgencyUserList || []).map(item => {
                    return {
                      type: 'member',
                      number: item.agencyUserNumber,
                      name: item.agencyUserName,
                      agencyUserId: item.agencyUserId
                    }
                  }),
                  ...(x.auditAuthAgencyDeptList || []).map(item => {
                    return {
                      type: 'dept',
                      name: item.agencyDeptName,
                      agencyUserId: item.agencyDeptId
                    }
                  }),
                  ...(x.auditAuthAgencyRoleList || []).map(item => {
                    return {
                      type: 'role',
                      name: item.agencyRoleName,
                      agencyUserId: item.agencyRoleId
                    }
                  })
                ]
            }
          })
        } else if (item.auditAuthType == 5) {
          item.auditUserFieldId = info.auditUserFieldId;
        }

        this.auditInfos.push(item);
      })
    },
    getConfig () {
      return { auditInfos: this.auditInfos, fieldAuths: this.fieldAuths };
    },
    // 设置字段权限
    setFieldAuthConfig (fieldAuths) {
      this.fieldAuths = [];

      if (fieldAuths)
        this.fieldAuths = fieldAuths;
    },

    fieldConditionsChanged (fieldConfig) {
      this.auditInfos[fieldConfig.index].auditAuthFieldList = [];
      this.auditInfos[fieldConfig.index].auditAuthFieldList = fieldConfig.fieldConditions;
      this.onAuditConfirm();
      this.$emit("change");
      this.$forceUpdate();
    },

    fieldUserSelectChanged (fieldConfig) {
      this.auditInfos[fieldConfig.index].auditAuthFieldList = [];
      this.auditInfos[fieldConfig.index].auditUserFieldId = fieldConfig.auditUserFieldId;
      this.$emit("change");
      this.$forceUpdate();
    },

    getFormFieldList () {
      const formJson = this.$parent.$parent.$refs.vformDesigner.getFormJson();
      this.authWidgetList.splice(0)

      formJson.widgetList.forEach(wgt => {
        if (['html-text'].find(x => x === wgt.type)) {
          return;
        }
        let auth = [];
        if (wgt.options.disabled) auth.push(2)
        if (wgt.options.hidden) auth.push(3)
        this.authWidgetList.push({
          name: wgt.options.name,
          label: wgt.options.label,
          auth
        })

        if (wgt.type === 'sub-form') {
          wgt.widgetList.forEach(subWgt => {
            auth = [];
            if (subWgt.options.disabled) auth.push(2)
            if (subWgt.options.hidden) auth.push(3)

            this.authWidgetList.push({
              name: subWgt.options.name,
              label: subWgt.options.label,
              auth
            })
          })
        }
      })
    },
    showFieldAuth (auditLevelIdx) {
      this.drawer = true;
      let findFieldAuth = this.fieldAuths.find(x => x.level == auditLevelIdx)
      if (findFieldAuth) {
        this.fieldAuth = deepClone(findFieldAuth.fieldAuth);
      } else {
        this.fieldAuth = {};
      }
      this.fieldAuthLevelIdx = auditLevelIdx;

      this.getFormFieldList();
      if (!this.fieldAuth || Object.keys(this.fieldAuth).length == 0) {
        this.fieldAuth = {};
        this.authWidgetList.forEach(x => {
          if (!this.fieldAuth[x.name]) {
            this.$set(this.fieldAuth, x.name, x.auth);
          }
        })
      }
      this.$forceUpdate()
    },
    onSaveFieldAuth () {
      this.drawer = false;
      let findFieldAuth = this.fieldAuths.find(x => x.level == this.fieldAuthLevelIdx)
      if (findFieldAuth) {
        findFieldAuth.fieldAuth = this.fieldAuth;
      } else {
        this.fieldAuths.push({
          fieldAuth: this.fieldAuth,
          level: this.fieldAuthLevelIdx
        })
      }
      this.$emit("change");
    },
    onCancelFieldAuth () {
      this.drawer = false;
    },
    onFieldAuthChange (widget, val) {
      console.log(val);
    },
    getFieldAuth (widgetName) {
      let auth = [];
      if(!this.fieldAuth[widgetName].includes(2)){
        auth.push(-2);
      }
      if(!this.fieldAuth[widgetName].includes(3)){
        auth.push(-3);
      }
      return auth;      
      // if (this.fieldAuth[widgetName])
      //   return this.fieldAuth[widgetName];
      // else
      //   return []
    },
    setFieldAuth(widgetName,val){
      let tmp=[];
      if(!val.includes(-2)){
        tmp.push(2)
      }
      if(!val.includes(-3)){
        tmp.push(3)
      }


      this.$set(this.fieldAuth,widgetName,tmp);
    }
  },
}
</script>
<style lang="scss" scoped>

::v-deep .el-dialog__header{
  text-align: left;
}

.form-set-pane {
  border-left: 1px solid #e7eaf1;
  box-sizing: border-box;
  flex: 1;
  -webkit-flex: 1;
  padding: 20px;
}
.review-flow-pane {
  width: 100%;
  margin: 20px auto;
  text-align: center;
  .node-wrap-container {
    width: 100%;
    margin-top: 20px;
    .auditAuth-title {
      font-size: 15px;
      margin-bottom: 20px;

      &:hover .auditAuth-title-delete,
      &:hover .auditAuth-field-auth {
        display: inline-block;
      }
    }
    .auditAuth-title-text {
      height: 30px;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      .auditAuth-title-delete {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        margin-left: 10px;
        color: #aaaaaa;
        font-size: 12px;
        user-select: none;
        cursor: pointer;
        display: none;
        position: absolute;
        right: -40px;
        top: 5px;
        color: white;
        font-size: 20px;
        padding: 0px;
        padding-top: 1px;
        background-color: rgb(217, 94, 94);
      }
      .auditAuth-field-auth {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        margin-left: 10px;
        color: #aaaaaa;
        font-size: 12px;
        user-select: none;
        cursor: pointer;
        display: none;
        position: absolute;
        right: -15px;
        top: 5px;
        color: white;
        font-size: 20px;
        padding: 0px;
        padding-top: 1px;
        color: rgb(217, 94, 94);
      }
    }
  }
}
.dept-admin {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  margin-top: 20px;
  .auditSelectEmpty {
    width: 100%;
    line-height: 30px;
    color: #666666;
    border: 1px solid #e0e0e0;
    padding: 10px 0px;
    font-size: 14px;
    span {
      cursor: pointer;
      font-size: 12px;
      color: #989898;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.designated-member {
  width: 100%;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  .member-empty {
    cursor: pointer;
    line-height: 78px;
    text-align: center;
    color: #989898;
    display: block;
    font-size: 14px;
  }
}

/*新版 3.0.0.1 填报人设置、查看人设置---指定成员可填写*/
.fx_member_select {
  margin-top: -30px;
  text-align: left;
  .select-menu {
    position: relative;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #e0e0e0;
    .select-btn {
      text-align: center;
      display: inline-block;
      cursor: pointer;
      line-height: 38px;
      height: 38px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 5px;
      margin: 0 10px;
      &.select {
        color: #3476f0;
        border-bottom: solid 4px #3476f0;
      }
    }
    .select-search-pane {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 114px;
      background: #fff;
      padding: 0 10px;
      box-sizing: border-box;
      transition: width 0.2s;
      input {
        line-height: 26px !important;
        height: 26px !important;
        width: 100%;
        border: 0;
        background: #f4f4f4;
        border-radius: 13px;
        margin-top: 6px;
        padding: 0 10px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: 0;
        &:focus {
          border: none;
          outline: 0;
        }
      }
    }
  }
  .select-pane {
    border: 1px solid #e0e0e0;
    border-top: none;
    height: 320px;
    .member-pane {
      .select-department {
        border-right: solid 1px #e0e0e0;
        width: 35%;
        box-sizing: border-box;
        height: 320px;
        .depart-root {
          background: #ebf8f7;
          text-indent: 10px;
          line-height: 30px;
          cursor: pointer;
        }
        .x-department-tree {
          height: 290px;
          overflow-y: auto;
        }
      }
      .member-wrapper {
        width: 65%;
        .empty-tips {
          display: inline-block;
          margin: 10px;
          color: #989898;
        }
        .select-all-item {
          cursor: pointer;
          height: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: space-between;
          -ms-flex-pack: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          padding: 0 8px 0 12px;
          border-bottom: solid 1px #e9e9e9;
          .count-label {
            color: #3476f0;
          }
        }
        .select-member {
          height: 290px;
          overflow-y: auto;
          ul {
            margin: 0;
            padding: 0;
            li {
              cursor: pointer;
              padding: 0 10px 0 10px;
              position: relative;
              line-height: 30px;
              width: 100%;
              box-sizing: border-box;
              display:flex;
              justify-content: space-between;
              align-items: center;
              &:hover {
                background: #f5f7fa;
              }
              .user-name {
                width: 28%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .select-check {
                cursor: pointer;
                width: 16px;
                .icon-blank {
                  display: inline-block;
                  width: 14px;
                  height: 14px;
                  border: 1px solid #91a1b7;
                  position: relative;
                  background: #fff;
                  -webkit-transition: all 218ms ease-in-out;
                  -moz-transition: all 218ms ease-in-out;
                  -o-transition: all 218ms ease-in-out;
                  transition: all 218ms ease-in-out;
                  &::before {
                    content: '';
                    display: block;
                    opacity: 0;
                    -webkit-transition: opacity 218ms ease-in-out;
                    -moz-transition: opacity 218ms ease-in-out;
                    -o-transition: opacity 218ms ease-in-out;
                    transition: opacity 218ms ease-in-out;
                    height: 6px;
                    width: 10px;
                    border-left: solid 2px #fff;
                    border-bottom: solid 2px #fff;
                    position: absolute;
                    top: 2px;
                    left: 1px;
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                  }
                }
                &.select {
                  .icon-blank {
                    border-color: #3476f0;
                    background: #3476f0;
                    &::before {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .tree-wrapper {
        width: 100%;
        height: 320px;
        box-sizing: border-box;
        overflow-y: auto;
        padding-top: 10px;
      }
    }
  }
}
.fx_member_close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.select-list {
  width: 100%;
  height: 80px;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
  overflow: auto;
  padding: 0;
  .select-item {
    display: inline-block;
    line-height: 30px;
    margin: 5px 0 0 5px;
    padding: 0 10px;
    border-radius: 1px;
    background: #f3f6fc;
    list-style: none;
    cursor: pointer;
    .remove-btn {
      margin-left: 10px;
      padding: 3px;
    }
  }
}

.audit-form-item {
  line-height: 40px !important;
}

.fieldWidgetDrawer {
  height: calc(100% - 50px);
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  .el-checkbox-group {
    display: flex;
  }
  .el-form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ::v-deep .el-form-item__label {
      line-height: 20px;
      flex-shrink: 0;
    }

    ::v-deep .el-form-item__content {
      width: 100%;
      margin-left: 20px !important;
    }
  }
  .el-form-item--small .el-radio {
    line-height: 20px;
    margin-bottom: 0px;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 15px !important;
  }
}
.drawer-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0px 20px;
  }
}
</style>
