<template>
  <div class="auditSelect">
    <div class="auditProcessWrapper">
      <div class="auditProcessItemTitle">
        可选项
      </div>
      <div class="auditProcessItemUser">
        <el-select placeholder="请选择" v-model="selectField" @change="onSelectField">
          <el-option v-for="(item,index) in fields" :key="index" :label="item.label" :value="item.formFieldId"></el-option>
        </el-select>
      </div>
      <div class="auditProcessItemUser" style="width:200px;text-align:right;flex-shrink: 0;">
          <el-button type="primary" @click="onExportClick">导出</el-button>
          <el-button type="primary" @click="onImportClick">导入</el-button>
      </div>
    </div>
    <div>
      <div class="auditProcessWrapper" v-for="(option, index) in selectFieldOptions" :key="index">
        <div class="auditProcessItemTitle">
          选项 {{ option.label }} 的{{tip}}
        </div>
        <div class="auditProcessItemUser">
          <div class="designated-member" v-if="!option.auditMember||option.auditMember.length==0" @click="selectProcessor(option)">
            <span class="member-empty">
              <svg-icon icon-class="add" style="
                  width: 20px;
                  height: 20px;
                  margin-right: 5px;
                  color: #5e6d82;
                  vertical-align: -5px;
                " />点击添加指定成员
            </span>
          </div>
          <ul class="select-list" v-else @click="selectProcessor(option)">
            <li v-for="(item, index) in option.auditMember" :key="index" class="select-item">
              <template v-if="item.type == 'member'">
                <svg-icon icon-class="memberIcon" style="
                      width: 20px;
                      height: 20px;
                      vertical-align: -4px;
                      margin-right: 5px;
                    " />
              </template>
              <template v-if="item.type == 'dept'">
                <svg-icon icon-class="deptIcon" style="
                      width: 20px;
                      height: 20px;
                      vertical-align: -4px;
                      margin-right: 5px;
                    " />
              </template>
              <template v-if="item.type == 'role'">
                <svg-icon icon-class="roleIcon" style="
                      width: 20px;
                      height: 20px;
                      vertical-align: -4px;
                      margin-right: 5px;
                    " />
              </template>
              <span>{{ item.name }}({{ item.number }})</span>
              <span class="remove-btn" @click.stop="onAudit1RemoveSelect(option.auditMember,item, index)">
                <svg-icon icon-class="del" style="width: 12px; height: 12px; vertical-align: -1px" />
              </span>
            </li>

          </ul>
        </div>
      </div>
    </div>
    <memberSelect ref="memberSelect" :agencyId="agencyId"></memberSelect>
    <el-dialog title="导入" append-to-body :visible.sync="dialogVisible" width="30%">
      <div style="text-align: left;">
      <el-upload
        class="upload-demo"
        ref="upload"
        action="#"
        :auto-upload="false">
        <el-button slot="trigger" type="primary">选取文件</el-button>
        <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
      </el-upload>
      </div>
      <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="importAndClose">导入并关闭</el-button>
    <el-button @click="cancelImport">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import memberSelect from './setAuditMemberSelect.vue'
import { getProvince } from '@/api/system/config.js'
import { deepClone } from '@/utils/deepClone.js'
import XLSX from 'xlsx' // 引入excel库
import { agencyUserList } from '@/api/system/agency';

export default {
  components: {
    memberSelect
  },
  props: {
    fields: Array,
    isUserSelect: {
      type: Boolean,
      default: false,
    },

    agencyId: {
      type: String|Number
    },
    fieldList: {
      type: Array
    },
    auditIndex: {
      type: Number
    },
    value: {
      type: Object,
      default: () => { }
    },
    tip: {
      type: String,
      default: '审核人'
    }
  },
  data () {
    return {
      selectField: "",
      selectFieldType: "",
      selectFieldOptions: [],
      dialogVisible: false,
      agencyUserList: [],
      queryParams: {
        number: ''
      }
    }
  },
  watch: {
    value: function (val) {
      this.reloadFieldOptions();
    },
    fieldList: function () {
      this.reloadFieldOptions();
    }
  },
  mounted () {
    this.reloadFieldOptions();
  },
  methods: {
    async onSelectField (value) {
      const optionItem = deepClone( this.fieldList.find((item) => item.formFieldId == value))

      this.selectFieldType = optionItem.type;
      if (optionItem.type == "region" || optionItem.type == "address") {
        let provice = await getProvince();
        this.selectFieldOptions = provice.data.map(x => {
          return {
            label: x,
            value: x
          }
        })
        // this.selectFieldOptions.forEach(item => {
        //   item.auditMember = this.value.auditAuthFieldList.find(x => x.fieldValue == item.value).selectAllAuditArr
        // })
      }
      else if (optionItem.type == "user-select") {
        this.$emit("userSelectFieldSelect", {
          index: this.auditIndex,
          auditUserFieldId: value
        });
        this.selectFieldOptions = []
      }
      else {
        if (!this.isBlank(optionItem)) {
          this.selectFieldOptions = optionItem.filterOptions
        }
      }
    },
    selectProcessor (option) {
      this.$refs.memberSelect.show(option.auditMember, (auditors) => {
        option.auditMember = auditors
        this.handleValueChange();
        this.$forceUpdate()
      })
    },
    onAudit1RemoveSelect (list, item, index) {
      list.splice(index, 1)
      this.handleValueChange();
      this.$forceUpdate();
    },
    handleValueChange () {

      let fieldConditions = [];
      this.selectFieldOptions.forEach(item => {

        fieldConditions.push({
          formFieldId: this.selectField,
          fieldValue: item.value,
          auditAuthAgencyDeptId: (item.auditMember || []).filter(x => x.type == 'dept').map(x => x.agencyDeptId).toString(),
          auditAuthAgencyRoleId: (item.auditMember || []).filter(x => x.type == 'role').map(x => x.agencyRoleId).toString(),
          auditAuthAgencyUserId: (item.auditMember || []).filter(x => x.type == 'member').map(x => x.agencyUserId).toString(),
        })
      })
      this.$emit("change", {
        index: this.auditIndex,
        fieldConditions: fieldConditions
      });
      
    },
    reloadFieldOptions () {
      if (this.tip === '审核人') {
        if (this.value.auditAuthType == 4) {
          if(this.value.auditAuthFieldList &&this.value.auditAuthFieldList.length>0){
            this.selectField = this.value.auditAuthFieldList[0].formFieldId
            this.onSelectField(this.value.auditAuthFieldList[0].formFieldId);
          }

          this.selectFieldOptions.forEach(item => {
            item.auditMember = this.value.auditAuthFieldList.find(x => x.fieldValue == item.value).selectAllAuditArr
          })
        } else if (this.value.auditAuthType == 5) {
          this.selectField = this.value.auditUserFieldId
        }
      } else {  // 管理员
        if (this.value.viewAuthFieldList && this.value.viewAuthFieldList.length > 0) {
          this.selectField = this.value.viewAuthFieldList[0].formFieldId
          this.onSelectField(this.value.viewAuthFieldList[0].formFieldId);
        }

        this.selectFieldOptions.forEach(item => {
          item.auditMember = this.value.viewAuthFieldList.find(x => x.fieldValue == item.value).selectAllAuditArr
        })
        this.$forceUpdate();
      }
    },
    onExportClick () {
      const data = this.selectFieldOptions.map(item => ({
        选项: item.label,
        处理人: (item.auditMember || []).map(member => `${member.name}(${member.number})`).join(', ')
      }))
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, '选项审核人员')
      XLSX.writeFile(wb, '选项审核人员导出.xlsx')
    },
    onImportClick () {
      this.dialogVisible = true;
    },
    async getAgencyUserList () {
      try {
        const response = await agencyUserList(this.queryParams);
        if (response.code == 200) {
          if(!response.rows) response.rows=[];
          for (let i = 0; i < response.rows.length; i++) {
            response.rows[i].showBtn = false;
          }
          this.agencyUserList.splice(0);
          response.rows.forEach(r => {
            this.agencyUserList.push(r);
          });
          this.total = response.total;
        } else {
          this.msgError(response.msg);
        }
      } catch (error) {
        console.error('Error fetching agency user list:', error);
      }
    },
    importAndClose() {
    if (this.$refs.upload.uploadFiles.length > 0) {
      this.handleFileChange(this.$refs.upload.uploadFiles[0]);
    }
    this.dialogVisible = false;
    this.$refs.upload.clearFiles();
  },

  cancelImport() {
    this.dialogVisible = false;
    this.$refs.upload.clearFiles();
  },
    async handleFileChange (file, fileList) {
      if (file.raw.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);
          let agencyUserNumbers = json.map(row => {
            const names = row['处理人'].split(/[，,]/g);
            return names.flat().filter(Boolean).map(x => {
              const parts = x.split(/[\(\)（）]/g);
              return parts[1] ? parts[1].trim() : '';
            }).filter(Boolean);
          }).filter(x=>x).flat();

          // agencyUserNumbers 去重
          agencyUserNumbers = [...new Set(agencyUserNumbers)];
          this.queryParams.pageNum= 1;
          this.queryParams.pageSize = 10000;
          delete this.queryParams.number;
          this.queryParams.numbers = agencyUserNumbers;
          await this.getAgencyUserList();

          json.forEach((row,index) => {
            const option = this.selectFieldOptions.find(opt => opt.label === row['选项']);
            if (option) {
              option.auditMember = row['处理人'].split(/[，,]/g).map(name => {
                if (name) {
                  const [agencyUserName, agencyUserNumber] = name.split(/[\(\)（）]/g);
                  const user = this.agencyUserList.find(u => u.number === agencyUserNumber.trim());
                  return {
                    type: 'member',
                    name: agencyUserName ? agencyUserName.trim() : user ? user.name : '',
                    number: agencyUserNumber ? agencyUserNumber.trim() : '',
                    agencyUserId: user ? user.agencyUserId : ""
                  };
                } else {
                  return null;
                }
              }).filter(x => x);
            }
          });
          this.handleValueChange();
          this.$forceUpdate();
        };
        reader.readAsArrayBuffer(file.raw);
      } else {
        this.$message.error('请上传有效的Excel文件');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.auditSelect {
  cursor: pointer;
  width: 100%;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  line-height: 20px;
  text-align: left;
  color: #989898;
  display: block;
  font-size: 14px;
  padding: 20px;
  box-sizing: border-box;
}
.auditProcessWrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  min-height:40px;
  .auditProcessItemTitle {
    display: inline-block;
    width: 270px;
    flex-shrink: 0;
  }

  .auditProcessItemUser {
    display: inline-block;
    width: 100%;
    flex-shrink: 1;
  }
}

.select-list {
  width: 100%;
  // height: 80px;
  line-height: 40px;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  box-sizing: border-box;
  .select-item {
    display: inline-block;
    line-height: 30px;
    margin: 5px 0 0 5px;
    padding: 0 10px;
    border-radius: 1px;
    background: #f3f6fc;
    list-style: none;
    cursor: pointer;
    .remove-btn {
      margin-left: 10px;
      padding: 3px;
    }
  }
}

ul.select-list {
  margin: 0;
  padding: 0;
  li {
    cursor: pointer;
    padding: 0 10px 0 10px;
    position: relative;
    line-height: 30px;
    box-sizing: border-box;
    &:hover {
      background: #ebf8fb;
    }
    .user-name {
      width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .select-check {
      cursor: pointer;
      width: 16px;
      .icon-blank {
        display: inline-block;
        width: 14px;
        height: 14px;
        border: 1px solid #91a1b7;
        position: relative;
        background: #fff;
        -webkit-transition: all 218ms ease-in-out;
        -moz-transition: all 218ms ease-in-out;
        -o-transition: all 218ms ease-in-out;
        transition: all 218ms ease-in-out;
        &::before {
          content: '';
          display: block;
          opacity: 0;
          -webkit-transition: opacity 218ms ease-in-out;
          -moz-transition: opacity 218ms ease-in-out;
          -o-transition: opacity 218ms ease-in-out;
          transition: opacity 218ms ease-in-out;
          height: 6px;
          width: 10px;
          border-left: solid 2px #fff;
          border-bottom: solid 2px #fff;
          position: absolute;
          top: 2px;
          left: 1px;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
      &.select {
        .icon-blank {
          border-color: #409eff;
          background: #409eff;
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
