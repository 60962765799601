<template>
  <div class="tags_input">
    <draggable v-model="list" v-bind="{draggable:'.tag-list',animation:500}" style="display:flex;padding:5px 0px;overflow-y:hidden;width:100%">
      <el-dropdown size="mini" class="tag-list" v-for="(tag,index) in list" :key="index" trigger="click" style="display:inline-block" @command="handleCommand">
        <span class="el-dropdown-link">
          <el-tag size="mini" closable :disable-transitions="false" @close="handleClose(tag)" :effect="tag.type=='symbol'?'plain':'light'">
            {{tag.label}}
          </el-tag>
        </span>
        <el-dropdown-menu slot="dropdown" v-if="tag.type=='symbol'">
          <el-dropdown-item :command="{item:item,tag:tag}" v-for="(item,index) in deepClone(symbolList)" :key="index">{{item.label}}</el-dropdown-item>
        </el-dropdown-menu>
        <el-dropdown-menu slot="dropdown" v-if="tag.type=='form'||tag.type =='system'">
          <el-dropdown-item :command="{item:item,tag:tag}" v-for="(item,index) in deepClone(fieldList)" :key="index">{{item.label}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-select size="mini" style="width:100px;height:20px;margin-top:-4px;" class="flex-input" value-key="name" v-model="selectValue" @change="add" placeholder="请选择">
        <el-option-group label="(连接符)">
          <el-option v-for="(item,index) in symbolList" :key="index" :value="item" :label="item.label"></el-option>
        </el-option-group>
        <el-option-group label="(字段)">
          <el-option v-for="(item,index) in deepClone(fieldList)" :key="index" :value="item" :label="item.label"></el-option>
        </el-option-group>
      </el-select>
    </draggable>
  </div>
</template>

<script>
import { deepClone } from '@/utils/index';
import draggable from 'vuedraggable';
export default {
  name: 'exportName-editor',
  components: { draggable },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    widgetList: {
      type: Array,
      default: () => [],
    },
    systemFieldList: {
      type: Array,
      default: () => [],
    }
  },
  watch: {
    watchProps: {
      deep: true,
      handler: function () {
        this.refresWidgetList(true);
      }
    },
    value:{
      deep:true,
      handler:function(){
        this.list = [];
        if(this.value){
          this.value.forEach(i=>{
            this.list.push(i) ;
          })
        }
      },
      immediate:true
    }
  },
  computed: {
    watchProps: function () {
      return [this.widgetList, this.systemFieldList];
    }
  },
  mounted () {
    // if (!this.value) {
    //   this.list = this.value;
    // }
    this.refresWidgetList(true);

  },
  data () {
    return {
      selectValue: {},
      list: [],
      placeholder: '',
      deepClone: deepClone,
      symbolList: [
        { label: '空格', name: ' ', type: 'symbol' },
        { label: '-', name: '-', type: 'symbol' },
        { label: '_', name: '_', type: 'symbol' },
        { label: '+', name: '+', type: 'symbol' },
      ],
      fieldList: [],
    };
  },
  methods: {
    add (item) {
      let self = this;
      self.list.push(item);
      self.selectValue = '';
      self.$emit('input', self.list);
    },
    handleClose (tag) {
      let self = this;
      self.list.splice(self.list.indexOf(tag), 1);
    },
    refresWidgetList (show) {
      if (show) {
        let allowdTypes = [
          'name',
          'mail',
          'mobile',
          'idcard',
          'gender',
          'nationality',
          'number',
          'input',
          'radio',
          'date',
          'select',
        ];
        this.fieldList = [];
        this.widgetList.forEach((x) => {
          if (allowdTypes.indexOf(x.type) >= 0) {
            this.fieldList.push({
              name: x.name,
              label: x.displayName,
              type: 'form',
            });
          }
        });

        const exceptText=['导出人','导出时间','填报人一级部门','填报人二级部门','填报人三级部门','填报人四级部门','填报人五级部门','一级审核人','二级审核人','三级审核人','一级审核意见','二级审核意见','三级审核意见','一级审核人签字','二级审核人签字','三级审核人签字','数据状态'];
        this.systemFieldList.forEach((x) => {
          if(exceptText.indexOf(x.field)<0)
          this.fieldList.push({
            name: x.field,
            label: x.field,
            type: 'system',
          });
        });

      }
    },
    handleCommand (command) {
      console.log(command);
      command.tag.label = command.item.label;
      command.tag.name = command.item.name;
    }
  },
};
</script>

<style lang="scss" scope="scoped">
.tags_input {
  .el-dropdown {
    margin-right: 5px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    height: 20px;
    cursor: pointer;
    //   float: left;
  }
}
.tags_input {
  width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
}
.tags_input .el-input__inner {
  border: none;
  padding: 0 10px;
  width: auto;
}

.tags_input {
  padding: 0px 7px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.tags_input:hover {
  // border: 1px solid #1989fa;
}
.flex-input {
  //   float: left;
}
::v-deep .el-tag {
  user-select: none;
}

.tags_input {
  /* 添加阴影效果 */
  ::-webkit-scrollbar {
    height: 5px;
  }

  /* 改变颜色 */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* 改变宽度 */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    height: 5px;
  }
}
</style>