var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags_input" },
    [
      _c(
        "draggable",
        _vm._b(
          {
            staticStyle: {
              display: "flex",
              padding: "5px 0px",
              "overflow-y": "hidden",
              width: "100%",
            },
            model: {
              value: _vm.list,
              callback: function ($$v) {
                _vm.list = $$v
              },
              expression: "list",
            },
          },
          "draggable",
          { draggable: ".tag-list", animation: 500 },
          false
        ),
        [
          _vm._l(_vm.list, function (tag, index) {
            return _c(
              "el-dropdown",
              {
                key: index,
                staticClass: "tag-list",
                staticStyle: { display: "inline-block" },
                attrs: { size: "mini", trigger: "click" },
                on: { command: _vm.handleCommand },
              },
              [
                _c(
                  "span",
                  { staticClass: "el-dropdown-link" },
                  [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          size: "mini",
                          closable: "",
                          "disable-transitions": false,
                          effect: tag.type == "symbol" ? "plain" : "light",
                        },
                        on: {
                          close: function ($event) {
                            return _vm.handleClose(tag)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(tag.label) + " ")]
                    ),
                  ],
                  1
                ),
                tag.type == "symbol"
                  ? _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      _vm._l(
                        _vm.deepClone(_vm.symbolList),
                        function (item, index) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: index,
                              attrs: { command: { item: item, tag: tag } },
                            },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }
                      ),
                      1
                    )
                  : _vm._e(),
                tag.type == "form" || tag.type == "system"
                  ? _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      _vm._l(
                        _vm.deepClone(_vm.fieldList),
                        function (item, index) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: index,
                              attrs: { command: { item: item, tag: tag } },
                            },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }
                      ),
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "el-select",
            {
              staticClass: "flex-input",
              staticStyle: {
                width: "100px",
                height: "20px",
                "margin-top": "-4px",
              },
              attrs: {
                size: "mini",
                "value-key": "name",
                placeholder: "请选择",
              },
              on: { change: _vm.add },
              model: {
                value: _vm.selectValue,
                callback: function ($$v) {
                  _vm.selectValue = $$v
                },
                expression: "selectValue",
              },
            },
            [
              _c(
                "el-option-group",
                { attrs: { label: "(连接符)" } },
                _vm._l(_vm.symbolList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { value: item, label: item.label },
                  })
                }),
                1
              ),
              _c(
                "el-option-group",
                { attrs: { label: "(字段)" } },
                _vm._l(_vm.deepClone(_vm.fieldList), function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { value: item, label: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }