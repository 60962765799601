<template>
    <el-row :gutter="20" style="margin: 0">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding: 0;padding-right:20px;box-sizing:border-box">
            <el-form ref="setForm" label-width="130px" class="clearfix form-set-base" label-position="left">
                <el-form-item label="提交成功提示">
                    <el-switch v-model="customTip.commitTipShow" :active-value="1" :inactive-value="0" active-color="#3476F0">
                    </el-switch>
                </el-form-item>
            </el-form>
            <RichEditor :agencyId="agencyId" :formId="formId" uploadPath="fillFile/{agencyId}/{formId}/commitTip/" v-model="customTip.commitTipContent" v-if="customTip.commitTipShow" @count="commitTipWordCount"></RichEditor>

            <el-form ref="setForm" label-width="130px" class="clearfix form-set-base" label-position="left">
                <el-form-item label="支付成功提示">
                    <el-switch v-model="customTip.payTipShow" :active-value="1" :inactive-value="0" active-color="#3476F0">
                    </el-switch>
                </el-form-item>
            </el-form>
            <RichEditor :agencyId="agencyId" :formId="formId" uploadPath="fillFile/{agencyId}/{formId}/payTip/" v-model="customTip.payTipContent" v-if="customTip.payTipShow" @count="payTipWordCount"></RichEditor>
        </el-col>
    </el-row>
</template>
<script>
import RichEditor from '@/components/RichEditor'
export default {
    components:{
        RichEditor
    },
    props:{
        value:Object,
        agencyId:"",
        formId:"",
    },
    watch:{
        value:function(val){
            this.customTip=val;
        }
    },
    data(){
        return {
            customTip:{
                commitTipShow: false,
                commitTipContent: "",
                commitTipWordCount:"",
                payTipShow: false,
                payTipContent: "",
                payTipWordCount:"",
            }
        }
    },
    mounted() {
        this.customTip=this.value;
    },
    methods:{
        commitTipWordCount(count){
            this.customTip.commitTipWordCount=count;
        },
        payTipWordCount(count){
            this.customTip.payTipWordCount=count;
        }
    }
}
</script>
<style scoped>
</style>
