<template>
  <div style="display:inline">
    <el-tooltip class="item" effect="light" :content="tipText" placement="top">
      <i class="el-icon-question"></i>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "HelpWidget",
  props: {
    tipText: {
      type: String,
      required: true,
    },
  },
};
</script>